import React from "react";
import { Button, TooltipIcon } from "carbon-components-react";
import { Edit16, Information16 } from "@carbon/icons-react";

const ProjectButtons = ({
    projectId,
    projectDescription,
    editProjectClick,
}) => {
    return (
        <div className="top-right">
            <TooltipIcon
                direction="top"
                id={`tooltip-${projectId}`}
                data-testid={`tooltip-${projectId}`}
                tooltipText={
                    projectDescription === "" ? "No Description" : projectDescription
                }
                className="project-tile-icon-button-dashboard no-border"
            >
                <Information16 />
            </TooltipIcon>
            <Button
                id="edit-project-tile-button"
                data-testid="edit-project-tile-button"
                renderIcon={Edit16}
                className="project-tile-icon-button-dashboard no-border"
                iconDescription="Edit Project Name"
                hasIconOnly
                onClick={editProjectClick}
                tooltipPosition="top"
            />
        </div>
    );
};

export default ProjectButtons;
