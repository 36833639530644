import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import { verifySMSMFA } from "../../redux/thunks/thunks.actions.js";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const AccordionMFASMS = ({
    mfaSMSAccordionBorder,
    isMfaSMSAccordionOpen,
    isMfaSMSFinished,
    setMfaSMSCode,
    mfaSMSCode,
}) => {
    const dispatch = useDispatch();
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);
    const [isValidateMFAButtonDisabled, setIsValidateMFAButtonDisabled] =
        useState(true);

    const handleMfaAuthentication = () => {
        dispatch(verifySMSMFA(mfaSMSCode));
    };

    useEffect(() => {
        verificationCodeConforms
            ? setIsValidateMFAButtonDisabled(false)
            : setIsValidateMFAButtonDisabled(true);
    }, [verificationCodeConforms]);

    return (
        <Accordion
            data-testid="mfa-sms-accordion"
            className={`${mfaSMSAccordionBorder} accordion-style`}
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isMfaSMSFinished ? (
                            <div
                                data-testid="mfa-sms-accordion-circle"
                                className={`circle ${
                                    isMfaSMSAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="mfa-sms-accordion-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>MFA Authentication</p>
                    </div>
                }
                disabled={true}
                open={isMfaSMSAccordionOpen}
            >
                <div
                    className="field-margin-bottom"
                    data-testid="mfa-sms-accordion-text"
                >
                    Please enter the code sent to the phone number associate with
                    this account
                </div>
                <div
                    className="field-margin-bottom"
                    data-testid="mfa-sms-accordion-verification-code"
                >
                    <InputFieldVerificationCode
                        setVerificationCode={setMfaSMSCode}
                        verificationCode={mfaSMSCode}
                        setVerificationCodeConforms={setVerificationCodeConforms}
                        verificationCodeConforms={verificationCodeConforms}
                        labelText={"MFA code"}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={isValidateMFAButtonDisabled}
                    onClickFunction={handleMfaAuthentication}
                    buttonDescription={"Validate MFA Code"}
                    dataTestId={"mfa-sms-accordion-validate-mfa-code"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionMFASMS;
