import { Folder32 } from "@carbon/icons-react";
import { SideNavMenu } from "carbon-components-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FileItem from "./file-explorer.file";
import { refCaller as importedRefCaller } from "./file-explorer.folder";

/**
 * Used to more easily mock ref
 * @returns fref current
 */
export const refCaller = (ref) => {
    return ref.current;
};

const FolderItem = ({
    selectedProjectInfo,
    projectName,
    filter,
    clickedFileId,
    handleProjectClick,
    closeFolders,
    closeFoldersReset,
}) => {
    const fref = useRef();

    const [showFolder, setShowFolder] = useState(true);
    const [filteredProjectInfo, setFilteredProjectInfo] = useState([]);

    /**
     * Upon clicking a file in a project
     * the dashboard's contents changes to that project
     */
    const handleFileClick = () => {
        handleProjectClick(selectedProjectInfo);
    };

    /**
     * When user clicks Folder name to expand it they will
     * be taken to the corresponding project
     *
     * event.nativeEvent.pointerId is to check that this was not triggered by filtering
     * @param {*} event
     */
    const folderClick = (event) => {
        if (
            importedRefCaller(fref).ariaExpanded === "false" &&
            event.nativeEvent.pointerId !== -1
        ) {
            handleProjectClick(selectedProjectInfo);
        }
    };

    /**
     * Opens all the folders
     * @returns
     */
    const openAllFolders = useCallback(() => {
        let isExpanded = importedRefCaller(fref).attributes.getNamedItem("aria-expanded").nodeValue === "true";

        if (filter.length !== 0 && !isExpanded) {
            importedRefCaller(fref).click();
        }
    }, [filter.length]);

    /**
     * Closes all the folders
     * @returns
     */
    const closeAllFolders = () => {
        let isExpanded = importedRefCaller(fref).attributes.getNamedItem("aria-expanded").nodeValue === "true";

        if (isExpanded) {
            importedRefCaller(fref).click();
        }
    };

    /**
     * Filters the selectedProjectInfo based on the filter prop
     */

    const filterFolders = useCallback(() => {
        let filteredProjects = selectedProjectInfo.files.filter((fileInfo) => {
            let fileItemKey = [selectedProjectInfo.name, fileInfo.name].join(
                "/"
            );
            if (fileItemKey.toLowerCase().includes(filter.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        });

        let projectNameContainsFilter = selectedProjectInfo.name
            .toLowerCase()
            .includes(filter.toLowerCase());

        if (projectNameContainsFilter) {
            setShowFolder(true);
        } else {
            if (filteredProjects.length === 0) {
                setShowFolder(false);
            } else {
                setShowFolder(true);
            }
        }

        setFilteredProjectInfo(filteredProjects);
    }, [filter, selectedProjectInfo.files, selectedProjectInfo.name]);

    // Opens all folders when using filter
    useEffect(() => {
        openAllFolders();
    }, [filter, openAllFolders]);

    // Closes all folders when clicking collapse all
    useEffect(() => {
        if (closeFolders) {
            closeAllFolders();
            closeFoldersReset();
        }
    }, [closeFolders, closeFoldersReset]);

    useEffect(() => {
        filterFolders();
    }, [selectedProjectInfo, filter, filterFolders]);

    return (
        <div
            id={`${selectedProjectInfo.project_id}-folder`}
            data-testid={`${selectedProjectInfo.project_id}-folder`}
            className={`${showFolder ? "" : "display-none"} side-nav-project`}
            onClick={folderClick}
        >
            <SideNavMenu
                renderIcon={Folder32}
                title={projectName}
                key={[projectName, "SideNavMenu"].join("/")}
                ref={fref}
            >
                {filteredProjectInfo.map((fileInfo) => (
                    <div
                        key={[
                            selectedProjectInfo.name,
                            fileInfo.name,
                            "FolderItem",
                        ].join("/")}
                        className="side-nav-file"
                    >
                        <FileItem
                            fileName={fileInfo.name}
                            fileId={fileInfo.file_id}
                            key={[selectedProjectInfo.name, fileInfo.name].join(
                                "/"
                            )}
                            handleFileClick={handleFileClick}
                            clickedFileId={clickedFileId}
                        />
                    </div>
                ))}
            </SideNavMenu>
        </div>
    );
};

export default FolderItem;
