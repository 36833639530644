import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const AccordionMFA = ({
    mfaAccordionBorder,
    isMfaAccordionOpen,
    isMfaFinished,
    setMfaCode,
    handleMfaAuthentication,
    mfaCode,
}) => {
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);
    const [isValidateMFAButtonDisabled, setIsValidateMFAButtonDisabled] =
        useState(true);

    useEffect(() => {
        verificationCodeConforms
            ? setIsValidateMFAButtonDisabled(false)
            : setIsValidateMFAButtonDisabled(true);
    }, [verificationCodeConforms]);

    return (
        <Accordion
            data-testid="mfa-accordion"
            className={`${mfaAccordionBorder} accordion-style`}
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isMfaFinished ? (
                            <div
                                data-testid="mfa-accordion-circle"
                                className={`circle ${
                                    isMfaAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="mfa-accordion-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>MFA Authentication</p>
                    </div>
                }
                disabled={true}
                open={isMfaAccordionOpen}
            >
                <div
                    className="field-margin-bottom"
                    data-testid="mfa-accordion-text"
                >
                    Please enter the code generated by the MFA Authenticator App
                    of your choice.
                </div>
                <div className="field-margin-bottom">
                    <InputFieldVerificationCode
                        setVerificationCode={setMfaCode}
                        verificationCode={mfaCode}
                        setVerificationCodeConforms={
                            setVerificationCodeConforms
                        }
                        verificationCodeConforms={verificationCodeConforms}
                        labelText={"MFA code"}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={isValidateMFAButtonDisabled}
                    onClickFunction={handleMfaAuthentication}
                    buttonDescription={"Validate MFA Code"}
                    dataTestId={"mfa"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionMFA;
