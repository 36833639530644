import {
    CheckmarkFilled16,
    ErrorFilled16,
    InformationFilled16,
    Launch16,
    Warning32,
    WarningFilled16,
} from "@carbon/icons-react";
import { ToastNotification } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import "./status-notification.styles.scss";

const StatusNotification = ({
    showNotification,
    fileNotes,
    fileWarnings,
    fileValidationErrors,
    fileState,
    fileId,
    projectId,
    iconType,
    setButtonDescription,
    setIcon,
    setIconType,
    toggleNotification,
}) => {
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationDescription, setNotificationDescription] = useState([]);
    const [showConfidence, setShowConfidence] = useState(false);

    const [warnings, setWarnings] = useState([]);
    const [notes, setNotes] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (fileWarnings) {
            setWarnings(fileWarnings);
        }
        if (fileNotes) {
            setNotes(fileNotes);
        }
        if (fileValidationErrors) {
            setErrors(fileValidationErrors);
        }
    }, [fileWarnings, fileNotes, fileValidationErrors]);

    useEffect(() => {
        if (warnings.length !== 0 && notes.length !== 0) {
            setShowConfidence(false);
        } else if (warnings.length !== 0) {
            setShowConfidence(true);
        }
    }, [notes.length, warnings.length]);


    useEffect(() => {
        const setStatusNotification = () => {
            if (fileState === "GridSaved") {
                setIconType("success");
                setButtonDescription("Reprocess file to see correct results");
                setIcon(Warning32);
            } else {
                if (
                    warnings.length !== 0 &&
                    errors.length !== 0 &&
                    notes.length !== 0
                ) {
                    setButtonDescription(
                        "Unresolved errors, Low Confidence and Notes"
                    );

                    const notification = [
                        "The output data has not passed validation.",
                        `${errors} validation failures.`,
                    ];

                    setIconType("error");
                    setNotificationTitle(
                        "Manual Review Required and Validation Errors"
                    );
                    setNotificationDescription(
                        warnings.concat(notification).concat(notes)
                    );
                    setIcon(ErrorFilled16);
                } else if (warnings.length !== 0 && errors.length !== 0) {
                    setButtonDescription("Unresolved errors and Low Confidence");

                    const notification = [
                        "The output data has not passed validation.",
                        `${errors} validation failures.`,
                    ];

                    setIconType("error");
                    setNotificationTitle(
                        "Manual Review Required and Validation Errors"
                    );
                    setNotificationDescription(warnings.concat(notification));
                    setIcon(ErrorFilled16);
                } else if (warnings.length !== 0 && notes.length !== 0) {
                    setButtonDescription("Low Confidence and Notes");

                    setIconType("warning");
                    setNotificationTitle("Manual Review Required");
                    setNotificationDescription(warnings.concat(notes));
                    setIcon(WarningFilled16);
                } else if (errors.length !== 0 && notes.length !== 0) {
                    setButtonDescription("Unresolved errors and Notes");

                    const notification = [
                        "The output data has not passed validation.",
                        `${errors} validation failures.`,
                    ];

                    setIconType("error");
                    setNotificationTitle(
                        "Manual Review Required and Validation Errors"
                    );
                    setNotificationDescription(notification.concat(notes));
                    setIcon(ErrorFilled16);
                } else if (warnings.length !== 0 && notes.length !== 0) {
                    setButtonDescription("Unresolved errors and Low Confidence");

                    const notification = [
                        "The output data has not passed validation.",
                        `${errors} validation failures.`,
                    ];

                    setIconType("error");
                    setNotificationTitle(
                        "Manual Review Required and Validation Errors"
                    );
                    setNotificationDescription(warnings.concat(notification));
                    setIcon(ErrorFilled16);
                } else if (errors.length !== 0) {
                    setButtonDescription("Unresolved errors");
                    const notification = [
                        "The output data has not passed validation.",
                        `${errors} validation failures.`,
                    ];

                    setIconType("error");
                    setNotificationTitle("Validation failure");
                    setNotificationDescription(notification);
                    setIcon(ErrorFilled16);
                } else if (warnings.length !== 0) {
                    setButtonDescription("Low Confidence");
                    setIconType("warning");
                    setNotificationTitle("Manual Review Required");
                    setNotificationDescription(warnings);
                    setIcon(WarningFilled16);
                } else if (notes.length !== 0) {
                    setButtonDescription("Processed File - Review Notes");
                    setIconType("note");
                    setNotificationTitle("Mask Notes");
                    setNotificationDescription(notes);
                    setIcon(InformationFilled16);
                } else {
                    setIconType("success");
                    setButtonDescription("Processed File");
                    setIcon(CheckmarkFilled16);
                }
            }
        };

        setStatusNotification();
    }, [
        notes,
        warnings,
        errors,
        fileState,
        setButtonDescription,
        setIconType,
        setIcon,
    ]);

    return (
        <>
            {showNotification ? (
                <div tabIndex="0">
                    <ToastNotification
                        className="unset-position fade-in-top"
                        data-testid={`${iconType}-notification-${fileId}`}
                        id={`${iconType}-notification-${fileId}`}
                        title={notificationTitle}
                        // kind={iconType}
                        kind={iconType === "note" ? "success" : iconType}
                        onCloseButtonClick={toggleNotification}
                        iconDescription={`Close the ${iconType} notification.`}
                        subtitle={notificationDescription.map((value, index) => {
                            return (
                                <p className="validation-text" key={index}>
                                    {value}
                                </p>
                            );
                        })}
                        caption={
                            <>
                                {errors.length === 0 ? null : (
                                    <a
                                        href={`results/${projectId}/${fileId}`}
                                        className="notification-link"
                                        data-testid={`resolve-results-link-${fileId}`}
                                        id={`resolve-results-link-${fileId}`}
                                    >
                                        <p className="validation-text">
                                            <Launch16 className="resolve" />
                                            {" Resolve Validation Errors"}
                                        </p>
                                    </a>
                                )}
                                {!showConfidence ? null : (
                                    <a
                                        href={`mask/${projectId}/${fileId}`}
                                        className="notification-link"
                                        data-testid={`resolve-mask-link-${fileId}`}
                                        id={`resolve-mask-link-${fileId}`}
                                    >
                                        <p className="validation-text">
                                            <Launch16 className="resolve" />
                                            {" Resolve Confidence Errors"}
                                        </p>
                                    </a>
                                )}
                                {notes.length === 0 ? null : (
                                    <a
                                        href={`mask/${projectId}/${fileId}`}
                                        className="notification-link"
                                        data-testid={`notes-mask-link-${fileId}`}
                                        id={`notes-mask-link-${fileId}`}
                                    >
                                        <p className="validation-text">
                                            <Launch16 className="resolve" />
                                            {" Review Mask"}
                                        </p>
                                    </a>
                                )}
                            </>
                        }
                        lowContrast
                    />
                </div>
            ) : null}
        </>
    );
};
export default StatusNotification;
