import types from "./file-details.types";

export const addFileDetails = (fileDetails) => {
    return {
        type: types.addFileDetails,
        payload: fileDetails,
    };
};

export const clearFileDetails = () => {
    return {
        type: types.clearFileDetails,
    };
};

export const updateAddedFileDetails = (fileDetails, projectId, fileId) => {
    return {
        type: types.updatedAddedFileDetails,
        payload: fileDetails,
        projectId: projectId,
        fileId: fileId,
    };
};

export const setRedirectionDetails = (redirectionDetails) => {
    return { type: types.setRedirectionDetails, payload: redirectionDetails };
};

export const clearRedirectionDetails = () => {
    return { type: types.clearRedirectionDetails };
};

export const updateFileDetails = (updateFileDetails) => {
    return { type: types.updateFileDetails, payload: updateFileDetails };
};

export const clearUpdateFileDetails = () => {
    return { type: types.clearUpdateFileDetails };
};
