import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TermsAndConditionsText from "../../components/accordion/terms-conditions-text.component";
import { acceptTermsAndConditions } from "../../redux/thunks/thunks.actions.js";
import "./accordion.styles.scss";

const AccordionTermsAndConditions = ({
    termsAndConditionsAccordionBorder,
    areTermsAndConditionsFinished,
    areTermsAndConditionsAccordionOpen,
}) => {
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

    const onClick = () => {
        dispatch(acceptTermsAndConditions());
    };

    useEffect(() => {
        if (isChecked) {
            setContinueButtonDisabled(false);
        } else {
            setContinueButtonDisabled(true);
        }
    }, [isChecked]);

    return (
        <Accordion
            className={`${termsAndConditionsAccordionBorder} accordion-style`}
            data-testid="terms-and-conditions-accordion"
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!areTermsAndConditionsFinished ? (
                            <div
                                data-testid="terms-and-conditions-circle"
                                className={`circle ${
                                    areTermsAndConditionsAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="terms-and-conditions-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>Terms and Conditions</p>
                    </div>
                }
                disabled={true}
                open={areTermsAndConditionsAccordionOpen}
            >
                <div className="terms-conditions field-margin-bottom">
                    <TermsAndConditionsText
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        continueButtonDisabled={continueButtonDisabled}
                        onClick={onClick}
                    />
                </div>
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionTermsAndConditions;
