import React from "react";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import InputFieldEmail from "./input-fields-email.component";
import InputFieldName from "./input-fields-name.component";
import InputFieldPassword from "./input-fields-password.component";
import "./input-fields.styles.scss";

const RegisterUserAllFields = ({
    setUser,
    user,
    setEmailConforms,
    emailConforms,
    setPass,
    pass,
    passwordConforms,
    setDisplayPasswordRequirements,
    displayPasswordRequirements,
    setPasswordConforms,
    handleRegisterNewUser,
    accountInfoNextButtonDisabled,
    setName,
    name,
    className,
    inputFieldPasswordId,
    buttonDescription,
    buttonId,
    inputFieldNamedId,
}) => {
    return (
        <>
            <div className={className}>
                <InputFieldName
                    name={name}
                    setName={setName}
                    inputFieldNamedId={inputFieldNamedId}
                />
            </div>
            <div className={className}>
                <InputFieldEmail
                    setUser={setUser}
                    user={user}
                    setEmailConforms={setEmailConforms}
                    emailConforms={emailConforms}
                    disabledField={name === "" ? true : false}
                />
            </div>
            <div className={className}>
                <InputFieldPassword
                    setPass={setPass}
                    pass={pass}
                    setPasswordConforms={setPasswordConforms}
                    passwordConforms={passwordConforms}
                    setDisplayPasswordRequirements={
                        setDisplayPasswordRequirements
                    }
                    displayPasswordRequirements={displayPasswordRequirements}
                    user={user}
                    emailConforms={emailConforms}
                    stopOnFocusEvent={false}
                    id={inputFieldPasswordId}
                />
            </div>
            <div className={className}>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={accountInfoNextButtonDisabled}
                    onClickFunction={handleRegisterNewUser}
                    buttonDescription={buttonDescription}
                    dataTestId={buttonId}
                />
            </div>
        </>
    );
};

export default RegisterUserAllFields;
