import React, { useCallback, useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { scrollIntoViewHelper } from "../../../helpers/helper-functions.js";
import { refImageCaller as importedRefImageCaller } from "./results-pdf-image.component";

export const refImageCaller = (ref) => {
    return ref.current;
};

const ResultsPagePdfImage = ({
    imageToScrollTo,
    imageId,
    imageSource,
    imageDataTestId,
    splitViewVertical,
    splitViewHorizontal,
    setImageToScrollTo,
    setZoomInFunction,
    setZoomOutFunction,
    setResetTransformFunction,
}) => {
    const imageRef = useRef();
    const [transNode, setTransNode] = useState(null);
    // const [isIntersecting, setIntersecting] = useState(false);
    // const [rootMargin, setRootMargin] = useState("-50% 0px");

    const onMouseDownEvent = useCallback(
        (e) => {
            e.preventDefault();
            setImageToScrollTo(imageId);
        },
        [setImageToScrollTo, imageId]
    );

    // const isImageVisibleCheck = useCallback(
    //     (isIntersecting) => {
    //         if (isIntersecting) {

    //             // setImageToScrollTo(imageId);
    //         }
    //     },
    //     [setImageToScrollTo, imageId]
    // );

    const setTransformWrapperReference = useCallback((node) => {
        if (node !== null) {
            setTransNode(node);
        }
    }, []);

    useEffect(() => {
        if (splitViewVertical || splitViewHorizontal) {
            if (imageId === imageToScrollTo) {
                if (imageToScrollTo !== null) {
                    scrollIntoViewHelper(importedRefImageCaller, imageRef);
                    setImageToScrollTo(undefined)
                }
            }
        }
    }, [
        imageToScrollTo,
        setImageToScrollTo,
        imageId,
        splitViewVertical,
        splitViewHorizontal,
        imageRef,
    ]);

    useEffect(() => {
        if (transNode !== null) {
            if (imageId === imageToScrollTo) {
                setZoomInFunction(() => transNode.zoomIn);
                setZoomOutFunction(() => transNode.zoomOut);
                setResetTransformFunction(() => transNode.resetTransform);
            }
        }
    }, [
        imageToScrollTo,
        imageId,
        transNode,
        setZoomOutFunction,
        setZoomInFunction,
        setResetTransformFunction,
    ]);

    // useEffect(() => {
    //     let options = {
    //         // define the viewport line. at 50% of the page
    //         rootMargin: rootMargin,
    //         threshold: 0, // any pixel on the screen
    //     };

    //     const callback = (entries, observer) => {
    //         entries.forEach((entry) => {
    //             setIntersecting(entry.isIntersecting);
    //         });
    //     };

    //     let observer = new IntersectionObserver(callback, options);
    //     observer.observe(importedRefImageCaller(imageRef));
    //     // Remove the observer as soon as the component is unmounted
    //     return () => {
    //         observer.disconnect();
    //     };
    // }, [rootMargin]);

    // useEffect(() => {
    //     if (splitViewVertical) {
    //         setRootMargin("-50% 0px");
    //     } else if (splitViewHorizontal) {
    //         setRootMargin("75% 0px");
    //     }
    // }, [setRootMargin, splitViewVertical, splitViewHorizontal]);

    // useEffect(() => {
    //     console.log("Is image visable useEffect")
    //     isImageVisibleCheck(isIntersecting);
    // }, [isIntersecting, isImageVisibleCheck]);

    return (
        <TransformWrapper
            ref={setTransformWrapperReference}
            doubleClick={{ disabled: true }}
            wheel={{ disabled: true }}
            panning={{ velocityDisabled: true }}
            pinch={{ disabled: true }}
            zoomAnimation={{ disabled: true }}
            alignmentAnimation={{ disabled: true }}
            velocityAnimation={{ disabled: true }}
        >
            {({ positionX, positionY, ...rest }) => (
                <div onMouseDown={(e) => onMouseDownEvent(e)}>
                    <TransformComponent>
                        <img
                            data-testid={`${imageDataTestId}-${imageId}`}
                            ref={imageRef}
                            src={imageSource}
                            alt={`results-pdf-img-${imageId}`}
                        />
                    </TransformComponent>
                </div>
            )}
        </TransformWrapper>
    );
};

export default ResultsPagePdfImage;
