import { ChevronSort32 } from "@carbon/icons-react";
import { Button, Link, Pagination, Row, Loading } from "carbon-components-react";
import PropTypes from "prop-types";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import SortMenu from "../../components/sort-menu/sort-menu.component";
import { removeExtension } from "../../helpers/helper-functions-2.js";
import {
    sortAlphabeticallyAscending,
    sortAlphabeticallyDescending,
    sortDateMostRecent,
    sortDateOldest,
} from "../../helpers/helper-functions.js";

import {
    deleteProjectAction,
    downloadMergedExcelFilesAction,
    projectDownloadMultipleExcelFilesAction,
} from "../../redux/thunks/thunks.actions";
import CommonButtons from "../common-buttons/common-buttons.component";
import FilesDashboard from "../files-dashboard/files-dashboard.component";
import ProjectTile from "../project-tile/project-tile.component";

const Dashboard = ({
    projectsLoading,
    allProjectsInfo,
    selectedProjectInfo,
    lastClickedFileId,
    handleBackClick,
    handleTileClick,
    tileType,
    onToggleProjectRenameModal,
    projectRedirection,
    setProjectModalOpen,
    setFileModalOpen,
    setAllProjects,
    blurButtons,
    homepageNumberOfWarnings,
    isCollapsed,
    setIsCollapsed,
}) => {
    const dispatch = useDispatch();
    const divRef = useRef();
    const tileRef = useRef();

    const [sortedProjects, setSortedProjects] = useState([]);

    const [projectsSelected, setSelectedProject] = useState([]);
    // boolean that toggles to indicate if files have been downloaded
    const [areProjectsDownloaded, setAreProjectsDownloaded] = useState(false);

    const [sortType, setSortType] = useState(JSON.parse(localStorage.getItem('projectsSortType')) || "dateOldest");
    const [showSortMenu, setShowSortMenu] = useState(false);

    const [pageSize, setPageSize] = useState(JSON.parse(localStorage.getItem('projectsPageSize')) || 10);
    const [currentPage, setCurrentPage] = useState(1);

    
    useEffect( () => {
        localStorage.setItem("projectsPageSize", JSON.stringify(pageSize))
    }, [pageSize])
    
    useEffect( () => {
        localStorage.setItem("projectsSortType", JSON.stringify(sortType))
    }, [sortType])

    const [projectsToDisplay, setProjectsToDisplay] = useState([]);

    const handleRemoveProjectSelected = (newObject) => {
        const newSelectedProject = projectsSelected.filter(
            (currentObject) => currentObject.projectId !== newObject.projectId
        );
        setSelectedProject(newSelectedProject);
    };

    const addProjectSelected = (newObject) => {
        const newSelectedProject = [...projectsSelected, newObject];
        setSelectedProject(newSelectedProject);
    };

    const prepareDeleteProjectWarningAlert = (event) => {
        let projectDeleteWarnings = [];
        let arrayProjectNames = [];

        for (let project of projectsSelected) {
            let fileList = [];
            let filesWithinProjectDeletionWarning = null;
            const projectsSelectedId = project.projectId;

            const projectedSelectedObject = allProjectsInfo.filter(
                (proj) => projectsSelectedId === proj.project_id
            )[0];

            const filesInsideProjectSelected = projectedSelectedObject.files;
            const projectSelectedName = projectedSelectedObject.name;

            arrayProjectNames.push(projectSelectedName);

            if (filesInsideProjectSelected.length !== 0) {
                for (let file of filesInsideProjectSelected) {
                    fileList.push(`\n${file.name}`);
                }
                filesWithinProjectDeletionWarning =
                    `\nProject ${projectSelectedName} currently contains the following files:` +
                    `${fileList.join()}`;

                projectDeleteWarnings.push(filesWithinProjectDeletionWarning);
            } else {
                filesWithinProjectDeletionWarning = null;
            }
        }

        let project = "project";
        let files = "";

        if (projectsSelected.length > 1) {
            project = "projects";
        }

        if (projectDeleteWarnings.length !== 0 && projectsSelected.length > 1) {
            files = "and their files?";
        } else if (
            projectDeleteWarnings.length !== 0 &&
            projectsSelected.length === 1
        ) {
            files = "and its files?";
        } else if (
            projectDeleteWarnings.length === 0 &&
            projectsSelected.length === 1
        ) {
            files = "";
        } else {
            files = "";
        }

        let finalProjectDeleteWarning = `Are you sure you wish to delete ${project} ${arrayProjectNames.join(
            ", "
        )} ${files} ${projectDeleteWarnings.join()}?`;

        if (window.confirm(finalProjectDeleteWarning)) {
            deleteSelectedProjects();
        }

        blurButtons();
    };

    const deleteSelectedProjects = (event) => {
        for (let project of projectsSelected) {
            const projectsSelectedId = project.projectId;
            const projectedSelectedObject = allProjectsInfo.filter(
                (proj) => projectsSelectedId === proj.project_id
            )[0];
            let projectSelectedId = projectedSelectedObject.project_id;
            dispatch(deleteProjectAction(projectSelectedId));

            resetDashboardUseStates();
        }
    };

    const resetDashboardUseStates = useCallback(() => {
        setSelectedProject([]);
        setAreProjectsDownloaded(false);
        blurButtons();
    }, [blurButtons]);

    const selectAll = useCallback(() => {
        let allProjects = [];

        allProjectsInfo.forEach((project) => {
            allProjects.push({
                projectName: project["name"],
                projectId: project["project_id"],
            });
        });

        setSelectedProject(allProjects);
        blurButtons();
    }, [allProjectsInfo, blurButtons]);

    const projectDownloadExcelFiles = (excelType) => {
        if (projectsSelected.length === 0) {
            return;
        } else {
            let projectIds = [];

            for (let project of projectsSelected) {
                projectIds.push(project.projectId);
            }

            dispatch(
                projectDownloadMultipleExcelFilesAction(projectIds, excelType)
            );
            setAreProjectsDownloaded(true);
        }
        blurButtons();
    };


    const calculateProjectsToDisplay = useCallback(() => {

        if (sortedProjects) {
            const currentPageProjects = sortedProjects.slice(
                pageSize * (currentPage - 1),
                pageSize * currentPage
            );


            setProjectsToDisplay(currentPageProjects); 
        }
        

    }, [currentPage, pageSize, sortedProjects, setProjectsToDisplay]);

    // const findPageSize = useCallback(() => {
    //     if (tileRef.current && divRef.current) {
    //         setPageSize(1);
    //         let newPageSize =
    //             (divRef.current.offsetHeight * divRef.current.offsetWidth) /
    //             (170 * 170);

    //         newPageSize = Math.floor(newPageSize);

    //         if (newPageSize < 1) {
    //             setPageSize(1);
    //         } else {
    //             setPageSize(newPageSize);
    //         }
    //     }
    // }, [divRef, tileRef]);

    const mergeBankStatements = () => {
        if (projectsSelected.length === 0) {
            return;
        } else {
            let arrayOfProjectIdsAndFiles = [];

            for (let project of projectsSelected) {
                let fileIds = [];

                let projectFiles = project.projectFiles;
                let projectId = project.projectId;

                if (projectFiles.length !== 0) {
                    for (let file of projectFiles) {
                        fileIds.push({
                            file_id: file.file_id,
                            file_name: removeExtension(file.name),
                        });
                    }

                    let objectToAppend = {
                        project_id: projectId,
                        files: fileIds,
                    };

                    arrayOfProjectIdsAndFiles.push(objectToAppend);
                    fileIds = [];
                }
            }

            dispatch(downloadMergedExcelFilesAction(arrayOfProjectIdsAndFiles));
            setSelectedProject([]);
        }
        blurButtons();
    };

    useEffect(() => {
        if (projectRedirection) {
            resetDashboardUseStates();
        }
    }, [projectRedirection, resetDashboardUseStates]);

    const sortProjectsCallback = useCallback(() => {
        if (sortType === "alphabeticallyAscending") {
            let sortedProjects = sortAlphabeticallyAscending(
                allProjectsInfo,
                "projects"
            );
            // let sortedProjectsCopy = JSON.parse(JSON.stringify(sortedProjects));
            setSortedProjects(sortedProjects);
            // setSortProjects(false);
            // setSortType("");
        } else if (sortType === "alphabeticallyDescending") {
            let sortedProjects = sortAlphabeticallyDescending(
                allProjectsInfo,
                "projects"
            );
            // let sortedProjectsCopy = JSON.parse(JSON.stringify(sortedProjects));
            setSortedProjects(sortedProjects);
            // setSortProjects(false);
            // setSortType("");
        } else if (sortType === "dateOldest") {
            let sortedProjects = sortDateOldest(allProjectsInfo, "projects");
            // let sortedProjectsCopy = JSON.parse(JSON.stringify(sortedProjects));
            setSortedProjects(sortedProjects);
            // setSortProjects(false);
            // setSortType("");
        } else if (sortType === "dateMostRecent") {
            let sortedProjects = sortDateMostRecent(allProjectsInfo, "projects");
            // let sortedProjectsCopy = JSON.parse(JSON.stringify(sortedProjects));
            setSortedProjects(sortedProjects);
            // setSortProjects(false);
            // setSortType("");
        } else {
            setSortedProjects(allProjectsInfo)
        }
    }, [sortType, setSortedProjects, allProjectsInfo]);

    useEffect(() => {
        sortProjectsCallback();
    }, [sortProjectsCallback, allProjectsInfo, sortType]);


    useEffect(() => {
        calculateProjectsToDisplay();
    }, [calculateProjectsToDisplay, sortedProjects]);

    const renderDashboard = () => {
        if (projectsLoading) {
            return(
                <Loading className="loading-size" withOverlay={false}/>
            )
        } else {
            if (allProjectsInfo.length !== 0) {
                return (<div
                    className="flex project-full-width"
                    ref={divRef}
                >
                    {projectsToDisplay.map((project) => (
                        <div
                            key={
                                "project" +
                                String(project.project_id)
                            }
                            ref={tileRef}
                        >
                            <ProjectTile
                                selectedProjectInfo={project}
                                handleTileClick={handleTileClick}
                                onToggleProjectRenameModal={
                                    onToggleProjectRenameModal
                                }
                                addProjectSelected={
                                    addProjectSelected
                                }
                                handleRemoveProjectSelected={
                                    handleRemoveProjectSelected
                                }
                                areProjectSelected={
                                    projectsSelected.length !== 0
                                }
                                resetDashboardUseStates={
                                    resetDashboardUseStates
                                }
                                areProjectsDownloaded={
                                    areProjectsDownloaded
                                }
                                projectsSelected={
                                    projectsSelected
                                }
                            />
                        </div>
                    ))}
                    {allProjectsInfo.length !== 0 ? (
                        <Pagination
                            backwardText="Previous page"
                            forwardText="Next page"
                            itemsPerPageText="Items per page:"
                            pageNumberText="Page Number"
                            page={currentPage}
                            pageSize={pageSize}
                            pageSizes={[1, 5, 10, 15, 20, 25]}
                            //pageSizeInputDisabled={true}
                            id="pagination-component-project-dashboard"
                            data-testid="pagination-component-project-dashboard"
                            totalItems={allProjectsInfo.length}
                            onChange={(event) => {
                                setPageSize(event.pageSize);
                                setCurrentPage(event.page);
                            }}
                            className="pagination-location pagination-size"
                        />
                    ) : null}
                </div>)
             } else {
                return (<div className="no-project">
                    You don't have any projects yet. Start by{" "}
                    <Link
                        className="create-project-link"
                        data-testid="no-projects-create-project-link-button"
                        id="no-projects-create-project-link-button"
                        onClick={() => setProjectModalOpen(true)}
                    >
                        creating one.
                    </Link>
                </div>)
            }
        }
    }

    return (
        <div data-testid="dashboard-component">
            {tileType === "projects" ? (
                <Fragment>
                    <div className="simple-row" style={{ height: "4rem" }}>
                        <div className="tile-title">Projects</div>
                        {allProjectsInfo.length > 1 ? (
                            <Button
                                id="projects-sort-button"
                                data-testid="projects-sort-button"
                                renderIcon={ChevronSort32}
                                className="icon-button-dashboard"
                                iconDescription={"Sort"}
                                hasIconOnly
                                onClick={() => {setShowSortMenu(!showSortMenu)}}
                                tooltipPosition="bottom"
                            />
                        ) : null}
                        <SortMenu
                            id={"projects"}
                            showSortMenu={showSortMenu}
                            setShowSortMenu={setShowSortMenu}
                            setSortType={setSortType}
                            sortType={sortType}
                        />
                        <CommonButtons
                            id="project"
                            deleteButtonClickFunction={() =>
                                prepareDeleteProjectWarningAlert()
                            }
                            genericTableButtonDescription={
                                "Download Generic Tables"
                            }
                            genericTableButtonClickFunction={() =>
                                projectDownloadExcelFiles("generic_tables")
                            }
                            bankStatementButtonDescription={
                                "Download Bank Statements"
                            }
                            bankStatementButtonClickFunction={() =>
                                projectDownloadExcelFiles("statement")
                            }
                            bankStatementMergeFunction={() =>
                                mergeBankStatements()
                            }
                            deselectAll={resetDashboardUseStates}
                            selectAll={selectAll}
                            selectedProjectOrFileLength={projectsSelected.length}
                            projectOrFileLength={allProjectsInfo.length}
                        />
                    </div>
                    <Row data-testid="project-dashboard-component">
                        <div className="project-dashboard-div">
                            {renderDashboard()}
                        </div>
                    </Row>
                </Fragment>
            ) : (
                <FilesDashboard
                    selectedProjectInfo={selectedProjectInfo}
                    lastClickedFileId={lastClickedFileId}
                    handleBackClick={handleBackClick}
                    setFileModalOpen={setFileModalOpen}
                    blurButtons={blurButtons}
                    homepageNumberOfWarnings={homepageNumberOfWarnings}
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                />
            )}
        </div>
    );
};

Dashboard.propTypes = {
    projects: PropTypes.array,
};

export default Dashboard;
