import React from "react";
import ZoomInButton from "../../../components/mask-page-image-toolbar/mask-page-image-toolbar-buttons/zoom-in-button.component";
import ZoomOutButton from "../../../components/mask-page-image-toolbar/mask-page-image-toolbar-buttons/zoom-out-button.component";
import ZoomRotateResetButton from "../../../components/mask-page-image-toolbar/mask-page-image-toolbar-buttons/zoom-rotate-reset-button.component";

const ResultsPageImageToolbar = ({
    pageDisplayed,
    zoomInFunction,
    zoomOutFunction,
    resetTransformFunction,
}) => {
    return (
        <>
            <div
                className="image-toolbar"
                data-testid="results-page-image-toolbar"
                style={{ zIndex: 1 }}
            >
                <ZoomInButton zoomInFunction={zoomInFunction} page={"results"} />
                <ZoomOutButton zoomOutFunction={zoomOutFunction} page={"mask"} />
                <ZoomRotateResetButton
                    pageDisplayed={pageDisplayed}
                    resetTransformFunction={resetTransformFunction}
                />
            </div>
        </>
    );
};

export default ResultsPageImageToolbar;
