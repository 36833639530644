import {
    Download16,
    Finance16,
    Jpg16,
    Pdf16,
    Png16,
    TableSplit16,
    Xls16,
} from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadExcelFilesAction } from "../../../../redux/thunks/thunks.actions";

const DownloadButton = ({ fileId, projectId, fileName }) => {
    const dispatch = useDispatch();

    const [showDownload, setShowDownload] = useState(false);
    const [showXml, setShowXml] = useState(false);
    const [downloadText, setDownloadText] = useState("PDF");
    const [iconToDisplay, setIconToDisplay] = useState(Pdf16);

    const openInNewTab = (excelType) => {
        dispatch(downloadExcelFilesAction(projectId, excelType, fileId));
    };

    const toggleDownload = () => {
        setShowDownload(!showDownload);
    };

    const toggleXml = () => {
        setShowXml(!showXml);
    };

    const closeDownload = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowDownload(false);
        }
    };

    useEffect(() => {
        if (!showDownload) setShowXml(false);
    }, [showDownload]);

    useEffect(() => {
        fileName !== ""
            ? setDownloadText(fileName.split(".").slice(-1)[0].toUpperCase())
            : setDownloadText("PDF");
    }, [fileName]);

    useEffect(() => {
        if (fileName !== "") {
            let extension = fileName.split(".").slice(-1)[0].toUpperCase();

            switch (extension) {
                case "PDF":
                    setIconToDisplay(Pdf16);
                    break;
                case "PNG":
                    setIconToDisplay(Png16);
                    break;
                case "JPEG":
                    setIconToDisplay(Jpg16);
                    break;
                case "JPG":
                    setIconToDisplay(Jpg16);
                    break;
                default:
                    break;
            }
        }
    }, [fileName]);

    return (
        <div onBlur={closeDownload}>
            <Button
                renderIcon={Download16}
                iconDescription="Download"
                data-testid={`download-toggle-${fileId}`}
                hasIconOnly={true}
                role="display-download-buttons"
                onClick={toggleDownload}
                className="make-buttons-disappear"
            />
            {showDownload ? (
                <div
                    id={`download-dropdown-${fileId}`}
                    data-testid={`download-dropdown-${fileId}`}
                    className="flex-column"
                >
                    <div className="flex-row">
                        <Button
                            renderIcon={Xls16}
                            data-testid={`toggle-xls-${fileId}`}
                            id={`toggle-xls-${fileId}`}
                            iconDescription="Toggle XLS files"
                            hasIconOnly
                            role="display-excel-download-buttons"
                            onClick={toggleXml}
                            className="make-buttons-disappear"
                        />
                        {showXml ? (
                            <span className="position-relative">
                                <span className="flex-row position-absolute">
                                    <Button
                                        renderIcon={TableSplit16}
                                        data-testid={`download-generic-tables-xls-${fileId}`}
                                        id={`download-generic-tables-xls-${fileId}`}
                                        iconDescription="Download raw tables Excel"
                                        hasIconOnly
                                        role="download-raw-tables"
                                        onClick={() =>
                                            openInNewTab("generic_tables")
                                        }
                                        className="make-buttons-disappear"
                                    />
                                    <Button
                                        renderIcon={Finance16}
                                        data-testid={`download-xls-${fileId}`}
                                        id={`download-xls-${fileId}`}
                                        iconDescription="Download bank statement Excel"
                                        hasIconOnly
                                        role="download-bank-statement"
                                        onClick={() => openInNewTab("statement")}
                                        className="make-buttons-disappear"
                                    />
                                </span>
                            </span>
                        ) : null}
                    </div>
                    <Button
                        renderIcon={iconToDisplay}
                        data-testid={`download-pdf-${fileId}`}
                        id={`download-pdf-${fileId}`}
                        iconDescription={`Download ${downloadText}`}
                        hasIconOnly
                        role="download-pdf"
                        onClick={() => openInNewTab("pdf")}
                        className="make-buttons-disappear"
                    />
                </div>
            ) : null}
        </div>
    );
};

export default DownloadButton;
