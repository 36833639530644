import types from "./websocket.types";

export const updateWebSocket = (webSocketData) => {
    return { type: types.UPDATE_WEBSOCKET, payload: webSocketData };
};

export const updateStateWebsocket = (fileIds) => {
    return { type: types.UPDATE_STATE_WEBSOCKET, payload: fileIds };
};

export const updateIsWebSocketConnected = (isWebSocketConnected) => {
    return { type: types.WEBSOCKET_CONNECTED, payload: isWebSocketConnected };
};

export const webSocketDisconnected = () => {
    return { type: types.WEBSOCKET_DISCONNECTED }
}

export const setWebsocketMessage = (message) => {
    return { type: types.SET_WEBSOCKET_MESSAGE, payload: message };
};

export const clearWebsocketMessage = () => {
    return { type: types.CLEAR_WEBSOCKET_MESSAGE };
};
