import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FileNameTitle from "../../components/file-name/file-name.component";
import HeaderComponent from "../../components/header/header.component";
import InlineNotificationError from "../../components/inline-notification/inline-notification-error.component";
import MaskPageFileToolbar from "../../components/mask-page-file-toolbar/mask-page-file-toolbar.component";
import MaskPageImageToolbar from "../../components/mask-page-image-toolbar/mask-page-image-toolbar.component";
import AllPDFImages from "../../components/pdf-images/all-pdf-images.component";
import SideNavBarInfo from "../../components/side-nav-bar-info/side-nav-bar-info.component";
import { InlineNotification } from "carbon-components-react";

import SideNavBar from "../../components/side-nav-bar/side-nav-bar.component";
import {
    genericOnKeyDownHandler,
    goToHomePage,
} from "../../helpers/helper-functions";
import { navigateThroughWarningsMaskPageDefineNewIndex } from "../../helpers/helper-functions-2";
import { clearApiError } from "../../redux/api/api.actions";
import {
    canFileBeRestored,
    fileTablesWarnings,
} from "../../redux/files/files.selectors";
import {
    getFileColSpecAction,
    getFileDetailsAction,
    getFileImagesAction,
    getFileResultsAction,
    getFileTableAction,
    restoreFileAction,
    getProjectList
} from "../../redux/thunks/thunks.actions";

const MaskPage = () => {
    const maskPageDivRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const allImageInfoInit = useSelector((state) => state.files.fileImages);
    const fileNameInit = useSelector((state) => state.files.fileName);
    const fileTablesInit = useSelector((state) => state.files.fileTables);
    const initialApiErrors = useSelector((state) => state.api.apiError);
    const fileTablesInitWarnings = useSelector(fileTablesWarnings);
    const canTheFileBeRestored = useSelector(canFileBeRestored);
    const projectState = useSelector((state => state.projects));

    const [fileName, setFileName] = useState("");
    const [allImagesInfo, setAllImagesInfo] = useState([]);
    const [fileTables, setFileTables] = useState([]);
    const [combinedInfo, setCombinedInfo] = useState([]);

    const [totalPages, setTotalPages] = useState(0);
    const [pageDisplayed, setPageDisplayed] = useState(1);

    const [pageToScrollTo, setPageToScrollTo] = useState(null);

    const [dispatchRun, setDispatchRun] = useState(false);

    const [zoomInFunction, setZoomInFunction] = useState(null);
    const [zoomOutFunction, setZoomOutFunction] = useState(null);
    const [resetTransformFunction, setResetTransformFunction] = useState(null);

    const [placeGrid, setPlaceGrid] = useState({
        boolean: false,
        pageNumber: 0,
    });
    const [gridButtonClass, setGridButtonClass] = useState(
        "default-button-colour"
    );

    const [selectedObject, setSelectedObject] = useState(null);
    const [deleteLineFunction, setDeleteLineFunction] = useState(null);
    const [deleteRectFunction, setDeleteRectFunction] = useState(null);
    const [clearRectFunction, setClearRectFunction] = useState(null);
    const [runAutoMerge, setRunAutoMerge] = useState(false);

    const [backEndSubmitGrids, setBackEndSubmitGrids] = useState(null);

    const [columnSpec, setColumnSpec] = useState([]);
    const [maxColumnsInfo, setMaxColumnsInfo] = useState(0);
    const [allWarningGrids, setAllWarningGrids] = useState([]);

    const [isCollapsed, setIsCollapsed] = useState(false);
    const [resetMaskSideBarInfo, setResetMaskSideBarInfo] = useState(false);
    const [resetAll, setResetAll] = useState(false);
    const [undoEventList, setUndoEventList] = useState([]);

    const [undo, setUndo] = useState(false);
    const [undoRunXTimes, setUndoRunXTimes] = useState(1);
    const [isUndoButtonMaskPageDisabled, setIsUndoButtonMaskPageDisabled] =
        useState(true);

    const [selectedPageUrl, setSelectedPageUrl] = useState(null);
    const [selectedRect, setSelectedRect] = useState(null);
    const [autoGridColsRows, setAutoGridColsRows] = useState(null);
    const [maskError, setMaskError] = useState("");

    const [isSideNavBarInfoOpen, setIsSideNavBarInfoOpen] = useState(false);
    const [navInfoToDisplay, setNavInfoToDisplay] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [undoAutoGrid, setUndoAutoGrid] = useState(false);

    const [apiErrors, setApiErrors] = useState("");
    const [currentPage, setCurrentPage] = useState("");
    const [allDocumentWarnings, setAllDocumentWarnings] = useState([]);

    const [displayUpDownWarningButtons, setDisplayUpDownWarningButtons] =
        useState(false);

    const [downNextPosition, setDownNextPosition] = useState(0);
    const [upNextPosition, setUpNextPosition] = useState(0);

    const [projectName, setProjectName] = useState(null);
    const [projectId, setProjectId] = useState(null);

    const [autoMergeColsRows, setAutoMergeColsRows] = useState([]);
    const [displayMergeButtons, setDisplayMergeButtons] = useState(false);
    const [isAutoMergeButtonDisabled, setIsAutoMergeButtonDisabled] =
        useState(false);

    const [showCopyButtons, setShowCopyButtons] = useState(false)

    const [gotProjects, setGotProjects] = useState(false);

    useEffect(() => {
        if (!gotProjects) {
            setGotProjects(true);
            dispatch(getProjectList());
        }
    }, [dispatch, projectState, gotProjects]);

    const navigateThroughWarningsUp = () => {
        const allDocumentWarningsCopy = JSON.parse(
            JSON.stringify(allDocumentWarnings)
        );

        let newIndex = upNextPosition - 1;
        let nextPage = allDocumentWarningsCopy[newIndex];

        let lastIndexOnAllDocumentWarningsCopy =
            allDocumentWarningsCopy.length - 1;

        if (nextPage === undefined) {
            newIndex = navigateThroughWarningsMaskPageDefineNewIndex(
                allDocumentWarningsCopy,
                pageDisplayed,
                "upUndefined"
            );
        }

        nextPage = allDocumentWarningsCopy[newIndex];

        if (nextPage !== pageDisplayed - 1 && nextPage !== undefined) {
            newIndex = navigateThroughWarningsMaskPageDefineNewIndex(
                allDocumentWarningsCopy,
                pageDisplayed,
                "upNextPageDifferentFromPageDisplayed"
            );
        }

        if (newIndex >= 0 && newIndex <= lastIndexOnAllDocumentWarningsCopy) {
            let warningPageToScrollTo = allDocumentWarningsCopy[newIndex];

            if (warningPageToScrollTo !== pageDisplayed) {
                setPageToScrollTo(warningPageToScrollTo);
            }

            setUpNextPosition(newIndex);
            setDownNextPosition(newIndex + 1);
            blurButtons();
        }
    };

    const navigateThroughWarningsDown = () => {
        const allDocumentWarningsCopy = JSON.parse(
            JSON.stringify(allDocumentWarnings)
        );

        let newIndex = downNextPosition;
        let nextPage = allDocumentWarningsCopy[newIndex];
        let lastIndexOnAllDocumentWarningsCopy =
            allDocumentWarningsCopy.length - 1;

        if (nextPage !== pageDisplayed && pageDisplayed >= nextPage) {
            newIndex = navigateThroughWarningsMaskPageDefineNewIndex(
                allDocumentWarningsCopy,
                pageDisplayed,
                "downNextPageDifferentFromPageDisplayed"
            );
        }

        if (newIndex > lastIndexOnAllDocumentWarningsCopy) {
            newIndex = lastIndexOnAllDocumentWarningsCopy;
        }

        if (newIndex >= 0 && newIndex <= lastIndexOnAllDocumentWarningsCopy) {
            let warningPageToScrollTo = allDocumentWarningsCopy[newIndex];

            if (warningPageToScrollTo !== pageDisplayed) {
                setPageToScrollTo(warningPageToScrollTo);
            }
            setUpNextPosition(newIndex - 1);
            setDownNextPosition(newIndex + 1);
            blurButtons();
        }
    };
    const recheckColSpec = useCallback(
        (newMaxColumns) => {
            let columnSpecCopy = [...columnSpec];

            if (columnSpecCopy.length > newMaxColumns) {
                columnSpecCopy.splice(newMaxColumns);
                setColumnSpec(columnSpecCopy);
            } else if (columnSpecCopy.length === 0 && newMaxColumns === 1) {
                setColumnSpec([{ columnIndex: 0, type: "Unselected" }]);
            } else if (columnSpecCopy.length < newMaxColumns) {
                let newIndex = 0;
                columnSpecCopy.forEach((info) => {
                    if (info["columnIndex"] > newIndex) {
                        newIndex = info["columnIndex"];
                    }
                });

                let newColumns = [];
                let columnsTooAdd = newMaxColumns - columnSpecCopy.length;
                for (var i = 0; i < columnsTooAdd; i++) {
                    newColumns.push({
                        columnIndex: newIndex + 1 + i,
                        type: "Unselected",
                    });
                }

                setColumnSpec([...columnSpecCopy, ...newColumns]);
            }
        },
        [columnSpec]
    );

    const blurButtons = () => {
        if (maskPageDivRef.current) {
            maskPageDivRef.current.focus();
        }
    };

    const hideUpDownWarningButtons = useCallback(() => {
        if (displayUpDownWarningButtons) {
            setDisplayUpDownWarningButtons(false);
        }
    }, [displayUpDownWarningButtons]);

    const toggleAllowPlaceGrid = () => {
        if (placeGrid.boolean) {
            disablePlaceGrid();
        } else {
            allowPlaceGrid();
        }
        blurButtons();
        hideUpDownWarningButtons();
    };

    const disablePlaceGrid = () => {
        setPlaceGrid({ boolean: false, pageNumber: pageDisplayed });
        setGridButtonClass("default-button-colour");
    };

    const allowPlaceGrid = () => {
        setPlaceGrid({ boolean: true, pageNumber: pageDisplayed });
        setGridButtonClass("selected-button-colour");
    };

    const addColumnTables = (columnLocation) => {
        let largestIndex = 0;
        let columnSpecCopy = [...columnSpec];

        for (let columnType of columnSpecCopy) {
            if (columnType["columnIndex"] > largestIndex) {
                largestIndex = columnType["columnIndex"];
            }
        }
        columnSpecCopy.splice(columnLocation, 0, {
            columnIndex: largestIndex + 1,
            type: "Unselected",
        });
        setColumnSpec(columnSpecCopy);
    };

    const removeColumnTables = useCallback(
        (columnLocation) => {
            let columnSpecCopy = [...columnSpec];
            columnSpecCopy.splice(columnLocation, 1);
            setColumnSpec(columnSpecCopy);
        },
        [columnSpec]
    );

    const onKeyDownHandler = (e) => {
        genericOnKeyDownHandler(
            e,
            pageDisplayed,
            zoomInFunction,
            zoomOutFunction,
            resetTransformFunction,
            selectedObject,
            deleteLineFunction,
            deleteRectFunction,
            handleUndo
        );
    };

    const handleUndo = useCallback(() => {
        let undoEventListCopy = JSON.parse(JSON.stringify(undoEventList));
        if (undoEventListCopy.length !== 0) {
            let lastEventIndex = undoEventListCopy.length - 1;
            let lastEvent = undoEventListCopy[lastEventIndex];
            let lastEventTableSchema = lastEvent.updatedTableSchema;
            setUndo(true);
            setFileTables(lastEventTableSchema);
            setColumnSpec(lastEvent.previousColSpec);
            undoEventListCopy.pop();
            setUndoEventList(undoEventListCopy);
            blurButtons();
            hideUpDownWarningButtons();
        }
    }, [
        undoEventList,
        setUndoEventList,
        setUndo,
        setFileTables,
        hideUpDownWarningButtons,
    ]);

    const onCloseButtonClick = (error) => {
        if (error === "mask-error") {
            setMaskError("");
        } else if (error === "api-error") {
            setApiErrors("");
            dispatch(clearApiError());
        }
    };

    const resetFunction = useCallback(
        (e) => {
            let pathnameSplit = location.pathname.split("/");

            let projectId = pathnameSplit[2];
            let fileId = pathnameSplit[3];
            dispatch(restoreFileAction(projectId, fileId));
            goToHomePage(navigate, projectId);
        },
        [location.pathname, dispatch, navigate]
    );

    useEffect(() => {
        let pathnameSplit = location.pathname.split("/");
        let projectId = pathnameSplit[2];
        let filesProject = projectState.projects.find(
            (project) => project["project_id"] === projectId
        );
        if (filesProject) {
            setProjectId(projectId)
            setProjectName(filesProject.name)
        }

    }, [projectState, location.pathname])

    // Runs the getFileImagesAction on page load
    useEffect(() => {
        let pathnameSplit = location.pathname.split("/");
        let page = pathnameSplit[1];
        let projectId = pathnameSplit[2];
        let fileId = pathnameSplit[3];
        setCurrentPage(page);
        dispatch(getFileImagesAction(projectId, fileId));
        dispatch(getFileTableAction(projectId, fileId));
        dispatch(getFileColSpecAction(projectId, fileId));
        dispatch(getFileDetailsAction(projectId, fileId));
        dispatch(getFileResultsAction(projectId, fileId));
        setDispatchRun(true);
    }, [location.pathname, dispatch]);

    // After dispatch get all File Info
    useEffect(() => {
        if (
            dispatchRun &&
            allImageInfoInit !== null &&
            allImageInfoInit.length !== 0 &&
            fileTablesInit !== null
        ) {
            setAllImagesInfo(JSON.parse(JSON.stringify(allImageInfoInit)));
            setFileName(fileNameInit);
            setFileTables(fileTablesInit);
            setTotalPages(allImageInfoInit.length);
        }
    }, [dispatchRun, fileNameInit, fileTablesInit, allImageInfoInit]);

    useEffect(() => {
        // Creates a list of images and tables
        if (allImagesInfo.length !== 0 || fileTables.length !== 0) {
            setCombinedInfo(
                allImagesInfo.map(function (imageInfo, i) {
                    return {
                        imageInfo: imageInfo,
                        fileTables: fileTables.filter(
                            (table) => imageInfo.imageId === table.page_number
                        ),
                    };
                })
            );
        }
    }, [fileTables, allImagesInfo]);

    useEffect(() => {
        undoEventList.length === 0
            ? setIsUndoButtonMaskPageDisabled(true)
            : setIsUndoButtonMaskPageDisabled(false);
    }, [undoEventList, setIsUndoButtonMaskPageDisabled]);
    /**
        * onKeyDown events in react only works when an element is focused
        * This usually is only activated onClick
        * Therefore a useRef was added to the outermost div element in the results page, 
     so that the element can be focused on automatically
        * This works in conjunction with the tabIndex in line 415
     */
    useEffect(() => {
        if (maskPageDivRef.current) {
            maskPageDivRef.current.focus();
        }
    }, [maskPageDivRef]);

    useEffect(() => {
        let allDocumentWarningsLength = allDocumentWarnings.length;
        if (fileTablesInitWarnings) {
            if (fileTablesInitWarnings === "All tables are valid") {
                if (allDocumentWarningsLength !== 0 && isAutoMergeButtonDisabled === false) {
                    if (allDocumentWarningsLength < totalPages) {
                        setMaskError("Some tables are invalid");
                    } else {
                        setMaskError("All tables are invalid");
                    }
                } else {
                    setMaskError("");
                }
            } else {
                if (allDocumentWarningsLength === 0) {
                    setMaskError("");
                } else {
                    setMaskError(fileTablesInitWarnings);
                }
            }
        }
    }, [fileTablesInitWarnings, allDocumentWarnings, totalPages, isAutoMergeButtonDisabled]);

    useEffect(() => {
        let checkList = [null, "", undefined];
        if (!checkList.includes(initialApiErrors)) {
            if (initialApiErrors.includes("Please try again")) {
                const error = `Ongoing Process: ${initialApiErrors}`;
                setApiErrors(error);
            } else {
                setApiErrors("");
            }
        }
    }, [initialApiErrors]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (apiErrors) {
                setTimeout(() => {
                    setApiErrors("");
                    dispatch(clearApiError());
                }, 5000);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [apiErrors, dispatch]);

    useEffect(() => { }, [backEndSubmitGrids]);

    const onOuterDivClick = () => {
        if (displayMergeButtons) {
            setDisplayMergeButtons(false);
        }
        if (showCopyButtons) {
            setShowCopyButtons(false);
        }
        hideUpDownWarningButtons();
    };

    useEffect(() => {
        setMaskError("");
        setApiErrors("");
        dispatch(clearApiError());
    }, [dispatch]);

    return (
        <>
            <MaskPageFileToolbar
                pathname={location.pathname}
                backEndSubmitGrids={backEndSubmitGrids}
                gridButtonClass={gridButtonClass}
                toggleAllowPlaceGrid={toggleAllowPlaceGrid}
                selectedObject={selectedObject}
                deleteLineFunction={deleteLineFunction}
                deleteRectFunction={deleteRectFunction}
                clearRectFunction={clearRectFunction}
                handleUndo={handleUndo}
                isUndoButtonMaskPageDisabled={isUndoButtonMaskPageDisabled}
                selectedPageUrl={selectedPageUrl}
                selectedRect={selectedRect}
                setAutoGridColsRows={setAutoGridColsRows}
                columnSpec={columnSpec}
                setUndoAutoGrid={setUndoAutoGrid}
                blurButtons={blurButtons}
                setAutoMergeColsRows={setAutoMergeColsRows}
                setRunAutoMerge={setRunAutoMerge}
                runAutoMerge={runAutoMerge}
                displayMergeButtons={displayMergeButtons}
                setDisplayMergeButtons={setDisplayMergeButtons}
                isAutoMergeButtonDisabled={isAutoMergeButtonDisabled}
                setIsAutoMergeButtonDisabled={setIsAutoMergeButtonDisabled}
                navigateThroughWarningsUp={navigateThroughWarningsUp}
                navigateThroughWarningsDown={navigateThroughWarningsDown}
                allDocumentWarnings={allDocumentWarnings}
                hideUpDownWarningButtons={hideUpDownWarningButtons}
                setDisplayUpDownWarningButtons={setDisplayUpDownWarningButtons}
                displayUpDownWarningButtons={displayUpDownWarningButtons}
                showCopyButtons={showCopyButtons}
                setShowCopyButtons={setShowCopyButtons}
            />
            <div
                className="bx--grid--full-width page-layout"
                onKeyDown={(e) => onKeyDownHandler(e)}
                //tabIndex is required for focusing on the element
                tabIndex="-1"
                ref={maskPageDivRef}
                onClick={onOuterDivClick}
            >
                <HeaderComponent isLogin={false} displayNavOptions={true} isMaskPage={true}/>
                <FileNameTitle
                    fileName={fileName}
                    projectName={projectName}
                    projectId={projectId}
                    pageLocation="mask"
                    isSideNavBarInfoOpen={isSideNavBarInfoOpen}
                />
                <div
                    className={`inline-notification-mask ${isCollapsed
                            ? "notification-margin-collapsed"
                            : "notification-margin-expanded"
                        }`}
                >
                    {maskError !== "" ? (
                        <InlineNotificationError
                            loginError={maskError}
                            hideCloseButton={false}
                            onCloseButtonClick={onCloseButtonClick}
                            displayExtraText={canTheFileBeRestored}
                            resetFunction={resetFunction}
                            errorType="mask-error"
                            pageLocation={currentPage}
                        />
                    ) : null}
                    {apiErrors !== "" ? (
                        <InlineNotificationError
                            loginError={apiErrors}
                            hideCloseButton={false}
                            onCloseButtonClick={onCloseButtonClick}
                            errorType="api-error"
                            pageLocation={currentPage}
                        />
                    ) : null}
                    {isAutoMergeButtonDisabled === true ? (
                        <InlineNotification
                            kind="info"
                            iconDescription="Close Notification"
                            title="Auto Merge running, please wait."
                            lowContrast={true}
                            className="inline-notification fade-in-1 small-notification"
                            data-testid="user-notification-inline-notification"
                        />
                    ) : null}
                    {columnSpec.length === 0 ? (
                        <InlineNotification
                            kind="info"
                            iconDescription="Close Notification"
                            title="Mask data loading, please wait."
                            lowContrast={true}
                            className="inline-notification fade-in-1 small-notification"
                        />
                    ) : null}

                </div>
                <div
                    className="mask-page-dashboard"
                    data-testid="mask-page-dashboard"
                >
                    <div className="file-pdf-images-container no-scrollbar">
                        <AllPDFImages
                            combinedInfo={combinedInfo}
                            pageToScrollTo={pageToScrollTo}
                            resetMaskSideBarInfo={resetMaskSideBarInfo}
                            setPageToScrollTo={setPageToScrollTo}
                            setPageDisplayed={setPageDisplayed}
                            setZoomInFunction={setZoomInFunction}
                            setZoomOutFunction={setZoomOutFunction}
                            setResetTransformFunction={setResetTransformFunction}
                            disablePlaceGrid={disablePlaceGrid}
                            placeGrid={placeGrid}
                            allWarningGrids={allWarningGrids}
                            setAllWarningGrids={setAllWarningGrids}
                            setSelectedObject={setSelectedObject}
                            selectedObject={selectedObject}
                            setDeleteLineFunction={setDeleteLineFunction}
                            setDeleteRectFunction={setDeleteRectFunction}
                            setClearRectFunction={setClearRectFunction}
                            setBackEndSubmitGrids={setBackEndSubmitGrids}
                            addColumnTables={addColumnTables}
                            removeColumnTables={removeColumnTables}
                            maxColumnsInfo={maxColumnsInfo}
                            setMaxColumnsInfo={setMaxColumnsInfo}
                            recheckColSpec={recheckColSpec}
                            setResetMaskSideBarInfo={setResetMaskSideBarInfo}
                            setUndoEventList={setUndoEventList}
                            undoEventList={undoEventList}
                            undo={undo}
                            setUndo={setUndo}
                            undoRunXTimes={undoRunXTimes}
                            setUndoRunXTimes={setUndoRunXTimes}
                            columnSpec={columnSpec}
                            setSelectedPageUrl={setSelectedPageUrl}
                            setSelectedRect={setSelectedRect}
                            autoGridColsRows={autoGridColsRows}
                            setAutoGridColsRows={setAutoGridColsRows}
                            isSideNavBarInfoOpen={isSideNavBarInfoOpen}
                            setIsSideNavBarInfoOpen={setIsSideNavBarInfoOpen}
                            setNavInfoToDisplay={setNavInfoToDisplay}
                            undoAutoGrid={undoAutoGrid}
                            setUndoAutoGrid={setUndoAutoGrid}
                            blurButtons={blurButtons}
                            setAllDocumentWarnings={setAllDocumentWarnings}
                            autoMergeColsRows={autoMergeColsRows}
                            setAutoMergeColsRows={setAutoMergeColsRows}
                            runAutoMerge={runAutoMerge}
                            setRunAutoMerge={setRunAutoMerge}
                            setIsAutoMergeButtonDisabled={
                                setIsAutoMergeButtonDisabled
                            }
                        />
                        <MaskPageImageToolbar
                            totalPages={totalPages}
                            pageDisplayed={pageDisplayed}
                            allImagesInfo={allImagesInfo}
                            zoomInFunction={zoomInFunction}
                            zoomOutFunction={zoomOutFunction}
                            resetTransformFunction={resetTransformFunction}
                            setPageToScrollTo={setPageToScrollTo}
                            setAllImagesInfo={setAllImagesInfo}
                            blurButtons={blurButtons}
                        />
                    </div>
                    <SideNavBar
                        pathname={location.pathname}
                        backEndSubmitGrids={backEndSubmitGrids}
                        maxColumnsInfo={maxColumnsInfo}
                        columnSpec={columnSpec}
                        allWarningGrids={allWarningGrids}
                        setColumnSpec={setColumnSpec}
                        setResetMaskSideBarInfo={setResetMaskSideBarInfo}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        pageLocation={location.pathname.split("/")[1]}
                        resetSideBarInfo={resetMaskSideBarInfo}
                        resetAll={resetAll}
                        setResetAll={setResetAll}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                    {isSideNavBarInfoOpen ? (
                        <SideNavBarInfo
                            setIsSideNavBarInfoOpen={setIsSideNavBarInfoOpen}
                            navInfoToDisplay={navInfoToDisplay}
                            setNavInfoToDisplay={setNavInfoToDisplay}
                            deleteRectFunction={deleteRectFunction}
                            selectedObject={selectedObject}
                            setSelectedRect={setSelectedRect}
                            setSelectedObject={setSelectedObject}
                            setPageToScrollTo={setPageToScrollTo}
                            allWarningGrids={allWarningGrids}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default MaskPage;
