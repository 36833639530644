import { Auth } from "@aws-amplify/auth";
import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

export const getProjects = async (headers) => {
    try {
        const response = await axios.get(`${baseURL}/projects/?limit=200`, {
            headers: headers,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const getProjectFiles = async (projectId, headers) => {

    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/?limit=200`,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const postProject = async (projectName, projectDescription, headers) => {
    let data = {
        name: projectName,
        description: projectDescription,
    };

    try {
        const response = await axios.post(`${baseURL}/projects/`, data, {
            headers: headers,
        });

        return response;
    } catch (e) {
        throw e;
    }
};

export const deleteFile = async (projectId, fileId, headers) => {
    try {
        const response = await axios.delete(
            `${baseURL}/projects/${projectId}/files/${fileId}`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const updateFileUploadStatus = async (
    projectId,
    fileId,
    uploadStatus,
    headers
) => {
    let data = {
        upload_status: uploadStatus,
    };

    try {
        const response = await axios.patch(
            `${baseURL}/projects/${projectId}/files/${fileId}/upload_status`,
            data,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const renameProjectAPI = async (
    projectId,
    projectName,
    projectDescription,
    headers
) => {
    let data = {
        name: projectName,
        description: projectDescription,
    };

    try {
        const response = await axios.patch(
            `${baseURL}/projects/${projectId}`,
            data,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const deleteProjectAPI = async (projectId, headers) => {
    try {
        const response = await axios.delete(`${baseURL}/projects/${projectId}`, {
            headers: headers,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const uploadFileAPI = async (projectId, file, headers) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(
            `${baseURL}/projects/${projectId}/files/`,
            formData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

//download selected files
export const downloadExcelFiles = async (
    projectId,
    excelType,
    files,
    headers
) => {
    try {
        const response = await axios.get(
            `${baseURL}/download/${projectId}/${excelType}/${files}`,
            {
                headers: headers,
                responseType: "blob", // important
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const downloadMergedExcelFiles = async (requestBody, headers) => {
    try {
        const response = await axios.post(
            `${baseURL}/download/download_merged`,
            requestBody,
            {
                headers: headers,
                responseType: "blob", // important
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

// download all files from the project
export const projectDownloadMultipleExcelFiles = async (
    projectIds,
    excelType,
    headers
) => {
    try {
        const response = await axios.get(
            `${baseURL}/download/${projectIds}/${excelType}`,
            {
                headers: headers,
                responseType: "blob", // important
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const getLoginTokenAPI = async (user, pass) => {
    let formData = new FormData();
    formData.append("username", user);
    formData.append("password", pass);
    try {
        const response = await axios.post(baseURL + "/login", formData);
        return response;
    } catch (e) {
        throw e;
    }
};

export const getFileResults = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/results`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const patchFileResults = async (
    projectId,
    fileId,
    updatedData,
    headers
) => {
    try {
        const response = await axios.patch(
            `${baseURL}/projects/${projectId}/files/${fileId}/results`,
            updatedData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const downloadFileResultsExcel = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/results/download`,
            {
                headers: headers,
                responseType: "blob", // important
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const getFileImages = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/images`,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const getFileTables = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/tables/`,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const postTablesUpdate = async (
    projectId,
    fileId,
    tableSchema,
    columnSpec,
    headers
) => {
    try {
        const response = await axios.post(
            `${baseURL}/projects/${projectId}/files/${fileId}/save_grid`,
            { tableSchema: tableSchema, columnSpec: columnSpec },
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const reprocessSavedFile = async (
    projectId,
    fileId,
    tableSchema,
    columnSpec,
    isFileToBeDownloaded,
    pageLocation,
    headers,
    autoIncrementYear,
    forceTwoDecimalPlaces,
) => {
    try {
        const response = await axios.post(
            `${baseURL}/projects/${projectId}/files/${fileId}/reprocess`,
            {
                tableSchema: tableSchema,
                columnSpec: columnSpec,
                isFileToBeDownloaded: isFileToBeDownloaded,
                pageLocation: pageLocation,
                autoIncrementYear: autoIncrementYear,
                forceTwoDecimalPlaces: forceTwoDecimalPlaces
            },
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const getFileColSpec = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/col_spec`,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const getFileDetails = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/file_details/${projectId}/${fileId}`,
            { headers: headers }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const updateFileDetails = async (
    projectId,
    fileId,
    fileDetails,
    headers
) => {
    try {
        const response = await axios.patch(
            `${baseURL}/file_details/${projectId}/${fileId}`,
            fileDetails,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoSignUp = async (user, pass, phoneNumber) => {
    try {
        const response = await Auth.signUp({
            username: user,
            password: pass,
            attributes: {
                email: user,
                "custom:phoneNumber": phoneNumber,
                "custom:acceptedTCs": "False",
            },
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsUpdateTermsAndConditionsUserAttributes = async (
    cognitoUser,
    value
) => {
    try {
        const response = await Auth.updateUserAttributes(cognitoUser, {
            "custom:acceptedTCs": value,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoConfirmSignUp = async (user, validationCode) => {
    try {
        const response = await Auth.confirmSignUp(user, validationCode);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoResendSignUp = async (user) => {
    try {
        const response = await Auth.resendSignUp(user);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoSignIn = async (user, password) => {
    try {
        const response = await Auth.signIn(user, password);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoSignOut = async () => {
    try {
        const response = await Auth.signOut();
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCurrentSession = async () => {
    try {
        const response = await Auth.currentSession();
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCurrentAuthenticatedUser = async () => {
    try {
        const response = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoSetupTOTP = async (cognitoUser) => {
    try {
        const response = await Auth.setupTOTP(cognitoUser);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoVerifyTotpToken = async (cognitoUser, mfaCode) => {
    try {
        const response = await Auth.verifyTotpToken(cognitoUser, mfaCode);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoSetPreferredMFA = async (cognitoUser, preferredMFA) => {
    try {
        const response = await Auth.setPreferredMFA(cognitoUser, preferredMFA);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoConfirmSignIn = async (cognitoUser, mfaCode, mfaType) => {
    try {
        const response = await Auth.confirmSignIn(cognitoUser, mfaCode, mfaType);
        return response;
    } catch (e) {
        throw e;
    }
};

export const registerUser = async (
    user,
    pass,
    name,
    awsUserId,
    company,
    phoneNumber,
    jobTitle,
    headers,
    subscriptionType,
    paymentSchedule,
    x_amzn_marketplace_token
) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("password", pass);
    formData.append("email", user);
    formData.append("aws_user_id", awsUserId);
    formData.append("company", company);
    formData.append("phone_number", phoneNumber);
    formData.append("job_title", jobTitle);

    if (subscriptionType) {
        formData.append("subscription", subscriptionType);
    }

    if (paymentSchedule) {
        formData.append("plan", paymentSchedule);
    }

    if (x_amzn_marketplace_token) {
        formData.append("x_amzn_marketplace_token", x_amzn_marketplace_token)
        try {
            const response = await axios.post(`${baseURL}/aws/registration`, formData, {
                headers: headers,
            });
            return response;
        } catch (e) {
            throw e;
        }
    } else {
        try {
            const response = await axios.post(`${baseURL}/register`, formData, {
                headers: headers,
            });
            return response;
        } catch (e) {
            throw e;
        }
    }
};

export const getCurrentUser = async (headers) => {
    try {
        const response = await axios.get(`${baseURL}/get_user`, {
            headers: headers,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const updateCurrentUser = async (
    headers,
    fieldToUpdate,
    updatedValue
) => {
    const formData = new FormData();
    formData.append("field_to_update", fieldToUpdate);
    formData.append("updated_value", updatedValue);

    try {
        const response = await axios.patch(`${baseURL}/update_user`, formData, {
            headers: headers,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoForgotPassword = async (userEmail) => {
    try {
        const response = await Auth.forgotPassword(userEmail);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoForgotPasswordSubmit = async (
    userEmail,
    verificationCode,
    newPassword
) => {
    try {
        const response = await Auth.forgotPasswordSubmit(
            userEmail,
            verificationCode,
            newPassword
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoNewPasswordRequired = async (
    cognitoUser,
    newPassword
) => {
    try {
        const response = await Auth.completeNewPassword(
            cognitoUser,
            newPassword
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const stripeCreateCheckoutSession = async (
    subscriptionType,
    upgrade,
    headers
) => {
    const formData = new FormData();
    if (subscriptionType) {
        formData.append("plan", subscriptionType);
    }

    if (upgrade) {
        formData.append("upgrade", upgrade);
    }

    try {
        const response = await axios.post(
            `${baseURL}/stripe/create-checkout-session`,
            formData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const stripeManageAccount = async (headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/stripe/manage_stripe_account`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const restoreFile = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/projects/${projectId}/files/${fileId}/restore`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const autogridMask = async (projectId, fileId, maskObject, headers) => {
    try {
        const response = await axios.post(
            `${baseURL}/projects/${projectId}/files/${fileId}/autogrid`,
            { maskObject: maskObject },
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const automergeMask = async (
    projectId,
    fileId,
    maskObject,
    mergeDirection,
    headers
) => {
    try {
        const response = await axios.post(
            `${baseURL}/projects/${projectId}/files/${fileId}/automerge`,
            { maskObject: maskObject, mergeDirection: mergeDirection },
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoChangePassword = async (
    cognitoUser,
    oldPassword,
    newPassword
) => {
    try {
        const response = await Auth.changePassword(
            cognitoUser,
            oldPassword,
            newPassword
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoVerifyUserAttribute = async (cognitoUser, attribute) => {
    try {
        const response = await Auth.verifyUserAttribute(cognitoUser, attribute);
        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoVerifyUserAttributeSubmit = async (
    cognitoUser,
    attribute,
    code
) => {
    try {
        const response = await Auth.verifyUserAttributeSubmit(
            cognitoUser,
            attribute,
            code
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const awsUpdatePhoneNumber = async (cognitoUser, value) => {
    try {
        const response = await Auth.updateUserAttributes(cognitoUser, {
            phone_number: value,
        });
        return response;
    } catch (e) {
        throw e;
    }
};

export const registerGuestUser = async (
    user,
    pass,
    name,
    awsUserId,
    company,
    phoneNumber,
    jobTitle,
    headers,
    subscriptionType,
    paymentSchedule
) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("password", pass);
    formData.append("email", user);
    formData.append("aws_user_id", awsUserId);
    formData.append("company", company);
    formData.append("phone_number", phoneNumber);
    formData.append("job_title", jobTitle);

    if (subscriptionType) {
        formData.append("subscription", subscriptionType);
    }

    if (paymentSchedule) {
        formData.append("plan", paymentSchedule);
    }

    try {
        const response = await axios.patch(
            `${baseURL}/register_guest_user`,
            formData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const emailVerifiedSendAlert = async (headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/email_verified_send_alert`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (e) {
        throw e;
    }
};

export const categoriseData = async (
    projectId,
    fileId,
    results,
    locale,
    headers
) => {
    let data = {
        results: results,
        locale: locale,
    };
    try {
        const response = await axios.post(
            `${baseURL}/categorise/${projectId}/${fileId}`,
            data,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const getCategoriseData = async (projectId, fileId, headers) => {
    try {
        const response = await axios.get(
            `${baseURL}/categorised_data/${projectId}/${fileId}`,
            {
                headers: headers,
            }
        );

        return response;
    } catch (e) {
        throw e;
    }
};

export const awsCognitoFederatedSignIn = async () => {
    try {
        const response = await Auth.federatedSignIn({ customProvider: process.env.REACT_APP_AZURE_AWS_COGNITO_AZURE_AD_APP_NAME });
        return response;
    } catch (e) {
        throw e;
    }
};
