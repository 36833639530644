import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputFieldVerificationCode from "../../../../../components/input-fields/input-fields-verification-code.component";
import QRCodeComponent from "../../../../../components/qr-code/qr-code.component";
import CommonRegistrationButton from "../../../../../components/registration-buttons/registration-button-common.component";
import { setUpMFAFromManageAccount } from "../../../../../redux/thunks/thunks.actions.js";

const SetMfaApp = () => {
    const dispatch = useDispatch();

    const [isValidateMFAButtonDisabled, setIsValidateMFAButtonDisabled] =
        useState(true);
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);
    const [mfaCode, setMfaCode] = useState("");

    const handleMFASetUp = (e) => {
        e.preventDefault();
        if (mfaCode) {
            dispatch(setUpMFAFromManageAccount(mfaCode));
        }
    };

    useEffect(() => {
        verificationCodeConforms
            ? setIsValidateMFAButtonDisabled(false)
            : setIsValidateMFAButtonDisabled(true);
    }, [verificationCodeConforms]);

    return (
        <div data-testid="set-mfa-app-div">
            <div className="larger-text margin-bottom">
                Please scan the QR Code using a MFA Authenticator App of your
                choice.
            </div>
            <QRCodeComponent />
            <div className="field-margin-bottom">
                <InputFieldVerificationCode
                    setVerificationCode={setMfaCode}
                    verificationCode={mfaCode}
                    setVerificationCodeConforms={setVerificationCodeConforms}
                    verificationCodeConforms={verificationCodeConforms}
                    labelText={"MFA code"}
                />
            </div>
            <CommonRegistrationButton
                buttonKind={"primary"}
                isDisabled={isValidateMFAButtonDisabled}
                onClickFunction={handleMFASetUp}
                buttonDescription={"Validate MFA Code"}
                dataTestId={"set-up-mfa"}
            />
        </div>
    );
};

export default SetMfaApp;
