import { ChevronUp16, ChevronDown16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const PageUpDown = ({ totalPages, pageDisplayed, setPageToScrollTo }) => {
    const [pageNumber, setPageNumber] = useState(1);

    const changePage = (direction) => {

        if (direction === "up") {
            let newPage = pageNumber - 1

            if (newPage > 0) {
                setPageToScrollTo(newPage);
                setPageNumber(newPage)
            } else {
                setPageToScrollTo(1);
                setPageNumber(1);
            }

        } else if (direction === "down") {

            let newPage = pageNumber + 1

            if (newPage <= totalPages) {
                setPageToScrollTo(newPage);
                setPageNumber(newPage);
            } else {
                setPageToScrollTo(totalPages);
                setPageNumber(totalPages);
            }
        }

    };

    useEffect(() => {
        setPageNumber(pageDisplayed);
    }, [pageDisplayed]);

    return (
        <>
            <Button
                id="page-up-button"
                className="page-info-button"
                data-testid="mask-page-image-toolbar-page-up"
                disabled={false}
                hasIconOnly={true}
                iconDescription="Previous Page"
                renderIcon={ChevronUp16}
                onClick={() => { changePage("up") }}
            />
            <Button
                id="page-down-button"
                className="page-info-button"
                data-testid="mask-page-image-toolbar-page-down"
                disabled={false}
                hasIconOnly={true}
                iconDescription="Next Page"
                renderIcon={ChevronDown16}
                onClick={() => { changePage("down") }}
            />
        </>
    );
};

export default PageUpDown;
