const pre = "api";
const SET_API_ERROR = `${pre}/SET_API_ERROR`;
const CLEAR_API_ERROR = `${pre}/CLEAR_API_ERROR`;

const apiTypes = {
    SET_API_ERROR,
    CLEAR_API_ERROR,
};

export default apiTypes;
