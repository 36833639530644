import Grids from "@oscar/grid-make";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
    gridInfoConversion,
    gridInfoUnConvert,
    scrollIntoViewHelper,
} from "../../helpers/helper-functions";
import { refImageCaller as importedRefImageCaller } from "./pdf-image.component";

/**
 * Used to more easily mock ref
 * @returns fref current
 */
export const refImageCaller = (ref) => {
    return ref.current;
};

const PDFImage = ({
    imageObject,
    gridInfos,
    resetMaskSideBarInfo,
    allGridInfos,
    setMultiplePageGrids,
    selectedObject,
    imageId,
    pageToScrollTo,
    setPageToScrollTo,
    allWarningGrids,
    setPageDisplayed,
    setZoomInFunction,
    setZoomOutFunction,
    setResetTransformFunction,
    setSelectedObject,
    pageGridUpdate,
    disablePlaceGrid,
    placeGrid,
    setDeleteLineFunction,
    setDeleteRectFunction,
    setClearRectFunction,
    addColumnTables,
    removeColumnTables,
    maxColumnsInfo,
    newColumn,
    setNewColumn,
    recheckColSpec,
    setResetMaskSideBarInfo,
    setUndoEventList,
    undoEventList,
    undo,
    setUndo,
    undoRunXTimes,
    setUndoRunXTimes,
    columnSpec,
    setSelectedPageUrl,
    setSelectedRect,
    autoGridColsRows,
    setAutoGridColsRows,
    isSideNavBarInfoOpen,
    setIsSideNavBarInfoOpen,
    setNavInfoToDisplay,
    undoAutoGrid,
    setUndoAutoGrid,
    blurButtons,
    autoMergeColsRows,
    setAutoMergeColsRows,
    runAutoMerge,
    setRunAutoMerge,
    setIsAutoMergeButtonDisabled,
}) => {
    const [isIntersecting, setIntersecting] = useState(false);
    const imageRef = useRef();
    const [transNode, setTransNode] = useState(null);

    const [pdfHeight, setHeight] = useState(0);
    const [pdfWidth, setWidth] = useState(0);

    const [singlePageGrids, setSinglePageGrids] = useState(null);
    const [populateGridsFunction, setPopulateGridsFunction] = useState(null);
    const [warningGrids, setWarningGrids] = useState([]);

    const [addColRowFunction, setAddColRowFunction] = useState(null);

    const [runPopulateGridsFunction, setRunPopulateGridsFunction] =
        useState(true);

    const [clearCurrentSelectionFunction, setClearCurrentSelectionFunction] =
        useState(null);

    // Values set within the Grid component
    const [localDeleteRectFunction, setLocalDeleteRectFunction] = useState([]);
    const [localDeleteLineFunction, setLocalDeleteLineFunction] = useState([]);
    const [localClearRectFunction, setLocalClearRectFunction] = useState(null);
    const [localDeleteAllRectsFunction, setLocalDeleteAllRectsFunction] =
        useState(null);

    const [localAddAutoMergeRowsColumns, setLocalAddAutoMergeRowsColumns] =
        useState(null);

    const defineEventDetails = useCallback(
        (event) => {
            setIsSideNavBarInfoOpen(false);
            setNavInfoToDisplay(null);

            const updatedTableSchema = [];

            let undoEventListCopy = JSON.parse(JSON.stringify(undoEventList));
            let copyEventDetails = JSON.parse(
                JSON.stringify(event.eventDetails)
            );

            for (let gridsObject of copyEventDetails) {
                let backEndGrid = gridInfoUnConvert(
                    gridsObject["grids"],
                    gridsObject["pdfWidth"],
                    gridsObject["pdfHeight"],
                    gridsObject["pageNumber"],
                    true
                );

                for (let gridObject of backEndGrid) {
                    updatedTableSchema.push({
                        ...gridObject,
                        page_number: gridsObject["pageNumber"],
                    });
                }
            }

            let undoEvent = {
                eventType: event.eventType,
                updatedTableSchema: updatedTableSchema,
                previousColSpec: event.eventColumnSpec,
            };

            undoEventListCopy.push(undoEvent);
            let updatedUndoEventListCopy;

            if (undoEventListCopy.length <= 5) {
                updatedUndoEventListCopy = undoEventListCopy;
            } else {
                updatedUndoEventListCopy = undoEventListCopy.slice(-5);
            }
            setUndoEventList(updatedUndoEventListCopy);
        },
        [
            undoEventList,
            setUndoEventList,
            setIsSideNavBarInfoOpen,
            setNavInfoToDisplay,
        ]
    );

    // Check if current page is Visible. Then setPageDisplayed to current
    const isImageVisibleCheck = useCallback(
        (isIntersecting) => {
            if (isIntersecting) {
                setPageDisplayed(imageId);
            }
        },
        [setPageDisplayed, imageId]
    );

    const recalculateMaxColumns = useCallback(
        (gridInfo) => {
            let newMaxColumns = 0;
            for (let oldGridsInfo of allGridInfos) {
                if (oldGridsInfo["pageNumber"] === imageId) {
                    for (let grid of gridInfo) {
                        if (grid["vLines"].length + 1 > newMaxColumns) {
                            newMaxColumns = grid["vLines"].length + 1;
                        }
                    }
                } else {
                    for (let oldGrid of oldGridsInfo["grids"]) {
                        if (oldGrid["vLines"].length + 1 > newMaxColumns) {
                            newMaxColumns = oldGrid["vLines"].length + 1;
                        }
                    }
                }
            }

            recheckColSpec(newMaxColumns);
        },
        [allGridInfos, imageId, recheckColSpec]
    );

    const onDeleteRect = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onDeleteRect",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
            recalculateMaxColumns(gridInfo);
        },
        [recalculateMaxColumns, allGridInfos, columnSpec, defineEventDetails]
    );

    const onFinishTransformRect = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onFinishTransformRect",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
            recalculateMaxColumns(gridInfo);
        },
        [recalculateMaxColumns, allGridInfos, columnSpec, defineEventDetails]
    );

    const onPlaceGrid = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onPlaceGrid",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
            disablePlaceGrid();
            recalculateMaxColumns(gridInfo);
        },
        [
            disablePlaceGrid,
            recalculateMaxColumns,
            allGridInfos,
            columnSpec,
            defineEventDetails,
        ]
    );

    const onSelectObject = useCallback(
        (selectedObject) => {
            setSelectedObject({ ...selectedObject, page: imageId });
            if (selectedObject["objectId"]) {
                setSelectedPageUrl(imageObject.imageLink);

                let selectedGrid = singlePageGrids.find(
                    (object) =>
                        object["rect"]["id"] === selectedObject["objectId"]
                );

                if (selectedGrid) {
                    setSelectedRect({
                        rectInfo: selectedGrid["rect"],
                        imageId: imageId,
                        pdfWidth: pdfWidth,
                        pdfHeight: pdfHeight,
                        vLines: selectedGrid["vLines"],
                        hLines: selectedGrid["hLines"],
                    });
                } else {
                    setSelectedRect(null);
                }
            } else {
                setSelectedPageUrl(null);
                setSelectedRect(null);
                setIsSideNavBarInfoOpen(false);
                setNavInfoToDisplay(null);
            }
        },
        [
            imageId,
            setSelectedObject,
            imageObject.imageLink,
            singlePageGrids,
            setSelectedPageUrl,
            setSelectedRect,
            setIsSideNavBarInfoOpen,
            setNavInfoToDisplay,
            pdfWidth,
            pdfHeight,
        ]
    );

    const onDeleteVLine = useCallback(
        (gridInfo, lineLocation) => {
            let event = {
                eventType: "onDeleteVLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);

            let gridId = `rect${lineLocation["id"].split("rect")[1]}`;

            for (let grid of gridInfo) {
                if (grid["rect"]["id"] === gridId) {
                    if (
                        grid["vLines"].length + 1 ===
                        maxColumnsInfo["maxColumns"] - 1 &&
                        maxColumnsInfo["repeatMax"] === 1
                    ) {
                        removeColumnTables(lineLocation["columnLocation"]);
                    }
                }
            }
        },
        [
            maxColumnsInfo,
            removeColumnTables,
            allGridInfos,
            columnSpec,
            defineEventDetails,
        ]
    );

    const onPlaceVLine = useCallback(
        (gridInfo, lineLocation, undo) => {
            let event = {
                eventType: "onPlaceVLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);

            for (let grid of gridInfo) {
                for (let vLine of grid["vLines"]) {
                    if (vLine["id"] === lineLocation["id"]) {
                        if (
                            grid["vLines"].length + 1 >
                            maxColumnsInfo["maxColumns"]
                        ) {
                            setNewColumn({
                                x: vLine["x"],
                                id: grid["rect"]["id"],
                                imageId: imageId,
                            });
                            addColumnTables(lineLocation["columnLocation"]);
                        }
                    }
                }
            }
        },
        [
            setNewColumn,
            maxColumnsInfo,
            imageId,
            addColumnTables,
            allGridInfos,
            columnSpec,
            defineEventDetails,
        ]
    );

    const onFinishMoveRect = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onFinishMoveRect",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
        },
        [allGridInfos, columnSpec, defineEventDetails]
    );

    const onDeleteHLine = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onDeleteHLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
        },
        [allGridInfos, columnSpec, defineEventDetails]
    );

    const onFinishMoveVLine = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onFinishMoveVLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
        },
        [allGridInfos, columnSpec, defineEventDetails]
    );

    const onFinishMoveHLine = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onFinishMoveHLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
        },
        [allGridInfos, columnSpec, defineEventDetails]
    );

    const onPlaceHLine = useCallback(
        (gridInfo) => {
            let event = {
                eventType: "onPlaceHLine",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
        },
        [allGridInfos, columnSpec, defineEventDetails]
    );

    const onPlaceVLineExt = useCallback(
        (gridInfo) => {
            recalculateMaxColumns(gridInfo);
        },
        [recalculateMaxColumns]
    );

    const onClearRectLines = useCallback(
        (gridInfo) => {
            recalculateMaxColumns(gridInfo);
        },
        [recalculateMaxColumns]
    );

    const transformWrapperRef = useCallback((node) => {
        if (node !== null) {
            setTransNode(node);
        }
    }, []);

    useEffect(() => {
        if (resetMaskSideBarInfo) {
            setResetMaskSideBarInfo(false);
            populateGridsFunction(
                gridInfoConversion(gridInfos, pdfWidth, pdfHeight)
            );
        }
    }, [
        resetMaskSideBarInfo,
        gridInfos,
        populateGridsFunction,
        pdfHeight,
        pdfWidth,
        runPopulateGridsFunction,
        setResetMaskSideBarInfo,
    ]);

    useEffect(() => {
        let allGridInfosLength = allGridInfos.length;
        if (undo && undoRunXTimes <= allGridInfosLength) {
            let convertedGrid = gridInfoConversion(
                gridInfos,
                pdfWidth,
                pdfHeight
            );
            populateGridsFunction(convertedGrid);
            setUndoRunXTimes(undoRunXTimes + 1);

            if (undoRunXTimes === allGridInfosLength) {
                setUndo(false);
                setUndoRunXTimes(1);
            }
        }
    }, [
        setUndo,
        undo,
        gridInfos,
        populateGridsFunction,
        pdfHeight,
        pdfWidth,
        setUndoRunXTimes,
        undoRunXTimes,
        allGridInfos,
    ]);

    // Sets up Zoom functions
    useEffect(() => {
        if (isIntersecting && transNode !== null) {
            setZoomInFunction(() => transNode.zoomIn);
            setZoomOutFunction(() => transNode.zoomOut);
            setResetTransformFunction(() => transNode.resetTransform);
            blurButtons();
        }
    }, [
        isIntersecting,
        setZoomInFunction,
        setZoomOutFunction,
        setResetTransformFunction,
        transNode,
        blurButtons,
    ]);

    // If this component is the pageToScrollTo then scroll
    //    to this section and reset pageToScrollTo
    useEffect(() => {
        if (imageId === pageToScrollTo) {
            if (pageToScrollTo !== 0) {
                scrollIntoViewHelper(importedRefImageCaller, imageRef);
                setPageToScrollTo(0);
            }
        }
    }, [pageToScrollTo, imageId, setPageToScrollTo, imageRef]);

    // If this component is the pageToScrollTo then scroll
    //    to this section and reset pageToScrollTo
    useEffect(() => {
        isImageVisibleCheck(isIntersecting);
    }, [isIntersecting, isImageVisibleCheck]);

    useEffect(() => {
        // Tries to add the new column to all other grids
        if (newColumn !== null) {
            for (let grid of singlePageGrids) {
                if (
                    grid["rect"]["id"] !== newColumn["id"] ||
                    imageId !== newColumn["imageId"]
                ) {
                    addColRowFunction(newColumn["x"], [], grid.rect.id);
                }
            }
            setNewColumn(null);
        }
    }, [newColumn, imageId, addColRowFunction, setNewColumn, singlePageGrids]);

    useEffect(() => {
        if (selectedObject !== null && selectedObject["page"] !== imageId) {
            clearCurrentSelectionFunction();
        }
    }, [selectedObject, imageId, clearCurrentSelectionFunction]);

    // Prevents access to the delete line function from being overwritten by
    // Non selected grids
    useEffect(() => {
        if (selectedObject !== null && selectedObject["page"] === imageId) {
            setDeleteLineFunction(() => localDeleteLineFunction);
        }
    }, [
        localDeleteLineFunction,
        selectedObject,
        setDeleteLineFunction,
        imageId,
    ]);

    useEffect(() => {
        if (runAutoMerge) {
            localDeleteAllRectsFunction();
            setRunAutoMerge(false);
            setLocalClearRectFunction(null);
        }
    }, [localDeleteAllRectsFunction, setRunAutoMerge, runAutoMerge]);

    useEffect(() => {
        if (selectedObject !== null && selectedObject["page"] === imageId) {
            setDeleteRectFunction(() => localDeleteRectFunction);
        }
    }, [
        localDeleteRectFunction,
        selectedObject,
        setDeleteRectFunction,
        imageId,
    ]);

    useEffect(() => {
        if (selectedObject !== null && selectedObject["page"] === imageId) {
            setClearRectFunction(() => localClearRectFunction);
        }
    }, [localClearRectFunction, selectedObject, setClearRectFunction, imageId]);

    useEffect(() => {
        let warningGrids = [];
        let pageWarningGrids = allWarningGrids.filter(
            (warningGridInfo) => warningGridInfo["pageId"] === imageId
        );

        for (let warningGridInfo of pageWarningGrids) {
            warningGrids.push(warningGridInfo["gridWarning"]);
        }
        setWarningGrids(warningGrids);
    }, [allWarningGrids, imageId]);


    useEffect(() => {
        if (
            singlePageGrids !== null &&
            pdfHeight !== null &&
            pdfHeight !== 0 &&
            pdfWidth !== null &&
            pdfWidth !== 0
        ) {
            setMultiplePageGrids(currentMultiplePageGrids => {
                // pageGridUpdate(singlePageGrids, pdfWidth, pdfHeight, imageId);

                const pageGrids = {
                    grids: singlePageGrids,
                    pdfWidth: pdfWidth,
                    pdfHeight: pdfHeight,
                    pageNumber: imageId,
                };
    
                const multiplePageGridsWithoutNewPage = currentMultiplePageGrids.filter(
                    (gridsInfo) => gridsInfo["pageNumber"] !== imageId
                );
    
                const oldInfo = currentMultiplePageGrids.filter(
                    (gridsInfo) => gridsInfo["pageNumber"] === imageId
                )[0];
    
                if (JSON.stringify(oldInfo) !== JSON.stringify(pageGrids)) {
                    let updateThing = JSON.parse(
                        JSON.stringify([
                            ...multiplePageGridsWithoutNewPage,
                            pageGrids,
                        ])
                    )
                    return updateThing
                }

                return currentMultiplePageGrids

            })
        }
    }, [singlePageGrids, pdfWidth, pdfHeight, imageId, setMultiplePageGrids]);

    // first function to run
    useEffect(() => {
        let valuesToExclude = [0, null];

        if (
            populateGridsFunction !== null &&
            !valuesToExclude.includes(pdfHeight) &&
            !valuesToExclude.includes(pdfWidth)
        ) {
            if (runPopulateGridsFunction) {
                setRunPopulateGridsFunction(false);
                populateGridsFunction(
                    gridInfoConversion(gridInfos, pdfWidth, pdfHeight)
                );
            }
        }
    }, [
        gridInfos,
        populateGridsFunction,
        pdfHeight,
        pdfWidth,
        runPopulateGridsFunction,
    ]);

    // eslint-disable-next-line
    useEffect(() => {
        if (
            importedRefImageCaller(imageRef).clientHeight !== pdfHeight &&
            importedRefImageCaller(imageRef).clientWidth !== pdfWidth
        ) {
            setHeight(importedRefImageCaller(imageRef).clientHeight);
            setWidth(importedRefImageCaller(imageRef).clientWidth);
        }
    });

    useEffect(() => {
        let options = {
            // define the viewport line. at 50% of the page
            rootMargin: "-50% 0px",
            threshold: 0, // any pixel on the screen
        };

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                setIntersecting(entry.isIntersecting);
            });
        };

        let observer = new IntersectionObserver(callback, options);
        observer.observe(importedRefImageCaller(imageRef));
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        let autoMergeColsRowsCopy = JSON.parse(
            JSON.stringify(autoMergeColsRows)
        );

        if (autoMergeColsRowsCopy.length !== 0) {
            localAddAutoMergeRowsColumns(autoMergeColsRowsCopy);
            setAutoMergeColsRows([]);
            setSelectedObject(null);
            setIsAutoMergeButtonDisabled(false);
        }
    }, [
        autoMergeColsRows,
        localAddAutoMergeRowsColumns,
        setAutoMergeColsRows,
        setSelectedObject,
        setIsAutoMergeButtonDisabled,
    ]);

    useEffect(() => {
        let autoGridColsRowsCopy = JSON.parse(JSON.stringify(autoGridColsRows));

        if (
            selectedObject &&
            selectedObject["objectType"] &&
            imageId === selectedObject["page"]
        ) {
            if (
                autoGridColsRowsCopy &&
                (autoGridColsRowsCopy["cols"].length > 0 ||
                    autoGridColsRowsCopy["rows"].length > 0)
            ) {
                let selectedGrid = singlePageGrids.find(
                    (object) =>
                        object["rect"]["id"] === selectedObject["objectId"]
                );

                addColRowFunction(
                    autoGridColsRowsCopy["cols"],
                    autoGridColsRowsCopy["rows"],
                    selectedGrid["rect"]["id"]
                );
                setAutoGridColsRows(null);
                setSelectedObject(null);
                setIsAutoMergeButtonDisabled(false);
            }
        } else {
            if (autoGridColsRowsCopy) {
                autoGridColsRowsCopy.columns = autoGridColsRowsCopy.cols;

                let autoGridColsRowsCopyArray = [autoGridColsRowsCopy];
                localAddAutoMergeRowsColumns(autoGridColsRowsCopyArray);
                setAutoGridColsRows(null);
                setSelectedObject(null);
                setIsAutoMergeButtonDisabled(false);
            }
        }
    }, [
        autoGridColsRows,
        selectedObject,
        setAutoGridColsRows,
        imageId,
        singlePageGrids,
        addColRowFunction,
        localAddAutoMergeRowsColumns,
        setSelectedObject,
        setIsAutoMergeButtonDisabled,
    ]);

    useEffect(() => {
        if (
            selectedObject &&
            imageId === selectedObject["page"] &&
            selectedObject["objectType"] === "rect"
        ) {
            let selectedGrid = singlePageGrids.find(
                (object) => object["rect"]["id"] === selectedObject["objectId"]
            );

            let rectInfo = selectedGrid["rect"];
            rectInfo.x1 = selectedGrid.x1;
            rectInfo.x2 = selectedGrid.x2;
            rectInfo.y1 = selectedGrid.y1;
            rectInfo.y2 = selectedGrid.y2;

            setSelectedRect({
                rectInfo: rectInfo,
                imageId: imageId,
                pdfWidth: pdfWidth,
                pdfHeight: pdfHeight,
                vLines: selectedGrid["vLines"],
                hLines: selectedGrid["hLines"]
            });
        }
    }, [
        singlePageGrids,
        selectedObject,
        imageId,
        setSelectedRect,
        pdfWidth,
        pdfHeight,
    ]);

    useEffect(() => {
        if (undoAutoGrid) {
            let event = {
                eventType: "autoGrid",
                eventDetails: allGridInfos,
                eventColumnSpec: columnSpec,
            };

            defineEventDetails(event);
            setUndoAutoGrid(false);
        }
    }, [
        undoAutoGrid,
        allGridInfos,
        columnSpec,
        defineEventDetails,
        setUndoAutoGrid,
    ]);

    const tooltipClickAction = (gridInfo) => {
        let warningText = gridInfo.warningMessage;

        if (warningText !== null) {
            const warningArray = warningText.split("\n");
            const filteredWarningArray = warningArray.filter((e) => e);

            setIsSideNavBarInfoOpen(true);
            setNavInfoToDisplay(filteredWarningArray);
        }

        let clickedObject = {
            objectType: "rect",
            objectId: gridInfo.rectProps.id,
            page: imageId,
        };
        setSelectedObject(clickedObject);
    };

    return (
        <TransformWrapper
            initialScale={imageObject.imageScale}
            ref={transformWrapperRef}
            doubleClick={{ disabled: true }}
            wheel={{ disabled: true }}
            panning={{ velocityDisabled: true }}
            pinch={{ disabled: true }}
            zoomAnimation={{ disabled: true }}
            alignmentAnimation={{ disabled: true }}
            velocityAnimation={{ disabled: true }}
        >
            {({ positionX, positionY, ...rest }) => (
                <div data-testid={`div-file-pdf-image-${imageId}`}>
                    <TransformComponent>
                        <div className="width-70vw flex-center">
                            <div className="change-raise-zIndex">
                                <Grids
                                    key={imageId}
                                    setPopulateGrids={setPopulateGridsFunction}
                                    allowPlaceGrid={placeGrid}
                                    gridWarnings={warningGrids}
                                    onPlaceGrid={onPlaceGrid}
                                    onPlaceVLine={onPlaceVLine}
                                    onDeleteVLine={onDeleteVLine}
                                    onDeleteRect={onDeleteRect}
                                    onFinishTransformRect={onFinishTransformRect}
                                    width={pdfWidth}
                                    height={pdfHeight}
                                    onSelectObject={onSelectObject}
                                    setAddColRowFunction={setAddColRowFunction}
                                    setClearCurrentSelectionFunction={
                                        setClearCurrentSelectionFunction
                                    }
                                    updateDeleteLineFunction={
                                        setLocalDeleteLineFunction
                                    }
                                    updateDeleteRectFunction={
                                        setLocalDeleteRectFunction
                                    }
                                    setExternalGrids={setSinglePageGrids}
                                    onFinishMoveRect={onFinishMoveRect}
                                    onDeleteHLine={onDeleteHLine}
                                    onPlaceHLine={onPlaceHLine}
                                    onFinishMoveVLine={onFinishMoveVLine}
                                    onFinishMoveHLine={onFinishMoveHLine}
                                    onPlaceVLineExt={onPlaceVLineExt}
                                    onClearRectLines={onClearRectLines}
                                    updateClearRectFunction={
                                        setLocalClearRectFunction
                                    }
                                    tooltipClickAction={tooltipClickAction}
                                    isSideNavBarInfoOpen={isSideNavBarInfoOpen}
                                    setClearAllRectsLines={
                                        setLocalDeleteAllRectsFunction
                                    }
                                    setAddAutoMergeRowsColumns={
                                        setLocalAddAutoMergeRowsColumns
                                    }
                                />
                            </div>
                            <img
                                name={`file-pdf-image-${imageId}`}
                                className="file-pdf-image"
                                id={`file-pdf-image-${imageId}`}
                                data-testid={`file-pdf-image-${imageId}`}
                                loading="lazy"
                                src={imageObject.imageLink}
                                style={{
                                    transform: `rotate(${imageObject.imageRotation}deg)`,
                                }}
                                ref={imageRef}
                                alt="Page component"
                            />
                        </div>
                    </TransformComponent>
                </div>
            )}
        </TransformWrapper>
    );
};

export default PDFImage;
