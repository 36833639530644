import { Form, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const InputFieldPhoneNumber = ({
    phoneNumber,
    setPhoneNumber,
    setIsSavePhoneNumberButtonDisabled,
    testId,
}) => {
    const [showPhoneNumberWarning, setShowPhoneNumberWarning] = useState(false);
    const [phoneNumberWarning, setPhoneNumberWarning] = useState("");

    useEffect(() => {
        let phoneNumberValidator =
            /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

        let isPhoneNumberValid = phoneNumberValidator.test(phoneNumber);
        const identifier = setTimeout(() => {
            if (phoneNumber === "") {
                setPhoneNumberWarning("");
                setShowPhoneNumberWarning(false);
                setIsSavePhoneNumberButtonDisabled(true);
            } else if (isPhoneNumberValid === false) {
                setPhoneNumberWarning("Please enter a valid phone number");
                setShowPhoneNumberWarning(true);
                setIsSavePhoneNumberButtonDisabled(true);
            } else {
                setPhoneNumberWarning("");
                setShowPhoneNumberWarning(false);
                setIsSavePhoneNumberButtonDisabled(false);
            }
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [phoneNumber, setIsSavePhoneNumberButtonDisabled]);

    return (
        <Form>
            <TextInput
                id={`${testId}-phone-number-input-field`}
                data-testid={`${testId}-phone-number-input-field`}
                labelText="Enter a phone number, including + and the country code, for example +12065551212."
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={() => {
                    if (phoneNumber === "") {
                        setPhoneNumberWarning("");
                        setShowPhoneNumberWarning(false);
                    }
                }}
                invalid={showPhoneNumberWarning}
                invalidText={phoneNumberWarning}
                type="text"
            />
        </Form>
    );
};

export default InputFieldPhoneNumber;
