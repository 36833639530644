import { ArrowDown16, ArrowUp16 } from "@carbon/icons-react";
import { Button, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const ResultsDataTableToolbarValidationChecks = ({
    onArrowUp,
    onArrowDown,
    totalValidationChecks,
    currentValidationCheckInput,
    filteringTable,
}) => {
    const [displayComponent, setDisplayComponent] = useState(false);

    useEffect(() => {
        let validationList = [0, null];
        validationList.includes(totalValidationChecks)
            ? setDisplayComponent(false)
            : setDisplayComponent(true);
    }, [totalValidationChecks, setDisplayComponent]);

    return (
        <>
            {displayComponent && !filteringTable ? (
                <>
                    <Button
                        renderIcon={ArrowUp16}
                        className="icon-button-dashboard"
                        data-testid="results-page-previous-validation-check-button"
                        id="results-page-previous-validation-check-button"
                        iconDescription="Previous Validation Check"
                        hasIconOnly
                        onClick={onArrowUp}
                    />
                    <Button
                        renderIcon={ArrowDown16}
                        className="icon-button-dashboard"
                        data-testid="results-page-next-validation-check-button"
                        id="results-page-next-validation-check-button"
                        iconDescription="Next Validation Check"
                        hasIconOnly
                        onClick={onArrowDown}
                    />
                    <TextInput
                        className="validation-check-input disabled-input"
                        id="results-page-validation-check-input"
                        data-testid="results-page-validation-check-input"
                        type="text"
                        value={`${currentValidationCheckInput} / ${totalValidationChecks}`}
                        disabled={true}
                        hideLabel={true}
                        labelText="Validation Check Number"
                    />
                </>
            ) : null}
        </>
    );
};

export default ResultsDataTableToolbarValidationChecks;
