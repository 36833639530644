import { Loading } from "carbon-components-react";
import React from "react";
import AltDownloadButtons from "./alt-download-buttons.component";
import AltGridResultsButton from "./alt-grid-results-button.component";
import AltStatusNotificationContainer from "./alt-status-notification-container.component";

const AltActionButtons = (props) => {
    const rowData = props["data"];
    const actionCell = rowData["Actions"];
    const loadingStatuses = ["Uploading", "ReLoading", "Categorising"];

    return (
        <span
            style={{
                display: "flex",
                alignItems: "flex-end",
            }}
            className="button-container"
        >
            {loadingStatuses.includes(rowData["File Status"]) ? (
                <span>
                    <Loading
                        description="Active loading indicator"
                        withOverlay={false}
                        small={true}
                        style={{ position: "relative", bottom: "-3.5px" }}
                    />
                </span>
            ) : (
                <span>
                    <AltStatusNotificationContainer
                        fileWarnings={actionCell["warnings"]}
                        fileValidationErrors={actionCell["validationErrors"]}
                        fileState={rowData["File Status"]}
                        fileId={actionCell["fileId"]}
                        projectId={actionCell["projectId"]}
                    />
                    <AltDownloadButtons
                        fileId={actionCell["fileId"]}
                        projectId={actionCell["projectId"]}
                        fileName={actionCell["fileName"]}
                    />
                    <AltGridResultsButton
                        fileId={actionCell["fileId"]}
                        projectId={actionCell["projectId"]}
                        fileName={actionCell["fileName"]}
                    />
                </span>
            )}
        </span>
    );
};

export default AltActionButtons;
