import { Search16 } from "@carbon/icons-react";
import { TextInput } from "carbon-components-react";
import React from "react";
import "./data-table-toolbar-search.styles.scss";

const ResultsDataTableToolbarSearch = ({
    onFilterTextBoxChanged,
    placeHolderText,
    searchedValue,
    pageLocation,
}) => {
    return (
        <>
            <div className="results-page-data-table-toolbar-search flex-direction-row">
                <Search16 className="margin-left grey" />
                <TextInput
                    id={`${pageLocation}-page-data-table-toolbar-search`}
                    data-testid={`${pageLocation}-page-data-table-toolbar-search`}
                    placeholder={placeHolderText}
                    onChange={(e) => onFilterTextBoxChanged(e)}
                    labelText=""
                    value={searchedValue}
                ></TextInput>
            </div>
        </>
    );
};

ResultsDataTableToolbarSearch.defaultProps = {
    onFilterTextBoxChanged: null,
    placeHolderText: "Search Table",
};

export default ResultsDataTableToolbarSearch;
