import { CheckmarkFilled32 } from "@carbon/icons-react";
import React, { Fragment } from "react";

const Selector = ({
    id,
    componentSpecificBoolean,
    generalBoolean,
    handleClick,
}) => {
    return (
        <Fragment>
            <div
                id={`circle-border-${id}`}
                data-testid={`circle-border-${id}`}
                className={`circle-position ${
                    generalBoolean ? "circle-border" : ""
                }`}
            ></div>
            <CheckmarkFilled32
                id={`circle-selector-${id}`}
                data-testid={`circle-selector-${id}`}
                className={`${
                    componentSpecificBoolean
                        ? "circle-selected"
                        : "circle-unselected"
                }  circle-design circle-position`}
                onClick={handleClick}
            />
        </Fragment>
    );
};

export default Selector;
