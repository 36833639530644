import { Undo16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const UndoButton = ({ handleUndo, isUndoButtonMaskPageDisabled,}) => {
    return (
        <Button
            renderIcon={Undo16}
            iconDescription="Undo"
            data-testid="mask-page-file-toolbar-undo-button"
            id="mask-page-file-toolbar-undo-button"
            hasIconOnly={true}
            onClick={handleUndo}
            className={`default-button-colour ${
                isUndoButtonMaskPageDisabled ? "disabled-button" : ""
            }`}
            disabled={isUndoButtonMaskPageDisabled}
        />
    );
};

export default UndoButton;
