import { Dropdown, Button } from "carbon-components-react";
import { InformationFilled16 } from "@carbon/icons-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const MaskTableSetup = ({
    pathname,
    columnSpec,
    maxColumnsInfo,
    setColumnSpec,
    setResetColSpec,
    setIsEditing,
    isEditing,
}) => {
    const [startUp, setStartUp] = useState(true);
    const [initMaxColumnsInfo, setInitMaxColumnsInfo] = useState(null);
    const initColSpec = useSelector((state) => state.files.fileColSpec);

    const columnOptions = useMemo(() => {
        let options = [
            "Date",
            "Transaction Code",
            "Description",
            "Debit",
            "Credit",
            "Combined Credit/Debit",
            "Balance",
            "Other",
            "Unselected",
        ];

        return options;
    }, []);

    const columnTypes = useMemo(() => {
        let types = [
            "Date",
            "TransactionCode",
            "Description",
            "Debit",
            "Credit",
            "Amount",
            "Balance",
            "NKF",
            "Unselected",
        ];
        return types;
    }, []);

    /**
     * Transforms user initColumnSpec to user values
     */
    const initColumnSpecTransform = useCallback(
        (columnSpec) => {
            return columnSpec.map((type, i) => {
                return {
                    columnIndex: i,
                    type: columnOptions[
                        columnTypes.findIndex((el) => el === type)
                    ],
                };
            });
        },
        [columnOptions, columnTypes]
    );

    /**
     * Creates initial array
     */
    const columnSpecSetUp = useCallback(
        (maxColumns) => {
            if (initColSpec["col_spec"].length !== 0) {
                setColumnSpec(initColumnSpecTransform(initColSpec["col_spec"]));
            } else {
                setColumnSpec(
                    [...Array(maxColumns).keys()].map((i) => {
                        return { columnIndex: i, type: "Unselected" };
                    })
                );
            }
        },
        [setColumnSpec, initColSpec, initColumnSpecTransform]
    );

    useEffect(() => {
        setResetColSpec(() => () => columnSpecSetUp(initMaxColumnsInfo));
    }, [columnSpecSetUp, initMaxColumnsInfo, setResetColSpec]);

    useEffect(() => {
        if (
            initColSpec !== null &&
            initColSpec.hasOwnProperty("file_id") &&
            initColSpec["file_id"] === pathname.split("/")[3] &&
            maxColumnsInfo.hasOwnProperty("maxColumns") &&
            !isEditing
        ) {
            if (startUp) {
                setInitMaxColumnsInfo(maxColumnsInfo["maxColumns"]);
                columnSpecSetUp(maxColumnsInfo["maxColumns"]);
                setStartUp(false);
            }
        }
    }, [
        maxColumnsInfo,
        startUp,
        initColSpec,
        pathname,
        isEditing,
        columnSpecSetUp,
    ]);

    /**
     * Changes the value in the array that was changed in the dropdown
     * @param {int} columnIndex
     * @param {object} event
     */
    const modColumnSpec = (columnIndex, event) => {
        let newColumnSpec = JSON.parse(JSON.stringify(columnSpec));
        let columnChange = newColumnSpec.filter(
            (value) => value["columnIndex"] === columnIndex
        )[0];
        columnChange["type"] = event["selectedItem"];
        setColumnSpec(newColumnSpec);
    };

    const renderLayoutButton = () => {
        if (columnSpec.length === 5) {
            return (
                <>
                    <Button
                        size="sm"
                        onClick={renderLayoutButtonOnClickHandler}
                        className="primary-colour">
                        Set Standard Layout
                    </Button>
                    <Button
                        renderIcon={InformationFilled16}
                        id="standard-layout-tooltip-button"
                        iconDescription="Sets the column definitions to Date, Description, Debit, Credit and Balance."
                        hasIconOnly={true}
                        className="standard-layout-info-button"
                    />
                </>
            )
        } else {

        }
    }

    const renderLayoutButtonOnClickHandler = () => {
        const standardLayout = ["Date", "Description", "Debit", "Credit", "Balance"]

        let newColumnSpec = columnSpec.map((value, i) => {
            return { "columnIndex": i, "type": standardLayout[i] }
        })
        setColumnSpec(newColumnSpec)
        setIsEditing(true)
    }

    return (
        <div
            className="mask-page-side-bar-sections"
            data-testid="mask-page-side-bar-set-up"
        >
            {renderLayoutButton()}
            {columnSpec.map((value, i) => {
                const columnIndex = value["columnIndex"];
                return (
                    <Dropdown
                        id={`mask-table-setup-column-${columnIndex}-dropdown`}
                        data-testid={`mask-table-setup-column-${columnIndex}-dropdown`}
                        key={columnIndex}
                        titleText={`Column ${i + 1}`}
                        label={value["type"]}
                        items={columnOptions}
                        className="drop-drown-extend"
                        onChange={(e) => {
                            modColumnSpec(columnIndex, e);
                            setIsEditing(true);
                        }}
                        selectedItem={value["type"]}
                    />
                );
            })}
        </div>
    );
};

MaskTableSetup.defaultProps = {
    columnSpec: [],
    setResetColSpec: () => { },
};

export default MaskTableSetup;
