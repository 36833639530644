import { Button } from "carbon-components-react";
import React, { useState } from "react";
import StatusNotification from "../../../status-notification/status-notification.component";

const StatusNotificationContainer = ({
    fileNotes,
    fileWarnings,
    fileValidationErrors,
    fileState,
    fileId,
    projectId,
    raiseZIndex,
}) => {
    const [showNotification, setShowNotification] = useState(false);
    const [buttonDescription, setButtonDescription] = useState();
    const [icon, setIcon] = useState();
    const [iconType, setIconType] = useState();

    const toggleNotification = () => {
        if (iconType === "success") {
            return;
        }
        raiseZIndex(true);
        setShowNotification(!showNotification);
    };

    const closeNotification = (event) => {
        raiseZIndex(false);
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowNotification(false);
        }
    };

    return (
        <div onBlur={closeNotification}>
            <Button
                renderIcon={icon}
                id={`${iconType}-status-${fileId}`}
                data-testid={`${iconType}-status-${fileId}`}
                className={
                    iconType === "note" ? "note-tick" : `${iconType}-tick`
                }
                iconDescription={buttonDescription}
                hasIconOnly={true}
                onClick={toggleNotification}
            />
            <StatusNotification
                showNotification={showNotification}
                fileNotes={fileNotes}
                fileWarnings={fileWarnings}
                fileValidationErrors={fileValidationErrors}
                fileState={fileState}
                fileId={fileId}
                projectId={projectId}
                iconType={iconType}
                setButtonDescription={setButtonDescription}
                setIcon={setIcon}
                setIconType={setIconType}
                toggleNotification={toggleNotification}
            />
        </div>
    );
};

export default StatusNotificationContainer;
