import { Button, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const PageInformation = ({ totalPages, pageDisplayed, setPageToScrollTo }) => {
    const [pageNumber, setPageNumber] = useState(1);

    const changePage = (e) => {
        if (e.key === "Enter") {
            const typedValue = parseInt(e.target.value);

            if (isNaN(parseFloat(typedValue))) {
                setPageNumber("");
                return;
            }

            if (typedValue > totalPages || typedValue === 0) {
                alert(
                    `Page ${typedValue} does not exist. Please select a page between 1 and ${totalPages}.`
                );
            } else {
                setPageToScrollTo(typedValue);
                setPageNumber(typedValue);
            }
        }
    };

    useEffect(() => {
        setPageNumber(pageDisplayed);
    }, [pageDisplayed]);

    return (
        <>
            <TextInput
                id="mask-page-image-toolbar-page-input"
                data-testid="mask-page-image-toolbar-page-input"
                onChange={(event) => setPageNumber(event.target.value)}
                onKeyUp={(event) => changePage(event)}
                type="text"
                value={pageNumber}
                hideLabel={true}
                labelText="Insert page number"
                title="Press Enter to confirm"
            />
            <Button
                id="page-info-button"
                className="page-info-button"
                data-testid="mask-page-image-toolbar-of"
                disabled={true}
            >
                of
            </Button>
            <Button
                id="mask-page-image-toolbar-total-pages"
                className="page-info-button"
                data-testid="mask-page-image-toolbar-total-pages"
                disabled={true}
            >
                {totalPages}
            </Button>
        </>
    );
};

export default PageInformation;
