import { ZoomReset16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const ZoomRotateResetButton = ({
    allImagesInfo,
    setAllImagesInfo,
    resetTransformFunction,
    pageDisplayed,
    page,
    blurButtons,
}) => {
    const resetImage = () => {
        let newAllImagesInfo = [...allImagesInfo];
        for (let element of newAllImagesInfo) {
            if (pageDisplayed === element.imageId) {
                element.imageRotation = 0;
            }
        }
        setAllImagesInfo(newAllImagesInfo);
        blurButtons();
    };

    return (
        <Button
            renderIcon={ZoomReset16}
            iconDescription="Reset Image (Ctrl+Enter)"
            data-testid={`${page}-page-image-toolbar-zoom-rotate-reset-button`}
            id={`${page}-page-image-toolbar-zoom-rotate-reset-button`}
            className="default-button-colour"
            hasIconOnly={true}
            onClick={() => {
                if (resetTransformFunction) {
                    resetTransformFunction();
                }
                if (page === "mask") {
                    resetImage();
                }
            }}
        />
    );
};

export default ZoomRotateResetButton;
