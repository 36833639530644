import types from './auth.types';

export const initialState = {
    authToken: null,
    tokenType: null,
    loginError: null,
    qrCode: null,
    qrCodeUsername: null,
    qrCognitoUser: null,
    progressToNextStep: null,
    redirectToLogin: null,
    authMessage: null,
    cognitoUser: null,
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_AUTH_TOKEN:
            return { ...state, authToken: action.payload };
        case types.SET_TOKEN_TYPE:
            return { ...state, tokenType: action.payload };
        case types.SET_LOGIN_ERROR:
            return { ...state, loginError: action.payload };
        case types.SET_QR_CODE:
            return { ...state, qrCode: action.payload };
        case types.SET_QR_CODE_USERNAME:
            return { ...state, qrCodeUsername: action.payload };
        case types.SET_QR_COGNITO_USER:
            return { ...state, qrCognitoUser: action.payload };
        case types.SET_PROGRESS_TO_NEXT_STEP:
            return { ...state, progressToNextStep: action.payload };
        case types.SET_REDIRECT_TO_LOGIN:
            return { ...state, redirectToLogin: action.payload };
        case types.SET_AUTH_MESSAGE:
            return { ...state, authMessage: action.payload };
        case types.SET_COGNITO_USER:
            return { ...state, cognitoUser: action.payload };
        case types.CLEAR_AUTH_TOKEN:
            return { ...state, authToken: null};
        default:
            return state;
    }
}

export default authReducer;