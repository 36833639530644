import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React from "react";
import RegisterUserAllFields from "../../components/input-fields/register-user-all-fields.component";
import "./accordion.styles.scss";

const AccordionAccountInfo = ({
    accountInfoAccordionBorder,
    isAccountInfoFinished,
    isAccountInfoAccordionOpen,
    setUser,
    user,
    setEmailConforms,
    emailConforms,
    setPass,
    pass,
    passwordConforms,
    setDisplayPasswordRequirements,
    displayPasswordRequirements,
    setPasswordConforms,
    handleRegisterNewUser,
    accountInfoNextButtonDisabled,
    setName,
    name,
    subscriptionType,
}) => {
    return (
        <Accordion
            className={accountInfoAccordionBorder}
            data-testid="accordion-account-info"
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isAccountInfoFinished ? (
                            <div
                                data-testid="accordion-account-info-circle"
                                className={`circle ${
                                    isAccountInfoAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                className="relative-position orange-fill"
                                data-testid="accordion-account-info-checkmark"
                            />
                        )}
                        <p>Account Details</p>
                    </div>
                }
                disabled={true}
                open={isAccountInfoAccordionOpen}
            >
                <RegisterUserAllFields
                    setUser={setUser}
                    user={user}
                    setEmailConforms={setEmailConforms}
                    emailConforms={emailConforms}
                    setPass={setPass}
                    pass={pass}
                    setPasswordConforms={setPasswordConforms}
                    passwordConforms={passwordConforms}
                    setDisplayPasswordRequirements={
                        setDisplayPasswordRequirements
                    }
                    displayPasswordRequirements={displayPasswordRequirements}
                    accountInfoNextButtonDisabled={accountInfoNextButtonDisabled}
                    handleRegisterNewUser={handleRegisterNewUser}
                    name={name}
                    setName={setName}
                    className={"field-margin-bottom"}
                    inputFieldPasswordId={"accordion-account-info"}
                    buttonDescription={"Next"}
                    buttonId={"register-new-user"}
                    inputFieldNamedId={"register-page"}
                />
                {subscriptionType === "starter" ? (
                    <div className="bold-text">No credit card required</div>
                ) : null}
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionAccountInfo;
