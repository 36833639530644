import { Select_0216 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const AddGridButton = ({ gridButtonClass, toggleAllowPlaceGrid }) => {
    return (
        <Button
            renderIcon={Select_0216}
            iconDescription="Add Mask"
            data-testid="mask-page-file-toolbar-add-grid-button"
            id="mask-page-file-toolbar-add-grid-button"
            className={gridButtonClass}
            hasIconOnly={true}
            onClick={toggleAllowPlaceGrid}
        />
    );
};

export default AddGridButton;
