import {
    setProjectFileState,
    updateProjectFile,
} from "../websocket/websocket.utils";
import types from "./projects.types";
import { addFiles } from "./projects.utils";

export const initialState = {
    projects: [],
    loading: false,
    files_uploading: false,
};

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TOGGLE_PROJECT_LOAD:
            return {
                ...state,
                loading: !state.loading
            }
        case types.TOGGLE_FILES_UPLOADING:
            return {
                ...state,
                files_uploading: !state.files_uploading
            }
        case types.ADD_PROJECT:
            return {
                ...state,
                projects: [...state.projects, action.payload],
                loading: false,
            };
        case types.REMOVE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(
                    (project) => action.payload !== project.project_id
                ),
                loading: false,
            };
        case types.SET_PROJECTS:
            return {
                ...state,
                projects: action.payload.map((project) => ({
                    ...project,
                    files: [],
                })),
                loading: false,
            };
        case types.ADD_FILES:
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.project_id === action.id
                        ? {
                              ...project,
                              files: addFiles(
                                  project.files,
                                  action.payload,
                              ),
                          }
                        : project
                ),
                loading: false,
                files_uploading: false
            };
        case types.REMOVE_FILE:
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.project_id === action.project_id
                        ? {
                              ...project,
                              files: project.files.filter(
                                  (file) => file.file_id !== action.file_id
                              ),
                          }
                        : project
                ),
                loading: false,
            };
        case types.RENAME_PROJECT:
            return {
                ...state,
                projects: state.projects.map((project) =>
                    project.project_id === action.project_id
                        ? {
                              ...project,
                              name: action.project_name,
                              description: action.project_description,
                          }
                        : project
                ),
                loading: false
            };
        case types.WEBSOCKET_UPDATE:
            return {
                ...state,
                projects: updateProjectFile(state.projects, action),
                loading: false,
            };
        case types.UPDATE_FILE_STATE:
            return {
                ...state,
                projects: setProjectFileState(state.projects, action),
                loading: false,
            };

        default:
            return state;
    }
};

export default projectReducer;
