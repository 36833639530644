import { Copy32, Table32, RowExpand32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import {
    autoMergeAutoGridReturnAll,
} from "../../../../src/helpers/helper-functions-2";

const CopyButton = ({
    selectedRect,
    setAutoMergeColsRows,
    setRunAutoMerge,
    backEndSubmitGrids,
    blurButtons,
    showCopyButtons,
    setShowCopyButtons,
    setDisplayMergeButtons,
}) => {

    var onClickCopyAllHandler = async () => {
        if (selectedRect) {
            var allRects = autoMergeAutoGridReturnAll(backEndSubmitGrids.page);

            // Take the columns from the selected rect and replicate to all other masks
            var colsToCopy = []
            selectedRect.vLines.forEach((line) => (
                colsToCopy.push(line.x)
            ))

            var masksToSet = []
            allRects.forEach((rect) => {
                // Set the rows back to absolute?
                rect.columns = colsToCopy
                var rectRows = []
                rect.rows.forEach((row) => {
                    rectRows.push(row * rect.height)
                }
                )
                rect.rows = rectRows
                masksToSet.push(rect)
            })

            setShowCopyButtons(!showCopyButtons)
            setRunAutoMerge(true)
            await new Promise(r => setTimeout(r, 1000));
            setAutoMergeColsRows(masksToSet)
        }
    }

    var onClickCopyDownHandler = async () => {
        if (selectedRect) {
            var allRects = autoMergeAutoGridReturnAll(backEndSubmitGrids.page);

            // Take the columns from the selected rect and replicate to all other masks
            var colsToCopy = []
            selectedRect.vLines.forEach((line) => (
                colsToCopy.push(line.x)
            ))

            var newMasks = allRects.map((rect) => {

                if (rect.pageNumber >= selectedRect.imageId) {
                    rect.columns = colsToCopy
                } else {
                    var rectCols = []
                    rect.columns.forEach((col) => {
                        rectCols.push(col * rect.width)
                    })
                    rect.columns = rectCols
                }
                // Set the rows back to absolute
                var rectRows = []
                rect.rows.forEach((row) => {
                    rectRows.push(row * rect.height)
                }
                )
                rect.rows = rectRows

                return rect
            })

            setShowCopyButtons(!showCopyButtons)
            setRunAutoMerge(true)
            await new Promise(r => setTimeout(r, 1000));
            setAutoMergeColsRows(newMasks)
        }
    }

    const onCopyToggle = () => {
        setDisplayMergeButtons(false)
        setShowCopyButtons(!showCopyButtons)
        blurButtons()
    }


    return (
        <div className="merge-buttons">
            <Button
                renderIcon={Copy32}
                iconDescription="Copy Columns"
                data-testid="mask-page-file-toolbar-copy-button"
                id="mask-page-file-toolbar-copy-button"
                className={`default-button-colour ${
                    selectedRect === null ? "disabled-button" : ""
                }`}
                hasIconOnly={true}
                onClick={onCopyToggle}
                disabled={selectedRect === null}
            />
            {showCopyButtons ? (
                <div className="merge-rows-buttons">
                    <Button
                        renderIcon={Table32}
                        iconDescription="Copy columns to all other masks"
                        data-testid="mask-page-file-toolbar-copy-button"
                        id="mask-page-file-toolbar-copy-button"
                        className="default-button-colour"
                        hasIconOnly={true}
                        onClick={() => onClickCopyAllHandler()}
                    />
                    <Button
                        renderIcon={RowExpand32}
                        iconDescription="Copy columns from this mask down"
                        data-testid="mask-page-file-toolbar-copy-down-button"
                        id="mask-page-file-toolbar-copy-down-button"
                        className="default-button-colour"
                        hasIconOnly={true}
                        onClick={() => onClickCopyDownHandler()}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default CopyButton;
