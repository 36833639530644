import { Checkbox } from "carbon-components-react";
import React from "react";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const TermsAndConditionsText = ({
    isChecked,
    setIsChecked,
    continueButtonDisabled,
    onClick,
}) => {
    return (
        <>
            <div data-testid="terms-and-conditions-text">
                <h3>
                    <b className="bold eula-tile">
                        Oscar End User License Agreement - v112022
                    </b>
                </h3>
                <br></br>
                <p>
                    <b className="bold eula-tile">
                        PLEASE READ THIS END USER LICENSE AGREEMENT CAREFULLY
                        BEFORE CONTINUING
                    </b>
                </p>
                <br></br>
                <p>
                    This End User License Agreement (‘Agreement’) is by and
                    between Demarq Limited, the company registered in England
                    with its address at the Old Electrical Workshop Main Street,
                    Welby, Grantham, Lincolnshire, NG32 3LT (Licensor’), and the
                    entity or individual entering into the Agreement (‘You’).
                    Licensor and You are hereinafter jointly referred to as the
                    Parties or each individually as a Party.
                </p>
                <br></br>
                <b className="bold">1. License Acceptance Procedure</b>
                <br></br>
                1.1 By clicking the ‘I agree’ button (or a similar button or
                checkbox) or by downloading, installing, opening or using the
                software product this Agreement relates to and/or into which it
                embedded (the ‘Software’), You accept and agree to the terms and
                conditions of this Agreement and the limited warranty and
                limitation of liability set out herein. If You do not agree to
                the terms herein, then You shall click the ‘I do not agree’
                button (or a similar button or checkbox) to terminate the
                operation of the Software and or shall not use or install the
                Software. You shall also delete any and all parts of the Software
                (including, but not limited to, setup and installation files)
                from your computer or device and, if you acquired the Software on
                installation media (and in any event, within 7 calendar days of
                its receipt) return to the Licensor or the relevant software
                reseller such installation media, any other items provided that
                are part of this Software and your dated proof of purchase.
                <br></br>
                1.2 If Your use of the Software is pursuant to an executed
                licence agreement, such agreement shall apply instead of the
                terms and conditions in this Agreement.
                <br></br>
                <br></br>
                <b className="bold">2. Effective Date</b>
                <br></br>
                2.1 This Agreement is effective on the earlier of (1) Your
                acceptance of this Agreement in the online version where You
                shall press the ‘I agree’ button (or a similar button or
                checkbox); (2) installation or use of the Software licensed
                hereunder by You or on Your behalf; (3) payment of the fees in
                the invoice referring to this Agreement; or (4) such other date
                as agreed in between the Parties in the Order or otherwise in
                writing (the ‘Effective Date’).
                <br></br>
                <br></br>
                <b className="bold">3. Grant of Rights</b>
                <br></br>
                3.1 Provided that You have paid the applicable licence fees, the
                Licensor grants to You a limited, non-exclusive,
                non-transferable, non-sublicensable right to use the Software in
                the Territory, during the Subscription Term, and strictly in
                accordance with and subject to all the terms and conditions of
                this Agreement.
                <br></br>
                3.2 The Software will be delivered to you in electronic form and
                shall be deemed accepted on delivery.
                <br></br>
                3.3 Where the Parties agree in the Order the Licensor shall also
                provide support services as a part of the Software license
                subscription.
                <br></br>
                <br></br>
                <b className="bold">4. License Restrictions</b>
                <br></br>
                4.1 During the Subscription Term, You may use, load, execute,
                employ and store the Software (including any related
                documentation) only for Your and Your Affiliates’ internal
                business purposes on computer(s) or device(s) owned, leased
                and/or controlled by You or, where relevant, Your Affiliates. The
                Software may not be used for the purposes of providing services
                to third parties (e.g., business process outsourcing, service
                bureau applications or third party training). You shall not
                lease, loan, resell, sublicense or otherwise distribute the
                Software.
                <br></br>
                4.2 The authorised use of the Software shall not exceed the
                Licensed Capacity purchased by You.
                <br></br>
                4.3 If you transfer possession of any copy of the Software to
                another party except as provided above, your licence is
                automatically terminated.
                <br></br>
                4.4 You may not copy, translate, reverse engineer, decompile,
                disassemble, modify or create derivative works based on the
                Software, except as expressly permitted by the law of this
                Licence Agreement. You may not vary, delete or obscure any
                notices of proprietary rights or any product identification or
                restrictions on or in the Software. You may not rent, lease,
                sub-licence, sell, assign, pledge, transfer or otherwise dispose
                of the Software, on a temporary or permanent basis, without the
                prior written consent of the Licensor.
                <br></br>
                <br></br>
                <b className="bold">5. Undertakings</b>
                <br></br>
                5.1 You undertake to:
                <br></br>
                a) Use the Software only as expressly permitted by the terms and
                conditions of this Agreement;
                <br></br>
                b) Ensure that, prior to use of the Software by your employees,
                agents or any third parties (where relevant), all such parties
                are notified of the terms and conditions of this Agreement; and
                <br></br>
                c) Reproduce and include our copyright notice (or such other
                party's copyright notice as specified on the Software) on all and
                any copies of the Software, including any partial copies of the
                Software.
                <br></br>
                5.2 In cases where the Software is used by You to process Your
                Data, including extracting any of Your Data from or to any third
                party applications, You shall be solely responsible for the
                accuracy of Your Data and for authorised the usage of any third
                party applications Your Data is extracted from or into, including
                without limitation any interoperability of such applications with
                the Software. You shall indemnify the Licensor, its Affiliates,
                officers, employees and agents, against any third party claims,
                associated losses, damages, costs and expenses arising out of or
                related to the use of Your Data and/or claims that assert that
                any of use of Your Data, including without limitation extraction
                thereof from or into third party applications, violate any law or
                infringe any third party right, including without limitation any
                Intellectual Property Right or privacy right.
                <br></br>
                <br></br>
                <b className="bold">6. Warranties</b>
                <br></br>
                6.1 You acknowledge that the Software is a standard product and
                that it is Your sole responsibility to ensure that the Software
                is appropriate for Your requirements.
                <br></br>
                6.2 To the maximum extent permitted by law, the Software and
                other materials are delivered to You "as is". Licensor does not
                and cannot warrant the performance or results You may obtain by
                using the Software or that the Software will operate free of
                errors or operate in an uninterrupted manner. You expressly
                acknowledge and agree that use of the Software is at your sole
                risk. Licensor is not responsible to You or any third party for
                unauthorized access to Your Data or for unauthorized use of the
                Software installed on Your systems, or any deliverables. Licensor
                makes no representation or warranty whatsoever regarding open
                source software or with regard to any third party products or
                services. Except to the extent any warranty, condition,
                representation or term cannot or may not be excluded or limited
                by law applicable to You in Your respective jurisdiction,
                Licensor makes no warranties, conditions, representations, or
                terms (expressed or implied whether by statute, law, custom,
                usage or otherwise) as to any matter including without limitation
                title or noninfringement of third party rights, merchantability,
                integration, satisfactory quality, or fitness for any particular
                purpose. Nothing in this agreement shall be construed as an
                obligation to bring an action or suit against third parties for
                infringement.
                <br></br>
                <br></br>
                <b className="bold">7. Intellectual Property Rights</b>
                <br></br>
                7.1 All Intellectual Property Rights in the Software, and all
                originals and copies thereof, as well as any updates, upgrades
                and extensions and any services in relation to the Software
                provided by or on behalf of the Licensor and any deliverables
                thereunder, shall remain the exclusive property of the Licensor
                or its licensors as appropriate. Your shall have no right, title
                or interest in the Software, its updates, upgrades and extensions
                and any services in relation to the Software as described above,
                except as expressly granted under this Agreement.
                <br></br>
                7.2 Except for the rights set forth in clause 4 of this
                Agreement, You are not permitted to copy, modify or otherwise
                make derivative works of the Software. Any such unauthorized
                works developed by You, and any Intellectual Property Rights
                embodied therein, shall be the sole and exclusive property of the
                Licensor.
                <br></br>
                7.3 You hereby grant to the Licensor and its Affiliates and
                contractors a royalty-free, worldwide, transferrable right,
                during the term of this Agreement to use the Intellectual
                Property Rights in the data, information and materials provided
                by You to the Licensor in connection with Your use of Software
                solely for the purposes to enable Licensor to provide you
                services associated with the Software and perform other
                obligations under this Agreement.
                <br></br>
                <br></br>
                <b className="bold">8. Liability</b>
                <br></br>
                8.1 Licensor shall not be liable for indirect, incidental or
                consequential loss or damage or loss of profits, loss of revenue,
                loss oF anticipated savings or loss of data.
                <br></br>
                8.2 Subject to clauses 8.1 and 8.3, in no event shall Licensor’s
                total liability to You for all damages exceed the amount of GBP
                100 (one hundred Sterling). The foregoing limitations will apply
                even if the above stated remedy fails of its essential purpose.
                <br></br>
                8.3 Limitations on liability agreed in clause 8.2 will not apply
                in respect of death or personal injury or death caused directly
                by negligence of Licensor or its employees, or in respect of
                fraud, or where it is not otherwise possible to limit the
                liability of the Parties under the applicable law.
                <br></br>
                8.4 In cases where the Software is used by You to process Your
                Data, including extracting any of Your Data from or to any third
                party applications, You shall be solely responsible for the
                accuracy of Your Data and for authorised the usage of any third
                party applications Your Data is extracted from or into, including
                without limitation any interoperability of such applications with
                the Software. You shall indemnify the Licensor, its Affiliates,
                officers, employees and agents, against any third party claims,
                associated losses, damages, costs and expenses arising out of or
                related to the use of Your Data and/or claims that assert that
                any of use of Your Data, including without limitation extraction
                thereof from or into third party applications, violate any law or
                infringe any third party right, including without limitation any
                Intellectual Property Right or privacy right.
                <br></br>
                <br></br>
                <b className="bold">9. Your Statutory Rights</b>
                <br></br>
                9.1 This Agreement gives you specific legal rights and you may
                also have other rights that vary from country to country. Some
                jurisdictions do not allow the exclusion of implied warranties,
                or certain kinds of limitations or exclusions of liability, so
                the above limitations and exclusions may not apply to you. Other
                jurisdictions allow limitations and exclusions subject to certain
                conditions. In such a case the above limitations and exclusions
                shall apply to the fullest extent permitted by the laws of such
                applicable jurisdictions. If any part of the above limitations or
                exclusions is held to be void or unenforceable, such part shall
                be deemed to be deleted from this Agreement and the remainder of
                the limitation or exclusion shall continue in full force and
                effect. Any rights that you may have as a consumer (i.e. a
                purchaser for private as opposed to business, academic or
                government use) are not affected.
                <br></br>
                <br></br>
                <b className="bold">10. Term and Termination</b>
                <br></br>
                10.1 This Agreement shall commence on the Effective Date and
                shall continue for the duration of the Subscription Term, unless
                terminated in accordance with the terms and conditions set forth
                in this Agreement.
                <br></br>
                10.2 The Software Subscription Term shall commence on the date
                agreed in the Order and will continue for a period stipulated in
                the Order (“Initial Subscription Term”). Thereafter, the
                subscription term shall renew for successive periods (each, a
                “Subscription Renewal Term”) equal to the original Subscription
                Term.
                <br></br>
                10.3 This Agreement may be terminated at any time by You or
                Licensor. You may terminate this Agreement at any time by
                destroying the Software together with all copies in any form.
                This Agreement will also terminate automatically upon conditions
                set out elsewhere in this Agreement or if you fail to comply with
                any term or condition of this Agreement or if you voluntarily
                return the Software to the Licensor. No termination will relieve
                You of the obligation to pay any fees accrued or payable to
                Licensor.
                <br></br>
                10.4 You agree upon termination or expiration to destroy the
                Software together with all copies in any form.
                <br></br>
                10.5 In case of expiration of this Agreement or its termination
                for any reason, the fees pre-paid by You under this Agreement
                will not be refunded.
                <br></br>
                <br></br>
                <b className="bold">11. Export laws</b>
                <br></br>
                11.1 You acknowledge that the Software or parts of the Software
                and related technical information may be subject to national, EU,
                US and other applicable export control laws and regulations which
                may prohibit delivery of the Software to certain countries and or
                customers, and accordingly, that the Licensor makes no
                representation or warranty as to international portability of the
                Software. You accept responsibility to obtain all necessary legal
                or other consents if necessary in the event that You should wish
                to export the Software or any part of it into the restricted
                jurisdictions, and shall indemnify the Licensor in respect of all
                claims, costs and fines in this respect.
                <br></br>
                <br></br>
                <b className="bold">12. Confidentiality</b>
                <br></br>
                12.1 Parties shall not, and shall procure that their employees,
                advisors and/or representatives shall not (except in the proper
                course of its or their duties), either during the term of this
                Agreement or at any time after the termination of this Agreement,
                use or disclose to any third party (and shall use their best
                endeavours to prevent the publication and disclosure of) any
                Confidential Information that belongs to the other Party. This
                restriction does not apply to:
                <br></br>
                a) any use or disclosure authorised by the Disclosing Party or
                required to be provided by law, courts or regulatory authorities;
                or
                <br></br>
                b) any information which is already in, or comes into, the public
                domain otherwise than through the Receiving Party’s unauthorised
                disclosure; or
                <br></br>
                c) any information which was rightfully in Receiving Party’s
                possession without any obligation of confidentiality before
                receipt from the Disclosing Party;
                <br></br>
                d) any information, which is independently developed by or for
                the Receiving Party.
                <br></br>
                12.2 You will promptly on request return to the Licensor all and
                any Confidential Information of Licensor in Your possession.
                <br></br>
                12.3 In addition to other confidentiality undertakings in this
                clause 12, You hereby represent that Your performance of duties
                and obligations under this Agreement does not breach, has not
                breached and will not breach any agreement to keep in confidence
                proprietary information, knowledge or data acquired by You in
                confidence or trust prior to the Effective Date or independently
                from this Agreement. From the Effective Date and for the duration
                of the Agreement, or any time thereafter, You and Your Affiliates
                shall not improperly use or disclose any confidential or
                proprietary information or trade secrets of any other person or
                entity and shall avoid misappropriating or infringing the
                intellectual property of other companies and individuals.
                <br></br>
                <br></br>
                <b className="bold">13. Data Protection</b>
                <br></br>
                13.1 Licensor will only use Your personal information as set out
                in the Licensor’s Privacy Policy available from https://
                demarq.com/privacy-policy/.
                <br></br>
                <br></br>
                <b className="bold">14. Data Processing</b>
                <br></br>
                14.1 In this clause 14, “personal data”, “data subject”, “data
                controller”, “data processor”, and “personal data breach” shall
                have the meaning defined in the Data Protection Legislation.
                <br></br>
                14.2 The Parties hereby agree that they shall both comply with
                all applicable data protection requirements set out in the Data
                Protection Legislation. This clause 14 shall not relieve either
                Party of any obligations set out in the Data Protection
                Legislation and does not remove or replace any of those
                obligations.
                <br></br>
                14.3 For the purposes of the Data Protection Legislation and for
                this clause 14, the Licensor is the “Data Processor” and You are
                the “Data Controller”.
                <br></br>
                14.4 The type(s) of personal data, the scope, nature and purpose
                of the processing, and the duration of the processing are set out
                in Schedule 2.
                <br></br>
                14.5 The Data Controller shall ensure that it has in place all
                necessary consents and notices required to enable the lawful
                transfer of personal data to the Data Processor for the purposes
                described in this Agreement.
                <br></br>
                14.6 The Data Processor shall, with respect to any personal data
                processed by it in relation to its performance of any of its
                obligations under this Agreement:
                <br></br>
                a) Process the personal data only on the written instructions of
                the Data Controller unless the Data Processor is otherwise
                required to process such personal data by law. The Data Processor
                shall promptly notify the Data Controller of such processing
                unless prohibited from doing so by law;
                <br></br>
                b) Ensure that it has in place suitable technical and
                organisational measures (as approved by the Data Controller) to
                protect the personal data from unauthorised or unlawful
                processing, accidental loss, damage or destruction. Such measures
                shall be proportionate to the potential harm resulting from such
                events, taking into account the current state of the art in
                technology and the cost of implementing those measures. Measures
                to be taken are set out in Schedule 2;
                <br></br>
                c) Ensure that any and all staff with access to the personal data
                (whether for processing purposes or otherwise) are contractually
                obliged to keep that personal data confidential;
                <br></br>
                d) Transfer any personal data outside of the UK (and the Data
                Controller hereby consents to such transfer) only if the
                following conditions are satisfied:
                <br></br>
                2.6.d.1 The Data Controller and/or the Data Processor has/have
                provided suitable safeguards for the transfer of personal data;
                <br></br>
                2.6.d.2 Affected data subjects have enforceable rights and
                effective legal remedies;
                <br></br>
                2.6.d.3 The Data Processor complies with its obligations under
                the Data Protection Legislation, providing an adequate level of
                protection to any and all personal data so transferred; and
                <br></br>
                2.6.d.4 The Data Processor complies with all reasonable
                instructions given in advance by the Data Controller with respect
                to the processing of the personal data.
                <br></br>
                e) Assist the Data Controller at the Data Controller’s cost, in
                responding to the requests from data subjects where relevant and
                in ensuring its compliance with the Data Protection Legislation
                with respect to security, breach notifications, impact
                assessments, and consultations with supervisory authorities or
                regulators (including, but not limited to, the Information
                Commissioner’s Office) to the extent the personal data processed
                by the Data Processor for the purposes of this Agreement is
                concerned;
                <br></br>
                f) Notify the Data Controller without undue delay of a personal
                data breach;
                <br></br>
                g) On the Data Controller’s written instruction, delete (or
                otherwise dispose of) or return all personal data and any and all
                copies thereof to the Data Controller on termination of this
                Agreement unless it is required to retain any of the personal
                data by law; and
                <br></br>
                h) Maintain complete and accurate records of all processing
                activities and technical and organisational measures implemented
                necessary to demonstrate compliance with this clause 14 and to
                allow for audits by the Data Controller;
                <br></br>
                14.7 The Data Controller acknowledges and agrees, that the Data
                Processor may sub-contract any of its obligations with respect to
                the processing of personal data under this clause 14 to a
                sub-contractor. The initial list of Data Processor’s
                sub-contractors is attached in Schedule 2. The Data Processor
                shall enter into a written agreement with the sub-processor,
                which shall impose upon the sub-processor the same obligations as
                are imposed upon the Data Processor by this clause 14 and which
                shall permit both the Data Processor and the Data Controller to
                enforce those obligations. Any additions or replacements of
                subcontractors will be notified to the Data Controller on the
                Data Processor’s webpage or via email. Customer may oppose the
                use of a new sub-processor, and shall notify the Data Processor
                thereof, in which case the Data Processor will use reasonable
                efforts to amend the Software product (if commercially possible)
                or service or offer an alternative, and if this is not possible
                within reasonable time, the Data Controller may terminate the
                applicable Order’s with a notice to the Data Processor, and the
                Data Processor will refund the Data Processor the pre-paid fees
                for the unused part of the Software in proportion from the
                effective date of termination. Data Processor shall ensure that
                the sub-processor complies fully with its obligations under that
                agreement and the Data Protection Legislation; and
                <br></br>
                14.8 Licensor may, at any time, and on at least a reasonable
                notice, alter this clause 14, replacing it with any applicable
                data processing clauses or similar terms that form part of an
                applicable certification scheme. Such terms shall apply when
                replaced and published on Licensor’s webpage.
                <br></br>
                <br></br>
                <b className="bold">15. Miscellaneous</b>
                <br></br>
                15.1 This Agreement constitutes the entire agreement and
                understanding of the Parties and supersedes any previous
                agreement between the Parties concerning the subject matter
                hereof. Neither Party has relied upon any statement or
                representation made by the other in agreeing to enter this
                Agreement.
                <br></br>
                15.2 You and Licensor are acting hereunder as independent
                contractors. Neither Party shall be considered or deemed to be an
                agent, employee, joint venturer or partner of the other Party.
                <br></br>
                15.3 If either Party is obstructed in performing any of its
                obligations under a this Agreement by an event outside its
                reasonable control, then performance of its obligations may be
                suspended for so long as the obstruction continues. If
                performance has been suspended for more than 20 (twenty) days,
                either Party may terminate this Agreement by immediate written
                notice.
                <br></br>
                15.4 This Agreement may be amended by Licensor at any time, in
                which case Licensor shall publish an amended version of this
                agreement online in the relevant publicly accessible directory of
                the Licensor. If You wish to amend this Agreements, such
                amendments may only be made in writing and shall be agreed to by
                the Licensor.
                <br></br>
                15.5 You agree that Licensor may identify You as its customer and
                reference client and use Your name and logo in sales
                presentations, and in marketing materials and press releases.
                <br></br>
                15.6 You shall not be entitled to assign this Agreement nor any
                of its rights or obligations under it.
                <br></br>
                15.7 No person who is not a party to this Agreement shall have
                any right under the Contracts (Rights of Third Parties) Act 1999
                to enforce any of its terms.
                <br></br>
                15.8 If any provision of this Agreement is adjudged to be
                invalid, void or unenforceable, the remainder of the provisions
                will remain in effect.
                <br></br>
                15.9 Failure or neglect by either Party to exercise any of its
                rights or remedies under this Agreement will not be construed as
                a waiver of that party's rights nor in any way affect the
                validity of the whole or part of this Agreement nor prejudice
                that Party's right to take subsequent action.
                <br></br>
                15.10 The headings in this Agreement are for purposes of
                convenience only and shall not affect the meaning or construction
                of the clauses to which they relate.
                <br></br>
                15.11 The laws of England & Wales, whose courts shall have sole
                jurisdiction in relation to all matters arising, govern these
                terms.
                <br></br>
                <p>
                    <b className="bold">
                        YOU AGREE THAT YOU HAVE READ THIS AGREEMENT AND AGREE TO
                        BE BOUND BY IT AS IF YOU HAVE SIGNED IT IN WRITING. IF
                        YOU ARE ACTING ON BEHALF OF A DIFFERENT ENTITY YOU
                        WARRANT THAT YOU HAVE THE AUTHORITY TO ACCEPT THE TERMS
                        OF THIS AGREEMENT FOR SUCH ENTITY.
                    </b>
                </p>
                <br></br>
                <p>
                    <h3>
                        <b className="bold">Schedule 1</b>
                    </h3>
                </p>
                <b className="bold">Definitions</b>
                <br></br>
                <br></br>
                <b className="bold">‘Affiliate’ </b>
                means any legal entity which is controlled by the Party where the
                term ‘control’ shall mean the possession of the power to direct
                or cause the direction of the management policies of the entity
                whether through the ownership of more than fifty per cent (50%)
                of shares or voting rights, by contracts or otherwise. Any such
                legal entity shall be considered an Affiliate only for as long as
                such control is maintained.
                <br></br>
                <br></br>
                <b className="bold">‘Confidential Information’ </b>
                means information in whatever form (including, without
                limitation, in written, oral, visual or electronic form or on any
                magnetic or optical disk or memory and wherever located) relating
                to the business, customers, products, affairs, finances and trade
                secrets of the party that has provided such information
                (‘Disclosing Party’) or any of its Affiliates for the time being
                confidential to the Disclosing Party or any of its Affiliates,
                whether or not such information (if in anything other than oral
                form) is marked confidential.
                <br></br>
                <br></br>
                <b className="bold">‘Data Protection Legislation’ </b>
                means (i) the Data Protection Act 2018 (ii) Regulation 2016/679
                (GDPR); (iii) any other EU and/or UK data protection legislation,
                including without limitation legislation replacing or adopting
                the GDPR or other EU regulations in the UK as applicable; and
                (iv) all applicable changes in law, any judicial or
                administrative interpretation of laws, any compulsory guidance,
                guidelines, codes of practice.
                <br></br>
                <br></br>
                <b className="bold">‘Documentation’ </b>
                means the technical, user and reference manuals, notes,
                instructions and summaries, technical release notes,
                specifications and any other supporting documentation related to
                the Software, whether in digital, printed or other form.
                <br></br>
                <br></br>
                <b className="bold">‘Intellectual Property Rights’ </b>
                means patents, utility models, rights to Inventions, copyright
                and neighbouring and related rights, moral rights, trade marks
                and service marks, business names and domain names, rights in
                get-up, goodwill and the right to sue for passing off or unfair
                competition, rights in designs, rights in computer software,
                database rights, rights to use, and protect the confidentiality
                of, confidential information (including know-how and trade
                secrets) and all other intellectual property rights, in each case
                whether registered or unregistered and including all applications
                and rights to apply for and be granted, renewals or extensions
                of, and rights to claim priority from, such rights and all
                similar or equivalent rights or forms of protection which subsist
                or will subsist now or in the future in any part of the world.
                <br></br>
                <br></br>
                <b className="bold">‘Order’ </b>
                means, where applicable, Licensor’s ordering documentation
                including without limitation any agreement between the Parties,
                invoice or other document referencing this Agreement and listing
                the Software licensed by You or on You behalf hereunder, Licensed
                Capacity, fees and other relevant commercial terms if applicable.
                <br></br>
                <br></br>
                <b className="bold">‘Licensed Capacity’ </b>
                means the amount of access to the Software purchased online,
                specified in the Order, and/or otherwise agreed in writing
                between the Parties.
                <br></br>
                <br></br>
                <b className="bold">‘Subscription Term’ </b>
                means the period of 12 months from the Effective Date of this
                Agreement plus any annual renewal period if any.
                <br></br>
                <br></br>
                <b className="bold">‘Territory’ </b>
                means the territory of the United Kingdom unless otherwise agreed
                by the parties in writing.
                <br></br>
                <br></br>
                <b className="bold">‘Your Data’ </b>
                means any data, information and materials that belong to You or
                third parties and that is processed by the Software or provided
                by You to the Licensor for the purposes of this Agreement.
                <br></br>
                <br></br>
                <h3>
                    <b className="bold">Schedule 2</b>
                </h3>
                <b className="bold">Details of Data Processing </b>
                <br></br>
                <br></br>
                <b className="bold">Scope and Nature </b>
                Your personal data processed is the data provided by You to
                Licensor for the exercise of your rights and performance of Your
                obligations under the Agreement.
                <br></br>
                <br></br>
                <b className="bold">Purpose </b>
                As set forth in the Agreement, Licensor processes Your data
                necessary for the exercise and performance of Your rights and
                obligations under the Agreement and the Order.
                <br></br>
                <br></br>
                <b className="bold">Duration </b>
                The duration of personal data processing is the Subscription
                Term.
                <br></br>
                <br></br>
                <b className="bold">Types of Personal Data </b>
                The extent of personal data processed by Licensor is determined
                and controlled by You in Your sole discretion. It will include
                personal data in the following categories:
                <br></br>• Contact details, such as name, business phone number,
                email address, office address, title, degree, date of birth;
                <br></br>• Product usage data such as media used, file type used,
                usage and status and information related to Licensor’s products
                such as location, language, data sharing choices;
                <br></br>• Other personal data provided by You when You interact
                with our support services or other representatives;
                <br></br>• Any other personal data You or Your employees may
                submit, send or store via Licensor’s products or services.
                <br></br>
                <br></br>
                <b className="bold">Categories of Data Subject </b>
                The extent of personal data processed by Licensor is determined
                and controlled by You in Your sole discretion. It may include but
                not limited to the following categories of data subjects:
                <br></br>• Your personnel including employees, agents and third
                party contractors using the product or services;
                <br></br>• Your customers, business partners and vendors.
                <br></br>
                <br></br>
                <b className="bold">Sub-processors </b>
                None
                <br></br>
                <br></br>
                <b className="bold">
                    Organisational and Technical Data Protection Measures
                </b>
                Licensor maintains appropriate technical and organisational
                measures in place and takes such precautions as are necessary to
                protect the personal data of its clients against unauthorised or
                unlawful processing, accidental loss, disclosure, improper use,
                damage or destruction. Such measures include, among others:
                <br></br>• the use of internationally recognised anti-virus
                software to detect and remove all known software threats and
                vulnerabilities;
                <br></br>• the use of internationally recognised mail protection
                and web proxy systems to protect computers from network-based
                threats;
                <br></br>• patching and updating computers regularly;
                <br></br>• the use of a mail data leakage protection system;
                <br></br>• protecting mobile devices with industry standard full
                disk encryption
                <br></br>• ensuring all personnel are: (i) vetted in accordance
                with good industry practice; and (ii) regularly and properly
                trained on preventing and mitigating security incidents;
                <br></br>• implementing appropriate policies and practices which
                as a minimum impose a level of security that meets statutory
                requirements and good industry practice;
                <br></br>• performing robust and detailed due diligence on all
                sub-contractors, including review of their security measures and
                procedures;
                <br></br>• pseudonymising and encrypting personal data where
                relevant;
                <br></br>• ensuring confidentiality, integrity, availability and
                resilience of company systems and services;
                <br></br>• maintaining appropriate business continuity and
                disaster recovery measures;
                <br></br>• maintaining appropriate security access controls in
                respect of company venues;
                <br></br>• implementing identity protection measures such as
                password manager and multi-factor authentication;
                <br></br>• regular assessments and evaluation of the
                effectiveness of the technical and organisational measures that
                the company adopts;
                <br></br>• conducting regular audits, tests and risk assessments
                on an ongoing basis (at least annually) of all policies and
                business practices relating to data and information security;
                <br></br>• access control to premises and facilities to prevent
                unauthorized persons from gaining access to data processing
                systems.
            </div>
            <div className="field-margin-bottom">
                <Checkbox
                    className="terms-checkbox"
                    labelText="I accept the T&Cs"
                    data-testid="terms-and-conditions-checkbox"
                    id="terms-and-conditions-checkbox"
                    checked={isChecked}
                    onChange={() => {
                        setIsChecked(!isChecked);
                    }}
                />
            </div>
            <CommonRegistrationButton
                buttonKind={"primary"}
                isDisabled={continueButtonDisabled}
                onClickFunction={onClick}
                buttonDescription={"Next"}
                dataTestId={"accept-terms-and-conditions-next"}
            />
        </>
    );
};

export default TermsAndConditionsText;
