import localforage from "localforage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { default as apiReducer } from "./api/api.reducer";
import authReducer from "./auth/auth.reducer";
import fileDetailsReducer from "./file-details/file-details.reducer";
import filesReducer from "./files/files.reducer";
import maskReducer from "./mask/mask.reducer";
import projectReducer from "./projects/projects.reducer";
import userReducer from "./user/user.reducer";
import webSocketUpdateReducer from "./websocket/websocket.reducer";

const rootReducer = combineReducers({
    projects: projectReducer,
    auth: authReducer,
    websocket: webSocketUpdateReducer,
    api: apiReducer,
    fileDetails: fileDetailsReducer,
    files: filesReducer,
    user: userReducer,
    mask: maskReducer,
});

const persistConfig = {
    key: "root",
    storage: localforage,
    whitelist: ["auth", "api", "files", "fileDetails", "user"],
};

export default persistReducer(persistConfig, rootReducer);
