import { Close20 } from "@carbon/icons-react";
import {
    Button,
    HeaderPanel,
    SideNavItems,
    SideNavMenuItem,
    SwitcherDivider
} from "carbon-components-react";
import React, { useEffect, useState } from "react";
import "./side-nav-bar-info.styles.scss";

const SideNavBarInfo = ({
    setIsSideNavBarInfoOpen,
    navInfoToDisplay,
    setNavInfoToDisplay,
    deleteRectFunction,
    selectedObject,
    setSelectedObject,
    setSelectedRect,
}) => {
    const [errorFixText, setErrorFixText] = useState("");
    const [extraDetailText, setExtraDetailText] = useState("");
    const [displayExtraDetailText, setDisplayExtraDetailText] = useState(false);
    const [displayFixButton, setDisplayFixButton] = useState(false);
    const [extraFixText, setExtraFixText] = useState("");

    const onCloseButtonClick = () => {
        setIsSideNavBarInfoOpen(false);
        setNavInfoToDisplay(null);
        setSelectedObject(null);
        setSelectedRect(null);
    };

    const deleteSelected = () => {
        if (selectedObject !== null) {
            if (selectedObject["objectType"] === "rect") {
                if (
                    window.confirm(
                        "Are you sure you want to delete selected mask?"
                    )
                ) {
                    deleteRectFunction();
                    onCloseButtonClick();
                }
            }
        }
    };

    useEffect(() => {
        let dateWarning = "No date column detected";
        let columnNumberWarning = "Too few columns";
        let noTransactionsWarning = "No transaction columns found";
        let noBalanceWarning = "No balance column found";

        if (navInfoToDisplay !== null) {
            if (
                navInfoToDisplay.includes(dateWarning) &&
                navInfoToDisplay.includes(columnNumberWarning) &&
                navInfoToDisplay.includes(noTransactionsWarning)
            ) {
                setErrorFixText("This mask can be deleted.");
                setDisplayExtraDetailText(true);
                setExtraDetailText(
                    "It most likely isn't a statement mask, therefore it can be deleted"
                );
                setDisplayFixButton(true);
                setExtraFixText(
                    "Is it a statement mask? Ensure all masks have the same number of columns. Reprocess the data by specifying date and transaction columns in the Table Columns"
                );
            } else if (
                navInfoToDisplay.includes(dateWarning) &&
                navInfoToDisplay.includes(noTransactionsWarning)
            ) {
                setErrorFixText(
                    "Reprocess the data by specifying date and transaction columns in the Table Columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (
                navInfoToDisplay.includes(dateWarning) &&
                navInfoToDisplay.includes(columnNumberWarning)
            ) {
                setErrorFixText(
                    "Reprocess the data by specifying date in Table Columns and ensure all masks have the same number of columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (
                navInfoToDisplay.includes(noTransactionsWarning) &&
                navInfoToDisplay.includes(columnNumberWarning)
            ) {
                setErrorFixText(
                    "Reprocess the data by specifying transaction columns in Table Columns and ensure all masks have the same number of columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (navInfoToDisplay.includes(dateWarning)) {
                setErrorFixText(
                    "Reprocess the data by specifying the date column in the Table Columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (navInfoToDisplay.includes(noTransactionsWarning)) {
                setErrorFixText(
                    "Reprocess the data by specifying transaction columns in the Table Columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (navInfoToDisplay.includes(columnNumberWarning)) {
                setErrorFixText(
                    "Ensure all masks have the same number of columns"
                );
                setDisplayExtraDetailText(false);
                setDisplayFixButton(false);
            } else if (navInfoToDisplay.includes(noBalanceWarning)) {
                setErrorFixText(
                    "Some statements do not include a running balance, and is common for credit card statements.  If the result looks good then no action needs to be taken, however be aware that without a statement balance Oscar is unable to reconcile the transcation details."
                );
                setExtraDetailText("");
                setDisplayExtraDetailText(true);
                setDisplayFixButton(false);
            }
        }
    }, [navInfoToDisplay]);

    useEffect(() => {
        if (selectedObject === null) {
            setIsSideNavBarInfoOpen(false);
            setNavInfoToDisplay(null);
        }
    }, [selectedObject, setIsSideNavBarInfoOpen, setNavInfoToDisplay]);

    return (
        <>
            <HeaderPanel
                aria-label="Header Panel"
                expanded
                data-testid="mask-page-side-nav-bar-info"
                className="side-nav-bar-info no-scrollbar"
            >
                <div className="info-nav-bar-components">
                    <SideNavItems className="no-scrollbar">
                        <Button
                            renderIcon={Close20}
                            className="close-button-info-bar"
                            data-testid="close-button-side-info-bar"
                            id="close-button-side-info-bar"
                            hasIconOnly
                            onClick={onCloseButtonClick}
                            iconDescription="Close"
                        />
                        <SideNavMenuItem>Warning Detail</SideNavMenuItem>
                        <div className="side-nav-bar-info-text">
                            <div
                                data-testid="warnings-identified-text"
                                className="info-detail-margin-bottom-bigger"
                            >
                                {" "}
                                The following issues have been identified for
                                this mask:
                            </div>
                            <div className="info-detail-margin-bottom-bigger">
                                {navInfoToDisplay !== null
                                    ? navInfoToDisplay.map(function (d, idx) {
                                          return (
                                              <li
                                                  className="info-detail-margin-bottom"
                                                  key={idx}
                                                  data-testid={`warning-item-${idx}`}
                                              >
                                                  - {d}
                                              </li>
                                          );
                                      })
                                    : null}
                            </div>
                            {displayExtraDetailText ? (
                                <div
                                    data-testid="extra-detail-text"
                                    className="info-detail-margin-bottom-bigger"
                                >
                                    {extraDetailText}
                                </div>
                            ) : null}
                        </div>
                        <SwitcherDivider />
                        <SideNavMenuItem>Warning Fix</SideNavMenuItem>
                        <div
                            data-testid="warning-fix-text"
                            className="side-nav-bar-info-text"
                        >
                            {errorFixText}
                        </div>
                        <div className="side-nav-bar-info-text">
                            {displayFixButton ? (
                                <Button
                                    className="mask-side-nav-button secondary-button fix-button info-detail-margin-bottom-bigger"
                                    data-testid="mask-side-nav-bar-info-button-fix"
                                    id="mask-side-nav-bar-info-button-fix"
                                    kind="secondary"
                                    onClick={deleteSelected}
                                >
                                    Delete
                                </Button>
                            ) : null}
                            {displayExtraDetailText ? (
                                <div
                                    data-testid="extra-warning-fix-text"
                                    className="info-detail-margin-bottom-bigger"
                                >
                                    {extraFixText}
                                </div>
                            ) : null}
                        </div>
                    </SideNavItems>
                </div>
            </HeaderPanel>
        </>
    );
};

SideNavBarInfo.defaultProps = {
    pathname: null,
    backEndSubmitGrids: [],
    maxColumnsInfo: null,
    columnSpec: [],
    allWarningGrids: [],
    setColumnSpec: null,
    setResetMaskSideBarInfo: null,
    currentProjectId: null,
    currentFileId: null,
    isCollapsed: null,
    setIsCollapsed: () => {},
    pageLocation: "",
    handleSaveFromResultsPage: () => {},
};

export default SideNavBarInfo;
