import { Button } from "carbon-components-react";
import React, { useCallback, useEffect, useRef } from "react";
import { Checkmark16 } from "@carbon/icons-react";

import "./sort-menu.styles.scss";

const SortMenu = ({
    id,
    showSortMenu,
    setSortType,
    setShowSortMenu,
    sortType,
}) => {
    const listRef = useRef();

    const handleClick = (buttonClicked) => {
        setSortType(buttonClicked);
        setShowSortMenu(false)
    };

    const handleBlur = useCallback(() => {
        setShowSortMenu(false);
    }, [setShowSortMenu]);

    useEffect(() => {
        if (showSortMenu) {
            listRef.current.focus();
        }
    }, [showSortMenu]);

    return (
        <>
            {showSortMenu ? (
                <ul
                    className={
                        id === "files" ? "sort-menu further-left" : "sort-menu"
                    }
                    onBlur={handleBlur}
                    tabIndex={-1}
                    ref={listRef}
                    data-testid={`${id}-sort-menu`}
                >
                    <Button
                        data-testid={`${id}-sort-menu-alphabetically-ascending-button`}
                        id={`${id}-sort-menu-alphabetically-ascending-button`}
                        onMouseDown={() =>
                            handleClick("alphabeticallyAscending")
                        }
                        {...(sortType === "alphabeticallyAscending" ? {renderIcon: Checkmark16} : {})}
                    >
                        Alphabetically A-Z
                    </Button>
                    <Button
                        id={`${id}-sort-menu-alphabetically-descending-button`}
                        data-testid={`${id}-sort-menu-alphabetically-descending-button`}
                        onMouseDown={() =>
                            handleClick("alphabeticallyDescending")
                        }
                        {...(sortType === "alphabeticallyDescending" ? {renderIcon: Checkmark16} : {})}
                    >
                        Alphabetically Z-A
                    </Button>
                    <Button
                        data-testid={`${id}-sort-menu-date-most-recent-button`}
                        id="sort-menu-date-most-recent-button"
                        onMouseDown={() => handleClick("dateMostRecent")}
                        {...(sortType === "dateMostRecent" ? {renderIcon: Checkmark16} : {})}
                    >
                        Most Recent
                    </Button>
                    <Button
                        data-testid={`${id}-sort-menu-date-oldest-button`}
                        id={`${id}-sort-menu-date-oldest-button`}
                        onMouseDown={() => handleClick("dateOldest")}
                        {...(sortType === "dateOldest" ? {renderIcon: Checkmark16} : {})}
                    >
                        Oldest
                    </Button>
                </ul>
            ) : null}
        </>
    );
};

export default SortMenu;
