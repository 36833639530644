export const updateProjectFile = (updatedProjectFile, action) => {
    return updatedProjectFile.map((project) =>
        project["project_id"] === action["project_id"]
            ? {
                  ...project,
                  files: project["files"].map((file) =>
                      file["file_id"] === action["file_id"]
                          ? {
                                ...file,
                                upload_status: action["upload_status"],
                                // name: action["name"],
                                preview_link: action["preview_link"],
                                warnings: action["warnings"],
                                validation_errors: action["validation_errors"],
                                link: action["link"],
                                excel_link: action["excel_link"],
                                generic_tables_excel_link:
                                    action["generic_tables_excel_link"],
                                notes: action["notes"],
                            }
                          : file
                  ),
              }
            : project
    );
};

export const setProjectFileState = (updatedProjectFile, action) => {
    return updatedProjectFile.map((project) =>
        project["project_id"] === action["project_id"]
            ? {
                  ...project,
                  files: project["files"].map((file) =>
                      file["file_id"] === action["file_id"]
                          ? {
                                ...file,
                                upload_status: action["upload_status"],
                            }
                          : file
                  ),
              }
            : project
    );
};
