import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import { Button } from "carbon-components-react";
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { deleteFileAction } from "../../../redux/thunks/thunks.actions";
import ResultsDataTableToolbarSearch from "../../data-table-toolbar-search/data-table-toolbar-search.component";
import AltActionButtons from "./alt-buttons/alt-action-buttons.component";

export const getNodeWidth = (node) => {
    return node.offsetWidth;
};

export const getNodeHeight = (node) => {
    return node.offsetHeight;
};

const AltFileTiles = ({
    selectedProjectInfo,
    selectedFiles,
    setSelectedFiles,
    setFileModalOpen,
    altFilesSelectAll,
    altFilesDeselectAll,
}) => {
    const gridRef = useRef();
    const dispatch = useDispatch();
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [node, setNode] = useState(null);
    const [searchedValue, setSearchedValue] = useState("");

    /**
     * Used to determine filePage size on mount
     */
    const fileTileContainerRef = useCallback((node) => {
        if (node !== null) {
            setNode(node);
        }
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            // minWidth: 200,
            editable: false,
            resizable: true,
        };
    }, []);

    const sizeColumnsToFitFunc = useCallback(() => {
        if (gridApi !== null) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi]);

    const columnTitles = [
        {
            field: "Document Name",
            checkboxSelection: true,
            sortable: true,
            headerCheckboxSelection: true,
        },
        {
            field: "Type",
            sortable: true,
            filter: "agTextColumnFilter",
            getQuickFilterText: () => {
                return "";
            },
        },
        {
            field: "File Status",
            sortable: true,
            filter: "agTextColumnFilter",
            getQuickFilterText: () => {
                return "";
            },
        },
        {
            field: "Created Date",
            sortable: true,
            filter: "agTextColumnFilter",
            getQuickFilterText: () => {
                return "";
            },
        },
        {
            field: "Actions",
            cellRenderer: AltActionButtons,
            getQuickFilterText: () => {
                return "";
            },
        },
    ];

    const onRowSelected = useCallback(() => {

        let selectedRowNodesWithSort = [];

        gridApi.forEachNodeAfterFilterAndSort((node) => {
        if (node.selected) {
            selectedRowNodesWithSort.push({
                fileName: node["data"]["Document Name"].concat(".").concat(node["data"]["Type"]),
                fileId: node["data"]["Actions"]["fileId"],
            });
        }
        });

        setSelectedFiles(selectedRowNodesWithSort);
    }, [gridApi, setSelectedFiles]);

    const loadingStatuses = ["Uploading", "ReLoading", "Failed", "Categorising"];

    const getRowClass = (params) => {
        if (loadingStatuses.includes(params["data"]["File Status"])) {
            return "ag-cell-loading";
        }
    };

    const rowDataCalc = useCallback(() => {
        let agGridData = [];
        for (let file of selectedProjectInfo["files"]) {
            if ("Failed" === file["upload_status"]) {
                dispatch(deleteFileAction(file["project_id"], file["file_id"]));
            } else {
                agGridData.push({
                    "Document Name": file["name"]
                        .split(".")
                        .slice(0, -1)
                        .join("."),
                    Type: file["name"].split(".").slice(-1)[0].toUpperCase(),
                    "File Status": file["upload_status"],
                    "Created Date": new Date(file["created_ts"]).toLocaleString(),
                    Actions: {
                        fileName: file["name"],
                        projectId: file["project_id"],
                        fileId: file["file_id"],
                        excelLink: file["excel_link"],
                        genericTablesExcelLink:
                            file["generic_tables_excel_link"],
                        pdfLink: file["link"],
                        previewLink: file["preview_link"],
                        validationErrors: file["validation_errors"],
                        warnings: file["warnings"],
                    },
                });
            }
        }
        setRowData(agGridData);
    }, [selectedProjectInfo, dispatch]);

    const onFilterTextBoxChanged = (e) => {
        let value = e.target.value;
        gridApi.setQuickFilter(value);
        setSearchedValue(value);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const isRowSelectable = (params) => {
        const loadingStatusesNoFailed = [
            "Uploading",
            "ReLoading",
            "Categorising",
        ];

        return !loadingStatusesNoFailed.includes(params["data"]["File Status"]);
    };

    useEffect(() => {
        sizeColumnsToFitFunc()
    })

    useEffect(() => {
        rowDataCalc();
    }, [rowDataCalc]);

    useEffect(() => {
        if (gridApi !== null) {
            if (selectedFiles.length === 0) {
                gridApi.deselectAll();
            }
        }
    }, [selectedFiles, gridApi]);

    useEffect(() => {
        if (gridApi !== null) {
            if (altFilesSelectAll) {
                gridApi.selectAll();
            }
        }
    }, [altFilesSelectAll, gridApi]);

    useEffect(() => {
        if (gridApi !== null) {
            if (altFilesDeselectAll) {
                gridApi.deselectAll();
            }
        }
    }, [altFilesDeselectAll, gridApi]);

    useLayoutEffect(() => {
        if (node) {
            window.addEventListener("resize", sizeColumnsToFitFunc);
            return () => {
                window.removeEventListener("resize", sizeColumnsToFitFunc);
            };
        }
    }, [node, sizeColumnsToFitFunc]);

    return (
        <>
            {rowData.length !== 0 ? (
                <div
                    data-testid="file-list-container"
                    className="file-tiles-container"
                    ref={fileTileContainerRef}
                >
                    <div
                        className="ag-theme-material"
                        style={{ height: "inherit", width: "100%" }}
                    >
                        <ResultsDataTableToolbarSearch
                            onFilterTextBoxChanged={onFilterTextBoxChanged}
                            placeHolderText="Document Name Search"
                            searchedValue={searchedValue}
                            pageLocation="homepage"
                        ></ResultsDataTableToolbarSearch>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={columnTitles}
                            defaultColDef={defaultColDef}
                            rowSelection={"multiple"}
                            rowMultiSelectWithClick={false}
                            onFirstDataRendered={sizeColumnsToFitFunc}
                            suppressRowClickSelection={true}
                            onRowDataChanged={() => {
                                setSelectedFiles([]);
                            }}
                            isRowSelectable={isRowSelectable}
                            getRowClass={getRowClass}
                            suppressColumnVirtualisation={true}
                            onGridReady={(params) => onGridReady(params)}
                            onRowSelected={onRowSelected}
                        />
                    </div>
                </div>
            ) : (
                <div className="no-file">
                    You don't have any files yet.
                    <Button
                        className="add-file-link"
                        kind="ghost"
                        data-testid="no-file-create-project-link-button-list"
                        id="no-file-create-project-link-button-list"
                        onClick={() => setFileModalOpen(true)}
                    >
                        {" "}
                        Add one.
                    </Button>
                </div>
            )}
        </>
    );
};

export default AltFileTiles;
