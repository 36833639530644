import { updateFileDetails } from "./file-details.selectors";
import types from "./file-details.types";
export const initialState = {
    fileDetails: null,
    redirectionDetails: {},
    newFileDetails: null,
};

const fileDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.addFileDetails:
            return {
                ...state,
                fileDetails: action.payload,
            };
        case types.clearFileDetails:
            return {
                ...state,
                fileDetails: null,
            };

        case types.updatedAddedFileDetails:
            return {
                ...state,
                fileDetails: updateFileDetails(state, action),
            };
        case types.setRedirectionDetails:
            return {
                ...state,
                redirectionDetails: action.payload,
            };
        case types.clearRedirectionDetails:
            return {
                ...state,
                redirectionDetails: {},
            };
        case types.updateFileDetails:
            return {
                ...state,
                newFileDetails: action.payload,
            };
        case types.clearUpdateFileDetails:
            return {
                ...state,
                newFileDetails: null,
            };
        default:
            return state;
    }
};

export default fileDetailsReducer;
