import { TrashCan32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";
import { deleteSelectedObject } from "../../../helpers/helper-functions.js";

const DeleteButton = ({
    selectedObject,
    deleteLineFunction,
    deleteRectFunction,
    blurButtons,
    hideUpDownWarningButtons,
}) => {
    const onDelete = () => {
        deleteSelectedObject(
            selectedObject,
            deleteLineFunction,
            deleteRectFunction
        );
        blurButtons();
        hideUpDownWarningButtons()
    };

    return (
        <Button
            renderIcon={TrashCan32}
            iconDescription="Delete"
            data-testid="mask-page-file-toolbar-delete-button"
            id="mask-page-file-toolbar-delete-button"
            className="default-button-colour"
            hasIconOnly={true}
            onClick={() => onDelete()}
        />
    );
};

export default DeleteButton;
