import { Save16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateTransformedColumnSpec } from "../../../helpers/helper-functions-2.js";
import {
    postTablesUpdateAction,
    updateFileDetailsAction,
} from "../../../redux/thunks/thunks.actions";

const SaveButton = ({
    pathname,
    backEndSubmitGrids,
    columnSpec,
    blurButtons,
    hideUpDownWarningButtons,
}) => {
    const dispatch = useDispatch();
    const newFileDetails = useSelector(
        (state) => state.fileDetails.newFileDetails
    );
    const [transformedColSpec, setTransformedColSpec] = useState([]);

    const saveTables = () => {
        const pathnameSplit = pathname.split("/");
        let projectId = pathnameSplit[2];
        let fileId = pathnameSplit[3];
        dispatch(updateFileDetailsAction(projectId, fileId, newFileDetails));
        dispatch(
            postTablesUpdateAction(
                projectId,
                fileId,
                backEndSubmitGrids,
                transformedColSpec
            )
        );
        blurButtons();
        hideUpDownWarningButtons();
    };

    useEffect(() => {
        let newColumnSpec = generateTransformedColumnSpec(columnSpec);
        setTransformedColSpec(newColumnSpec);
    }, [columnSpec]);

    return (
        <Button
            renderIcon={Save16}
            iconDescription="Save"
            data-testid="mask-page-file-toolbar-save-button"
            id="mask-page-file-toolbar-save-button"
            className="default-button-colour"
            hasIconOnly={true}
            onClick={saveTables}
        />
    );
};

export default SaveButton;
