import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InputFieldEmail from "../input-fields/input-fields-email.component";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const AccordionLogIn = ({
    loginAccordionBorder,
    isLoginFinished,
    isLoginAccordionOpen,
    setUser,
    user,
    setEmailConforms,
    emailConforms,
    setPass,
    pass,
    handleLogin,
}) => {
    const [type, setType] = useState("password");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const togglePasswordVisibility = () => {
        setType(type === "password" ? "text" : "password");
    };

    useEffect(() => {
        pass !== "" && emailConforms
            ? setIsButtonDisabled(false)
            : setIsButtonDisabled(true);
    }, [emailConforms, pass]);

    return (
        <Accordion
            className={`${loginAccordionBorder} accordion-style`}
            data-testid="login-accordion"
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isLoginFinished ? (
                            <div
                                data-testid="login-accordion-circle"
                                className={`circle ${
                                    isLoginAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="login-accordion-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>Log In</p>
                    </div>
                }
                disabled={true}
                open={isLoginAccordionOpen}
            >
                <div className="field-margin-bottom">
                    <InputFieldEmail
                        setUser={setUser}
                        user={user}
                        setEmailConforms={setEmailConforms}
                        emailConforms={emailConforms}
                        disabledField={false}
                    />
                </div>
                <div className="field-margin-bottom">
                    <TextInput.ControlledPasswordInput
                        id="password-input"
                        data-testid="password-input"
                        labelText="Password"
                        togglePasswordVisibility={togglePasswordVisibility}
                        onChange={(e) => setPass(e.target.value)}
                        type={type}
                        disabled={emailConforms ? false : true}
                        placeholder="Password"
                        helperText={
                            <Link
                                data-testid="forgot-password-link"
                                className="blue-text"
                                to="/forgot-password"
                            >
                                Forgot password
                            </Link>
                        }
                        onKeyDown={(e) => e.key === 'Enter' ? handleLogin : null}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={isButtonDisabled}
                    onClickFunction={handleLogin}
                    buttonDescription={"Next"}
                    dataTestId={"login"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionLogIn;
