import {
    Launch16,
    SettingsAdjust16,
    Table16,
} from "@carbon/icons-react";
import Tippy from "@tippyjs/react";
import { Button } from "carbon-components-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeExtension } from "../../../../helpers/helper-functions-2";
import "./alt-file-tiles.styles.scss";

const AltGridResultsButton = ({ fileId, projectId, fileName }) => {
    const navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState(false);

    const toggleGrid = () => {
        setShowDropDown(!showDropDown);
    };

    const closeGrid = () => {
        setShowDropDown(false);
    };

    const handleLink = (e, projectId, fileId, redirection) => {
        e.preventDefault();
        const url = `${redirection}/${projectId}/${fileId}`;
        navigate(url);
    };

    return (
        <Tippy
            content={
                <div className="menu-container margin-left-position">
                    <div
                        id={`grid-results-dropdown-${fileId}`}
                        data-testid={`grid-results-dropdown-${fileId}`}
                        className="buttons-grid-width buttons-grid"
                    >
                        <Button
                            role="mask-redirect"
                            data-testid={`open-mask-${fileId}`}
                            id={`open-mask-${fileId}`}
                            className="button-colour"
                            renderIcon={SettingsAdjust16}
                            onClick={(e) =>
                                handleLink(e, projectId, fileId, "mask")
                            }
                            size="sm"
                        >
                            Open Mask
                        </Button>
                        <Button
                            role="results-redirect"
                            data-testid={`open-results-${fileId}`}
                            id={`open-results-${fileId}`}
                            className="button-colour"
                            renderIcon={Table16}
                            onClick={(e) =>
                                handleLink(e, projectId, fileId, "results")
                            }
                            size="sm"
                        >
                            Open Results
                        </Button>
                        {/* <Button
                            role="categorisation-redirect"
                            data-testid={`open-categorisation-${fileId}`}
                            id={`open-categorisation-${fileId}`}
                            className="button-colour"
                            renderIcon={Category16}
                            onClick={(e) =>
                                handleLink(
                                    e,
                                    projectId,
                                    fileId,
                                    "categorisation"
                                )
                            }
                            size="sm"
                        >
                            Open Categorisation
                        </Button> */}
                    </div>
                </div>
            }
            visible={showDropDown}
            onClickOutside={closeGrid}
            allowHTML={true}
            arrow={false}
            appendTo={() => document.body}
            interactive={true}
            placement="auto"
        >
            <div className="tile-container-display">
                <Button
                    role="display-redirect-buttons"
                    renderIcon={Launch16}
                    focusable={false}
                    data-testid={`mask-results-toggle-${removeExtension(
                        fileName
                    )}`}
                    id={`mask-results-toggle-${fileId}`}
                    iconDescription="Open"
                    className="
                        icon-button-design 
                        button-colour 
                        tile-button-position 
                        remove-tooltip 
                        margin-left-position
                        make-buttons-disappear
                    "
                    hasIconOnly={true}
                    onClick={toggleGrid}
                />
            </div>
        </Tippy>
    );
};

export default AltGridResultsButton;
