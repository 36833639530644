import { Column, InlineNotification, Row } from "carbon-components-react";
// import { Link } from "react-router-native";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../components/dashboard/dashboard.component";
import FileAddModal from "../../components/file-add-modal/file-add-modal.component";
import FileExplorer from "../../components/file-explorer/file-explorer.component";
import HeaderComponent from "../../components/header/header.component";
import ProjectAddModal from "../../components/project-add-modal/project-add-modal.component";
import ProjectModalButtons from "../../components/project-file-add-buttons/project-file-add-buttons.component";
import ProjectRenameModal from "../../components/project-rename-modal/project-rename-modal.component";
import { configureInAppApiErrors } from "../../helpers/helper-functions-2.js";
import { clearApiError } from "../../redux/api/api.actions";
import {
    getProjectFilesAction,
    getProjectList,
    redirectionActions,
} from "../../redux/thunks/thunks.actions";

require("dotenv").config();

const HomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const apiErrors = useSelector((state) => state.api.apiError);
    const projectsLoading = useSelector((state) => state.projects.loading)
    const projects = useSelector((state) => state.projects.projects);
    const user = useSelector((state) => state.user.user);

    const pageDivRef = useRef(null);

    const [project, setProject] = useState({ name: "" });
    const [tileType, setTileType] = useState("projects");
    const [projectRenameModalOpen, setProjectRenameModalOpen] = useState(false);
    const [projectModalOpen, setProjectModalOpen] = useState(false);
    const [fileModalOpen, setFileModalOpen] = useState(false);
    const [closeButtons, setCloseButtons] = useState(false);
    const [projectToRename, setProjectToRename] = useState("");
    const [fadeClass, setFadeClass] = useState("fade-out-1");
    const [lastClickedFileId, setLastClickedFileId] = useState("");
    const [projectRedirection, setProjectRedirection] = useState(false);
    const [gotProjects, setGotProjects] = useState(false);
    const [apiNotificationTitle, setApiNotificationTitle] = useState("");
    const [apiNotificationSubTitle, setApiNotificationSubTitle] = useState("");
    const [allProjects, setAllProjects] = useState([]);

    const [homepageNumberOfWarnings, setHomepageNumberOfWarnings] = useState(0);

    const [isCollapsed, setIsCollapsed] = useState(true);

    const blurButtons = () => {
        if (pageDivRef.current) {
            pageDivRef.current.focus();
        }
    };

    const updateProject = useCallback(() => {
        if (project.name === "") {
            return;
        }

        let updatedProject = projects.find(
            (newProject) => project.project_id === newProject.project_id
        );
        setProject(updatedProject);
    }, [project, projects]);

    const selectProject = useCallback(
        (project, redirection) => {
            setTileType("files");
            setProject(project);
            setProjectRedirection(true);
            if (redirection) {
                dispatch(redirectionActions(project.project_id));
            } else {
                dispatch(getProjectFilesAction(project.project_id));
            }
        },
        [dispatch]
    );

    const unselectProject = () => {
        setTileType("projects");
        setProject({ name: "" });
        setProjectRedirection(false);
        setLastClickedFileId("");
    };

    /**
     * Checks if page was opened due to redirect from Grids page.
     *   If so opens corresponding project page
     */
    const projectPageFromRedirect = useCallback(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params.hasOwnProperty("projectId") && projects.length !== 0) {
            let paramsProjectId = params["projectId"];

            let redirectedProject = projects.find(
                (project) => project["project_id"] === paramsProjectId
            );

            selectProject(redirectedProject, true);
            urlSearchParams.delete("projectId");

            navigate({
                search: urlSearchParams.toString(),
            });
        }
    }, [navigate, location, projects, selectProject]);

    /**
     * Tells file-tiles what project was just clicked. This is cleared after 0.1 seconds
     * @param {int} fileId
     */
    const clickedFileId = (fileId) => {
        setLastClickedFileId(fileId);
        setProjectRedirection(true);
        setTimeout(() => {
            setLastClickedFileId(-1);
        }, [100]);
    };

    const toggleProjectModal = (event) => {
        setProjectModalOpen(!projectModalOpen);
    };

    const toggleProjectRenameModal = (selectedProjectInfo) => {
        setProjectToRename(selectedProjectInfo);
        setProjectRenameModalOpen(!projectRenameModalOpen);
    };

    const toggleFileModal = () => {
        setFileModalOpen(!fileModalOpen);
    };

    const removeApiErrorNotification = useCallback(() => {
        if (apiErrors === null) {
            setFadeClass("fade-out-1");
        } else {
            setFadeClass("fade-in-1");

            setTimeout(() => {
                dispatch(clearApiError());
            }, 5000);
        }
    }, [apiErrors, dispatch]);

    const onSubmitFiles = () => {
        setFileModalOpen(false);
        setCloseButtons(true);
    };

    const onCloseButtons = () => {
        setCloseButtons(false);
    };

    useEffect(() => {
        if (apiErrors !== null) {
            let [notificationTitle, notificationSubTitle] =
                configureInAppApiErrors(apiErrors);

            setApiNotificationTitle(notificationTitle);
            setApiNotificationSubTitle(notificationSubTitle);
        }
    }, [apiErrors]);

    useEffect(() => {
        // console.log("user", user)
        // if (!gotProjects && user) {
        if (!gotProjects) {
            setGotProjects(true);
            dispatch(getProjectList());
        }
    }, [dispatch, projects, gotProjects]);

    useEffect(() => {
        removeApiErrorNotification();
    }, [removeApiErrorNotification]);

    useEffect(() => {
        updateProject();
    }, [updateProject]);

    useEffect(() => {
        projectPageFromRedirect();
    }, [projectPageFromRedirect]);

    useEffect(() => {
        setAllProjects(projects);
    }, [projects]);

    useEffect(() => {
        let list_of_errors = [];

        if (apiErrors) {
            list_of_errors.push(1);
        }
        setHomepageNumberOfWarnings(list_of_errors.length);
    }, [user, apiErrors]);

    return (
        <>
            <div
                className="bx--grid--full-width page-layout"
                tabIndex="-1"
                ref={pageDivRef}
            >
                <HeaderComponent isLogin={false} displayNavOptions={true} isProjectsPage={true}/>
                <Row className="row" style={{ overflow: "hidden" }}>
                    <Column sm={2} md={3} lg={3} className="file-explorer-box">
                        <FileExplorer
                            handleProjectClick={selectProject}
                            allProjectsInfo={allProjects}
                            clickedFileId={clickedFileId}
                        />
                    </Column>
                    <Column sm={2} md={4} lg={12} className="dashboard-box">
                        <Dashboard
                            projectsLoading={projectsLoading}
                            allProjectsInfo={allProjects}
                            selectedProjectInfo={project}
                            lastClickedFileId={lastClickedFileId}
                            handleTileClick={selectProject}
                            handleBackClick={unselectProject}
                            tileType={tileType}
                            onToggleProjectRenameModal={toggleProjectRenameModal}
                            projectRedirection={projectRedirection}
                            setProjectModalOpen={setProjectModalOpen}
                            setFileModalOpen={setFileModalOpen}
                            setAllProjects={setAllProjects}
                            blurButtons={blurButtons}
                            homepageNumberOfWarnings={homepageNumberOfWarnings}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={setIsCollapsed}
                        />
                    </Column>
                    <ProjectAddModal
                        onToggleProjectModal={toggleProjectModal}
                        projectModalShow={projectModalOpen}
                        allProjectsInfo={allProjects}
                        clickedFileId={clickedFileId}
                    />
                    <FileAddModal
                        onToggleFileModal={toggleFileModal}
                        onSubmitFiles={onSubmitFiles}
                        fileModalShow={fileModalOpen}
                        allProjectsInfo={allProjects}
                        selectedProject={project}
                    />
                    <ProjectRenameModal
                        onToggleProjectRenameModal={toggleProjectRenameModal}
                        projectRenameModalShow={projectRenameModalOpen}
                        allProjectsInfo={allProjects}
                        selectedProjectInfo={projectToRename}
                    />
                    <ProjectModalButtons
                        onToggleProjectModal={toggleProjectModal}
                        onToggleFileModal={toggleFileModal}
                        closeButtons={closeButtons}
                        onCloseButtons={onCloseButtons}
                        isCollapsed={isCollapsed}
                    />
                    <div
                        className={`inline-notification-homepage higher-z-index`}
                    >
                        {apiErrors ? (
                            <InlineNotification
                                kind="error"
                                iconDescription="Close Notification"
                                subtitle={<span>{apiNotificationSubTitle}</span>}
                                title={apiNotificationTitle}
                                onCloseButtonClick={() => {
                                    dispatch(clearApiError());
                                }}
                                lowContrast={true}
                                className={`InlineNotification_mod ${fadeClass}`}
                                data-testid="file-not-uploaded-notification"
                            />
                        ) : null}
                    </div>
                </Row>
            </div>
        </>
    );
};

export default HomePage;
