import { Download16, Finance16, Pdf16, TableSplit16 } from "@carbon/icons-react";
import Tippy from "@tippyjs/react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadExcelFilesAction } from "../../../../redux/thunks/thunks.actions";

const AltDownloadButtons = ({ fileId, projectId, fileName }) => {
    const dispatch = useDispatch();

    const [downloadText, setDownloadText] = useState("PDF");
    const [showDropDown, setShowDropDown] = useState(false);

    const toggleGrid = () => {
        setShowDropDown(!showDropDown);
    };

    const closeGrid = () => {
        setShowDropDown(false);
    };

    const downloadFiles = (excelType) => {
        dispatch(downloadExcelFilesAction(projectId, excelType, fileId));
    };

    useEffect(() => {
        fileName !== ""
            ? setDownloadText(fileName.split(".").slice(-1)[0].toUpperCase())
            : setDownloadText("PDF");
    }, [fileName]);

    return (
        <Tippy
            content={
                <div className="menu-container margin-left-position">
                    <div
                        id={`download-dropdown-${fileId}`}
                        data-testid={`download-dropdown-${fileId}`}
                        className="buttons-grid buttons-grid-width-2"
                    >
                        <Button
                            renderIcon={TableSplit16}
                            data-testid={`download-generic-tables-xls-${fileId}`}
                            id={`download-generic-tables-xls-${fileId}`}
                            className="button-colour make-buttons-disappear"
                            iconDescription="Download Generic Tables"
                            onClick={() => downloadFiles("generic_tables")}
                            size="sm"
                        >
                            Download Generic Tables
                        </Button>
                        <Button
                            renderIcon={Finance16}
                            className="button-colour make-buttons-disappear"
                            data-testid={`download-xls-${fileId}`}
                            id={`download-xls-${fileId}`}
                            iconDescription="Download Bank Statement"
                            onClick={() => downloadFiles("statement")}
                            size="sm"
                        >
                            Download Bank Statement
                        </Button>
                        <Button
                            renderIcon={Pdf16}
                            data-testid={`download-pdf-${fileId}`}
                            id={`download-pdf-${fileId}`}
                            iconDescription="Download PDF"
                            className="button-colour make-buttons-disappear"
                            onClick={() => downloadFiles("pdf")}
                            size="sm"
                        >
                            Download {downloadText}
                        </Button>
                    </div>
                </div>
            }
            visible={showDropDown}
            onClickOutside={closeGrid}
            allowHTML={true}
            arrow={false}
            appendTo={document.body}
            interactive={true}
            placement="auto"
        >
            <div className="tile-container-display">
                <Button
                    renderIcon={Download16}
                    iconDescription="Download"
                    data-testid={`download-toggle-${fileId}`}
                    className="icon-button-design 
                    button-colour 
                    tile-button-position 
                    remove-tooltip 
                    margin-left-position 
                    make-buttons-disappear"
                    hasIconOnly={true}
                    onClick={toggleGrid}
                />
            </div>
        </Tippy>
    );
};

export default AltDownloadButtons;
