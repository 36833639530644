import types from "./auth.types";

export const setAuthToken = (token) => {
    return { type: types.SET_AUTH_TOKEN, payload: token };
};

export const setTokenType = (tokenType) => {
    return { type: types.SET_TOKEN_TYPE, payload: tokenType };
};

export const setLoginError = (message) => {
    return { type: types.SET_LOGIN_ERROR, payload: message };
};

export const setQRCode = (code) => {
    return { type: types.SET_QR_CODE, payload: code };
};

export const setQRCodeUsername = (qrCodeUsername) => {
    return { type: types.SET_QR_CODE_USERNAME, payload: qrCodeUsername };
};

export const setQRCognitoUser = (qrCognitoUser) => {
    return { type: types.SET_QR_COGNITO_USER, payload: qrCognitoUser };
};

export const setProgressToNextStep = (nextStep) => {
    return { type: types.SET_PROGRESS_TO_NEXT_STEP, payload: nextStep };
};

export const setRedirectToLogin = (redirectToLogin) => {
    return { type: types.SET_REDIRECT_TO_LOGIN, payload: redirectToLogin };
};

export const setAuthMessage = (authMessage) => {
    return { type: types.SET_AUTH_MESSAGE, payload: authMessage };
};

export const setCognitoUser = (cognitoUser) => {
    return { type: types.SET_COGNITO_USER, payload: cognitoUser };
};

export const clearAuthToken = () => {
    return {type: types.CLEAR_AUTH_TOKEN}
}
