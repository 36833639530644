import Tippy from "@tippyjs/react";
import { Button } from "carbon-components-react";
import React, { useState } from "react";
import StatusNotification from "../../../status-notification/status-notification.component";

const AltStatusNotificationContainer = ({
    fileWarnings,
    fileValidationErrors,
    fileState,
    fileId,
    projectId,
}) => {
    const [showDropDown, setShowDropDown] = useState(false);
    const [buttonDescription, setButtonDescription] = useState();
    const [icon, setIcon] = useState();
    const [iconType, setIconType] = useState();

    const toggleGrid = () => {
        setShowDropDown(!showDropDown);
    };

    const closeGrid = () => {
        setShowDropDown(false);
    };

    return (
        <Tippy
            content={
                <StatusNotification
                    showNotification={true}
                    fileWarnings={fileWarnings}
                    fileValidationErrors={fileValidationErrors}
                    fileState={fileState}
                    fileId={fileId}
                    projectId={projectId}
                    iconType={iconType}
                    setButtonDescription={setButtonDescription}
                    setIcon={setIcon}
                    setIconType={setIconType}
                    toggleNotification={() => {}}
                />
            }
            visible={showDropDown}
            onClickOutside={closeGrid}
            allowHTML={true}
            arrow={false}
            appendTo={document.body}
            interactive={true}
            placement="bottom-start"
        >
            <div className="tile-container-display">
                <Button
                    renderIcon={icon}
                    id={`status-notification-${fileId}`}
                    data-testid={`status-notification-${fileId}`}
                    className={`${iconType}-tick icon-button-design button-colour tile-button-position remove-tooltip`}
                    iconDescription={buttonDescription}
                    hasIconOnly={true}
                    onClick={toggleGrid}
                />
            </div>
        </Tippy>
    );
};

export default AltStatusNotificationContainer;
