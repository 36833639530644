import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React from "react";
import { useDispatch } from "react-redux";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import {
    resendEmailVerificationCodeHelper,
    validateEmailHelper,
} from "../../helpers/helper-functions.js";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const AccordionVerifyEmail = ({
    verifyEmailAccordionBorder,
    isVerifyEmailFinished,
    isVerifyEmailAccordionOpen,
    user,
    setVerificationCode,
    verificationCode,
    setVerificationCodeConforms,
    verificationCodeConforms,
    verifyEmailNextButtonDisabled,
}) => {
    const dispatch = useDispatch();

    const handleEmailValidation = (e) => {
        e.preventDefault();
        validateEmailHelper(user, verificationCode, dispatch);
    };

    const handleResendEmailVerificationCode = (e) => {
        e.preventDefault();
        resendEmailVerificationCodeHelper(user, dispatch);
    };
    return (
        <Accordion
            className={verifyEmailAccordionBorder}
            data-testid="verify-email-accordion"
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isVerifyEmailFinished ? (
                            <div
                                data-testid="verify-email-circle"
                                className={`circle ${
                                    isVerifyEmailAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="verify-email-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>Verify Email</p>
                    </div>
                }
                disabled={true}
                open={isVerifyEmailAccordionOpen}
            >
                <div
                    className="field-margin-bottom"
                    data-testid="verify-email-accordion-text"
                >
                    We sent a 6-digit verification code to {user}. This code is
                    valid for 30 minutes.
                </div>
                <div className="field-margin-bottom">
                    <InputFieldVerificationCode
                        setVerificationCode={setVerificationCode}
                        verificationCode={verificationCode}
                        setVerificationCodeConforms={setVerificationCodeConforms}
                        verificationCodeConforms={verificationCodeConforms}
                        labelText={"Verification code"}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={verifyEmailNextButtonDisabled}
                    onClickFunction={handleEmailValidation}
                    buttonDescription={"Next"}
                    dataTestId={"verify-email"}
                />
                <CommonRegistrationButton
                    buttonKind={"ghost"}
                    isDisabled={false}
                    onClickFunction={handleResendEmailVerificationCode}
                    buttonDescription={"Resend Code"}
                    dataTestId={"resend-email-verification-code"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionVerifyEmail;
