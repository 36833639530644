import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginError } from "../../redux/auth/auth.actions";
import { awsCognitoLogIn } from "../../redux/thunks/thunks.actions";
import InputFieldPassword from "../input-fields/input-fields-password.component";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";

const AccordionNewPasswordRequired = ({
    newPasswordRequiredAccordionBorder,
    isNewPasswordRequiredFinished,
    isNewPasswordRequiredAccordionOpen,
    user,
    pass,
}) => {
    const dispatch = useDispatch();
    const loginError = useSelector((state) => state.auth.loginError);

    const [passwordConforms, setPasswordConforms] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [displayPasswordRequirements, setDisplayPasswordRequirements] =
        useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleNewPasswordChallenge = () => {
        if (pass === newPassword) {
            dispatch(
                setLoginError(
                    "The new password has to be different from current one"
                )
            );
        } else {
            dispatch(awsCognitoLogIn(user, pass, null, newPassword));
        }
    };

    useEffect(() => {
        passwordConforms
            ? setIsButtonDisabled(false)
            : setIsButtonDisabled(true);
    }, [passwordConforms]);

    useEffect(() => {
        if (loginError) {
            setTimeout(() => {
                dispatch(setLoginError(null));
            }, 5000);
        }
    }, [loginError, dispatch]);

    return (
        <Accordion
            className={newPasswordRequiredAccordionBorder}
            data-testid="new-password-required-accordion"
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isNewPasswordRequiredFinished ? (
                            <div
                                data-testid="new-password-required-accordion-circle"
                                className={`circle ${
                                    isNewPasswordRequiredAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="new-password-required-accordion-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>New Password Required</p>
                    </div>
                }
                disabled={true}
                open={isNewPasswordRequiredAccordionOpen}
            >
                <div className="field-margin-bottom">
                    <InputFieldPassword
                        setPass={setNewPassword}
                        pass={newPassword}
                        setPasswordConforms={setPasswordConforms}
                        passwordConforms={passwordConforms}
                        setDisplayPasswordRequirements={
                            setDisplayPasswordRequirements
                        }
                        displayPasswordRequirements={displayPasswordRequirements}
                        user={user}
                        emailConforms={true}
                        stopOnFocusEvent={false}
                        id={"accordion-new-password-required"}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={isButtonDisabled}
                    onClickFunction={handleNewPasswordChallenge}
                    buttonDescription={"Next"}
                    dataTestId={"new-password-required-user"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionNewPasswordRequired;
