import {
    Dropdown,
    FileUploaderDropContainer,
    FileUploaderItem,
    Modal,
} from "carbon-components-react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearApiError } from "../../redux/api/api.actions";
import { uploadFiles } from "../../redux/thunks/thunks.actions";

const FileAddModal = ({
    fileModalShow,
    onSubmitFiles,
    onToggleFileModal,
    allProjectsInfo,
    selectedProject,
}) => {
    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [project, setProject] = useState(null);

    const addFile = (event, addedFiles) => {
        let matchingNames = files.reduce(
            (accumulator, file) =>
                accumulator.concat(
                    addedFiles.addedFiles.filter(
                        (addedFile) => file.name === addedFile.name
                    )
                ),
            []
        );

        const nameOnlyMatches = matchingNames.map((file) => file.name);
        if (matchingNames.length === 1) {
            alert(`Cannot add file with the same name ${nameOnlyMatches[0]}`);
        } else if (matchingNames.length > 1) {
            alert(
                `Cannot add files with the same names ` +
                    `${nameOnlyMatches.slice(0, -1).join(", ")} ` +
                    `and ${nameOnlyMatches.slice(-1)}`
            );
        } else {
            setFiles([...files, ...addedFiles.addedFiles]);
        }
    };

    const changeProject = (projectsSelected) => {
        setProject(projectsSelected.selectedItem);
    };

    const removeFile = (fileName) => {
        setFiles(files.filter((file) => file.name !== fileName));
    };

    const submitFiles = (e) => {
        e.preventDefault();
        dispatch(clearApiError());
        const filesNotZero = files.length !== 0;
        const projectNotNull = project !== null;

        if (projectNotNull && filesNotZero) {
            let projectFiles = new Set(
                allProjectsInfo
                    .find((proj) => proj.project_id === project.id)
                    .files.map((file) => file.name)
            );

            let repeatedFiles = [];
            for (let file of files) {
                if (projectFiles.has(file.name)) {
                    repeatedFiles.push(file.name);
                }
            }

            if (repeatedFiles.length === 1) {
                alert(
                    `Cannot add file with the same name ` +
                        `${repeatedFiles[0]} in project ${project.label}`
                );
                return;
            } else if (repeatedFiles.length > 1) {
                alert(
                    `Cannot add files with the same names ` +
                        `${repeatedFiles.slice(0, -1).join(", ")} ` +
                        `and ${repeatedFiles.slice(-1)} in project ${
                            project.label
                        }`
                );
                return;
            }

            dispatch(uploadFiles(project.id, files));
            setFiles([]);
            setProject(null);
            onSubmitFiles();
        } else {
            let alertMessage;
            if (projectNotNull) {
                alertMessage = "Please choose at least one file to be uploaded";
            } else if (filesNotZero) {
                alertMessage = "Please choose a project";
            } else {
                alertMessage =
                    "Please choose a project and at least one file to be uploaded";
            }
            alert(alertMessage);
        }
    };

    useEffect(() => {
        if (selectedProject.name !== "") {
            let selectedItem = {
                id: selectedProject.project_id,
                label: selectedProject.name,
            };
            setProject(selectedItem);
        } else {
            setProject(null);
        }
    }, [selectedProject.name, selectedProject.project_id]);

    return (
        <Fragment>
            <Modal
                id="file-add-modal"
                className="file-add-modal"
                data-testid="file-add-modal"
                modalHeading="Upload File"
                open={fileModalShow}
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"
                hasScrollingContent={false}
                iconDescription="Close"
                size="sm"
                onRequestSubmit={submitFiles}
                onRequestClose={onToggleFileModal}
            >
                <Dropdown
                    id="project-list"
                    data-testid="file-add-dropdown"
                    label="Choose project"
                    items={allProjectsInfo.map((project) => ({
                        id: project.project_id,
                        label: project.name,
                    }))}
                    className="project-dropdown"
                    onChange={changeProject}
                    selectedItem={project}
                />
                <p
                    className="file-modal-title"
                    data-testid="supported-files-text"
                >
                    Supported file types are .pdf, .jpeg/.jpg and .png
                </p>
                <p className="file-modal-text">
                    Note that files with page counts greater than 50 will be split and appear as separate parts in Oscar.
                </p>
                <FileUploaderDropContainer
                    multiple={true}
                    data-testid="file-drop-upload"
                    onAddFiles={addFile}
                    labelText="Drag and drop files here or click to select"
                ></FileUploaderDropContainer>
                <div className="file-container" data-testid="file-container">
                    {files.map((file) => (
                        <FileUploaderItem
                            key={file.name}
                            name={file.name}
                            onDelete={(e) => removeFile(file.name)}
                            status="edit"
                        />
                    ))}
                </div>
            </Modal>
        </Fragment>
    );
};

export default FileAddModal;
