import { Button } from "carbon-components-react";
import React from "react";

const ContextMenu = ({ anchorPoint, showContextMenu, setContextMenuOption }) => {
    const handleSwapCreditDebit = () => {
        setContextMenuOption("handleSwapCreditDebit");
    };

    const handleCopyBalanceToStatementBalance = () => {
        setContextMenuOption("handleCopyBalanceToStatementBalance");
    };

    const handleIncrementYear = () => {
        setContextMenuOption("handleIncrementYear");
    };

    const handleDecrementYear = () => {
        setContextMenuOption("handleDecrementYear");
    };

    return (
        <>
            {showContextMenu ? (
                <ul
                    className="results-page-context-menu"
                    data-testid="results-page-context-menu"
                    style={{
                        top: `${anchorPoint.y}px`,
                        left: `${anchorPoint.x}px`,
                    }}
                >
                    <Button
                        type="submit"
                        data-testid="context-menu-swap-credit-debit-button"
                        id="context-menu-swap-credit-debit-button"
                        onClick={handleSwapCreditDebit}
                    >
                        Swap Credit/Debit
                    </Button>
                    <Button
                        type="submit"
                        data-testid="context-menu-copy-balance-to-statement-balance-button"
                        id="context-menu-copy-balance-to-statement-balance-button"
                        onClick={handleCopyBalanceToStatementBalance}
                    >
                        Copy Balance To Statement Balance
                    </Button>
                    <Button
                        type="submit"
                        data-testid="context-menu-increment-year-button"
                        id="context-menu-increment-year-button"
                        onClick={handleIncrementYear}
                    >
                        Increment Year
                    </Button>
                    <Button
                        type="submit"
                        data-testid="context-menu-decrement-year-button"
                        id="context-menu-decrement-year-button"
                        onClick={handleDecrementYear}
                    >
                        Decrement Year
                    </Button>
                </ul>
            ) : null}
        </>
    );
};

export default ContextMenu;
