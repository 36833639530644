import types from "./projects.types";

export const addProject = (project) => {
    return { type: types.ADD_PROJECT, payload: project };
};

export const deleteProject = (project_id) => {
    return { type: types.REMOVE_PROJECT, payload: project_id };
};

export const setProjects = (projects) => {
    return { type: types.SET_PROJECTS, payload: projects };
};

export const toggleProjectLoad = () => {
    return {type: types.TOGGLE_PROJECT_LOAD}
}

export const toggleFilesUploading = () => {
    return {type: types.TOGGLE_FILES_UPLOADING}
}

export const addFilesToProject = (project_id, files) => {
    return { type: types.ADD_FILES, id: project_id, payload: files };
};

export const removeFileFromProject = (project_id, file_id) => {
    return { type: types.REMOVE_FILE, project_id: project_id, file_id: file_id };
};

export const renameProject = (project_id, project_name, project_description) => {
    return {
        type: types.RENAME_PROJECT,
        project_id: project_id,
        project_name: project_name,
        project_description: project_description,
    };
};

export const websocketUpdateProjectFile = (project_id, file_id, websocketInfo) => {
    return {
        type: types.WEBSOCKET_UPDATE,
        file_id: file_id,
        project_id: project_id,
        upload_status: websocketInfo["message"],
        name: websocketInfo["fileName"],
        preview_link: websocketInfo["previewLink"],
        warnings: websocketInfo["warnings"],
        validation_errors: websocketInfo["validationErrors"],
        link: websocketInfo["pdfLink"],
        excel_link: websocketInfo["excelLink"],
        generic_tables_excel_link: websocketInfo["genericTablesExcelLink"],
        notes: websocketInfo["notes"],
    };
};

export const updateProjectFileState = (project_id, file_id, newState) => {
    return {
        type: types.UPDATE_FILE_STATE,
        file_id: file_id,
        project_id: project_id,
        upload_status: newState,
    };
};
