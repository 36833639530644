import { InlineNotification } from "carbon-components-react";
import React from "react";
import "./inline-notification.styles.scss";

const InlineNotificationSuccess = ({ authMessage, pageLocation }) => {
    return (
        <InlineNotification
            kind="success"
            id={`${pageLocation}-page-auth-notification`}
            data-testid={`${pageLocation}-page-auth-notification`}
            title={authMessage}
            lowContrast={true}
            className="inline-notification fade-in-1"
            hideCloseButton={true}
        />
    );
};

export default InlineNotificationSuccess;
