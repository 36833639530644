import { Header, HeaderName } from "carbon-components-react";
import React from "react";
import logo from "../../assets/logo_blue.png";

const HeaderHelperPagesComponent = () => {
    return (
        <div
            id="helper-page-header-component"
            data-testid="helper-page-header-component"
            className="login-helper-page-header-component"
        >
            <Header
                className="login-helper-page-header-component"
                aria-label="Demarq Oscar"
            >
                <HeaderName prefix="" href="/">
                    <img
                        className="logo"
                        data-testid="logo-image"
                        src={logo}
                        alt="logo"
                    />
                </HeaderName>
            </Header>
        </div>
    );
};

export default HeaderHelperPagesComponent;
