import types from "./mask.types";

export const initialState = {
    mask: null,
    autoMergeMasks: [],
};

export const maskReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_AUTOGRID:
            return { ...state, mask: action.payload };
        case types.CLEAR_AUTOGRID:
            return { ...state, mask: null };
        case types.SET_AUTOMERGE:
            return { ...state, autoMergeMasks: action.payload };
        case types.CLEAR_AUTOMERGE:
            return { ...state, autoMergeMasks: [] };
        default:
            return state;
    }
};

export default maskReducer;
