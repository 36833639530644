import { ChevronLeft16, ChevronRight16, Close16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";
import "../../components/collapse-buttons/collapse-buttons.styles.scss";

const CollapseButtons = ({ isCollapsed, setIsCollapsed, pageLocation }) => {
    const onClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div>
            {isCollapsed ? (
                <Button
                    onClick={onClick}
                    className="collapse-button smaller-width"
                    data-testid={`collapsed-buttons-${pageLocation}-page`}
                >
                    <ChevronLeft16
                        className="first-chevron"
                        data-testid="collapsed-button-first-chevron"
                    />
                    <ChevronLeft16
                        className="second-chevron"
                        data-testid="collapsed-button-second-chevron"
                    />
                </Button>
            ) : (
                <Button
                    onClick={onClick}
                    className="collapse-button full-width"
                    data-testid={`expanded-buttons-${pageLocation}-page`}
                >
                    {pageLocation !== "filesDashboard" ? (
                        <div
                            className="flex-display"
                            data-testid="expanded-button-collapse-side-bar"
                        >
                            <div className="double-chevron-right">
                                <ChevronRight16
                                    className="first-chevron"
                                    data-testid="expanded-button-first-chevron"
                                />
                                <ChevronRight16
                                    className="second-chevron"
                                    data-testid="expanded-button-second-chevron"
                                />
                            </div>
                            Collapse sidebar
                        </div>
                    ) : (
                        <div
                            className="flex-display"
                            data-testid="expanded-button-close-file-details"
                        >
                            <div className="double-chevron-right">
                                <Close16 className="second-chevron" />
                            </div>
                            Close File Details
                        </div>
                    )}
                </Button>
            )}
        </div>
    );
};

export default CollapseButtons;
