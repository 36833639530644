import { WarningAlt20 } from "@carbon/icons-react";
import { Button, ButtonSet, TooltipIcon } from "carbon-components-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateTransformedColumnSpec } from "../../helpers/helper-functions-2";
import { handleReprocessingAction } from "../../redux/thunks/thunks.actions";

const SideBarNavButtons = ({
    pathname,
    resetColSpec,
    setResetMaskSideBarInfo,
    backEndSubmitGrids,
    columnSpec,
    allWarningGrids,
    pageLocation,
    handleSaveFromResultsPage,
    handleResetFromResultsPage,
    setResetAll,
    invalidStatementYear,
    ignoreAllWarnings,
    autoIncrementYear,
    forceTwoDecimals,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newFileDetails = useSelector(
        (state) => state.fileDetails.newFileDetails
    );
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [warningMessage, setWarningMessage] = useState(null);
    const [transformedColSpec, setTransformedColSpec] = useState([]);

    const allOrNothingCheck = (unselectedElements, columnSpec) => {
        let allOrNothing;
        if (
            unselectedElements === 0 ||
            unselectedElements === columnSpec.length
        ) {
            allOrNothing = true;
        } else {
            allOrNothing = false;
        }

        return allOrNothing;
    };

    const isUniqueCheck = (transformedColumnSpec) => {
        let noNKF = transformedColumnSpec.filter((type) => type !== "NKF");
        return new Set(noNKF).size === noNKF.length;
    };

    const generateColumnSpec = useCallback(() => {
        let newColumnSpec = generateTransformedColumnSpec(columnSpec);
        setTransformedColSpec(newColumnSpec);
    }, [columnSpec]);

    const creditDebitCheck = (transformedColSpec, unselectedElements) => {
        if (unselectedElements === 0) {
            return !(
                transformedColSpec.includes("Amount") ||
                (transformedColSpec.includes("Credit") &&
                    transformedColSpec.includes("Debit"))
            );
        } else {
            return false;
        }
    };

    const generateWarningMessage = useCallback(() => {
        let warningMessage;
        const unselectedElements = columnSpec.filter(
            (column) => column["type"] === "Unselected"
        ).length;

        let allOrNothing = allOrNothingCheck(unselectedElements, columnSpec);
        let isUnique = isUniqueCheck(transformedColSpec);
        let noCreditDebit = creditDebitCheck(
            transformedColSpec,
            unselectedElements
        );
        let hasWarnings = allWarningGrids.length !== 0;

        if (hasWarnings) {
            warningMessage = "All tables must have the same number of columns";
        } else if (!allOrNothing) {
            warningMessage = "Either select all or no column types";
        } else if (!isUnique) {
            warningMessage =
                "Cannot have multiple of the same type selected. Selected other instead";
        } else if (noCreditDebit) {
            warningMessage =
                "Must either have a Combined Credit/Debit column or a Credit and a Debit column";
        } else {
            if (invalidStatementYear) {
                warningMessage = "Invalid statement year";
            } else {
                warningMessage = null;
            }
        }

        setWarningMessage(warningMessage);
    }, [columnSpec, allWarningGrids, transformedColSpec, invalidStatementYear]);

    const handleReset = () => {
        resetColSpec();
        setResetMaskSideBarInfo(true);
        setResetAll(true);
    };

    /**
     * Submits file for reprocessing
     */
    const handleSubmit = () => {
        const pathnameSplit = pathname.split("/");
        let projectId = pathnameSplit[2];
        let fileId = pathnameSplit[3];
        dispatch(
            handleReprocessingAction(
                projectId,
                fileId,
                newFileDetails,
                backEndSubmitGrids,
                transformedColSpec,
                null,
                "mask",
                navigate,
                autoIncrementYear,
                forceTwoDecimals,
            )
        );
    };

    useEffect(() => {
        generateColumnSpec();
    }, [generateColumnSpec]);

    useEffect(() => {
        generateWarningMessage();
    }, [generateWarningMessage]);

    useEffect(() => {
        setDisableSubmit(warningMessage !== null);
    }, [warningMessage]);

    useEffect(() => {
        if (ignoreAllWarnings) {
            setWarningMessage(null);
        } else {
            generateWarningMessage();
        }
    }, [ignoreAllWarnings, setWarningMessage, generateWarningMessage]);

    return (
        <ButtonSet
            className="mask-side-nav-buttons"
            id="mask-side-nav-buttons"
            data-testid="mask-side-nav-buttons"
        >
            <Button
                className="mask-side-nav-button secondary-button"
                data-testid={`${pageLocation}-side-nav-button-reset`}
                id={`${pageLocation}-side-nav-button-reset`}
                kind="secondary"
                type="submit"
                onClick={
                    pageLocation === "mask"
                        ? handleReset
                        : handleResetFromResultsPage
                }
            >
                Reset
            </Button>
            <Button
                className="primary-colour mask-side-nav-button"
                data-testid={`${pageLocation}-side-nav-button-${
                    pageLocation === "mask" ? "submit" : "save"
                }`}
                id={`${pageLocation}-side-nav-button-${
                    pageLocation === "mask" ? "submit" : "save"
                }`}
                kind="primary"
                type="submit"
                disabled={
                    pageLocation === "mask"
                        ? disableSubmit
                        : invalidStatementYear
                }
                onClick={
                    pageLocation === "mask"
                        ? handleSubmit
                        : handleSaveFromResultsPage
                }
            >
                {pageLocation === "mask" ? "Submit" : "Save"}
            </Button>
            {warningMessage && pageLocation === "mask" ? (
                <TooltipIcon
                    direction="top"
                    align="end"
                    id="tooltip-warning"
                    data-testid="tooltip-warning"
                    tooltipText={warningMessage}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                    className="icon-button-dashboard no-border-tooltip warning-message-position"
                >
                    <WarningAlt20 />
                </TooltipIcon>
            ) : null}
        </ButtonSet>
    );
};

SideBarNavButtons.defaultProps = {
    backEndSubmitGrids: [],
    columnSpec: [],
    allWarningGrids: [],
    pageLocation: "",
    handleSaveFromResultsPage: () => {},
};

export default SideBarNavButtons;
