import { Toggle } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const ValidationColumn = (props) => {
    const [ignoreValidation, setIgnoreValidation] = useState(false);

    let componentProps;
    let gridApi;

    if (props.hasOwnProperty("testProps")) {
        gridApi = props.testProps.api;
        componentProps = props.testProps;
    } else {
        componentProps = props;
        gridApi = props.api;
    }

    let rowIndex = componentProps.rowIndex;
    let validationList = componentProps.validationList;
    let currentRow = gridApi.getDisplayedRowAtIndex(rowIndex);
    let previousRow = gridApi.getDisplayedRowAtIndex(rowIndex - 1);
    let difference = parseFloat(
        Number(gridApi.getValue("Difference", currentRow)).toFixed(2)
    );
    let currentDate = new Date(gridApi.getValue("Date", currentRow));
    let previousDate = new Date(gridApi.getValue("Date", previousRow));
    let updateValidationListFunction = componentProps.updateValidationList;
    let filteringTable = componentProps.filteringTable;

    const onToggle = () => {
        setIgnoreValidation(!ignoreValidation);
        updateValidationListFunction({
            rowIndex: rowIndex,
            ignoreValidation: !ignoreValidation,
        });
    };

    useEffect(() => {
        if (validationList) {
            let validationListCopy = JSON.parse(JSON.stringify(validationList));
            if (validationListCopy !== null) {
                let infoForThisToggle = validationListCopy.filter(
                    (validationItem) => validationItem.rowIndex === rowIndex
                );
                if (infoForThisToggle.length !== 0) {
                    setIgnoreValidation(infoForThisToggle[0]["ignoreValidation"]);
                } else {
                    setIgnoreValidation(false);
                }
            }
        }

    }, [filteringTable, rowIndex, validationList]);

    return (
        <div>
            {(difference !== 0 || previousDate > currentDate) &&
                !filteringTable ? (
                <Toggle
                    data-testid={`column-toggle-row-${rowIndex}`}
                    size="sm"
                    labelA="No"
                    labelB="Yes"
                    toggled={ignoreValidation}
                    className="align-toggle-middle"
                    id={`column-toggle-row-${rowIndex}`}
                    onChange={onToggle}
                />
            ) : null}
        </div>
    );
};

export default ValidationColumn;
