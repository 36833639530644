import React, { useState } from "react";
import { Document16 } from "@carbon/icons-react";
import { SideNavMenuItem } from "carbon-components-react";

const FileItem = ({ fileName, fileId, handleFileClick, clickedFileId }) => {
    const [isCurrentFocus, setIsCurrentFocus] = useState(false);
    const [buttonWasClicked, setButtonWasClicked] = useState(false);

    const clickedOutside = () => {
        setIsCurrentFocus(false);
    };

    /**
     * Makes current file be focused
     * Tells FileDashboard that this is the most recently clicked file
     * Adds fileId to clickedFileIds in Homepage
     * The timeout is given to prevent weird behavior
     *      of a user clicks multiple times on file
     */
    const clickedFile = () => {
        setIsCurrentFocus(true);
        handleFileClick();
        if (!buttonWasClicked) {
            clickedFileId(fileId);
            setButtonWasClicked(true);
            setTimeout(() => {
                setButtonWasClicked(false);
            }, [3000]);
        }
    };

    return (
        <SideNavMenuItem
            id={`fileItem-${fileId}`}
            data-testid={`fileItem-${fileId}`}
            isActive={isCurrentFocus}
            onClick={clickedFile}
            className="file-item"
            key={[fileId, "SideNavMenuItem"].join("/")}
            onBlur={clickedOutside}
            tabIndex="0"
        >
            <span>
                <Document16 className="file-icon-offset" />
                {fileName}
            </span>
        </SideNavMenuItem>
    );
};

export default FileItem;
