import { DatePicker, DatePickerInput } from "carbon-components-react";
import { default as React } from "react";
import { checkIsDateIsValid } from "../../helpers/helper-functions.js";
import "./pick-date.styles.scss";
import * as dayjs from 'dayjs';

var CustomParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(CustomParseFormat)


const PickDate = (props) => {

    let componentProps;

    if (props.hasOwnProperty("testProps")) {
        componentProps = props.testProps;
    } else {
        componentProps = props;
    }

    let rowIndex = componentProps.rowIndex;

    const formatDate = (date) => {

        const isDate = checkIsDateIsValid(date);

        var formattedDate

        if (isDate) {
            formattedDate = dayjs(date).format('YYYY-MM-DD')
        } else {
            formattedDate = date
        }

        return formattedDate;
    };

    const onDateChanged = (e) => {
        let formattedDate = formatDate(new Date(e));
        componentProps.updateDate(rowIndex, formattedDate);
    };

    const processDateValue = (inputDate) => {

        var formattedDate = ""

        if (inputDate) {
            let outputDate = dayjs(inputDate, 'YYYY-MM-DD', true)
            if (outputDate.isValid()) {
                formattedDate = formatDate(outputDate.toDate())
            } else {
                formattedDate = inputDate
            }
        } else {
            formattedDate = ""
        }

        let output = renderDatePicker(formattedDate)
        return output

    }

    const renderDatePicker = (value) => {
        return (
            <DatePicker
                datePickerType="single"
                className="text"
                onChange={onDateChanged}
                dateFormat="Y-m-d"
            >
                <DatePickerInput
                    size="sm"
                    className="text"
                    value={value}
                    labelText=""
                    data-testid={`date-picker-row-${rowIndex}`}
                    id={`date-picker-row-${rowIndex}`}
                    disabled={props.rowIndex === 0 ? true : false}
                    title="Please enter a date in YYYY-MM-DD format"
                />
            </DatePicker>
        )
    }

    // const renderPlainText = (value) => {
    //     return (
    //         <>{value}</>
    //     )
    // }

    return (
        <>{processDateValue(props.value)}</>
    );
};

export default PickDate;
