const pre = "projects/";
const ADD_PROJECT = pre + "ADD_PROJECT";
const REMOVE_PROJECT = pre + "REMOVE_PROJECT";
const SET_PROJECTS = pre + "SET_PROJECTS";
const TOGGLE_FILES_UPLOADING = pre + "TOGGLE_FILES_UPLOADING";
const TOGGLE_PROJECT_LOAD = pre + "TOGGLE_PROJECT_LOAD";
const ADD_FILES = pre + "ADD_FILES";
const REMOVE_FILE = pre + "REMOVE_FILE";
const RENAME_PROJECT = pre + "RENAME_PROJECT";
const WEBSOCKET_UPDATE = pre + "WEBSOCKET_UPDATE";
const UPDATE_FILE_STATE = pre + "UPDATE_FILE_STATE";

const projectTypes = {
    ADD_PROJECT,
    REMOVE_PROJECT,
    SET_PROJECTS,
    TOGGLE_FILES_UPLOADING,
    TOGGLE_PROJECT_LOAD,
    ADD_FILES,
    REMOVE_FILE,
    RENAME_PROJECT,
    WEBSOCKET_UPDATE,
    UPDATE_FILE_STATE,
};


export default projectTypes;
