import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideNavBar from "../../components/side-nav-bar/side-nav-bar.component";
import { makeTableSchema } from "../../helpers/helper-functions-2.js";

import {
    getFileDetailsAction,
    getFileTableAction,
    handleReprocessingAction,
    updateFileDetailsAction,
} from "../../redux/thunks/thunks.actions";

const ResultsFileDetails = ({
    isCollapsed,
    setIsCollapsed,
    currentProjectId,
    currentFileId,
    currentPage,
    resetResultsSideBarInfo,
    setResetResultsSideBarInfo,
    isEditing,
    setIsEditing,
    selectedFiles,
    setSelectedFiles,
    selectedProjectInfo,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newFileDetails = useSelector(
        (state) => state.fileDetails.newFileDetails
    );
    const [resetAll, setResetAll] = useState(false);
    const fileDetails = useSelector((state) => state.fileDetails.fileDetails);
    const fileTablesInit = useSelector((state) => state.files.fileTables);
    const fileResultsInit = useSelector((state) => state.files.fileResults);

    useEffect(() => {
        if (!isCollapsed && selectedFiles.length === 0) {
            let projectId;
            let fileId = currentFileId;
            if (currentProjectId) {
                projectId = currentProjectId;
            } else {
                if (selectedProjectInfo !== null) {
                    projectId = selectedProjectInfo.project_id;
                }
            }

            if (projectId !== null && fileId !== null) {
                dispatch(getFileDetailsAction(projectId, fileId));
                dispatch(getFileTableAction(projectId, fileId));
            }
        }
    }, [
        isCollapsed,
        currentFileId,
        dispatch,
        selectedFiles.length,
        currentProjectId,
        selectedProjectInfo,
    ]);

    const defineMakeTableSchema = useCallback(() => {
        let finalTableSchema = makeTableSchema(fileTablesInit);

        return finalTableSchema;
    }, [fileTablesInit]);

    const handleSaveClick = () => {
        if (selectedFiles.length !== 0) {
            for (let file of selectedFiles) {
                if (selectedProjectInfo !== null) {
                    dispatch(
                        updateFileDetailsAction(
                            selectedProjectInfo.project_id,
                            file.fileId,
                            newFileDetails
                        )
                    );
                }
            }
        } else {
            if (
                newFileDetails.statement_start_year !==
                fileDetails.statement_start_year
            ) {
                let tableSchema = defineMakeTableSchema();
                let columnSpec = [];

                if (fileResultsInit.hasOwnProperty("user_generated_col_spec")) {
                    columnSpec = fileResultsInit.user_generated_col_spec;
                }

                dispatch(
                    handleReprocessingAction(
                        currentProjectId,
                        currentFileId,
                        newFileDetails,
                        tableSchema,
                        columnSpec,
                        false,
                        "results",
                        navigate
                    )
                );
            } else {
                dispatch(
                    updateFileDetailsAction(
                        currentProjectId,
                        currentFileId,
                        newFileDetails
                    )
                );
            }
        }
        setResetAll(true);
        setSelectedFiles([]);
    };

    const handleResetClick = () => {
        setResetResultsSideBarInfo(true);
        setResetAll(true);
    };

    return (
        <>
            <SideNavBar
                setIsCollapsed={setIsCollapsed}
                isCollapsed={isCollapsed}
                pageLocation={currentPage}
                handleSaveFromResultsPage={handleSaveClick}
                resetSideBarInfo={resetResultsSideBarInfo}
                handleResetFromResultsPage={handleResetClick}
                resetAll={resetAll}
                setResetAll={setResetAll}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
            />
        </>
    );
};

export default ResultsFileDetails;
