import { CollapseAll32 } from "@carbon/icons-react";
import {
    Button,
    SideNav,
    SideNavItems,
    SideNavMenuItem,
    TextInput,
} from "carbon-components-react";
import React, { useEffect, useState } from "react";

import FolderItem from "./file-explorer.folder";

const FileExplorer = ({
    handleProjectClick,
    allProjectsInfo,
    clickedFileId,
}) => {

    const [filter, setFilter] = useState("");
    const [closeFolders, setCloseFolders] = useState(false);

    const closeAllFolders = () => {
        setCloseFolders(true);
    };

    const closeFoldersReset = () => {
        setCloseFolders(false);
    };

    useEffect(() => {
        if (filter === "") closeAllFolders();
    }, [filter]);

    return (
        <div className="file-explorer" data-testid="file-explorer">
            <SideNav
                expanded={false}
                isChildOfHeader={true}
                aria-label="ariaLabel"
                className="bx--side-nav--expanded"
            >
                <SideNavItems>
                    <div style={{ height: "7rem" }}>
                        <TextInput
                            data-modal-primary-focus
                            id="project-search"
                            data-testid="project-search"
                            placeholder="Project Search..."
                            labelText=""
                            onChange={(e) => setFilter(e.target.value)}
                            value={filter}
                            style={{ marginBottom: "1rem" }}
                        />
                        <SideNavMenuItem className="flex-nav">
                            File
                            <Button
                                renderIcon={CollapseAll32}
                                className={`
                                    icon-button-dashboard 
                                    white-icon 
                                    left-tooltip 
                                    collapse-icon
                                `}
                                id="collapse-all"
                                data-testid="collapse-all"
                                iconDescription="Collapse All"
                                hasIconOnly
                                onClick={closeAllFolders}
                            />
                        </SideNavMenuItem>
                        <hr className="white-line"></hr>
                    </div>
                    <div
                        className="file-explorer-scroll"
                        id="folder-items-container"
                        data-testid="folder-items-container"
                    >
                        {allProjectsInfo.map((project) => {
                            return (
                                <FolderItem
                                    selectedProjectInfo={project}
                                    projectName={project.name}
                                    handleProjectClick={handleProjectClick}
                                    filter={filter}
                                    clickedFileId={clickedFileId}
                                    key={[project.name, "FolderItem"].join("/")}
                                    closeFolders={closeFolders}
                                    closeFoldersReset={closeFoldersReset}
                                />
                            );
                        })}
                    </div>
                </SideNavItems>
            </SideNav>
        </div>
    );
};

export default FileExplorer;
