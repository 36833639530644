import { Data_232 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoMergeGridReturnSelectedRect } from "../../../../src/helpers/helper-functions-2";
import { clearAutoGrid } from "../../../redux/mask/mask.actions";
import { autogridMaskAction } from "../../../redux/thunks/thunks.actions.js";

const AutoGrid = ({
    pathname,
    selectedRect,
    selectedPageUrl,
    setAutoGridColsRows,
    backEndSubmitGrids,
    clearRectFunction,
    setUndoAutoGrid,
    blurButtons,
    hideUpDownWarningButtons,
    setIsAutoMergeButtonDisabled,
}) => {
    const dispatch = useDispatch();

    const autoGridState = useSelector((state) => state.mask.mask);

    useEffect(() => {
        if (autoGridState !== null) {
            setAutoGridColsRows(autoGridState);
            dispatch(clearAutoGrid());
        }
    }, [autoGridState, setAutoGridColsRows, dispatch]);

    const autoGridApply = () => {
        let pathnameSplit = pathname.split("/");
        let projectId = pathnameSplit[2];
        let fileId = pathnameSplit[3];

        let pages = backEndSubmitGrids.page;

        var selectedMask = autoMergeGridReturnSelectedRect(pages, selectedRect);

        var maskData;

        if (selectedRect || selectedPageUrl) {
            backEndSubmitGrids["page"].forEach((grid) => {
                maskData = grid["mask"][0];
            });

            let allowAutoGrid = true;
            if (maskData["rows"].length > 0 || maskData["columns"].length > 0) {
                allowAutoGrid = window.confirm(
                    "All rows and columns will be removed. \nAre you sure you want to continue?"
                );
            }
            if (allowAutoGrid) {
                dispatch(autogridMaskAction(projectId, fileId, selectedMask));

                clearRectFunction();
            }
        }
        setUndoAutoGrid(true);
        // sometimes when processing a whole document it might take a while for the grids to be populated so the button should be disabled
        setIsAutoMergeButtonDisabled(true);
        blurButtons();
        hideUpDownWarningButtons();
    };

    return (
        <Button
            renderIcon={Data_232}
            iconDescription="Add Auto Mask"
            data-testid="mask-page-file-toolbar-auto-grid-button"
            id="mask-page-file-toolbar-auto-grid-button"
            className={`default-button-colour ${
                selectedRect === null ? "disabled-button" : ""
            }`}
            disabled={selectedRect === null}
            hasIconOnly={true}
            onClick={autoGridApply}
        />
    );
};

export default AutoGrid;
