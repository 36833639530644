import types from "./user.types";

export const setUser = (user) => {
    return { type: types.SET_USER, payload: user };
};

export const clearUser = () => {
    return { type: types.CLEAR_USER };
};

export const setUserNotification = (userNotification) => {
    return { type: types.SET_USER_NOTIFICATION, payload: userNotification };
};
