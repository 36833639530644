import { Accordion, AccordionItem } from "carbon-components-react";
import React from "react";
import AccountDetailsUpdatePassword from "../../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/account-details/account-details-update-password.component";
import AccountDetailsUpdatePhoneNumber from "../../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/account-details/account-details-update-phone-number.component";
import "../account-page-manage-account.styles.scss";

const ManageAccountAccountDetails = ({
    userInfo,
    setMfaChoice,
    authMessage,
    setIsManagePasswordOpen,
    isManagePasswordOpen,
    setIsManagePhoneNumberOpen,
    isManagePhoneNumberOpen,
}) => {
    const handleManagePasswordClick = () => {
        setMfaChoice("");
        setIsManagePasswordOpen(true);
        setIsManagePhoneNumberOpen(false);
    };

    const handleManagePhoneNumberClick = () => {
        setMfaChoice("");
        setIsManagePasswordOpen(false);
        setIsManagePhoneNumberOpen(true);
    };

    return (
        <div className="card-style" data-testid="update-account-details-div">
            <div className="card-text">
                <div className="margin-bottom card-title">
                    Update Account Details
                </div>
                <div className="whole-width">
                    <Accordion>
                        <AccordionItem
                            title="Update Password"
                            open={isManagePasswordOpen}
                            onHeadingClick={handleManagePasswordClick}
                            data-testid="account-update-password-accordion"
                        >
                            <AccountDetailsUpdatePassword
                                userInfo={userInfo}
                                authMessage={authMessage}
                                setIsManagePasswordOpen={setIsManagePasswordOpen}
                            />
                        </AccordionItem>
                        <div className="margin-bottom"></div>
                        <AccordionItem
                            title="Update Phone Number"
                            open={isManagePhoneNumberOpen}
                            onHeadingClick={handleManagePhoneNumberClick}
                            data-testid="account-update-phone-number-accordion"
                        >
                            <AccountDetailsUpdatePhoneNumber
                                authMessage={authMessage}
                                setIsManagePhoneNumberOpen={
                                    setIsManagePhoneNumberOpen
                                }
                            />
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default ManageAccountAccountDetails;
