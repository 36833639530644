import { createSelector } from "reselect";

const fileResultsState = (state) => state.files.fileResults;

const formatDate = (date) => {
    let formattedDate;
    switch (date) {
        case "":
            formattedDate = null;
            return formattedDate;
        default:
            formattedDate = date;
            return formattedDate;
    }
};

const formatValues = (value) => {
    let formattedValue;
    switch (value) {
        case null:
            formattedValue = 0;
            return formattedValue;
        default:
            formattedValue = value;
            return formattedValue;
    }
};

export const fileResults = createSelector([fileResultsState], (document) => {
    if (document !== (undefined || null)) {
        let documentCopy = JSON.parse(JSON.stringify(document));
        let fileResults = documentCopy["file_results"];

        let updatedFileResults = [];
        for (let row in fileResults) {
            let tableRow = fileResults[row];
            tableRow["Date"] = formatDate(tableRow["Date"]);
            tableRow["Difference"] = formatValues(tableRow["Difference"]);
            updatedFileResults.push(tableRow);
        }
        return updatedFileResults;
    }
});

export const fileResultsIgnoreValidationList = createSelector(
    [fileResultsState],
    (document) => {
        if (document !== (undefined || null)) {
            let documentCopy = JSON.parse(JSON.stringify(document));
            let fileResultsIgnoreValidationList =
                documentCopy["ignore_validation_errors"];

            return fileResultsIgnoreValidationList;
        }
    }
);

export const fileTablesWarnings = createSelector(
    [fileResultsState],
    (document) => {
        if (document !== (undefined || null)) {
            let documentCopy = JSON.parse(JSON.stringify(document));
            let tableWarnings = documentCopy["table_warnings"];

            return tableWarnings;
        }
    }
);

export const canFileBeRestored = createSelector(
    [fileResultsState],
    (document) => {
        if (document !== (undefined || null)) {
            let documentCopy = JSON.parse(JSON.stringify(document));
            let canFileBeRestored = documentCopy["can_file_be_restored"];

            return canFileBeRestored;
        }
    }
);
