import types from "./user.types";

export const initialState = {
    user: null,
    userNotification: null,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_USER:
            return { ...state, user: action.payload };
        case types.CLEAR_USER:
            return { ...state, user: null };
        case types.SET_USER_NOTIFICATION:
            return { ...state, userNotification: action.payload };
        default:
            return state;
    }
};

export default userReducer;
