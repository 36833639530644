import { columnSpecToType } from "./helper-functions";

export const makeTableSchema = (fileTablesInit) => {
    let tableSchema = [];
    for (let page of fileTablesInit) {
        let perPageTableSchema = {
            pageNumber: page.page_number,
            mask: [
                {
                    x1: page.x1,
                    x2: page.x2,
                    y1: page.y1,
                    y2: page.y2,
                    columns: page.column_boundaries,
                    rows: page.row_boundaries,
                    id: `rect${page.page_number}`,
                    processing_status: page.processing_status,
                    maskNumber: page.page_number,
                },
            ],
            angle: 0,
        };

        tableSchema.push(perPageTableSchema);
    }

    let finalTableSchema = {
        page: tableSchema,
    };

    return finalTableSchema;
};

export const removeExtension = (filename) => {
    let editedName = filename.split(".").slice(0, -1).join(".");

    return editedName;
};

export const generateTransformedColumnSpec = (columnSpec) => {
    const unselectedElements = columnSpec.filter(
        (column) => column["type"] === "Unselected"
    ).length;

    let transformedColumnSpec;
    if (unselectedElements === 0) {
        transformedColumnSpec = columnSpecToType(columnSpec);
    } else {
        transformedColumnSpec = [];
    }

    return transformedColumnSpec;
};

export const configureInAppApiErrors = (apiErrors) => {
    if (typeof apiErrors === "object") {
        apiErrors = apiErrors.detail;
    }

    let notificationTitle = "";
    let notificationSubTitle = "";

    let checkList = [null, "", undefined];
    if (!checkList.includes(apiErrors)) {
        if (
            apiErrors ===
            "Page limit exceeded, you have no pages remaining in this period!"
        ) {
            notificationTitle = "Page limit exceeded";
            notificationSubTitle = "You have no pages remaining in this period";
        } else if (apiErrors.includes("You have cancelled your subscription")) {
            notificationTitle = "Subscription Cancelled";
            notificationSubTitle =
                "Reactivate your subscription in Account - Subscription";
        } else if (apiErrors.includes("not currently supported")) {
            notificationTitle = "Unsupported file type";
            notificationSubTitle = apiErrors;
        } else {
            notificationTitle = "";
            notificationSubTitle = apiErrors;
        }
    }

    return [notificationTitle, notificationSubTitle];
};

export const autoMergeGridReturnSelectedRect = (pages, selectedRect) => {
    let selectedMask;

    for (var i = 0; i < pages.length; i++) {
        for (var j = 0; j < pages[i].mask.length; j++) {
            let pageObject = pages[i];
            let maskObject = pageObject.mask[j];
            if (
                pageObject.mask[j].id === selectedRect.rectInfo.id &&
                pageObject.pageNumber === selectedRect.imageId
            ) {
                selectedMask = defineObjectToBeReturned(pageObject, maskObject);
                break;
            }
        }
    }
    return selectedMask;
};

export const autoMergeAutoGridReturnAll = (pages) => {
    let allMasks = [];
    let selectedMask;

    for (var i = 0; i < pages.length; i++) {
        for (var j = 0; j < pages[i].mask.length; j++) {
            let pageObject = pages[i];
            let maskObject = pageObject.mask[j];
            selectedMask = defineObjectToBeReturned(pageObject, maskObject);
            allMasks.push(selectedMask);
        }
    }
    return allMasks;
};

export const defineObjectToBeReturned = (pageObject, maskObject) => {
    let objectToReturn = {
        pageNumber: pageObject.pageNumber,
        x1: maskObject.x1,
        y1: maskObject.y1,
        x2: maskObject.x2,
        y2: maskObject.y2,
        height: pageObject.pdfHeight,
        width: pageObject.pdfWidth,
        rectId: maskObject.id,
        columns: maskObject.columns,
        rows: maskObject.rows,
    };

    return objectToReturn;
};

export const navigateThroughWarningsMaskPageDefineNewIndex = (
    arrayOfWarnings,
    pageDisplayed,
    condition
) => {
    for (var i = 0; i < arrayOfWarnings.length; i++) {
        let currentValue = arrayOfWarnings[i];
        let nextValue;

        nextValue = arrayOfWarnings[i + 1];

        nextValue === undefined
            ? (nextValue = pageDisplayed)
            : (nextValue = arrayOfWarnings[i + 1]);

        if (pageDisplayed >= currentValue && pageDisplayed <= nextValue) {
            if (condition === "upUndefined") {
                return i;
            } else if (condition === "upNextPageDifferentFromPageDisplayed") {
                let newIndex = i;
                let previousPage = arrayOfWarnings[newIndex];

                if (arrayOfWarnings.includes(previousPage)) {
                    return arrayOfWarnings.indexOf(previousPage);
                } else {
                    return i - 1;
                }
            } else if (condition === "downNextPageDifferentFromPageDisplayed") {
                return i + 1;
            }
            break;
        }
    }
};
