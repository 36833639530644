import types from "./files.types";

export const setFileImages = (fileImages) => {
    return { type: types.GET_FILE_IMAGES, payload: fileImages };
};

export const clearFileImages = () => {
    return { type: types.CLEAR_FILE_IMAGES };
};

export const setImagesFileName = (fileName) => {
    return { type: types.SET_IMAGES_FILE_NAME, payload: fileName };
};

export const clearImagesFileName = () => {
    return { type: types.CLEAR_IMAGES_FILE_NAME };
};

export const setFileTables = (fileTables) => {
    return { type: types.SET_FILE_TABLES, payload: fileTables };
};

export const clearFileTables = () => {
    return { type: types.CLEAR_FILE_TABLES };
};

export const setFileColSpec = (fileColSpec) => {
    return { type: types.SET_FILE_COL_SPEC, payload: fileColSpec };
};

export const clearFileColSpec = () => {
    return { type: types.CLEAR_FILE_COL_SPEC };
};

export const addFileResults = (fileResults) => {
    return { type: types.SET_FILE_RESULTS, payload: fileResults };
};

export const clearFileResults = () => {
    return { type: types.CLEAR_FILE_RESULTS };
};

export const saveFileResults = (fileDetails) => {
    return { type: types.SAVE_FILE_RESULTS, payload: fileDetails };
};

export const downloadFileResults = (fileDetails) => {
    return { type: types.DOWNLOAD_FILE_RESULTS, payload: fileDetails };
};

export const downloadFiles = (fileIds) => {
    return { type: types.DOWNLOAD_FILES, payload: fileIds };
};

export const downloadProjectFiles = (projectId) => {
    return { type: types.DOWNLOAD_PROJECT_FILES, payload: projectId };
};

export const setCategorisedFileResults = (categorisedFileResults) => {
    return {
        type: types.SET_CATEGORISED_FILE_RESULTS,
        payload: categorisedFileResults,
    };
};

export const clearCategorisedFileResults = () => {
    return { type: types.CLEAR_CATEGORISED_FILE_RESULTS };
};

export const setFileStatus = (fileStatus) => {
    return { type: types.SET_FILE_STATUS, payload: fileStatus };
};

export const clearFileStatus = () => {
    return { type: types.CLEAR_FILE_STATUS };
};
