import { Analytics20, UserProfile20, Wallet20 } from "@carbon/icons-react";
import { SideNav, SideNavItems, SideNavMenuItem } from "carbon-components-react";
import React from "react";
import "./account-page-side-nav-bar.styles.scss";

const AccountPageSideNavBar = ({
    isSubscriptionBeingDisplayed,
    setIsSubscriptionBeingDisplayed,
    isMIBeingDisplayed,
    setIsMIBeingDisplayed,
    isManageAccountBeingDisplayed,
    setIsManageAccountBeingDisplayed,
}) => {
    const handleSubscriptionClick = () => {
        setIsSubscriptionBeingDisplayed(true);
        setIsMIBeingDisplayed(false);
        setIsManageAccountBeingDisplayed(false);
    };

    const handleMIClick = () => {
        setIsMIBeingDisplayed(true);
        setIsSubscriptionBeingDisplayed(false);
        setIsManageAccountBeingDisplayed(false);
    };

    const handleManageAccountClick = () => {
        setIsManageAccountBeingDisplayed(true);
        setIsMIBeingDisplayed(false);
        setIsSubscriptionBeingDisplayed(false);
    };

    return (
        <div className="" data-testid="account-page-side-nav-bar">
            <SideNav
                expanded={false}
                isChildOfHeader={true}
                aria-label="ariaLabel"
                className="bx--side-nav--expanded"
            >
                <SideNavItems>
                    <div
                        className="nav-items-style"
                        data-testid="subscription-nav-item"
                    >
                        <SideNavMenuItem
                            className={`${isSubscriptionBeingDisplayed
                                ? "is-displayed"
                                : ""
                                }`}
                            onClick={handleSubscriptionClick}
                        >
                            <Wallet20
                                data-testid="subscription-nav-item-icon"
                                className="icon-margin-right"
                            />
                            Subscription
                        </SideNavMenuItem>
                    </div>
                    <div
                        className="nav-items-style"
                        data-testid="manage-account-nav-item"
                    >
                        <SideNavMenuItem
                            className={`${isManageAccountBeingDisplayed
                                ? "is-displayed"
                                : ""
                                }`}
                            onClick={handleManageAccountClick}
                        >
                            <UserProfile20
                                data-testid="manage-account-nav-item-icon"
                                className="icon-margin-right"
                            />
                            Manage Account
                        </SideNavMenuItem>
                    </div>
                    <div className="nav-items-style" data-testid="mi-nav-item">
                        <SideNavMenuItem
                            className={`${isMIBeingDisplayed ? "is-displayed" : ""
                                }`}
                            onClick={handleMIClick}
                        >
                            <Analytics20
                                data-testid="mi-nav-item-icon"
                                className="icon-margin-right"
                            />
                            Management Information
                        </SideNavMenuItem>
                    </div>
                </SideNavItems>
            </SideNav>
        </div>
    );
};

export default AccountPageSideNavBar;
