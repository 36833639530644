import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/orange.png";
import "./qr-code.styles.scss";

import { QRCode } from "react-qrcode-logo";

const QRCodeComponent = () => {
    const qrCode = useSelector((state) => state.auth.qrCode);
    const qrCodeUsername = useSelector((state) => state.auth.qrCodeUsername);

    const [qrCodeValue, setQRCodeValue] = useState("");

    useEffect(() => {
        if (qrCode !== null && qrCodeUsername !== null) {
            const str = `otpauth://totp/Oscar:${qrCodeUsername}?secret=${qrCode}&issuer=Oscar`;
            setQRCodeValue(str);
        } else {
            setQRCodeValue("");
        }
    }, [qrCodeUsername, qrCode]);

    return (
        <Fragment>
            <div
                data-testid="set-up-mfa-qr-code"
                className="field-margin-bottom"
            >
                <QRCode
                    value={qrCodeValue}
                    logoImage={logo}
                    logoOpacity={1}
                    removeQrCodeBehindLogo={true}
                    qrStyle={"dots"}
                    fgColor="#2e3191"
                    bgColor="#dbdbdb"
                    eyeRadius={20}
                />
            </div>
            {qrCode ? (
                <div
                    data-testid="set-up-mfa-code-input-manually"
                    className="field-margin-bottom"
                >
                    If the above code won't work please enter the code manually:{" "}
                    <div className="bold-text" data-testid="qr-code-value">
                        {qrCode}
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default QRCodeComponent;
