import { InlineNotification } from "carbon-components-react";
import React, { Fragment, useCallback, useEffect, useState } from "react";

const FileCategorisingNotification = ({
    selectedProjectFiles,
    showCategorisationNotification,
    setShowCategorisationNotification,
}) => {
    const [alreadyCheckedFiles, setAlreadyCheckedFiles] = useState(
        selectedProjectFiles.filter(
            (obj) => obj["upload_status"] === "Categorised"
        )
    );
    const [loadingFiles, setLoadingFiles] = useState([]);
    const [loadedFiles, setLoadedFiles] = useState([]);
    const [failedFiles, setFailedFiles] = useState([]);

    // const [showCategorisationNotification, setShowCategorisationNotification] = useState(false);
    const [notificationClass, setNotificationClass] = useState("fade-in-1");

    const [notificationText, setNotificationText] = useState("");
    const [iconType, setIconType] = useState("info");

    /**
     * Runs when no more files are being uploaded
     * @param {*} loadedFiles
     * @param {*} failedFiles
     * @param {*} alreadyCheckedFiles
     */
    const loadingComplete = useCallback(
        (loadedFiles, failedFiles, alreadyCheckedFiles) => {
            setTimeout(() => {
                setAlreadyCheckedFiles([
                    ...alreadyCheckedFiles,
                    ...loadedFiles,
                    ...failedFiles,
                ]);
                setLoadingFiles([]);
                setLoadedFiles([]);
                setFailedFiles([]);
                setNotificationClass("fade-out-1");
                setTimeout(() => {
                    setShowCategorisationNotification(false);
                }, 1000);
            }, 3000);
        },
        [setShowCategorisationNotification]
    );

    /**
     * Categorize files after removing all files already checked
     * @param {*} selectedProjectFiles
     * @param {*} alreadyCheckedFiles
     */
    const categorizeFiles = (selectedProjectFiles, alreadyCheckedFiles) => {
        const alreadyCheckedFileIds = alreadyCheckedFiles.map(
            (el) => el["file_id"]
        );

        const newFiles = selectedProjectFiles.filter(
            (obj) => !alreadyCheckedFileIds.includes(obj["file_id"])
        );

        if (newFiles.length !== 0) {
            setLoadingFiles(
                newFiles.filter((obj) => obj["upload_status"] === "Categorising")
            );
            setLoadedFiles(
                newFiles.filter((obj) => obj["upload_status"] === "Categorised")
            );
            setFailedFiles(
                newFiles.filter(
                    (obj) => obj["upload_status"] === "Categorisation Failed"
                )
            );
        }
    };

    /**
     * Decides what text should be displayed on
     *  screen as well as icon type for a single file
     * @param {int} numLoadingFiles
     * @param {int} numLoadedFiles
     * @param {int} numFailedFiles
     * @param {str} fileName
     */
    const singleFile = (
        numLoadingFiles,
        numLoadedFiles,
        numFailedFiles,
        fileName
    ) => {
        if (numLoadingFiles === 0) {
            if (numLoadedFiles === 1) {
                setNotificationText(`Loaded ${fileName}`);
                setIconType("success");
            } else if (numFailedFiles === 1) {
                setNotificationText(`Failed to Load ${fileName}`);
                setIconType("error");
            }
        } else {
            setIconType("info");
            setNotificationText(`Processing ${fileName}`);
        }
    };

    /**
     * Decides what text should be displayed on
     *  screen as well as icon type for multiple files
     * @param {int} numLoadingFiles
     * @param {int} numLoadedFiles
     * @param {int} numFailedFiles
     */
    const multipleFiles = (numLoadingFiles, numLoadedFiles, numFailedFiles) => {
        if (numLoadingFiles === 0) {
            if (numFailedFiles === 0) {
                setIconType("success");
                setNotificationText(`Categorised ${numLoadedFiles} files`);
            } else if (numLoadedFiles === 0) {
                setIconType("error");
                setNotificationText(
                    `${numFailedFiles} files failed to categorise`
                );
            } else {
                setIconType("warning");
                setNotificationText(
                    `Categorised ${numLoadedFiles} file(s) with ${numFailedFiles} failing`
                );
            }
        } else {
            setIconType("info");
            setNotificationText(
                `Categorising ${numLoadingFiles} out of ${numLoadingFiles} files`
            );
            if (numFailedFiles === 0 && numLoadingFiles === 0) {
                setNotificationText(
                    `Categorised ${numLoadedFiles} out of ${
                        numLoadingFiles + numLoadedFiles
                    } files`
                );
            }
        }
    };

    /**
     * Updates the on screen state notification
     */
    const updateFileStateNotification = useCallback(
        (loadingFiles, loadedFiles, failedFiles) => {
            const numLoadingFiles = loadingFiles.length;
            const numLoadedFiles = loadedFiles.length;
            const numFailedFiles = failedFiles.length;

            if (numLoadingFiles !== 0) {
                setShowCategorisationNotification(true);
                setNotificationClass("fade-in-1");
            }

            if (numLoadingFiles + numLoadedFiles + numFailedFiles === 1) {
                let fileName;

                if (numLoadingFiles === 1) {
                    fileName = loadingFiles[0]["name"];
                } else if (numLoadedFiles === 1) {
                    fileName = loadedFiles[0]["name"];
                } else if (numFailedFiles === 1) {
                    fileName = failedFiles[0]["name"];
                }

                singleFile(
                    numLoadingFiles,
                    numLoadedFiles,
                    numFailedFiles,
                    fileName
                );
            }

            if (numLoadingFiles + numLoadedFiles + numFailedFiles > 1) {
                multipleFiles(numLoadingFiles, numLoadedFiles, numFailedFiles);
            }
        },
        [setShowCategorisationNotification]
    );

    useEffect(() => {
        categorizeFiles(selectedProjectFiles, alreadyCheckedFiles);
    }, [selectedProjectFiles, alreadyCheckedFiles]);

    useEffect(() => {
        if (
            loadingFiles.length === 0 &&
            showCategorisationNotification === true
        ) {
            loadingComplete(loadedFiles, failedFiles, alreadyCheckedFiles);
        }
        updateFileStateNotification(loadingFiles, loadedFiles, failedFiles);
    }, [
        loadingFiles,
        loadedFiles,
        failedFiles,
        alreadyCheckedFiles,
        showCategorisationNotification,
        updateFileStateNotification,
        loadingComplete,
    ]);

    return (
        <Fragment>
            {showCategorisationNotification ? (
                <InlineNotification
                    kind={iconType}
                    iconDescription="Close button"
                    id="loading-notification"
                    data-testid="loading-notification"
                    subtitle={<span>{notificationText}</span>}
                    title="Categorisation"
                    lowContrast={true}
                    className={`InlineNotification_mod ${notificationClass}`}
                />
            ) : null}
        </Fragment>
    );
};

export default FileCategorisingNotification;
