const pre = "mask";
const SET_AUTOGRID = `${pre}/SET_AUTOGRID`;
const CLEAR_AUTOGRID = `${pre}/CLEAR_AUTOGRID`;
const SET_AUTOMERGE = `${pre}/SET_AUTOMERGE`;
const CLEAR_AUTOMERGE = `${pre}/CLEAR_AUTOMERGE`;



const maskTypes = {
    SET_AUTOGRID,
    CLEAR_AUTOGRID,
    SET_AUTOMERGE,
    CLEAR_AUTOMERGE,
};

export default maskTypes;
