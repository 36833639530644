import {
    Checkbox,
    HeaderPanel,
    SideNavItems,
    SideNavMenuItem,
    SwitcherDivider,
} from "carbon-components-react";
import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import CollapseButtons from "../../components/collapse-buttons/collapse-buttons.component";
import FileDetails from "../../components/file-details/file-details.component";
import MaskTableSetup from "../../components/mask-page-side-nav-bar/mask-page-side-nav-bar-table-setup.component";
import SideBarNavButtons from "../../components/side-nav-bar/side-nav-bar-buttons.component";

const SideNavBar = ({
    pathname,
    backEndSubmitGrids,
    maxColumnsInfo,
    columnSpec,
    allWarningGrids,
    setColumnSpec,
    setResetMaskSideBarInfo,
    currentProjectId,
    currentFileId,
    isCollapsed,
    setIsCollapsed,
    pageLocation,
    handleSaveFromResultsPage,
    resetSideBarInfo,
    handleResetFromResultsPage,
    resetAll,
    setResetAll,
    isEditing,
    setIsEditing,
}) => {
    const [resetColSpec, setResetColSpec] = useState(null);
    const [collapsedClass, setCollapsedClass] = useState(
        "mask-file-details-expanded"
    );
    const [invalidStatementYear, setInvalidStatementYear] = useState(false);
    const [ignoreAllWarnings, setIgnoreAllWarnings] = useState(false);

    // const fileDetails = useSelector((state) => state.fileDetails.fileDetails);

    const [autoIncrementYear, setAutoIncrementYear] = useState(true);
    const [forceTwoDecimals, setForceTwoDecimals] = useState(true);

    useEffect(() => {
        if (pageLocation === "filesDashboard") {
            if (isCollapsed) {
                setCollapsedClass("mask-file-details-null");
            } else {
                setCollapsedClass("mask-file-details-expanded");
            }
        } else {
            if (isCollapsed) {
                setCollapsedClass("mask-file-details-collapsed");
            } else {
                setCollapsedClass("mask-file-details-expanded");
            }
        }
    }, [isCollapsed, pageLocation]);

    useEffect(() => {
        if (resetAll) {
            setIsEditing(false);
            setResetAll(false);
        }
    }, [resetAll, setResetAll, setIsEditing]);

    return (
        <HeaderPanel
            aria-label="Header Panel"
            expanded
            data-testid={`${pageLocation}-page-side-nav-bar`}
            className={`placement-fix ${collapsedClass} no-scrollbar`}
        >
            {isCollapsed ? null : (

                    <SideNavItems className="no-scrollbar">
                        <SideNavMenuItem>File Details</SideNavMenuItem>
                        <SwitcherDivider />
                        {/* <SideNavMenuItem>File Details</SideNavMenuItem> */}
                        <FileDetails
                            currentProjectId={currentProjectId}
                            currentFileId={currentFileId}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={setIsCollapsed}
                            pageLocation={pageLocation}
                            resetSideBarInfo={resetSideBarInfo}
                            setIsEditing={setIsEditing}
                            isEditing={isEditing}
                            invalidStatementYear={invalidStatementYear}
                            setInvalidStatementYear={setInvalidStatementYear}
                            autoIncrementYear={autoIncrementYear}
                            setAutoIncrementYear={setAutoIncrementYear}
                            forceTwoDecimals={forceTwoDecimals}
                            setForceTwoDecimals={setForceTwoDecimals}
                        />
                        {pageLocation === "mask" ? (
                                <>
                                    <SwitcherDivider />
                                    <SideNavMenuItem>
                                        Table Columns
                                    </SideNavMenuItem>
                                    <MaskTableSetup
                                        pathname={pathname}
                                        columnSpec={columnSpec}
                                        maxColumnsInfo={maxColumnsInfo}
                                        setColumnSpec={setColumnSpec}
                                        setResetColSpec={setResetColSpec}
                                        setIsEditing={setIsEditing}
                                        isEditing={isEditing}
                                    />
                                </>
                            ) : null}
                        </SideNavItems>
            )}
            <div
                className={`buttons-file-details-mask ${allWarningGrids.length !== 0
                    ? "buttons-file-details-mask-increased-height"
                    : "buttons-file-details-mask-normal-height"
                    }`}
            >
                {allWarningGrids.length !== 0 ? (
                    <div className="checkbox-ignore-all">
                        <Checkbox
                            labelText="Ignore All Warnings"
                            className="ignore-all-checkbox"
                            data-testid="ignore-all-mask-warnings-checkbox"
                            id="ignore-all-mask-warnings-checkbox"
                            checked={ignoreAllWarnings}
                            onChange={() => {
                                setIgnoreAllWarnings(!ignoreAllWarnings);
                            }}
                        />
                    </div>
                ) : null}
                {!isCollapsed ? (
                    <SideBarNavButtons
                        pathname={pathname}
                        resetColSpec={resetColSpec}
                        setResetMaskSideBarInfo={setResetMaskSideBarInfo}
                        backEndSubmitGrids={backEndSubmitGrids}
                        columnSpec={columnSpec}
                        allWarningGrids={allWarningGrids}
                        pageLocation={pageLocation}
                        handleSaveFromResultsPage={handleSaveFromResultsPage}
                        handleResetFromResultsPage={
                            handleResetFromResultsPage
                        }
                        setResetAll={setResetAll}
                        invalidStatementYear={invalidStatementYear}
                        ignoreAllWarnings={ignoreAllWarnings}
                        autoIncrementYear={autoIncrementYear}
                        forceTwoDecimals={forceTwoDecimals}
                    />
                ) : null}
                <CollapseButtons
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    pageLocation={pageLocation}
                />
            </div>
        </HeaderPanel>
    );
};

SideNavBar.defaultProps = {
    pathname: null,
    backEndSubmitGrids: [],
    maxColumnsInfo: null,
    columnSpec: [],
    allWarningGrids: [],
    setColumnSpec: null,
    setResetMaskSideBarInfo: null,
    currentProjectId: null,
    currentFileId: null,
    isCollapsed: null,
    setIsCollapsed: () => { },
    pageLocation: "",
    handleSaveFromResultsPage: () => { },
};

export default SideNavBar;
