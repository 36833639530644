import { Accordion } from "carbon-components-react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AccountPageSubscriptionBusinessOption from "../../../../components/account-page-components/account-page-dashboard/account-page-dashboard-subscription/business-accordion.component";
import AccountPageSubscriptionEnterpriseOption from "../../../../components/account-page-components/account-page-dashboard/account-page-dashboard-subscription/enterprise-accordion.components";
import CommonRegistrationButton from "../../../../components/registration-buttons/registration-button-common.component";
import { capitaliseFirstLetter } from "../../../../helpers/helper-functions.js";
import { stripeManageAccountAction } from "../../../../redux/thunks/thunks.actions.js";
import "../account-page-dashboard.styles.scss";
import "./account-page-dashboard-subscription.styles.scss";
import { Link} from "react-router-dom";

const AccountPageSubscriptionDashboard = ({ userInfo }) => {
    const dispatch = useDispatch();

    const [subscription, setSubscription] = useState("");

    const [isBusinessAccordionOpen, setIsBusinessAccordionOpen] =
        useState(false);
    const [isEnterpriseAccordionOpen, setIsEnterpriseAccordionOpen] =
        useState(false);

    const handleBusinessAccordionClick = () => {
        setIsBusinessAccordionOpen(true);
        setIsEnterpriseAccordionOpen(false);
    };

    const handleEnterpriseAccordionClick = () => {
        setIsBusinessAccordionOpen(false);
        setIsEnterpriseAccordionOpen(true);
    };

    const handleManageSubscriptionClick = () => {
        dispatch(stripeManageAccountAction());
    };

    useEffect(() => {
        var subscription;
        if (userInfo.organisation_info) {
            subscription = "organisation"
        }
        else if (userInfo.subscription == null) {
            subscription = "starter";
        } else {
            subscription = userInfo.subscription;
        }
        setSubscription(capitaliseFirstLetter(subscription));
    }, [userInfo.subscription, userInfo.organisation_info]);

    const render_fragment = () => {

        if (userInfo.organisation_info) {
            return (<div className="card-style">
                <div className="card-text">
                    <div
                        className="margin-bottom card-title"
                        data-testid="current-subscription-text"
                    >
                        Current Subscription: Organisation
                    </div>
                    <div
                        className="join-text margin-bottom"
                        data-testid="current-subscription-helper-text"
                    >
                        You are currently part of an organisation, {userInfo.organisation_info.organisation_name}.
                    </div>
                    <div
                        className="margin-bottom"
                        data-testid="number-of-pages-info"
                    >
                        Your page limit of {userInfo.organisation_info.organisation_page_limit} is shared by all users within the organisation.
                    </div>
                </div>
            </div>)
        } else {
            return (<div className="card-style">
                <div className="card-text">
                    <div
                        className="margin-bottom card-title"
                        data-testid="current-subscription-text"
                    >
                        Current Subscription: {subscription.toLowerCase() === "aws" ? "AWS Marketplace" : subscription}
                    </div>
                    <div
                        className="join-text margin-bottom"
                        data-testid="current-subscription-helper-text"
                    >
                        You currently have a {subscription.toLowerCase() === "aws" ? "AWS Marketplace" : subscription} subscription.
                    </div>
                    {subscription.toLowerCase() === "aws" ? (
                        <>
                            <div className="margin-bottom"
                                data-testid="number-of-pages-info">
                                Your Oscar subscription is linked to AWS account {userInfo.aws_marketplace_account_id}.  You have an allowance of {userInfo.page_limit} pages until {userInfo.aws_marketplace_expiration_date}.
                            </div>
                            <div className="margin-bottom"
                                data-testid="number-of-pages-info">
                                Please manage your subscription via the{" "}
                                <Link
                                    data-testid="aws-marketplace-link"
                                    className="blue-text"
                                    to={"https://aws.amazon.com/marketplace"}
                                >
                                    AWS Marketplace Portal
                                </Link>
                            </div></>
                    ) : (
                        <div
                            className="margin-bottom"
                            data-testid="number-of-pages-info"
                        >
                            This means you can process {userInfo.page_limit}{" "}
                            pages.
                        </div>
                    )}

                </div>
            </div>)
        }
    }

    return (
        <Fragment>
            <div
                className="row-page"
                data-testid="account-page-subscription-dashboard"
            >
                <div
                    className="tile-title"
                    data-testid="account-page-subscription-dashboard-title"
                >
                    Subscription
                </div>
                {userInfo.subscription_status !== "cancelled" ? (
                    render_fragment()
                ) : (
                    <div className="card-style">
                        <div className="card-text">
                            <div
                                className="margin-bottom card-title"
                                data-testid="current-subscription-text"
                            >
                                Your {subscription.toLowerCase() === "aws" ? "AWS Marketplace" : subscription} subscription was cancelled
                            </div>
                            <div
                                className="margin-bottom"
                                data-testid="number-of-pages-info"
                            >
                                This means you can't process any pages.
                            </div>
                            {subscription.toLowerCase() === "aws" ? (
                                <div
                                className="margin-bottom"
                                data-testid="number-of-pages-info"
                            >
                                To resubscribe, please visit the{" "}
                                <Link
                                    data-testid="aws-marketplace-link"
                                    className="blue-text"
                                    to={"https://aws.amazon.com/marketplace"}
                                >
                                    AWS Marketplace Portal
                                </Link>
                                </div>
                            )
                            : null}
                        </div>
                    </div>
                )}
                {subscription.toLowerCase() === "starter" ? (
                    <div className="card-style">
                        <div className="card-text">
                            <div
                                className="margin-bottom card-title"
                                data-testid="upgrade-subscription-text"
                            >
                                Upgrade Your Subscription
                            </div>
                            <div className="join-text margin-bottom">
                                You currently have a {subscription} subscription
                            </div>
                            <div className="whole-width">
                                <Accordion>
                                    <AccountPageSubscriptionBusinessOption
                                        isBusinessAccordionOpen={
                                            isBusinessAccordionOpen
                                        }
                                        handleBusinessAccordionClick={
                                            handleBusinessAccordionClick
                                        }
                                    />
                                    <AccountPageSubscriptionEnterpriseOption
                                        isEnterpriseAccordionOpen={
                                            isEnterpriseAccordionOpen
                                        }
                                        handleEnterpriseAccordionClick={
                                            handleEnterpriseAccordionClick
                                        }
                                        dataTestId=""
                                    />
                                </Accordion>
                            </div>
                        </div>
                    </div>
                ) : null}
                {subscription.toLowerCase() === "business" &&
                    userInfo.subscription_status === "active" ? (
                    <>
                        <div className="card-style">
                            <div className="card-text">
                                <div
                                    className="margin-bottom card-title"
                                    data-testid="business-manage-subscription-text"
                                >
                                    Manage Your Subscription
                                </div>
                                <div className="join-text margin-bottom">
                                    You currently have a {subscription}{" "}
                                    subscription
                                </div>
                                <div className="margin-bottom larger-button">
                                    <CommonRegistrationButton
                                        buttonKind="primary"
                                        isDisabled={false}
                                        onClickFunction={
                                            handleManageSubscriptionClick
                                        }
                                        buttonDescription="Manage Subscription"
                                        dataTestId="business-manage-subscription"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-style">
                            <div className="card-text">
                                <div
                                    className="margin-bottom card-title"
                                    data-testid="business-upgrade-subscription-text"
                                >
                                    Your upgrade options
                                </div>
                                <div className="join-text margin-bottom">
                                    You currently have a {subscription}{" "}
                                    subscription
                                </div>
                                <div className="whole-width no-marker">
                                    <AccountPageSubscriptionEnterpriseOption
                                        isEnterpriseAccordionOpen={
                                            isEnterpriseAccordionOpen
                                        }
                                        handleEnterpriseAccordionClick={
                                            handleEnterpriseAccordionClick
                                        }
                                        dataTestId="business"
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
                {subscription.toLowerCase() === "business" &&
                    userInfo.subscription_status === "cancelled" ? (
                    <>
                        <div className="card-style">
                            <div className="card-text">
                                <div
                                    className="margin-bottom card-title"
                                    data-testid="upgrade-subscription-text"
                                >
                                    Reactivate Your Subscription
                                </div>
                                <div className="join-text margin-bottom">
                                    Your subscription was cancelled, reactivate
                                    it to keep using Oscar
                                </div>
                                <div className="whole-width">
                                    <Accordion>
                                        <AccountPageSubscriptionBusinessOption
                                            isBusinessAccordionOpen={
                                                isBusinessAccordionOpen
                                            }
                                            handleBusinessAccordionClick={
                                                handleBusinessAccordionClick
                                            }
                                        />
                                        <AccountPageSubscriptionEnterpriseOption
                                            isEnterpriseAccordionOpen={
                                                isEnterpriseAccordionOpen
                                            }
                                            handleEnterpriseAccordionClick={
                                                handleEnterpriseAccordionClick
                                            }
                                            dataTestId=""
                                        />
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        </Fragment>
    );
};

export default AccountPageSubscriptionDashboard;
