import {
    CheckmarkOutline16,
    DirectionMerge32,
    Edit32,
    Finance16,
    RadioButton32,
    TableSplit16,
    TrashCan32,
} from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";
import "./common-buttons.styles.scss";

const CommonButtons = ({
    id,
    projectsSelectedCount,
    deleteButtonClickFunction,
    genericTableButtonDescription,
    genericTableButtonClickFunction,
    bankStatementButtonDescription,
    bankStatementButtonClickFunction,
    bankStatementMergeFunction,
    handleEditFileDetailsClick,
    deselectAll,
    selectAll,
    selectedProjectOrFileLength,
    projectOrFileLength,
}) => {
    return (
        <div className="all-buttons">
            {projectOrFileLength !== 0 ? (
                <Button
                    renderIcon={CheckmarkOutline16}
                    className="icon-button-dashboard"
                    data-testid={`${id}-select-all-button`}
                    id={`${id}-select-all-button`}
                    iconDescription={"Select All"}
                    hasIconOnly
                    onClick={selectAll}
                    tooltipPosition="bottom"
                />
            ) : null}
            {selectedProjectOrFileLength !== 0 ? (
                <>
                    <Button
                        renderIcon={RadioButton32}
                        className="icon-button-dashboard margin-left-position"
                        data-testid={`${id}-deselect-all-button`}
                        id={`${id}-deselect-all-button`}
                        iconDescription={"Deselect All"}
                        hasIconOnly
                        onClick={deselectAll}
                        tooltipPosition="bottom"
                    />
                    <Button
                        renderIcon={TrashCan32}
                        className="icon-button-dashboard margin-left-position"
                        data-testid={`${id}-delete-button`}
                        id={`${id}-delete-button`}
                        iconDescription="Delete Selected"
                        hasIconOnly
                        onClick={deleteButtonClickFunction}
                        tooltipPosition="bottom"
                    />
                    <Button
                        renderIcon={TableSplit16}
                        className="icon-button-dashboard margin-left-position"
                        data-testid={`${id}-download-generic-tables-button`}
                        id={`${id}-download-generic-tables-button`}
                        iconDescription={genericTableButtonDescription}
                        hasIconOnly
                        onClick={genericTableButtonClickFunction}
                        tooltipPosition="bottom"
                    />
                    <Button
                        renderIcon={Finance16}
                        className="icon-button-dashboard margin-left-position"
                        data-testid={`${id}-download-bank-statements-button`}
                        id={`${id}-download-bank-statements-button`}
                        iconDescription={bankStatementButtonDescription}
                        hasIconOnly
                        onClick={bankStatementButtonClickFunction}
                        tooltipPosition="bottom"
                    />
                    {id === "file" ? (
                        <Button
                            renderIcon={Edit32}
                            className="icon-button-dashboard margin-left-position"
                            data-testid={`${id}-edit-file-details-button`}
                            id={`${id}-edit-file-details-button`}
                            iconDescription={"Edit File Details"}
                            hasIconOnly
                            onClick={handleEditFileDetailsClick}
                            tooltipPosition="bottom"
                        />
                    ) : null}
                </>
            ) : null}
            {selectedProjectOrFileLength > 1 ? (
                <Button
                    id={`${id}-merge-excel-files-button`}
                    renderIcon={DirectionMerge32}
                    className="icon-button-dashboard margin-left-position"
                    data-testid={`${id}-merge-excel-files-button`}
                    iconDescription={"Download Merged Bank Statements"}
                    hasIconOnly
                    onClick={bankStatementMergeFunction}
                    tooltipPosition="bottom"
                />
            ) : null}
        </div>
    );
};

export default CommonButtons;
