import { InformationFilled16 } from "@carbon/icons-react";
import { Button, TextInput, Toggle } from "carbon-components-react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearUpdateFileDetails,
    updateFileDetails,
} from "../../redux/file-details/file-details.actions";
import "./file-details.styles.scss";

const FileDetails = ({
    pageLocation,
    resetSideBarInfo,
    setIsEditing,
    isEditing,
    invalidStatementYear,
    setInvalidStatementYear,
    autoIncrementYear,
    setAutoIncrementYear,
    forceTwoDecimals,
    setForceTwoDecimals
}) => {
    const dispatch = useDispatch();
    const fileDetails = useSelector((state) => state.fileDetails.fileDetails);

    const [accountName, setAccountName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [statementBranch, setStatementBranch] = useState("");
    const [statementSortCode, setStatementSortCode] = useState("");
    const [statementStartYear, setStatementStartYear] = useState("");

    const onStatementYearChange = useCallback(
        (e) => {
            let value = e.target.value;
            setStatementStartYear(value);

            const pattern = /^[0-9]*$/;
            let matches = pattern.test(value);

            if (matches && value.length === 4 && ![null, ""].includes(value)) {
                setInvalidStatementYear(false);
            } else if ([null, ""].includes(value)) {
                setInvalidStatementYear(false);
            } else {
                setInvalidStatementYear(true);
            }
        },
        [setInvalidStatementYear]
    );

    useEffect(() => {
        dispatch(clearUpdateFileDetails());
        dispatch(
            updateFileDetails({
                account_name: accountName,
                account_type: accountType,
                account_number: accountNumber,
                statement_branch: statementBranch,
                statement_sort_code: statementSortCode,
                statement_start_year: statementStartYear,
                auto_increment_year: autoIncrementYear,
                force_two_decimals: forceTwoDecimals,
            })
        );
    }, [
        accountName,
        accountType,
        accountNumber,
        statementBranch,
        statementSortCode,
        statementStartYear,
        autoIncrementYear,
        forceTwoDecimals,
        dispatch,
    ]);

    useEffect(() => {
        if (resetSideBarInfo || !isEditing) {
            if (fileDetails !== null) {
                fileDetails["account_name"] === null
                    ? setAccountName("")
                    : setAccountName(fileDetails["account_name"]);

                fileDetails["account_type"] === null
                    ? setAccountType("")
                    : setAccountType(fileDetails["account_type"]);

                fileDetails["account_number"] === null
                    ? setAccountNumber("")
                    : setAccountNumber(fileDetails["account_number"]);

                fileDetails["statement_branch"] === null
                    ? setStatementBranch("")
                    : setStatementBranch(fileDetails["statement_branch"]);

                fileDetails["statement_sort_code"] === null
                    ? setStatementSortCode("")
                    : setStatementSortCode(fileDetails["statement_sort_code"]);

                fileDetails["statement_start_year"] === null
                    ? setStatementStartYear("")
                    : setStatementStartYear(fileDetails["statement_start_year"]);

                fileDetails["auto_increment_year"] === null
                    ? setAutoIncrementYear(true)
                    : setAutoIncrementYear(fileDetails["auto_increment_year"])

                fileDetails["force_two_decimals"] === null
                    ? setForceTwoDecimals(true)
                    : setForceTwoDecimals(fileDetails["force_two_decimals"])

            }
        }
    }, [
        fileDetails,
        resetSideBarInfo,
        setAccountName,
        setAccountType,
        setAccountNumber,
        setStatementBranch,
        setStatementSortCode,
        setStatementStartYear,
        setAutoIncrementYear,
        setForceTwoDecimals,
        isEditing,
    ]);

    const renderToggles = () => {
        if (pageLocation === "mask") {
            return (
                <>
                <Toggle
                    id={`${pageLocation}-auto-increment-year-toggle`}
                    className="increment-year-toggle"
                    labelText="Auto-increment year?"
                    labelA="No"
                    labelB="Yes"
                        toggled={autoIncrementYear}
                    onToggle={(value) => { setAutoIncrementYear(value) }}
                />
                    <Toggle
                        id={`${pageLocation}-force-two-decimal-places-toggle`}
                        className="force-two-decimals-toggle"
                        labelText="Force two decimal places?"
                        labelA="No"
                        labelB="Yes"
                        toggled={forceTwoDecimals}
                        onToggle={(value) => { setForceTwoDecimals(value) }}
                    />
                    <Button
                        renderIcon={InformationFilled16}
                        id="force-two_decimals-tooltip-button"
                        iconDescription="Set to false if currency values do not consistently contain decimal points"
                        hasIconOnly={true}
                        className="force-two_decimals-info-button"
                    />
                </>

            )
        }
    }

    return (
        <div
            className="mask-page-side-bar-sections"
            data-testid={`${pageLocation}-page-side-bar-file-details`}
        >
            <TextInput
                className="margin-bottom"
                id={`${pageLocation}-account-name-input`}
                data-testid={`${pageLocation}-account-name-input`}
                labelText="Account Name"
                type="text"
                placeholder={"e.g. John Smith"}
                value={accountName}
                onChange={(e) => {
                    setAccountName(e.target.value);
                    // setIsEditing(true);
                }}
            />
            <TextInput
                className="margin-bottom"
                id={`${pageLocation}-account-type-input`}
                data-testid={`${pageLocation}-account-type-input`}
                labelText="Account Type"
                type="text"
                placeholder={"e.g. Expenses"}
                value={accountType}
                onChange={(e) => {
                    setAccountType(e.target.value);
                }}
            />
            <TextInput
                className="margin-bottom"
                id={`${pageLocation}-account-number-input`}
                data-testid={`${pageLocation}-account-number-input`}
                labelText="Account Number"
                type="number"
                placeholder={"e.g. 12345678"}
                value={accountNumber}
                onChange={(e) => {
                    setAccountNumber(e.target.value);
                }}
            />
            <TextInput
                className="margin-bottom"
                id={`${pageLocation}-account-sort-code-input`}
                data-testid={`${pageLocation}-account-sort-code-input`}
                labelText="Sort Code"
                type="text"
                placeholder={"e.g. 00-00-00"}
                value={statementSortCode}
                onChange={(e) => {
                    setStatementSortCode(e.target.value);
                }}
            />
            <TextInput
                className="margin-bottom"
                id={`${pageLocation}-account-branch-input`}
                data-testid={`${pageLocation}-account-branch-input`}
                labelText="Branch Number"
                type="text"
                placeholder={"e.g. IIIGGB22"}
                value={statementBranch}
                onChange={(e) => {
                    setStatementBranch(e.target.value);
                }}
            />{" "}
            <TextInput
                className={invalidStatementYear ? "" : "start-year-margin-bottom"}
                id={`${pageLocation}-statement-start-year-input`}
                data-testid={`${pageLocation}-statement-start-year-input`}
                labelText="Statement Year"
                type="text"
                placeholder={"e.g. 2020"}
                value={statementStartYear}
                onChange={(e) => onStatementYearChange(e)}
                invalid={invalidStatementYear}
                invalidText={invalidStatementYear ? "Invalid Year" : ""}
            />
            <Button
                renderIcon={InformationFilled16}
                id={`${pageLocation}-start-year-info`}
                data-testid={`${pageLocation}-start-year-info`}
                className="start-year-info-button"
                iconDescription="Enter the start year if not present in the date column.  If a year is present in the data then this field is ignored."
                hasIconOnly={true}
            />
            {renderToggles()}
        </div>
    );
};

FileDetails.defaultProps = {
    pageLocation: "",
    resetSideBarInfo: null,
};

export default FileDetails;
