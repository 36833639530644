import types from "./mask.types";

export const setAutoGrid = (autoGrid) => {
    return { type: types.SET_AUTOGRID, payload: autoGrid };
};

export const clearAutoGrid = () => {
    return { type: types.CLEAR_AUTOGRID };
};

export const setAutoMerge = (autoMerge) => {
    return { type: types.SET_AUTOMERGE, payload: autoMerge };
};

export const clearAutoMerge = () => {
    return { type: types.CLEAR_AUTOMERGE };
};
