import { CheckmarkOutline16 } from "@carbon/icons-react";
import { AccordionItem } from "carbon-components-react";
import React from "react";
import "../account-page-dashboard.styles.scss";
import "./account-page-dashboard-subscription.styles.scss";

const AccountPageSubscriptionEnterpriseOption = ({
    isEnterpriseAccordionOpen,
    handleEnterpriseAccordionClick,
    dataTestId,
}) => {
    return (
        <AccordionItem
            title="Enterprise Subscription"
            open={isEnterpriseAccordionOpen}
            onClick={handleEnterpriseAccordionClick}
            data-testid={
                dataTestId === ""
                    ? "enterprise-subscription-option"
                    : `${dataTestId}-enterprise-subscription-option`
            }
        >
            <div
                className="larger-text margin-bottom"
                data-testid="enterprise-subscription-text"
            >
                Contact us on oscar@demarq.com
            </div>
            <div
                className="list"
                data-testid={
                    dataTestId === ""
                        ? "enterprise-subscription-feature-list"
                        : `${dataTestId}-enterprise-subscription-feature-list`
                }
            >
                <div
                    data-testid={
                        dataTestId === ""
                            ? "enterprise-subscription-feature-list-left"
                            : `${dataTestId}-enterprise-subscription-feature-list-left`
                    }
                >
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Bespoke
                        pricing available on request
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Unlimited
                        access for multiple users
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> AI driven
                        solution designed to meet your specific need
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Formatted
                        and categorised for quick analysis
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Search and
                        index your imported data
                    </p>
                </div>
                <div
                    data-testid={
                        dataTestId === ""
                            ? "enterprise-subscription-feature-list-right"
                            : `${dataTestId}-enterprise-subscription-feature-list-right`
                    }
                >
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Secure,
                        cloud-based document store
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Up to 1TB
                        of storage included
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Save and
                        re-visit your work
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> In-built
                        data quality checks
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> API’s
                        available to feed data back into your own systems
                    </p>
                    <p className="text-font">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Tailorable
                        components for your specific use cases
                    </p>
                </div>
            </div>
        </AccordionItem>
    );
};

export default AccountPageSubscriptionEnterpriseOption;
