import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import InputFieldPhoneNumber from "../../../../../components/input-fields/input-fields-phone-number.component";
import CommonRegistrationButton from "../../../../../components/registration-buttons/registration-button-common.component";
import { cognitoUpdatePhoneNumberAction } from "../../../../../redux/thunks/thunks.actions.js";
import "../account-page-manage-account.styles.scss";

const AccountDetailsUpdatePhoneNumber = ({
    authMessage,
    setIsManagePhoneNumberOpen,
}) => {
    const dispatch = useDispatch();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [isSavePhoneNumberButtonDisabled, setIsSavePhoneNumberButtonDisabled] =
        useState(true);

    const handleSubmitSavePhoneNumber = () => {
        dispatch(
            cognitoUpdatePhoneNumberAction(phoneNumber, "updatePhoneNumber")
        );
    };

    useEffect(() => {
        switch (authMessage) {
            case "Phone number successfully updated":
                setIsManagePhoneNumberOpen(false);
                setPhoneNumber("");
                break;
            default:
                break;
        }
    }, [authMessage, setIsManagePhoneNumberOpen]);

    return (
        <div className="margin-bottom" data-testid="update-phone-number-element">
            <div className="password-div">
                <div
                    className="larger-text margin-bottom"
                    data-testid="phone-number-field-text"
                >
                    Please enter your new phone number
                </div>
                <InputFieldPhoneNumber
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setIsSavePhoneNumberButtonDisabled={
                        setIsSavePhoneNumberButtonDisabled
                    }
                    testId={"account-details-update"}
                />
            </div>
            <CommonRegistrationButton
                buttonKind="primary"
                isDisabled={isSavePhoneNumberButtonDisabled ? true : false}
                onClickFunction={handleSubmitSavePhoneNumber}
                buttonDescription="Save phone number"
                dataTestId="submit-update-phone-number-verification-code"
            />
        </div>
    );
};

export default AccountDetailsUpdatePhoneNumber;
