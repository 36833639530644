import React from "react";
import PageInformation from "./mask-page-image-toolbar-buttons/display-page-information.component";
import PageUpDown from "./mask-page-image-toolbar-buttons/page-up-down.component";
import RotateClockWiseButton from "./mask-page-image-toolbar-buttons/rotate-clockwise-button.component";
import RotateCounterClockWiseButton from "./mask-page-image-toolbar-buttons/rotate-counter-clockwise-button.component";
import ZoomInButton from "./mask-page-image-toolbar-buttons/zoom-in-button.component";
import ZoomOutButton from "./mask-page-image-toolbar-buttons/zoom-out-button.component";
import ZoomRotateResetButton from "./mask-page-image-toolbar-buttons/zoom-rotate-reset-button.component";

const MaskPageImageToolbar = ({
    totalPages,
    pageDisplayed,
    allImagesInfo,
    zoomInFunction,
    zoomOutFunction,
    resetTransformFunction,
    setPageToScrollTo,
    setAllImagesInfo,
    blurButtons,
}) => {
    return (
        <>
            <div
                className="image-toolbar"
                data-testid="mask-page-image-toolbar"
                style={{ zIndex: 1 }}
            >
                <PageUpDown 
                    totalPages={totalPages}
                    pageDisplayed={pageDisplayed}
                    setPageToScrollTo={setPageToScrollTo}
                />
                <PageInformation
                    totalPages={totalPages}
                    pageDisplayed={pageDisplayed}
                    setPageToScrollTo={setPageToScrollTo}
                />
                <RotateCounterClockWiseButton
                    allImagesInfo={allImagesInfo}
                    pageDisplayed={pageDisplayed}
                    setAllImagesInfo={setAllImagesInfo}
                    blurButtons={blurButtons}
                />
                <RotateClockWiseButton
                    allImagesInfo={allImagesInfo}
                    pageDisplayed={pageDisplayed}
                    setAllImagesInfo={setAllImagesInfo}
                    blurButtons={blurButtons}
                />
                <ZoomInButton zoomInFunction={zoomInFunction} page={"mask"} />
                <ZoomOutButton zoomOutFunction={zoomOutFunction} page={"mask"} />
                <ZoomRotateResetButton
                    allImagesInfo={allImagesInfo}
                    pageDisplayed={pageDisplayed}
                    resetTransformFunction={resetTransformFunction}
                    setAllImagesInfo={setAllImagesInfo}
                    page={"mask"}
                    blurButtons={blurButtons}
                />
            </div>
        </>
    );
};

export default MaskPageImageToolbar;
