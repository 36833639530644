import { RotateClockwise32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const RotateClockWiseButton = ({
    allImagesInfo,
    setAllImagesInfo,
    pageDisplayed,
    blurButtons,
}) => {
    const rotateRight = () => {
        let newAllImagesInfo = [...allImagesInfo];

        for (let element of newAllImagesInfo) {
            if (pageDisplayed === element.imageId) {
                let newRotation = element.imageRotation + 90;
                if (newRotation >= 360) {
                    newRotation = -360;
                }
                element.imageRotation = newRotation;
            }
        }
        setAllImagesInfo(newAllImagesInfo);
        blurButtons();
    };

    return (
        <Button
            renderIcon={RotateClockwise32}
            iconDescription="Rotate Image Clockwise"
            data-testid="mask-page-image-toolbar-rotate-clockwise-button"
            id="mask-page-image-toolbar-rotate-clockwise-button"
            className="default-button-colour"
            hasIconOnly={true}
            onClick={rotateRight}
        />
    );
};

export default RotateClockWiseButton;
