import { Folder32 } from "@carbon/icons-react";
import { Column, Tile } from "carbon-components-react";
import React, { useCallback, useEffect, useState } from "react";
import Selector from "../../components/selector/selector.component";
import ProjectButtons from "./project-buttons.component";

const ProjectTile = ({
    selectedProjectInfo,
    handleTileClick,
    onToggleProjectRenameModal,
    addProjectSelected,
    handleRemoveProjectSelected,
    areProjectSelected,
    resetDashboardUseStates,
    areProjectsDownloaded,
    projectsSelected,
}) => {
    const [isDisplayedProjectSelected, setIsDisplayedProjectSelected] =
        useState(false);

    const toggleProjectRenameModal = (event) => {
        event.stopPropagation();
        onToggleProjectRenameModal(selectedProjectInfo);
    };

    /**
     *
     * @param {*} event
     */
    const projectSelectorClick = (event) => {
        // this prevents a click on the project selector from opening the project
        event.stopPropagation();
        setIsDisplayedProjectSelected(!isDisplayedProjectSelected);

        if (isDisplayedProjectSelected) {
            handleRemoveProjectSelected({
                projectName: selectedProjectInfo.name,
                projectId: selectedProjectInfo.project_id,
                projectFiles: selectedProjectInfo.files,
            });
        } else {
            addProjectSelected({
                projectName: selectedProjectInfo.name,
                projectId: selectedProjectInfo.project_id,
                projectFiles: selectedProjectInfo.files,
            });
        }
    };

    const projectClick = () => {
        handleTileClick(selectedProjectInfo);
        resetDashboardUseStates(false);
    };

    const checkDownloadStatus = useCallback(() => {
        if (areProjectsDownloaded) {
            setIsDisplayedProjectSelected(false);
            resetDashboardUseStates(false);
        }
    }, [areProjectsDownloaded, resetDashboardUseStates]);

    useEffect(() => {
        checkDownloadStatus();
    }, [checkDownloadStatus]);

    useEffect(() => {
        if (!areProjectSelected) {
            setIsDisplayedProjectSelected(false);
        }
    }, [areProjectSelected]);

    useEffect(() => {
        if (projectsSelected.length === 0) {
            setIsDisplayedProjectSelected(false);
        } else {
            for (let project of projectsSelected) {
                if (project["projectId"] === selectedProjectInfo["project_id"]) {
                    setIsDisplayedProjectSelected(true);
                }
            }
        }
    }, [projectsSelected, selectedProjectInfo]);

    return (
        <Column sm={2} md={2} lg={2} className="tile-holder">
            <div
                className={`${
                    isDisplayedProjectSelected ? "item-expand" : "hover-grow"
                }`}
            >
                <Selector
                    id={selectedProjectInfo.project_id}
                    componentSpecificBoolean={isDisplayedProjectSelected}
                    generalBoolean={areProjectSelected}
                    handleClick={projectSelectorClick}
                />
                <Tile
                    onClick={projectClick}
                    className="
                    project-tile-size 
                    project-tile-contents 
                    project-tile-margin 
                    "
                    data-testid={`project-grid-element-${selectedProjectInfo.name}`}
                >
                    <ProjectButtons
                        projectId={selectedProjectInfo.project_id}
                        projectDescription={selectedProjectInfo.description}
                        editProjectClick={toggleProjectRenameModal}
                    />
                    <Folder32 className="project-folder-icon" />
                    <p>{selectedProjectInfo.name}</p>
                </Tile>
            </div>
        </Column>
    );
};

export default ProjectTile;
