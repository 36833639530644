import { CheckmarkOutline16 } from "@carbon/icons-react";
import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import QRCodeComponent from "../../components/qr-code/qr-code.component";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./accordion.styles.scss";
const AccordionSetUpMFA = ({
    mfaSetUpAccordionBorder,
    isMfaSetUpAccordionOpen,
    isMfaSetUpFinished,
    setMfaCode,
    handleMFASetUp,
    mfaCode,
}) => {
    const [isValidateMFAButtonDisabled, setIsValidateMFAButtonDisabled] =
        useState(true);
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);


    useEffect(() => {
        verificationCodeConforms
            ? setIsValidateMFAButtonDisabled(false)
            : setIsValidateMFAButtonDisabled(true);
    }, [verificationCodeConforms]);

    return (
        <Accordion
            data-testid="set-up-mfa-accordion"
            className={`${mfaSetUpAccordionBorder} accordion-style`}
        >
            <AccordionItem
                title={
                    <div className="flex-display">
                        {!isMfaSetUpFinished ? (
                            <div
                                data-testid="set-up-mfa-accordion-circle"
                                className={`circle ${
                                    isMfaSetUpAccordionOpen
                                        ? "active-circle"
                                        : "inactive-circle"
                                }`}
                            ></div>
                        ) : (
                            <CheckmarkOutline16
                                data-testid="set-up-mfa-accordion-checkmark"
                                className="relative-position orange-fill"
                            />
                        )}
                        <p>Set Up MFA</p>
                    </div>
                }
                disabled={true}
                open={isMfaSetUpAccordionOpen}
            >
                <div
                    data-testid="set-up-mfa-accordion-text"
                    className="field-margin-bottom"
                >
                    Please scan the QR Code using a MFA Authenticator App of your
                    choice.
                </div>
                <QRCodeComponent />
                <div className="field-margin-bottom">
                    <InputFieldVerificationCode
                        setVerificationCode={setMfaCode}
                        verificationCode={mfaCode}
                        setVerificationCodeConforms={setVerificationCodeConforms}
                        verificationCodeConforms={verificationCodeConforms}
                        labelText={"MFA code"}
                    />
                </div>
                <CommonRegistrationButton
                    buttonKind={"primary"}
                    isDisabled={isValidateMFAButtonDisabled}
                    onClickFunction={handleMFASetUp}
                    buttonDescription={"Validate MFA Code"}
                    dataTestId={"set-up-mfa"}
                />
            </AccordionItem>
        </Accordion>
    );
};

export default AccordionSetUpMFA;
