import types from "./files.types";

export const initialState = {
    fileImages: null,
    fileName: null,
    fileTables: null,
    fileColSpec: null,
    fileResults: [],
    categorisedFileResults: [],
    fileStatus: "",
};

export const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_FILE_IMAGES:
            return { ...state, fileImages: action.payload };
        case types.CLEAR_FILE_IMAGES:
            return { ...state, fileImages: null };
        case types.SET_IMAGES_FILE_NAME:
            return { ...state, fileName: action.payload };
        case types.CLEAR_IMAGES_FILE_NAME:
            return { ...state, fileName: null };
        case types.SET_FILE_TABLES:
            return { ...state, fileTables: action.payload };
        case types.CLEAR_FILE_TABLES:
            return { ...state, fileTables: null };
        case types.SET_FILE_COL_SPEC:
            return { ...state, fileColSpec: action.payload };
        case types.CLEAR_FILE_COL_SPEC:
            return { ...state, fileColSpec: null };
        case types.SET_FILE_RESULTS:
            return { ...state, fileResults: action.payload };
        case types.CLEAR_FILE_RESULTS:
            return { ...state, fileResults: {} };
        case types.SET_CATEGORISED_FILE_RESULTS:
            return { ...state, categorisedFileResults: action.payload };
        case types.CLEAR_CATEGORISED_FILE_RESULTS:
            return { ...state, categorisedFileResults: [] };
        case types.SET_FILE_STATUS:
            return { ...state, fileStatus: action.payload };
        case types.CLEAR_FILE_STATUS:
            return { ...state, fileStatus: "" };
        default:
            return state;
    }
};

export default filesReducer;
