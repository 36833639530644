import React from "react";
import { useNavigate } from "react-router-dom";
import "./file-name.styles.scss";


const FileNameTitle = ({ projectName, projectId, fileName, pageLocation, isSideNavBarInfoOpen }) => {
    const navigate = useNavigate();

    return (
        <>
            <div
                className={`page-title ${isSideNavBarInfoOpen ? "page-title-info-nav-bar-open" : ""
                    }`}
                data-testid={`page-title-${pageLocation}`}
            >
                <span style={{ cursor: 'pointer' }} onClick={(e) => navigate('/')}>Projects</span> / <span style={{ cursor: 'pointer' }} onClick={(e) => navigate(`/?projectId=${projectId}`)}>{projectName}</span> / {fileName}
            </div>
        </>
    );
};

export default FileNameTitle;
