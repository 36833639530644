import { createSelector } from "reselect";

const fileDetailsState = (state) => state.fileDetails.fileDetails;

export const updateFileDetails = (state, action) => {
    let currentFileDetails = state.fileDetails;
    let currentFileDetailsCopy = JSON.parse(JSON.stringify(currentFileDetails));
    let newFileDetails;

    if (
        currentFileDetailsCopy !== null ||
        currentFileDetailsCopy !== undefined
    ) {
        if (
            currentFileDetailsCopy.project_id === action.projectId &&
            currentFileDetailsCopy.file_id === action.fileId
        ) {
            newFileDetails = action.payload;
        } else {
            newFileDetails = currentFileDetailsCopy;
        }
    } else {
        newFileDetails = currentFileDetailsCopy;
    }

    return newFileDetails;
};

const removeFileExtension = (fileName) => {
    let newFileName;
    switch (fileName) {
        case undefined:
            newFileName = fileName;
            return newFileName;
        default:
            newFileName = fileName.split(".").slice(0, -1).join(".");
            return newFileName;
    }
};

const extractFileType = (fileType) => {
    let newFileType;
    switch (fileType) {
        case undefined:
            newFileType = fileType;
            return newFileType;
        default:
            newFileType = fileType.split("/")[1].toUpperCase();
            return newFileType;
    }
};

const determineDocumentType = (documentType) => {
    let newDocumentType;
    switch (documentType) {
        case "":
            newDocumentType = "Bank Statement";
            return newDocumentType;
        default:
            newDocumentType = documentType;
            return newDocumentType;
    }
};

export const fileDetails = createSelector([fileDetailsState], (details) => {
    const validation = [undefined, null];

    if (!validation.includes(details)) {
        let updatedFileDetails;

        updatedFileDetails = {
            projectId: details["project_id"],
            fileId: details["file_id"],
            fileName: removeFileExtension(details["file_name"]),
            fileType: extractFileType(details["file_type"]),
            documentType: determineDocumentType(details["doc_type"]),
            statementBranch: details["statement_branch"],
            statementSortCode: details["statement_sort_code"],
            accountNumber: details["account_number"],
        };

        return updatedFileDetails;
    }
});
