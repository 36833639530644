import React from "react";
import RegistrationCard from "../../components/registration-card/registration-card.component";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


const RegistrationPage = () => {

    let query = useQuery();

    // Check if this is an AWS Marketplace redirect
    let x_amzn_marketplace_token = query.get("x_amzn_marketplace_token")
    console.log(x_amzn_marketplace_token)

    return (
        <div
            className="login-page-background"
            data-testid="registration-page-background"
        >
            <RegistrationCard x_amzn_marketplace_token={x_amzn_marketplace_token}/>
        </div>
    );
};

export default RegistrationPage;
