const pre = "auth/";
const SET_AUTH_TOKEN = pre + "SET_AUTH_TOKEN";
const SET_TOKEN_TYPE = pre + "SET_TOKEN_TYPE";
const SET_LOGIN_ERROR = pre + "SET_LOGIN_ERROR";
const SET_QR_CODE = pre + "SET_QR_CODE";
const SET_QR_CODE_USERNAME = pre + "SET_QR_CODE_USERNAME";
const SET_QR_COGNITO_USER = pre + "SET_QR_COGNITO_USER";
const SET_PROGRESS_TO_NEXT_STEP = pre + "SET_PROGRESS_TO_NEXT_STEP";
const SET_REDIRECT_TO_LOGIN = pre + "SET_REDIRECT_TO_LOGIN";
const SET_AUTH_MESSAGE = pre + "SET_AUTH_MESSAGE";
const SET_COGNITO_USER = pre + "SET_COGNITO_USER";
const CLEAR_AUTH_TOKEN = pre + "CLEAR_AUTH_TOKEN";


const authTypes = {
    SET_AUTH_TOKEN,
    SET_TOKEN_TYPE,
    SET_LOGIN_ERROR,
    SET_QR_CODE,
    SET_QR_CODE_USERNAME,
    SET_QR_COGNITO_USER,
    SET_PROGRESS_TO_NEXT_STEP,
    SET_REDIRECT_TO_LOGIN,
    SET_AUTH_MESSAGE,
    SET_COGNITO_USER,
    CLEAR_AUTH_TOKEN
};

export default authTypes;
