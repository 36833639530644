import { Add32, DocumentAdd32, FolderAdd32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearApiError } from "../../redux/api/api.actions";
import { useSelector } from "react-redux";

const ProjectModalButtons = ({
    onToggleFileModal,
    onToggleProjectModal,
    closeButtons,
    onCloseButtons,
    isCollapsed,
}) => {
    const dispatch = useDispatch();

    const [showButtons, setShowButtons] = useState(false);
    const [clickMoreThanOnce, setClickMoreThanOnce] = useState(false);
    const filesUploading = useSelector((state) => state.projects.files_uploading)

    const toggleProjectAdd = () => {
        setShowButtons(!showButtons);
        setClickMoreThanOnce(true);
    };

    const toggleProjectModal = () => {
        onToggleProjectModal();
        dispatch(clearApiError());
    };

    const toggleFileModal = () => {
        onToggleFileModal();
        dispatch(clearApiError());
    };

    useEffect(() => {
        if (closeButtons) {
            setShowButtons(false);
            onCloseButtons();
        }
    }, [closeButtons, onCloseButtons]);

    useEffect(() => {
        if (closeButtons) {
            setShowButtons(false);
            onCloseButtons();
        }
    }, [closeButtons, onCloseButtons]);

    return (
        <div
            className={`add-buttons-div higher-z-index ${
                isCollapsed
                    ? "add-button-div-sidebar-collapsed"
                    : "add-button-div-sidebar-expanded"
            }`}
        >
            {clickMoreThanOnce && (
                <>
                    <Button
                        id="show-modal-button"
                        data-testid="show-file-modal-button"
                        hasIconOnly={true}
                        kind="secondary"
                        iconDescription="File Modal Button"
                        className={`
                            button-design
                            add-button-cursor
                            remove-tooltip
                            ${showButtons ? "fade-in-up-2" : "fade-out-down-2"}
                        `}
                        size="sm"
                        onClick={toggleFileModal}
                        disabled={filesUploading ? true : false}
                    >
                        <DocumentAdd32 className="project-icon" />
                    </Button>
                    <Button
                        id="show-modal-button"
                        data-testid="show-project-modal-button"
                        hasIconOnly={true}
                        kind="secondary"
                        iconDescription="Project Modal Button"
                        className={`
                            button-design
                            add-button-cursor
                            remove-tooltip
                            ${showButtons ? "fade-in-up" : "fade-out-down"}
                        `}
                        size="sm"
                        onClick={toggleProjectModal}
                    >
                        <FolderAdd32 className="project-icon" />
                    </Button>
                </>
            )}
            <Button
                hasIconOnly={true}
                id="show-buttons-button"
                data-testid="show-buttons-button"
                kind="secondary"
                iconDescription="Show Buttons Button"
                className="button-design \
                add-button-cursor \
                remove-tooltip"
                size="sm"
                onClick={toggleProjectAdd}
            >
                <Add32 className="project-icon" />
            </Button>
        </div>
    );
};

export default ProjectModalButtons;
