import React, { Fragment, useEffect, useState } from "react";
import ManageAccountAccountDetails from "../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/account-details/account-details.component";
import ManageAccountSetUpMFA from "../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/mfa-setup/mfa-setup.component";
import "../account-page-dashboard.styles.scss";
import "./account-page-manage-account.styles.scss";

const ManageAccountDashboard = ({ userInfo, authMessage }) => {
    const [isManagePasswordOpen, setIsManagePasswordOpen] = useState(false);
    const [isManagePhoneNumberOpen, setIsManagePhoneNumberOpen] =
        useState(false);
    const [mfaChoice, setMfaChoice] = useState("");

    const [username, setUsername] = useState("");

    useEffect(() => {
        if (userInfo !== null) {
            setUsername(userInfo.username);
        }
    }, [userInfo]);

    return (
        <Fragment>
            <div
                className="row-page"
                data-testid="account-page-manage-account-dashboard"
            >
                <div
                    className="tile-title"
                    data-testid="account-page-manage-account-dashboard-title"
                >
                    Welcome, {username}{" "}
                </div>
                <div data-testid="account-page-manage-account-dashboard-sub-title">
                    Manage your info, privacy and security to make Oscar work
                    better for you.
                </div>
                <ManageAccountAccountDetails
                    userInfo={userInfo}
                    setMfaChoice={setMfaChoice}
                    authMessage={authMessage}
                    setIsManagePasswordOpen={setIsManagePasswordOpen}
                    isManagePasswordOpen={isManagePasswordOpen}
                    setIsManagePhoneNumberOpen={setIsManagePhoneNumberOpen}
                    isManagePhoneNumberOpen={isManagePhoneNumberOpen}
                />

                <ManageAccountSetUpMFA
                    userInfo={userInfo}
                    setIsManagePasswordOpen={setIsManagePasswordOpen}
                    authMessage={authMessage}
                    mfaChoice={mfaChoice}
                    setMfaChoice={setMfaChoice}
                />
            </div>
        </Fragment>
    );
};

export default ManageAccountDashboard;
