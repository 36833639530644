import React, { Fragment, useState } from "react";
import { Modal, TextInput, TextArea } from "carbon-components-react";
import { useDispatch } from "react-redux";
import { renameProjectAction } from "../../redux/thunks/thunks.actions";
import {
    cleanCheckProjectName,
    forbiddenCharactersCheck,
} from "../../helpers/helper-functions.js";

const ProjectRenameModal = ({
    onToggleProjectRenameModal,
    projectRenameModalShow,
    allProjectsInfo,
    selectedProjectInfo,
}) => {
    const [enteredProjectNewName, setEnteredProjectNewName] = useState("");
    const [enteredProjectNewDescription, setEnteredProjectNewDescription] = useState(
        ""
    );

    const dispatch = useDispatch();
    const submitProjectRename = (event) => {
        // Check if there is any new data
        if (
            enteredProjectNewName.trim().length === 0 &&
            enteredProjectNewDescription.trim().length === 0
        ) {
            return;
        }

        let cleanedCheckedProjectName;

        // Check if a New project name was provided (if not take old project name)
        if (enteredProjectNewName.trim().length === 0) {
            cleanedCheckedProjectName = selectedProjectInfo.name;
        } else {
            cleanedCheckedProjectName = cleanCheckProjectName(
                allProjectsInfo,
                enteredProjectNewName
            );
        }

        // Check that cleanedCheckedProjectName is acceptable
        if (cleanedCheckedProjectName === null) {
            return;
        }

        let cleanedProjectDescription;

        if (enteredProjectNewDescription.trim().length === 0) {
            cleanedProjectDescription = selectedProjectInfo.description;
        } else {
            cleanedProjectDescription = enteredProjectNewDescription.trim();
        }

        // Dispatch new name
        dispatch(
            renameProjectAction(
                selectedProjectInfo.project_id,
                cleanedCheckedProjectName,
                cleanedProjectDescription
            )
        );
        setEnteredProjectNewName("");
        setEnteredProjectNewDescription("");
        onToggleProjectRenameModal(selectedProjectInfo);
    };

    const userProjectNewNameChangeHandler = (event) => {
        const newLetter = event.target.value.slice(-1);

        if (forbiddenCharactersCheck(newLetter)) {
            return;
        }

        setEnteredProjectNewName(event.target.value);
    };

    const userProjectNewDescriptionChangeHandler = (event) => {
        setEnteredProjectNewDescription(event.target.value);
    };

    const toggleProjectRenameModal = () => {
        onToggleProjectRenameModal(selectedProjectInfo);
    };

    return (
        <Fragment>
            <Modal
                id="project-rename-modal"
                className="project-add-modal"
                data-testid="project-rename-modal"
                modalHeading={`Rename Project "${selectedProjectInfo.name}"`}
                open={projectRenameModalShow}
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"
                hasScrollingContent={false}
                iconDescription="Close"
                size="sm"
                onRequestSubmit={submitProjectRename}
                onRequestClose={toggleProjectRenameModal}
            >
                <TextInput
                    data-modal-primary-focus
                    id="text-input-3"
                    data-testid="text-input-3"
                    labelText="New Project Name"
                    placeholder="e.g. New name"
                    onChange={userProjectNewNameChangeHandler}
                    value={enteredProjectNewName}
                    style={{ marginBottom: "1rem" }}
                />
                <TextArea
                    data-modal-primary-focus
                    id="text-input-4"
                    data-testid="text-input-4"
                    labelText="New Project Description"
                    cols={20}
                    placeholder="e.g. New Description"
                    onChange={userProjectNewDescriptionChangeHandler}
                    value={enteredProjectNewDescription}
                    style={{ marginBottom: "1rem" }}
                />
            </Modal>
        </Fragment>
    );
};

export default ProjectRenameModal;
