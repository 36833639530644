import { loadStripe } from "@stripe/stripe-js";
import { clearApiError, setAPIError } from "../../src/redux/api/api.actions";
import {
    setAuthMessage,
    setAuthToken,
    setLoginError,
    setProgressToNextStep,
    setQRCode,
    setQRCodeUsername,
    setTokenType,
} from "../../src/redux/auth/auth.actions";
import {
    awsCognitoConfirmRegistration,
    awsCognitoLogOut,
    awsCognitoRegister,
    awsCognitoResendConfirmationCode,
    getCurrentUserAction,
    stripeCreateCheckoutSessionAction,
    verifyUserAttributeAction,
} from "../../src/redux/thunks/thunks.actions";
import {
    awsCognitoSetPreferredMFA,
    awsCognitoSetupTOTP,
    awsCurrentAuthenticatedUser,
    getCurrentUser,
    updateCurrentUser,
} from "../api/index.js";

export const getHeaders = (state) => {
    let headers = {
        Authorization: `${state.auth.tokenType} ${state.auth.authToken}`,
    };
    return headers;
};

export const getRedirectionDetails = (projectId, fileId) => {
    const redirectionDetails = {
        projectId: projectId,
        fileId: fileId,
    };

    return redirectionDetails;
};

export const cleanCheckProjectName = (projectsInfo, projectName) => {
    if (projectName !== projectName.trim()) {
        projectName = projectName.trim();
    }

    for (let project of projectsInfo) {
        if (project.name === projectName) {
            alert(
                `The project name ${projectName} is already in use. Please pick a different name`
            );
            return null;
        }
    }

    return projectName;
};

export const forbiddenCharactersCheck = (newLetter) => {
    const forbiddenCharacters = ["<", ">", ":", `"`, "/", "\\", "|", "?", "*"];

    if (forbiddenCharacters.includes(newLetter)) {
        alert(
            `A project name cannot contain any of the following characters:\n ${forbiddenCharacters.join(
                ",   "
            )}`
        );
        return true;
    }
    return false;
};

export const getDownloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.className = fileName;
    // filename based on the excelType
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};

/**
 * Transform grid data from backend style to Grids component style
 * @param {*} gridInfos
 * @param {*} pdfWidth
 * @param {*} pdfHeight
 * @returns
 */
export const gridInfoConversion = (gridInfos, pdfWidth, pdfHeight) => {
    const pageGrids = [];

    for (let gridInfo of gridInfos) {
        const vLines = [];
        const hLines = [];
        const rect = {
            x: gridInfo.x1 * pdfWidth,
            y: gridInfo.y1 * pdfHeight,
            width: (gridInfo.x2 - gridInfo.x1) * pdfWidth,
            height: (gridInfo.y2 - gridInfo.y1) * pdfHeight,
        };
        gridInfo.column_boundaries.forEach((columnX) =>
            vLines.push({ x: columnX * pdfWidth })
        );
        gridInfo.row_boundaries.forEach((rowY) =>
            hLines.push({ y: rowY * pdfHeight })
        );
        let pageGrid = {
            rect: rect,
            vLines: vLines,
            hLines: hLines,
            x1: gridInfo.x1,
            x2: gridInfo.x2,
            y1: gridInfo.y1,
            y2: gridInfo.y2,
            processing_status: gridInfo.processing_status,
            page_number: gridInfo.page_number,
        };

        if (gridInfo.hasOwnProperty("processing_status")) {
            if (gridInfo["processing_status"][0] !== "Successful") {
                pageGrid["warning"] = {
                    colourWarning: false,
                    message: gridInfo["processing_status"],
                };
            }
        }

        pageGrids.push(pageGrid);
    }

    return pageGrids;
};

/**
 * Transform grid data from Grids component style to backend style
 * @param {*} gridInfos
 * @param {*} pdfWidth
 * @param {*} pdfHeight
 * @param {*} imageId
 * @returns
 */
export const gridInfoUnConvert = (
    gridInfos,
    pdfWidth,
    pdfHeight,
    imageId,
    undo
) => {
    const pageGrids = [];
    for (let gridInfo of gridInfos) {
        if (undo) {
            let table = {
                x1: gridInfo.rect.x / pdfWidth,
                x2: (gridInfo.rect.x + gridInfo.rect.width) / pdfWidth,
                y1: gridInfo.rect.y / pdfHeight,
                y2: (gridInfo.rect.y + gridInfo.rect.height) / pdfHeight,
                column_boundaries: gridInfo.vLines.map((vLine) => {
                    return vLine.x / pdfWidth;
                }),
                column_count: gridInfo.vLines.length + 1,
                row_boundaries: gridInfo.hLines.map((hLine) => {
                    return hLine.y / pdfHeight;
                }),
                row_count: gridInfo.hLines.length + 1,
                processing_status: gridInfo.processing_status,
                id: `${gridInfo.rect.id}`,
                page_number: gridInfo.page_number,
            };
            pageGrids.push(table);
        } else {
            let table = {
                x1: gridInfo.rect.x / pdfWidth,
                x2: (gridInfo.rect.x + gridInfo.rect.width) / pdfWidth,
                y1: gridInfo.rect.y / pdfHeight,
                y2: (gridInfo.rect.y + gridInfo.rect.height) / pdfHeight,
                columns: gridInfo.vLines.map((vLine) => {
                    return vLine.x / pdfWidth;
                }),
                rows: gridInfo.hLines.map((hLine) => {
                    return hLine.y / pdfHeight;
                }),
                id: `${gridInfo.rect.id}`,
                processing_status: gridInfo.processing_status,
                page_number: gridInfo.page_number,
            };
            pageGrids.push(table);
        }
    }
    return pageGrids;
};

/**
    * Handles the keyboard events for the zoomIn, zoomOut and zoomReset functionality
    * The default event (i.e. browser zoom in has to be blocked)
    * Ctrl + handles zooming in: 107 is the keyCode for the numpad plus and 187 is
    keyCode for the keyboard minus
    * Ctrl - handles zooming out: 109 is the keyCode for the numpad minus and 189 is
    keyCode for the keyboard minus
    * Ctrl + Enter - handles reset zoom
    * Ctrl + Z - handles undo
*/
export const genericOnKeyDownHandler = (
    event,
    currentImage,
    zoomInFunction,
    zoomOutFunction,
    resetTransformFunction,
    selectedObject,
    deleteLineFunction,
    deleteRectFunction,
    handleUndo
) => {
    if (event.ctrlKey && (event.keyCode === 107 || event.keyCode === 187)) {
        event.preventDefault();
        if (currentImage !== null) {
            if (zoomInFunction !== null) {
                zoomInFunction();
            }
        }
    } else if (
        event.ctrlKey &&
        (event.keyCode === 109 || event.keyCode === 189)
    ) {
        event.preventDefault();
        if (currentImage !== null) {
            if (zoomOutFunction !== null) {
                zoomOutFunction();
            }
        }
    } else if (event.ctrlKey && event.keyCode === 13) {
        event.preventDefault();
        if (currentImage !== null) {
            if (resetTransformFunction !== null) {
                resetTransformFunction();
            }
        }
    } else if (event.ctrlKey && event.keyCode === 90) {
        event.preventDefault();
        handleUndo();
    } else if (event.keyCode === 46) {
        deleteSelectedObject(
            selectedObject,
            deleteLineFunction,
            deleteRectFunction
        );
    }
};

export const handleApiError = (error, dispatch, isLoginEndpoint) => {
    let errorMessage;
    if (error.hasOwnProperty("response")) {
        let response = error.response;
        let errorStatus;
        let errorDetail;
        if (response !== undefined && response.hasOwnProperty("status")) {
            errorStatus = error.response.status;
        }

        if (response !== undefined) {
            errorDetail = error.response.data.detail;
        }

        if (typeof errorDetail === "object") {
            errorDetail = errorDetail.detail;
        }

        if (errorStatus === 402) {
            if (errorDetail) {
                let subscriptionType =
                    error.response.data.detail.subscription_type;

                switch (errorDetail) {
                    case "Renew Subscription":
                        dispatch(
                            stripeCreateCheckoutSessionAction(subscriptionType)
                        );
                        break;
                    default:
                        dispatch(setAPIError(errorDetail));
                        break;
                }
            }
        } else if (errorStatus === 409) {
            if (errorDetail) {
                dispatch(setAPIError(errorDetail));
            }
        } else if (errorStatus === 412) {
            if (errorDetail) {
                dispatch(setAPIError(errorDetail));
            }
        } else {
            if (isLoginEndpoint) {
                if (errorStatus === 401) {
                    errorMessage = "*Incorrect username or password";
                    dispatch(setLoginError(errorMessage));
                } else {
                    dispatch(setLoginError(errorDetail));
                }
            } else {
                if (errorStatus === 401) {
                    errorMessage =
                        "You have signed out";
                    dispatch(setLoginError(errorMessage));
                    logOutFunction(dispatch);
                } else {
                    dispatch(setAPIError(errorDetail));
                }
            }
        }
    } else {
        let errorMessage = error.message;
        if (isLoginEndpoint) {
            switch (errorMessage) {
                case "User cannot be confirmed. Current status is CONFIRMED":
                    let newMessage =
                        "You have already confirmed your account. You can now sign in.";
                    dispatch(setAuthMessage(newMessage));
                    break;
                case "Password cannot be empty":
                    break;
                case "Cannot read properties of undefined (reading 'then')":
                    break;
                default:
                    dispatch(setLoginError(errorMessage));
            }
        } else {
            dispatch(setAPIError(errorMessage));
        }
    }
};

export const scrollIntoViewHelper = (importedRefImageCaller, imageRef) => {
    importedRefImageCaller(imageRef).scrollIntoView();
};

/**
 * Transforms user generated spec into actual spec
 */
export const columnSpecToType = (columnSpec) => {
    const columnTypes = [
        "Date",
        "TransactionCode",
        "Description",
        "Credit",
        "Debit",
        "Amount",
        "Balance",
        "NKF",
        "Unselected",
    ];

    const columnOptions = [
        "Date",
        "Transaction Code",
        "Description",
        "Credit",
        "Debit",
        "Combined Credit/Debit",
        "Balance",
        "Other",
        "Unselected",
    ];

    let columnType = [];
    for (let column of columnSpec) {
        const typeLocation = columnOptions.findIndex(
            (value) => value === column["type"]
        );
        columnType.push(columnTypes[typeLocation]);
    }
    return columnType;
};

export const determineBorderClass = (finishedValue, openValue, setFunction) => {
    if (finishedValue) {
        setFunction("finished-border");
    } else {
        if (openValue) {
            setFunction("active-border");
        } else {
            setFunction("inactive-border");
        }
    }
};

export const qrCodeReset = (dispatch, cognitoUser, headers) => {
    dispatch(setQRCode(null));
    dispatch(setQRCodeUsername(null));
    setPreferredMFAFlow(dispatch, cognitoUser, "TOTP", headers);
    updateCurrentUser(headers, "is_mfa_setup", true);
};

export const checkIfUserAttributeHasBeenVerified = (attribute, cognitoUser) => {
    let attributeVerifiedString = `${attribute}_verified`;
    let attributeVerified = cognitoUser.attributes[attributeVerifiedString];

    if (attributeVerified) {
        return true;
    } else {
        return false;
    }
};

export const setMFAPreference = (dispatch, attribute, state) => {
    let isLoginEndpoint = false;
    const headers = getHeaders(state);

    awsCurrentAuthenticatedUser()
        .then((res) => {
            let cognitoUser = res;
            let attributeVerified = checkIfUserAttributeHasBeenVerified(
                attribute,
                cognitoUser
            );
            let responseAttribute = cognitoUser.attributes[attribute];

            // check if attribute exists i.e. phone number
            if (responseAttribute) {
                if (attributeVerified) {
                    if (attribute === "phone_number") {
                        setPreferredMFAFlow(
                            dispatch,
                            cognitoUser,
                            "SMS",
                            headers
                        );
                    }
                } else {
                    phoneNumberNotVerifiedFlow(dispatch, cognitoUser, attribute);
                }
            } else {
                dispatch(setProgressToNextStep("phoneNumberRequired"));
            }
        })
        .catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
};

export const setJWTToken = (dispatch, jwtToken) => {
    dispatch(setAuthToken(jwtToken));
    dispatch(setTokenType("Bearer"));
};

export const checkTermsAndConditions = (
    dispatch,
    headers,
    jwtToken,
    isLoginEndpoint
) => {
    getCurrentUser(headers)
        .then(() => {
            checkTermsAndConditionsAccepted(dispatch, jwtToken, isLoginEndpoint);
        })
        .catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
};

export const checkIsMFASetUp = (
    dispatch,
    headers,
    jwtToken,
    user,
    isLoginEndpoint
) => {
    getCurrentUser(headers)
        .then(() => {
            checkTermsAndConditionsAccepted(dispatch, jwtToken, isLoginEndpoint);
        })
        .catch((error) => {
            let errorStatus = error.response.status;
            if (errorStatus === 428) {
                if (error.response.hasOwnProperty("data")) {
                    let errorDetail = error.response.data.detail;
                    switch (errorDetail) {
                        case "MFA_SETUP_REQUIRED":
                            setUpMFAApp(dispatch, isLoginEndpoint, user);
                            break;
                        default:
                            break;
                    }
                }
            }
        });
};

export const disableMFA = (state, dispatch) => {
    const headers = getHeaders(state);

    awsCurrentAuthenticatedUser().then((response) => {
        const cognitoUser = response;
        setPreferredMFAFlow(dispatch, cognitoUser, "NOMFA", headers);
    });
};

export const checkTermsAndConditionsAccepted = (
    dispatch,
    jwtToken,
    isLoginEndpoint
) => {
    awsCurrentAuthenticatedUser()
        .then((response) => {
            let termsAndConditionsAccepted =
                response.attributes["custom:acceptedTCs"];
            if (termsAndConditionsAccepted === "False") {
                dispatch(setProgressToNextStep("showTermsAndConditions"));
            } else {
                setJWTToken(dispatch, jwtToken);
            }
        })
        .catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
};

const getStripe = () => {
    let stripePromise;
    if (!stripePromise) {
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    }
    return stripePromise;
};

export const redirectToCheckout = async (stripeSessionId) => {
    const stripe = await getStripe();
    const { response } = await stripe.redirectToCheckout({
        sessionId: stripeSessionId,
    });

    return response;
};

export const redirectToAccount = async (portalURL) => {
    window.location = portalURL;
};

export const setUpMFAApp = async (dispatch, isLoginEndpoint, user) => {
    awsCurrentAuthenticatedUser()
        .then((res) => {
            let cognitoUser = res;

            awsCognitoSetupTOTP(cognitoUser)
                .then((code) => {
                    if (isLoginEndpoint) {
                        dispatch(setProgressToNextStep("mfaSetup"));
                    }
                    dispatch(setQRCode(code));
                    dispatch(setQRCodeUsername(user));
                })
                .catch((error) => {
                    handleApiError(error, dispatch, isLoginEndpoint);
                });
        })
        .catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
};

export const checkSubscriptionPayment = (
    dispatch,
    headers,
    jwtToken,
    user,
    isLoginEndpoint
) => {
    getCurrentUser(headers)
        .then(() => {
            checkTermsAndConditionsAccepted(dispatch, jwtToken, isLoginEndpoint);
        })
        .catch((error) => {
            let errorStatus = error.response.status;
            if (errorStatus === 402) {
                awsCurrentAuthenticatedUser()
                    .then((res) => {
                        let jwtToken =
                            res.signInUserSession.accessToken.jwtToken;
                        const newHeaders = {
                            Authorization: `Bearer ${jwtToken}`,
                        };

                        dispatch(
                            stripeCreateCheckoutSessionAction(
                                newHeaders,
                                jwtToken
                            )
                        );
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            }
        });
};

export const addTimeToDate = (
    date,
    lengthOfTime,
    dateTarget,
    typeOfOperation
) => {
    let yearFromDate = date.getFullYear();
    let monthFromYear = date.getMonth();
    let dayFromYear = date.getDate();
    let newDate;
    if (dateTarget === "month") {
        if (typeOfOperation === "addition") {
            newDate = new Date(
                yearFromDate,
                monthFromYear + lengthOfTime,
                dayFromYear
            );
        } else if (typeOfOperation === "subtraction") {
            newDate = new Date(
                yearFromDate,
                monthFromYear - lengthOfTime,
                dayFromYear
            );
        }
    } else if (dateTarget === "year") {
        if (typeOfOperation === "addition") {
            newDate = new Date(
                yearFromDate,
                monthFromYear,
                dayFromYear + lengthOfTime
            );
        } else if (typeOfOperation === "subtraction") {
            newDate = new Date(
                yearFromDate,
                monthFromYear,
                dayFromYear - lengthOfTime
            );
        }
    }
    return newDate;
};

export const logOutFunction = (dispatch) => {
    dispatch(awsCognitoLogOut());
    dispatch(setProgressToNextStep(null));
};

export const handleUserInfo = (dispatch, userData) => {
    if (userData !== null) {
        dispatch(clearApiError());

        let subscriptionPaidDate = userData.subscription_paid_for_date;
        let subscriptionType = userData.subscription_type;
        let subscription = userData.subscription;
        let subscriptionStatus = userData.subscription_status;
        let nextPaymentDueDate;
        let isSubscriptionPaymentDue;

        if (subscriptionPaidDate === null) {
            let currentDate = new Date();
            if (subscriptionType === "monthly") {
                nextPaymentDueDate = addTimeToDate(
                    currentDate,
                    1,
                    "month",
                    "addition"
                );
            } else if (subscriptionType === "yearly") {
                nextPaymentDueDate = addTimeToDate(
                    currentDate,
                    1,
                    "year",
                    "subtraction"
                );
            }

            if (currentDate < nextPaymentDueDate) {
                isSubscriptionPaymentDue = false;
            } else {
                isSubscriptionPaymentDue = true;
            }
        }

        if (
            subscription === "business" &&
            (subscriptionPaidDate === null || isSubscriptionPaymentDue)
        ) {
            dispatch(stripeCreateCheckoutSessionAction(subscriptionType));
        } else if (
            subscription === "business" &&
            subscriptionStatus === "cancelled"
        ) {
            let message = "You have cancelled your subscription.";
            dispatch(setAPIError(message));
        } else if (
            subscription === "business" &&
            subscriptionStatus === "active"
        ) {
            dispatch(clearApiError());
        }
    }
};

export const capitaliseFirstLetter = (string) => {
    let newCapitalisedString = `${string.charAt(0).toUpperCase()}${string.slice(
        1
    )}`;
    return newCapitalisedString;
};

export const goToHomePage = (navigate, projectId) => {
    navigate({
        pathname: "/",
        search: `?projectId=${projectId}`,
    });
};

export const sortAlphabeticallyAscending = (object, objectType) => {
    let objectCopy = JSON.parse(JSON.stringify(object));
    let sortedObject;

    if (objectType === "files") {
        sortedObject = objectCopy.sort((a, b) =>
            a.file.name.localeCompare(b.file.name)
        );
    } else if (objectType === "file_tiles") {
        sortedObject = objectCopy.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    } else if (objectType === "projects") {
        sortedObject = objectCopy.sort((a, b) => a.name.localeCompare(b.name));
    }

    return sortedObject;
};

export const sortAlphabeticallyDescending = (object, objectType) => {
    let objectCopy = JSON.parse(JSON.stringify(object));
    let sortedObject;

    if (objectType === "files") {
        sortedObject = objectCopy.sort((a, b) =>
            b.file.name.localeCompare(a.file.name)
        );
    } else if (objectType === "file_tiles") {
        sortedObject = objectCopy.sort((a, b) =>
            b.name.localeCompare(a.name)
        );
    } else if (objectType === "projects") {
        sortedObject = objectCopy.sort((a, b) => b.name.localeCompare(a.name));
    }

    return sortedObject;
};

export const sortDateMostRecent = (object, objectType) => {
    let objectCopy = JSON.parse(JSON.stringify(object));
    let sortedObject;

    if (objectType === "files") {
        sortedObject = objectCopy.sort((a, b) =>
            b.file.created_ts.localeCompare(a.file.created_ts)
        );
    } else if (objectType === "file_tiles") {
        sortedObject = objectCopy.sort((a, b) =>
            b.created_ts.localeCompare(a.created_ts)
        );

    } else if (objectType === "projects") {
        sortedObject = objectCopy.sort((a, b) =>
            b.created_ts.localeCompare(a.created_ts)
        );
    }

    return sortedObject;
};

export const sortDateOldest = (object, objectType) => {
    let objectCopy = JSON.parse(JSON.stringify(object));
    let sortedObject;

    if (objectType === "files") {
        sortedObject = objectCopy.sort((a, b) =>
            a.file.created_ts.localeCompare(b.file.created_ts)
        );
    } else if (objectType === "file_tiles") {
        sortedObject = objectCopy.sort((a, b) =>
            a.created_ts.localeCompare(b.created_ts)
        );
    } else if (objectType === "projects") {
        sortedObject = objectCopy.sort((a, b) =>
            a.created_ts.localeCompare(b.created_ts)
        );
    }

    return sortedObject;
};

export const phoneNumberNotVerifiedFlow = (dispatch, cognitoUser, attribute) => {
    dispatch(setAuthMessage(null));
    dispatch(setAPIError("Phone number has not been verified"));
    dispatch(setProgressToNextStep("verifyPhoneNumber"));
    dispatch(verifyUserAttributeAction(cognitoUser, attribute));
};

export const setPreferredMFAFlow = (
    dispatch,
    cognitoUser,
    mfaChoice,
    headers
) => {
    let isLoginEndpoint = false;

    awsCognitoSetPreferredMFA(cognitoUser, mfaChoice)
        .then((response) => {
            if (response === "SUCCESS") {
                if (mfaChoice !== "NOMFA") {
                    updateCurrentUser(headers, "is_mfa_setup", true);
                    updateCurrentUser(headers, "mfa_type", mfaChoice);
                    dispatch(getCurrentUserAction());
                    dispatch(
                        setAuthMessage(
                            "Multi Factor Authentication successfully set up"
                        )
                    );
                    dispatch(getCurrentUserAction());
                } else {
                    updateCurrentUser(headers, "is_mfa_setup", false);
                    updateCurrentUser(headers, "mfa_type", "");
                    dispatch(getCurrentUserAction());
                    dispatch(
                        setAuthMessage(
                            "Multi Factor Authentication successfully disabled"
                        )
                    );
                }
            } else if (response === "No change for mfa type") {
                if (mfaChoice !== "NOMFA") {
                    updateCurrentUser(headers, "is_mfa_setup", true);
                    updateCurrentUser(headers, "mfa_type", mfaChoice);
                    dispatch(getCurrentUserAction());
                    dispatch(
                        setAuthMessage(
                            "Multi Factor Authentication successfully set up"
                        )
                    );
                    dispatch(getCurrentUserAction());
                } else {
                    updateCurrentUser(headers, "is_mfa_setup", false);
                    updateCurrentUser(headers, "mfa_type", "");
                    dispatch(getCurrentUserAction());
                    dispatch(
                        setAuthMessage(
                            "Multi Factor Authentication successfully disabled"
                        )
                    );
                    dispatch(setProgressToNextStep(""));
                }
            }
        })
        .catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
};

export const checkIsDateIsValid = (date) => {
    let isDateValid = true;

    if (date instanceof Date) {
        return true
    } else if (![null, undefined].includes(date)) {
        let alphaPattern = new RegExp(/[A-Z]/g);
        let numericPattern = new RegExp(/[0-9]/g);
        let upper = date.toUpperCase();
        let dateAlphaPattern = upper.replace(alphaPattern, "A");
        let dateNumericPattern = dateAlphaPattern.replace(numericPattern, "9");

        let shortMonthDayPattern = new RegExp(
            /\b(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(\d{4}|\d{2})\b/
        );

        // day, short month
        let dayShortMonthPattern = new RegExp(
            /(\d{1,2})\s*(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)/
        );

        let anotherPattern = new RegExp(
            /([\w ]*)(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)([\w ]*)/
        );

        let anotherRandomPattern = new RegExp(
            /([\w ]*)(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)/
        );

        let canDateBeParsed = Date.parse(date);
        if (date === "nan" || date === "NAN" || date === "NaN") {
            isDateValid = true;
        } else if (
            dateNumericPattern === "99 AAA" ||
            dateNumericPattern === "9 AAA"
        ) {
            isDateValid = false;
        } else if (dateNumericPattern === "9999") {
            isDateValid = false;
        } else if (shortMonthDayPattern.test(upper)) {
            // # Short month followed by a day, no space
            isDateValid = false;
        } else if (dayShortMonthPattern.test(upper)) {
            // # day and short month no space
            isDateValid = false;
        } else if (anotherRandomPattern.test(upper)) {
            isDateValid = false;
        } else if (anotherPattern.test(upper)) {
            isDateValid = false;
        } else if (isNaN(canDateBeParsed)) {
            isDateValid = false;
        } else {
            isDateValid = true;
        }
    } else {
        isDateValid = true;
    }

    return isDateValid;
};

export const deleteSelectedObject = (
    selectedObject,
    deleteLineFunction,
    deleteRectFunction
) => {
    if (![null, undefined].includes(selectedObject)) {
        if (selectedObject["objectType"] === "rect") {
            deleteRectFunction();
        } else if (selectedObject["objectType"] === "line") {
            deleteLineFunction();
        }
    }
};

export const handleRegisterUser = (
    subscriptionType,
    paymentSchedule,
    user,
    pass,
    name,
    dispatch,
    isGuest,
    x_amzn_marketplace_token
) => {
    if (subscriptionType !== "" && paymentSchedule !== "") {
        dispatch(
            awsCognitoRegister(
                user,
                pass,
                name,
                "phoneNumber",
                subscriptionType,
                paymentSchedule,
                isGuest,
                x_amzn_marketplace_token
            )
        );
    } else if (subscriptionType !== "") {
        dispatch(
            awsCognitoRegister(
                user,
                pass,
                name,
                "phoneNumber",
                subscriptionType,
                null,
                isGuest,
                x_amzn_marketplace_token
            )
        );
    } else {
        dispatch(
            awsCognitoRegister(
                user,
                pass,
                name,
                "phoneNumber",
                null,
                null,
                isGuest,
                x_amzn_marketplace_token
            )
        );
    }
};

export const validateEmailHelper = (user, verificationCode, dispatch) => {
    dispatch(awsCognitoConfirmRegistration(user, verificationCode));
};

export const resendEmailVerificationCodeHelper = (user, dispatch) => {
    dispatch(awsCognitoResendConfirmationCode(user));
};

export const makeTableSchema = (fileTablesInit) => {
    let tableSchema = [];
    for (let page of fileTablesInit) {
        let perPageTableSchema = {
            pageNumber: page.page_number,
            mask: [
                {
                    x1: page.x1,
                    x2: page.x2,
                    y1: page.y1,
                    y2: page.y2,
                    columns: page.column_boundaries,
                    rows: page.row_boundaries,
                    id: `rect${page.page_number}`,
                    processing_status: page.processing_status,
                    maskNumber: page.page_number,
                },
            ],
            angle: 0,
        };

        tableSchema.push(perPageTableSchema);
    }

    let finalTableSchema = {
        page: tableSchema,
    };

    return finalTableSchema;
};
