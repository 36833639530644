import {
    DirectionMerge32,
    RowCollapse32,
    RowExpand32,
} from "@carbon/icons-react";
import { Button } from "carbon-components-react";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    autoMergeAutoGridReturnAll,
    autoMergeGridReturnSelectedRect,
} from "../../../../src/helpers/helper-functions-2";
import { clearAutoMerge } from "../../../redux/mask/mask.actions";
import { automergeMaskAction } from "../../../redux/thunks/thunks.actions.js";
import "./auto-merge.styles.scss";

const AutoMerge = ({
    pathname,
    selectedRect,
    selectedPageUrl,
    backEndSubmitGrids,
    clearRectFunction,
    blurButtons,
    setAutoMergeColsRows,
    setRunAutoMerge,
    setUndoAutoGrid,
    displayMergeButtons,
    setDisplayMergeButtons,
    isAutoMergeButtonDisabled,
    setIsAutoMergeButtonDisabled,
    setShowCopyButtons,
}) => {
    const dispatch = useDispatch();
    const autoMergeState = useSelector((state) => state.mask.autoMergeMasks);

    useEffect(() => {
        if (autoMergeState.length !== 0) {
            setAutoMergeColsRows(autoMergeState);
            dispatch(clearAutoMerge());
        }
    }, [autoMergeState, setAutoMergeColsRows, dispatch]);

    const autoGridApply = (mergeDirection) => {
        let pathnameSplit = pathname.split("/");
        let projectId = pathnameSplit[2];
        let fileId = pathnameSplit[3];

        var allMasks = [];
        let pages = backEndSubmitGrids.page;

        if (selectedRect === null) {
            allMasks = autoMergeAutoGridReturnAll(pages);
        } else {
            let newObjectToAppend = autoMergeGridReturnSelectedRect(
                pages,
                selectedRect
            );
            allMasks.push(newObjectToAppend);
        }

        if (selectedRect) {
            var maskData;
            backEndSubmitGrids["page"].forEach((grid) => {
                maskData = grid["mask"][0];
            });

            let allowAutoGrid = true;
            if (maskData["rows"].length > 0 || maskData["columns"].length > 0) {
                allowAutoGrid = window.confirm(
                    "All rows and columns will be removed. \nAre you sure you want to continue?"
                );
            }
            if (allowAutoGrid) {
                clearRectFunction();
                dispatch(
                    automergeMaskAction(
                        projectId,
                        fileId,
                        allMasks,
                        mergeDirection
                    )
                );
                setUndoAutoGrid(true);
                setIsAutoMergeButtonDisabled(true);
            }
        } else if (selectedPageUrl) {
            let message = "Please select a single mask, or deselect all objects before running AutoMerge";
                window.confirm(message)
        } else {
            let message =
                "No mask selected, will apply to all masks in the document";

            if (window.confirm(message)) {
                setRunAutoMerge(true);

                dispatch(
                    automergeMaskAction(
                        projectId,
                        fileId,
                        allMasks,
                        mergeDirection
                    )
                );
                setUndoAutoGrid(true);
                setIsAutoMergeButtonDisabled(true);
            }
        }

        setDisplayMergeButtons(!displayMergeButtons);
    };

    const onMergeClick = () => {
        setShowCopyButtons(false)
        setDisplayMergeButtons(!displayMergeButtons);
        blurButtons();
    };

    return (
        <div className="merge-buttons">
            <Button
                renderIcon={DirectionMerge32}
                iconDescription="Auto Merge"
                data-testid="mask-page-file-toolbar-auto-grid-button"
                id="mask-page-file-toolbar-auto-grid-button"
                className={`default-button-colour ${
                    isAutoMergeButtonDisabled ? "disabled-button" : ""
                }`}
                hasIconOnly={true}
                onClick={onMergeClick}
                disabled={isAutoMergeButtonDisabled}
            />
            {displayMergeButtons ? (
                <div className="merge-rows-buttons">
                    <Button
                        renderIcon={RowCollapse32}
                        iconDescription="Merge Up"
                        data-testid="mask-page-file-toolbar-auto-grid-button"
                        id="mask-page-file-toolbar-auto-grid-button"
                        className="default-button-colour"
                        hasIconOnly={true}
                        onClick={() => autoGridApply("up")}
                    />
                    <Button
                        renderIcon={RowExpand32}
                        iconDescription="Merge Down"
                        data-testid="mask-page-file-toolbar-auto-grid-button"
                        id="mask-page-file-toolbar-auto-grid-button"
                        className="default-button-colour"
                        hasIconOnly={true}
                        onClick={() => autoGridApply("down")}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default AutoMerge;
