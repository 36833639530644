import React, { Fragment, useState } from "react";
import { Modal, TextArea, TextInput } from "carbon-components-react";
import { addNewProject } from "../../redux/thunks/thunks.actions";
import { useDispatch } from "react-redux";
import {
    cleanCheckProjectName,
    forbiddenCharactersCheck,
} from "../../helpers/helper-functions.js";

const ProjectAddModal = ({ onToggleProjectModal, projectModalShow, allProjectsInfo }) => {
    const [enteredProjectName, setEnteredProjectName] = useState("");
    const [enteredProjectDescription, setEnteredProjectDescription] = useState("");
    const dispatch = useDispatch();

    const userProjectNameChangeHandler = (event) => {
        const newLetter = event.target.value.slice(-1);

        if (forbiddenCharactersCheck(newLetter)) {
            return;
        }

        setEnteredProjectName(event.target.value);
    };

    const userProjectDescriptionChangeHandler = (event) => {
        setEnteredProjectDescription(event.target.value);
    };

    const addProjectInput = () => {
        let projectName = enteredProjectName;

        if (projectName.trim().length === 0) {
            return;
        }

        const cleanedCheckedProjectName = cleanCheckProjectName(
            allProjectsInfo,
            projectName
        );

        if (cleanedCheckedProjectName === null) {
            return;
        }

        dispatch(addNewProject(cleanedCheckedProjectName, enteredProjectDescription));
        setEnteredProjectName("");
        setEnteredProjectDescription("");

        onToggleProjectModal();
    };

    return (
        <Fragment>
            <Modal
                id="project-add-modal"
                className="project-add-modal"
                data-testid="project-add-modal"
                modalHeading={`Create New Project`}
                open={projectModalShow}
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"
                hasScrollingContent={false}
                iconDescription="Close"
                size="sm"
                onRequestSubmit={addProjectInput}
                onRequestClose={onToggleProjectModal}
            >
                <TextInput
                    data-modal-primary-focus
                    id="text-input-1"
                    data-testid="text-input-1"
                    labelText="Project name"
                    placeholder="e.g. New Project"
                    onChange={userProjectNameChangeHandler}
                    value={enteredProjectName}
                    style={{ marginBottom: "1rem" }}
                />
                <TextArea
                    data-modal-primary-focus
                    id="text-input-2"
                    data-testid="text-input-2"
                    labelText="Project description"
                    placeholder="e.g. This is a ..."
                    cols={20}
                    onChange={userProjectDescriptionChangeHandler}
                    value={enteredProjectDescription}
                    style={{ marginBottom: "1rem" }}
                />
            </Modal>
        </Fragment>
    );
};

export default ProjectAddModal;
