import { Accordion, AccordionItem } from "carbon-components-react";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SetMfaApp from "../../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/mfa-setup/mfa-setup-app.component";
import SetMfaSMS from "../../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/mfa-setup/mfa-setup-sms.component";
import CommonRegistrationButton from "../../../../../components/registration-buttons/registration-button-common.component";
import {
    disableMFA,
    setMFAPreference,
    setUpMFAApp,
} from "../../../../../helpers/helper-functions";
import { setProgressToNextStep } from "../../../../../redux/auth/auth.actions.js";
import "../account-page-manage-account.styles.scss";

const ManageAccountSetUpMFA = ({
    userInfo,
    setIsManagePasswordOpen,
    authMessage,
    mfaChoice,
    setMfaChoice,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const state = useSelector((state) => state);
    const progressToNextStep = useSelector(
        (state) => state.auth.progressToNextStep
    );

    const [showPhoneNumberField, setShowPhoneNumberField] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showVerifyPhoneNumber, setShowVerifyPhoneNumber] = useState(false);
    const [isDisableMfaAccordionOpen, setIsDisableMfaAccordionOpen] =
        useState(false);

    const handleDisableMFAAccordionClick = () => {
        setMfaChoice("disabledMFA");
        setIsManagePasswordOpen(false);
        setIsDisableMfaAccordionOpen(!isDisableMfaAccordionOpen);
    };

    const handleDisableMFA = () => {
        let message =
            "Are you sure you want to disable multi factor authentication?";
        if (window.confirm(message)) {
            disableMFA(state, dispatch, navigate);
            setIsDisableMfaAccordionOpen(!isDisableMfaAccordionOpen);
        }
    };

    const handleSMSPreference = () => {
        setMFAPreference(dispatch, "phone_number", state);
        setMfaChoice("phone_number");
        setIsManagePasswordOpen(false);
    };

    const handleMfaAppPreference = () => {
        setMfaChoice("mfaApp");
        setUpMFAApp(dispatch, false, userInfo.email);
        setIsManagePasswordOpen(false);
    };

    useEffect(() => {
        if (location.pathname.includes("account")) {
            switch (progressToNextStep) {
                case "phoneNumberRequired":
                    setShowPhoneNumberField(true);
                    break;
                case "verifyPhoneNumber":
                    setShowVerifyPhoneNumber(true);
                    setShowPhoneNumberField(false);
                    setPhoneNumber("");
                    break;
                default:
                    break;
            }
        }
    }, [progressToNextStep, location.pathname]);

    useEffect(() => {
        switch (authMessage) {
            case "Multi Factor Authentication successfully set up":
                setShowVerifyPhoneNumber(false);
                setShowPhoneNumberField(false);
                setMfaChoice("");
                setPhoneNumber("");
                break;
            case "Multi Factor Authentication successfully disabled":
                setMfaChoice("");
                setShowVerifyPhoneNumber(false);
                setShowPhoneNumberField(false);
                setPhoneNumber("");
                break;
            default:
                break;
        }
    }, [authMessage, setMfaChoice]);

    useEffect(() => {
        dispatch(setProgressToNextStep(null));
    }, [dispatch]);

    return (
        <Fragment>
            {!userInfo.is_mfa_setup ? (
                <div className="card-style" data-testid="mfa-setup-div">
                    <div className="card-text">
                        <div
                            className="margin-bottom card-title"
                            data-testid="mfa-not-setup-text"
                        >
                            Set Up Multi Factor Authentication
                        </div>
                        <div className="whole-width">
                            <Accordion>
                                <AccordionItem
                                    title="Set Up Multi Factor Authentication using SMS"
                                    open={
                                        mfaChoice === "phone_number"
                                            ? true
                                            : false
                                    }
                                    onHeadingClick={handleSMSPreference}
                                    className="field-margin-bottom"
                                    data-testid="manage-mfa-setup-mfa-sms"
                                >
                                    <>
                                        <div className="field-margin-bottom"></div>
                                        {mfaChoice === "phone_number" ? (
                                            <SetMfaSMS
                                                showPhoneNumberField={
                                                    showPhoneNumberField
                                                }
                                                showVerifyPhoneNumber={
                                                    showVerifyPhoneNumber
                                                }
                                                mfaChoice={mfaChoice}
                                                setPhoneNumber={setPhoneNumber}
                                                phoneNumber={phoneNumber}
                                            />
                                        ) : null}
                                    </>
                                </AccordionItem>
                                <AccordionItem
                                    title="Set Up Multi Factor Authentication using MFA App"
                                    open={mfaChoice === "mfaApp" ? true : false}
                                    onHeadingClick={handleMfaAppPreference}
                                    data-testid="manage-mfa-setup-mfa-app"
                                >
                                    <>
                                        <div className="field-margin-bottom"></div>
                                        {mfaChoice === "mfaApp" ? (
                                            <SetMfaApp />
                                        ) : null}
                                    </>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="card-style" data-testid="manage-mfa-setup">
                    <div className="card-text">
                        <div
                            className="margin-bottom card-title"
                            data-testid="manage-mfa-text"
                        >
                            Manage Multi Factor Authentication
                        </div>
                        <div
                            className="join-text margin-bottom"
                            data-testid="current-mfa-text"
                        >
                            Your chosen Multi Factor Authentication method is:{" "}
                            {userInfo.mfa_type}
                        </div>
                        <div className="whole-width">
                            <Accordion>
                                <AccordionItem
                                    title="Manage Multi Factor Authentication"
                                    open={
                                        mfaChoice === "disableMFA" ? true : false
                                    }
                                    onHeadingClick={
                                        handleDisableMFAAccordionClick
                                    }
                                    data-testid="manage-mfa-accordion-item"
                                >
                                    {isDisableMfaAccordionOpen ? (
                                        <>
                                            <div
                                                className="larger-text margin-bottom"
                                                data-testid="disable-mfa-text"
                                            >
                                                Click here to disable multi
                                                factor authentication
                                            </div>
                                            <CommonRegistrationButton
                                                buttonKind={"primary"}
                                                isDisabled={false}
                                                onClickFunction={
                                                    handleDisableMFA
                                                }
                                                buttonDescription={"Disable MFA"}
                                                dataTestId={"disable-mfa"}
                                            />
                                        </>
                                    ) : null}
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ManageAccountSetUpMFA;
