import { Button } from "carbon-components-react";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteFileAction } from "../../../redux/thunks/thunks.actions";
import FileTile from "./file-tile/file-tile.component";
import {
    sortAlphabeticallyAscending,
    sortAlphabeticallyDescending,
    sortDateMostRecent,
    sortDateOldest,
} from "../../../helpers/helper-functions.js";


export const getNodeWidth = (node) => {
    return node.offsetWidth;
};

export const getNodeHeight = (node) => {
    return node.offsetHeight;
};



const FileTiles = ({
    selectedProjectInfo,
    selectedFiles,
    lastClickedFileId,
    areFilesSelected,
    pageSize,
    currentPage,
    updateCurrentPage,
    updatePageSize,
    addFileSelected,
    removeFileSelected,
    setFileModalOpen,
    isCollapsed,
    setIsCollapsed,
    setCurrentProjectId,
    setCurrentFiletId,
    displayedFiles,
    setDisplayedFiles,
    sortType
}) => {
    const dispatch = useDispatch();
    const [checkedFiles, setCheckedFiles] = useState([]);
    const [node, setNode] = useState(null);

    /**
     * Used to determine filePage size on mount
     */
    const onRefChange = useCallback((node) => {
        if (node !== null) {
            setNode(node);
        }

    }, []);

    /**
     * Sets the page sizes on mount
     */
    // const findPageSizeInit = useCallback(() => {
    //     let newPageSize =
    //         Math.floor(importedGetNodeWidth(node) / 160) *
    //         Math.floor(importedGetNodeHeight(node) / 172);

    //     if (newPageSize < 1) {
    //         updatePageSize(1);
    //     } else {
    //         updatePageSize(newPageSize);
    //     }
    // }, [node, updatePageSize]);

    /**
     * Selects which page should be shown based on the provided fileId
     * @param {int} fileId
     */
    const selectPageOfFileItemClicked = useCallback(
        (fileId) => {
            if (fileId === -1) {
                return;
            }

            let fileIndex = "";
            let index = 0;
            for (let file of selectedProjectInfo.files) {
                index++;
                if (file.file_id === fileId) {
                    fileIndex = index;
                    break;
                }
            }

            if (fileIndex !== "") {
                const filePage = Math.ceil(fileIndex / pageSize);
                updateCurrentPage(filePage);
            } else {
                updateCurrentPage(1);
            }
        },
        [selectedProjectInfo, pageSize, updateCurrentPage]
    );

    /**
     * Calculates what files should be shown on the current page
     */
    const calculateDisplayedFiles = useCallback(() => {
        let displayedFilesInfo = [];

        if (checkedFiles) {
                const currentPageFiles = checkedFiles.slice(
                pageSize * (currentPage - 1),
                pageSize * currentPage
            );

            for (let file of currentPageFiles) {
                let isFileSelected = false;
                for (let selectedFile of selectedFiles) {
                    if (selectedFile.fileId === file.file_id) {
                        isFileSelected = true;
                        break;
                    }
                }

                let startAnimation = false;
                if (lastClickedFileId === file.file_id) {
                    startAnimation = true;
                }

                displayedFilesInfo.push({
                    file: file,
                    isFileSelected: isFileSelected,
                    startAnimation: startAnimation,
                });
            }

            setDisplayedFiles(displayedFilesInfo);
        }

        
    }, [
        currentPage,
        lastClickedFileId,
        pageSize,
        selectedFiles,
        checkedFiles,
        setDisplayedFiles,
    ]);

    /**
     * Removes Failed Files
     */
    const checkFiles = useCallback(() => {
        let checkedFilesInfo = [];
        for (let file of selectedProjectInfo.files) {
            if (file.upload_status === "Failed") {
                dispatch(deleteFileAction(file["project_id"], file["file_id"]));
            }

            checkedFilesInfo.push(file);
        }

        var sortedFiles;

        if (sortType === "alphabeticallyAscending") {
            sortedFiles = sortAlphabeticallyAscending(checkedFilesInfo, "file_tiles");
            setCheckedFiles(sortedFiles)
        } else if (sortType === "alphabeticallyDescending") {
            sortedFiles = sortAlphabeticallyDescending(checkedFilesInfo, "file_tiles");
            setCheckedFiles(sortedFiles)
        } else if (sortType === "dateOldest") {
            sortedFiles = sortDateOldest(checkedFilesInfo, "file_tiles");
            setCheckedFiles(sortedFiles)
        } else if (sortType === "dateMostRecent") {
            sortedFiles = sortDateMostRecent(checkedFilesInfo, "file_tiles");
            setCheckedFiles(sortedFiles)
        } else {
            setCheckedFiles(checkedFilesInfo)
        }

    }, [dispatch, selectedProjectInfo.files, setCheckedFiles, sortType]);

    useEffect(() => {
        checkFiles();
    }, [checkFiles, sortType]);

    useEffect(() => {
        selectPageOfFileItemClicked(lastClickedFileId);
    }, [selectPageOfFileItemClicked, lastClickedFileId]);

    useEffect(() => {
        calculateDisplayedFiles();
    }, [calculateDisplayedFiles]);

    useLayoutEffect(() => {
        if (node) {
            const findPageSize = () => {
                updateCurrentPage(1);
                // findPageSizeInit();
            };
            // findPageSizeInit();

            window.addEventListener("resize", findPageSize);
            return () => {
                window.removeEventListener("resize", findPageSize);
            };
        }
    }, [node, updateCurrentPage]);

    return (
        <div
            data-testid="file-tiles-container"
            className={`file-tiles-container ${
                isCollapsed
                    ? "file-files-container-sidebar-collapsed"
                    : "file-files-container-sidebar-expanded"
            }`}
            ref={onRefChange}
        >
            {displayedFiles.length !== 0 ? (
                displayedFiles.map((fileInfo) => (
                    <div
                        key={`file-${fileInfo.file.file_id}`}
                        id={`file-tile-${fileInfo.file.file_id}`}
                        data-testid={`file-tile-element-${fileInfo.file.file_id}`}
                    >
                        <FileTile
                            file={fileInfo.file}
                            isFileSelected={fileInfo.isFileSelected}
                            areFilesSelected={areFilesSelected}
                            startAnimation={fileInfo.startAnimation}
                            handleAddFileSelected={addFileSelected}
                            handleRemoveFileSelected={removeFileSelected}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={setIsCollapsed}
                            setCurrentProjectId={setCurrentProjectId}
                            setCurrentFiletId={setCurrentFiletId}
                        />
                    </div>
                ))
            ) : (
                <div className="no-file">
                    You don't have any files yet.
                    <Button
                        className="add-file-link"
                        kind="ghost"
                        data-testid="no-file-create-project-link-button-tile"
                        id="no-file-create-project-link-button-tile"
                        onClick={() => setFileModalOpen(true)}
                    >
                        {" "}
                        Add one.
                    </Button>
                </div>
            )}
        </div>
    );
};

export default FileTiles;
