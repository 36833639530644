import { Edit16 } from "@carbon/icons-react";
import {
    Button,
    Column,
    Loading,
    Tile,
    TooltipDefinition,
} from "carbon-components-react";
import React, { useCallback, useEffect, useState } from "react";
import { removeExtension } from "../../../../helpers/helper-functions-2.js";
import Selector from "../../../selector/selector.component";
import DownloadButton from "./file-tile.download-button";
import GridResultsButton from "./file-tile.grid-results-button";
import StatusNotificationContainer from "./file-tile.status-notification-container";

const FileTile = ({
    file,
    isFileSelected,
    areFilesSelected,
    startAnimation,
    handleAddFileSelected,
    handleRemoveFileSelected,
    isCollapsed,
    setIsCollapsed,
    setCurrentProjectId,
    setCurrentFiletId,
}) => {
    const [showNotification, setShowNotification] = useState(false);

    const [playAnimation, setPlayAnimation] = useState(false);

    /**
     * If the notification is visible the tile will be given a higher
     * zIndex to avoid other elements blocking it
     * @param {Boolean} show
     */
    const raiseZIndex = (show) => {
        setShowNotification(show);
    };

    /**
     * Triggers the animation and then removes the animation once completed
     */
    const triggerAnimation = useCallback(() => {
        if (startAnimation) {
            setPlayAnimation(true);
            setTimeout(() => {
                setPlayAnimation(false);
            }, [2500]);
        }
    }, [startAnimation]);

    const fileClick = () => {
        if (isFileSelected) {
            handleRemoveFileSelected({
                fileName: file["name"],
                fileId: file["file_id"],
            });
        } else {
            handleAddFileSelected({
                fileName: file["name"],
                fileId: file["file_id"],
            });
        }
    };

    const ediFileClickHandler = () => {
        setIsCollapsed(!isCollapsed);
        setCurrentFiletId(file["file_id"]);
        setCurrentProjectId(file["project_id"]);
    };

    useEffect(() => {
        triggerAnimation();
    }, [startAnimation, triggerAnimation]);

    const loadingStatuses = ["Uploading", "ReLoading", "Failed", "Categorising"];
    const completedStatuses = [
        "Processed",
        "ReProcessed",
        "ReFailed",
        "GridSaved",
        "Categorised",
    ];

    const fileNameNoExtension = removeExtension(file["name"]);

    return (
        <Column className="tile-holder">
            <div
                id={`file-glow-${file["file_id"]}`}
                data-testid={`file-glow-${file["file_id"]}`}
                className={`
                    ${isFileSelected ? "item-expand" : "hover-grow"}
                    ${showNotification ? "higher-z-index" : ""}
                    ${playAnimation ? "fade-select-out" : "fade-unselected-out"}
                    file-size
                `}
                style={{ position: "absolute" }}
            />
            <div
                className={`
                    ${isFileSelected ? "item-expand" : "hover-grow"}
                    ${showNotification ? "higher-z-index" : ""}
                    file-size
                `}
            >
                <Selector
                    id={file["file_id"]}
                    componentSpecificBoolean={isFileSelected}
                    generalBoolean={areFilesSelected}
                    handleClick={fileClick}
                />
                <Button
                    id={`edit-file-tile-button-${file["file_id"]}`}
                    data-testid={`edit-file-tile-button-${file["file_id"]}`}
                    renderIcon={Edit16}
                    className="icon-button-dashboard no-border top-right edit-button circle-unselected"
                    iconDescription="Edit File"
                    hasIconOnly
                    onClick={ediFileClickHandler}
                />
                <Tile
                    className="grey-tile-file"
                    data-testid={`file-tile-${fileNameNoExtension}-${file["upload_status"]}`}
                >
                    {loadingStatuses.includes(file["upload_status"]) ? (
                        <Loading
                            className="loading-size"
                            description="Active loading indicator"
                            withOverlay={true}
                            id={`tile-overlay-${file["file_id"]}`}
                            data-testid={`tile-overlay-${file["file_id"]}`}
                        />
                    ) : null}
                    <div
                        className={`${
                            loadingStatuses.includes(file["upload_status"])
                                ? "invisible"
                                : ""
                        }`}
                    >
                        <img
                            className="file-thumbnail"
                            src={file["preview_link"]}
                            alt="file thumbnail"
                        />
                    </div>
                    <TooltipDefinition
                        align="center"
                        direction="top"
                        tooltipText={fileNameNoExtension}
                        data-testid={`file-tile-tooltip-${fileNameNoExtension}`}
                    >
                        <p
                            slot="tooltip"
                            className="project-title smaller overflow-text"
                        >
                            {fileNameNoExtension}
                        </p>
                    </TooltipDefinition>
                    {completedStatuses.includes(file["upload_status"]) ? (
                        <div
                            data-testid={`buttons-div-${file["file_id"]}`}
                            className="tile-buttons"
                        >
                            <StatusNotificationContainer
                                fileNotes={file["notes"]}
                                fileWarnings={file["warnings"]}
                                fileValidationErrors={file["validation_errors"]}
                                fileState={file["upload_status"]}
                                fileId={file["file_id"]}
                                projectId={file["project_id"]}
                                raiseZIndex={raiseZIndex}
                            />
                            <GridResultsButton
                                fileId={file["file_id"]}
                                projectId={file["project_id"]}
                                fileName={file["name"]}
                            />
                            <DownloadButton
                                fileId={file["file_id"]}
                                projectId={file["project_id"]}
                                fileName={file["name"]}
                            />
                        </div>
                    ) : null}
                </Tile>
            </div>
        </Column>
    );
};

export default FileTile;
