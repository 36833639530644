import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderHelperPagesComponent from "../../components/header/header-helper-pages.component";
import InlineNotificationError from "../../components/inline-notification/inline-notification-error.component";
import InputFieldEmail from "../../components/input-fields/input-fields-email.component";
import InputFieldPassword from "../../components/input-fields/input-fields-password.component";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import CommonRegistrationButton from "../../components/registration-buttons/registration-button-common.component";
import { setAuthMessage, setLoginError } from "../../redux/auth/auth.actions";
import {
    awsCognitoForgotPasswordAction,
    awsCognitoForgotPasswordSubmitAction,
} from "../../redux/thunks/thunks.actions";
import "./forgot-password.styles.scss";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginError = useSelector((state) => state.auth.loginError);
    const authMessage = useSelector((state) => state.auth.authMessage);

    const [user, setUser] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [emailConforms, setEmailConforms] = useState(false);

    const [newPasswordConforms, setNewPasswordConforms] = useState(false);
    const [displayPasswordRequirements, setDisplayPasswordRequirements] =
        useState(false);

    const [verifyEmail, setVerifyEmail] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(true);

    const handleSubmitNewPassword = () => {
        dispatch(
            awsCognitoForgotPasswordSubmitAction(
                user,
                verificationCode,
                newPassword
            )
        );
    };

    const handleRetrieveForgottenPassword = () => {
        dispatch(awsCognitoForgotPasswordAction(user));
        setVerifyEmail(true);
    };

    const resendVerificationCode = () => {
        handleRetrieveForgottenPassword();
        setVerificationCode("");
        setNewPassword("");
    };

    useEffect(() => {
        switch (authMessage) {
            case "Password successfully changed. You can now sign in.":
                navigate("/login");
                break;
            default:
                break;
        }
    }, [authMessage, navigate]);

    useEffect(() => {
        if (loginError) {
            setTimeout(() => {
                dispatch(setLoginError(null));
            }, 5000);
        }
        if (authMessage) {
            setTimeout(() => {
                dispatch(setAuthMessage(null));
            }, 5000);
        }
    }, [loginError, authMessage, dispatch]);

    return (
        <div className="background" data-testid="forgot-password-page">
            <HeaderHelperPagesComponent />
            <div className="forgot-password-div">
                <div className="field-margin-bottom">
                    <div className="forgot-password-title field-margin-bottom">
                        Having trouble logging in?
                    </div>
                    <div>Enter your email to reset your password.</div>
                </div>
                {loginError ? (
                    <div className="error-div" data-testid="error-div">
                        <InlineNotificationError
                            loginError={loginError}
                            hideCloseButton={true}
                            errorType="login-error"
                            pageLocation="forgot-password"
                        />
                    </div>
                ) : null}
                <div className="field-margin-bottom">
                    <InputFieldEmail
                        setUser={setUser}
                        user={user}
                        setEmailConforms={setEmailConforms}
                        emailConforms={emailConforms}
                        disabledField={verifyEmail ? true : false}
                    />
                </div>
                {verifyEmail ? null : (
                    <CommonRegistrationButton
                        buttonKind="primary"
                        isDisabled={emailConforms ? false : true}
                        onClickFunction={handleRetrieveForgottenPassword}
                        buttonDescription="Next"
                        dataTestId="resend-email-verification-code"
                    />
                )}
                {verifyEmail ? (
                    <>
                        <div
                            className="field-margin-bottom"
                            data-testid="verification-code-text"
                        >
                            We sent a 6-digit verification code to {user}. This
                            code is valid for 30 minutes.
                        </div>
                        <div className="field-margin-bottom">
                            <InputFieldVerificationCode
                                setVerificationCode={setVerificationCode}
                                verificationCode={verificationCode}
                                setVerificationCodeConforms={
                                    setVerificationCodeConforms
                                }
                                verificationCodeConforms={
                                    verificationCodeConforms
                                }
                                labelText="Verify email code"
                            />
                        </div>
                        <div className="field-margin-bottom">
                            <InputFieldPassword
                                setPass={setNewPassword}
                                pass={newPassword}
                                setPasswordConforms={setNewPasswordConforms}
                                passwordConforms={newPasswordConforms}
                                setDisplayPasswordRequirements={
                                    setDisplayPasswordRequirements
                                }
                                displayPasswordRequirements={
                                    displayPasswordRequirements
                                }
                                user={user}
                                emailConforms={true}
                                stopOnFocusEvent={false}
                                id={"forgot-password-page"}
                            />
                        </div>
                        <CommonRegistrationButton
                            buttonKind="primary"
                            isDisabled={
                                newPasswordConforms && verificationCodeConforms
                                    ? false
                                    : true
                            }
                            onClickFunction={handleSubmitNewPassword}
                            buttonDescription="Verify"
                            dataTestId="submit-password-verification-code"
                        />
                        <CommonRegistrationButton
                            buttonKind="ghost"
                            isDisabled={false}
                            onClickFunction={resendVerificationCode}
                            buttonDescription="Resend Code"
                            dataTestId="resend-retrieve-password-verification-code"
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
};

ForgotPasswordPage.propTypes = {};

export default ForgotPasswordPage;
