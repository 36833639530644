import { DonutChart } from "@carbon/charts-react";
import "@carbon/charts/styles.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../account-page-dashboard.styles.scss";
import "./account-page-mi-dashboard.styles.scss";

import "@carbon/charts/styles.css";

const MIDashboard = ({ userInfo }) => {
    const [numberOfPagesUsed, setNumberOfPagesUsed] = useState(0);
    const [numberOfPagesRemaining, setNumberOfPagesRemaining] = useState(0);
    const [projectCount, setProjectCount] = useState(0);
    const [documentCount, setDocumentCount] = useState(0);
    const [userBelongsToOrganisation, setUserBelongsToOrganisation] =
        useState(false);
    const [organisationNumberOfPagesUsed, setOrganisationNumberOfPagesUsed] =
        useState(0);

    const [organisationNumberOfPageLimit, setOrganisationNumberOfPageLimit] =
        useState(0);

    const [
        numberOfPagesUsedByTheOrganisationExcludingCurrentUser,
        setNumberOfPagesUsedByTheOrganisationExcludingCurrentUser,
    ] = useState(0);

    const [
        numberOfPagesUsedByTheOrganisation,
        setNumberOfPagesUsedByTheOrganisation,
    ] = useState(0);

    const projects = useSelector((state) => state.projects.projects);

    useEffect(() => {
        if (projects !== null) {
            setProjectCount(projects.length);
        }
    }, [projects]);

    useEffect(() => {
        if (userInfo !== null) {
            if (
                "organisation_info" in userInfo &&
                userInfo.organisation_info !== null
            ) {
                setUserBelongsToOrganisation(true);
                setOrganisationNumberOfPagesUsed(
                    userInfo.organisation_info.organisation_current_page_count
                );
                setOrganisationNumberOfPageLimit(
                    userInfo.organisation_info.organisation_page_limit
                );
            }
        }
    }, [userInfo]);

    useEffect(() => {
        if (userInfo !== null) {
            if (userBelongsToOrganisation) {
                setNumberOfPagesRemaining(
                    organisationNumberOfPageLimit - organisationNumberOfPagesUsed
                );

                setNumberOfPagesUsedByTheOrganisationExcludingCurrentUser(
                    organisationNumberOfPagesUsed - userInfo.page_count
                );

                setNumberOfPagesUsedByTheOrganisation(
                    organisationNumberOfPagesUsed
                );
            } else {
                setNumberOfPagesRemaining(
                    userInfo.page_limit - userInfo.page_count
                );
            }
            setNumberOfPagesUsed(userInfo.page_count);
            setDocumentCount(userInfo.file_count);
        }
    }, [
        userInfo,
        organisationNumberOfPageLimit,
        organisationNumberOfPagesUsed,
        userBelongsToOrganisation,
    ]);

    let state = {
        data: [
            {
                group: "Pages remaining",
                value: numberOfPagesRemaining,
            },
            {
                group: "Pages used",
                value: numberOfPagesUsed,
            },
        ],
        options: {
            donut: {
                center: {
                    label: "Pages",
                },
            },
            height: "400px",
            color: {
                scale: {
                    "Pages used": "#8d8d8d",
                    "Pages remaining": "#ed8045",
                },
            },
            legend: {
                truncation: { numCharacter: 4000 },
            },
            tooltip: {
                truncation: { numCharacter: 4000 },
            },
        },
    };

    let organisationState = {
        data: [
            {
                group: "Pages remaining",
                value: numberOfPagesRemaining,
            },
            {
                group: "Pages used by user",
                value: numberOfPagesUsed,
            },
            {
                group: "Pages used by rest of organisation",
                value: numberOfPagesUsedByTheOrganisationExcludingCurrentUser,
                // value: numberOfPagesUsedByTheOrganisation,
            },
        ],
        options: {
            donut: {
                center: {
                    label: "Pages",
                },
            },
            height: "400px",
            color: {
                scale: {
                    "Pages used by user": "#8d8d8d",
                    "Pages remaining": "#ed8045",
                    "Pages used by rest of organisation": "#2E3191",
                },
            },
            legend: {
                truncation: { numCharacter: 4000 },
            },
            tooltip: {
                truncation: { numCharacter: 4000 },
            },
        },
    };

    return (
        <div className="row-page" data-testid="account-page-mi-dashboard">
            <div
                className="tile-title"
                data-testid="account-page-mi-dashboard-title"
            >
                Management Information
            </div>
            <div className="mi-dashboard-container">
                <div
                    className="charts display"
                    // data-testid="remaining-pages-doughnut-chart"
                >
                    <div className="smaller-margin-bottom">Number of Pages</div>
                    {userBelongsToOrganisation ? (
                        <div data-testid="remaining-pages-doughnut-chart-organisation">
                            <DonutChart
                                data={organisationState.data}
                                options={organisationState.options}
                            ></DonutChart>
                        </div>
                    ) : (
                        <div data-testid="remaining-pages-doughnut-chart-no-organisation">
                            <DonutChart
                                data={state.data}
                                options={state.options}
                            ></DonutChart>
                        </div>
                    )}
                </div>
                <div className="charts">
                    <div className="larger-margin-bottom">
                        <div
                            className="smaller-margin-bottom"
                            data-testid="project-count-info-div"
                        >
                            User Project Count
                        </div>
                        <div className="smaller-title">{projectCount}</div>
                    </div>
                    <div className="larger-margin-bottom">
                        <div
                            className="smaller-margin-bottom"
                            data-testid="document-count-info-div"
                        >
                            User Document Count
                        </div>
                        <div className="smaller-title">{documentCount}</div>
                    </div>
                    <div className="larger-margin-bottom">
                        <div
                            className="smaller-margin-bottom"
                            data-testid="document-count-info-div"
                        >
                            User Page Count
                        </div>
                        <div className="smaller-title">{numberOfPagesUsed}</div>
                    </div>
                    <div>
                        <div
                            className="smaller-margin-bottom"
                            data-testid="document-count-info-div"
                        >
                            Organisation Page Count
                        </div>
                        <div className="smaller-title">{numberOfPagesUsedByTheOrganisation}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MIDashboard;
