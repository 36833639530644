import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FileNameTitle from "../../components/file-name/file-name.component";
import HeaderComponent from "../../components/header/header.component";
import InlineNotificationError from "../../components/inline-notification/inline-notification-error.component";
import ResultsDataTable from "../../components/results-page-components/results-page-components-data-table.component";
import ResultsPageImageToolbar from "../../components/results-page-components/results-page-components-data-table/results-pdf-image-toolbar.component";
import ResultsPagePdfImage from "../../components/results-page-components/results-page-components-data-table/results-pdf-image.component";
import ResultsFileDetails from "../../components/results-page-components/results-page-components-file-details.component";
import {
    genericOnKeyDownHandler,
    goToHomePage,
} from "../../helpers/helper-functions";
import { clearApiError } from "../../redux/api/api.actions";
import { fileDetails } from "../../redux/file-details/file-details.selectors";
import {
    canFileBeRestored,
    fileResults,
    fileResultsIgnoreValidationList,
    fileTablesWarnings,
} from "../../redux/files/files.selectors";
import {
    getFileDetailsAction,
    getFileImagesAction,
    getFileResultsAction,
    getFileTableAction,
    restoreFileAction,
    getProjectList
} from "../../redux/thunks/thunks.actions";

const ResultsPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const initialFileDetails = useSelector(fileDetails);
    const initialFileResults = useSelector(fileResults);
    const initialIgnoreValidationList = useSelector(
        fileResultsIgnoreValidationList
    );
    const fileTablesInitWarnings = useSelector(fileTablesWarnings);
    const initialAllImages = useSelector((state) => state.files.fileImages);
    const canTheFileBeRestored = useSelector(canFileBeRestored);
    const initialApiErrors = useSelector((state) => state.api.apiError);
    const projectState = useSelector((state => state.projects));

    const pageDivRef = useRef(null);

    const [splitViewVertical, setSplitViewVertical] = useState(false);
    const [splitViewHorizontal, setSplitViewHorizontal] = useState(false);
    const [splitViewClass, setSplitViewClass] = useState("");
    const [imageSize, setImageSize] = useState("");
    const [imageDataTestId, setImageDataTestId] = useState(
        "results-page-pdf-image"
    );
    const [imageToScrollTo, setImageToScrollTo] = useState(null);
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [currentFileId, setCurrentFiletId] = useState(null);
    const [currentPage, setCurrentPage] = useState("");
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [initialBalance, setInitialBalance] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [clickedRowIndex, setClickedRowIndex] = useState(null);
    const [clickedColumnId, setClickedColumnId] = useState(null);
    const [multipleRowsSelected, setMultipleRowsSelected] = useState(null);

    const [zoomInFunction, setZoomInFunction] = useState(null);
    const [zoomOutFunction, setZoomOutFunction] = useState(null);
    const [resetTransformFunction, setResetTransformFunction] = useState(null);
    const [tableError, setTableError] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [sizeOfOuterDiveOnCollapsed, setSizeOfOuterDiveOnCollapsed] =
        useState("slide-in");
    const [fileName, setFileName] = useState("");
    const [resetResultsSideBarInfo, setResetResultsSideBarInfo] =
        useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [apiErrors, setApiErrors] = useState("");
    const [displayExtraText, setDisplayExtraText] = useState(false);

    const [projectName, setProjectName] = useState(null);
    const [projectId, setProjectId] = useState(null);

    const [gotProjects, setGotProjects] = useState(false);

    useEffect(() => {
        if (!gotProjects) {
            setGotProjects(true);
            dispatch(getProjectList());
        }
    }, [dispatch, projectState, gotProjects]);

    const blurButtons = () => {
        if (pageDivRef.current) {
            pageDivRef.current.focus();
        }
    };

    const onKeyDownHandler = (e) => {
        if (e.ctrlKey && e.keyCode === 90) { }
        else {
            genericOnKeyDownHandler(
                e,
                imageToScrollTo,
                zoomInFunction,
                zoomOutFunction,
                resetTransformFunction
            );
        }

    };

    const resetFunction = useCallback(
        (e) => {
            let pathnameSplit = location.pathname.split("/");

            let projectId = pathnameSplit[2];
            let fileId = pathnameSplit[3];
            dispatch(restoreFileAction(projectId, fileId));
            goToHomePage(e, navigate, projectId);
        },
        [location.pathname, dispatch, navigate]
    );

    const onCloseButtonClick = (error) => {
        if (error === "table-error") {
            setTableError("");
        } else if (error === "api-error") {
            setApiErrors("");
            dispatch(clearApiError());
        }
    };

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            let pathnameSplit = location.pathname.split("/");
            let page = pathnameSplit[1];
            let projectId = pathnameSplit[2];
            let fileId = pathnameSplit[3];
            setCurrentProjectId(projectId);
            setCurrentFiletId(fileId);
            setCurrentPage(page);
            dispatch(getFileDetailsAction(projectId, fileId));
            dispatch(getFileResultsAction(projectId, fileId));
            dispatch(getFileImagesAction(projectId, fileId));
            dispatch(getFileTableAction(projectId, fileId));
        }

        return () => {
            unmounted = true;
        };
    }, [location.pathname, dispatch]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (splitViewVertical) {
                setSplitViewClass("flex-split-view-vertical");
                setImageSize("split-view-vertical");
                setImageDataTestId("results-page-pdf-image-vertical");
            } else if (splitViewHorizontal) {
                setSplitViewClass("flex-split-view-horizontal");
                setImageSize("split-view-horizontal");
                setImageDataTestId("results-page-pdf-image-horizontal");
            }
        }
        return () => {
            unmounted = true;
        };
    }, [splitViewVertical, splitViewHorizontal]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (isCollapsed) {
                setSizeOfOuterDiveOnCollapsed("slide-in");
            } else {
                setSizeOfOuterDiveOnCollapsed("slide-out");
            }
        }

        return () => {
            unmounted = true;
        };
    }, [isCollapsed, setSizeOfOuterDiveOnCollapsed]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (initialFileResults.length !== 0) {
                let dataCopy = JSON.parse(JSON.stringify(initialFileResults));
                setDataToDisplay(dataCopy);
                setInitialBalance(dataCopy[0]["Balance"]);
            } else {
                setDataToDisplay([]);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [initialFileResults, setDataToDisplay, setInitialBalance]);

    useEffect(() => {
        let unmounted = false;
        const validation = [undefined, null];

        if (!unmounted) {
            if (!validation.includes(initialFileDetails)) {
                setFileName(initialFileDetails.fileName);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [initialFileDetails, setFileName]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            resetResultsSideBarInfo
                ? setResetResultsSideBarInfo(false)
                : setResetResultsSideBarInfo(resetResultsSideBarInfo);
        }

        return () => {
            unmounted = true;
        };
    }, [resetResultsSideBarInfo, setResetResultsSideBarInfo]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (fileTablesInitWarnings) {
                switch (fileTablesInitWarnings) {
                    case "All tables are valid":
                        setTableError("");
                        break;
                    default:
                        setTableError(fileTablesInitWarnings);
                        break;
                }
            }
        }

        return () => {
            unmounted = true;
        };
    }, [fileTablesInitWarnings]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            let checkList = [null, "", undefined];
            if (!checkList.includes(initialApiErrors)) {
                if (initialApiErrors.includes("Please try again")) {
                    const error = `Ongoing Process: ${initialApiErrors}`;
                    setApiErrors(error);
                } else {
                    setApiErrors("");
                    dispatch(clearApiError());
                }
            }
        }
        return () => {
            unmounted = true;
        };
    }, [initialApiErrors, dispatch]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (tableError) {
                if (tableError.includes("tables")) {
                    setTimeout(() => {
                        setTableError("");
                    }, 5000);
                }
            }

            if (apiErrors) {
                setTimeout(() => {
                    setApiErrors("");
                    dispatch(clearApiError());
                }, 5000);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [tableError, apiErrors, dispatch]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setTableError("");
            setApiErrors("");
            dispatch(clearApiError());
        }
        return () => {
            unmounted = true;
        };
    }, [dispatch]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (
                tableError !== "" &&
                canTheFileBeRestored &&
                tableError.includes("tables")
            ) {
                setDisplayExtraText(true);
            } else {
                setDisplayExtraText(false);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [canTheFileBeRestored, tableError]);

    useEffect(() => {
        let pathnameSplit = location.pathname.split("/");
        let projectId = pathnameSplit[2];
        let filesProject = projectState.projects.find(
            (project) => project["project_id"] === projectId
        );
        if (filesProject) {
            setProjectId(projectId)
            setProjectName(filesProject.name)
        }

    }, [projectState, location.pathname])

    return (
        <>
            <HeaderComponent isLogin={false} displayNavOptions={true} isResultsPage={true}/>
            <div
                className="results-page-div"
                data-testid="results-page-div"
                onKeyDown={(e) => onKeyDownHandler(e)}
                tabIndex="-1"
                ref={pageDivRef}
            >
                <div className={`outer-div ${sizeOfOuterDiveOnCollapsed}`}>
                    <FileNameTitle fileName={fileName} projectName={projectName} projectId={projectId} pageLocation="results" />
                    <div className="inline-notification-results">
                        {tableError !== "" ? (
                            <InlineNotificationError
                                loginError={tableError}
                                hideCloseButton={false}
                                onCloseButtonClick={onCloseButtonClick}
                                displayExtraText={displayExtraText}
                                resetFunction={resetFunction}
                                errorType="table-error"
                                pageLocation={currentPage}
                            />
                        ) : null}
                        {apiErrors !== "" ? (
                            <InlineNotificationError
                                loginError={apiErrors}
                                hideCloseButton={false}
                                onCloseButtonClick={onCloseButtonClick}
                                errorType="api-error"
                                pageLocation={currentPage}
                            />
                        ) : null}
                    </div>
                    <div
                        className={`results-page-table-file-details ${splitViewClass}`}
                    >
                        <ResultsDataTable
                            currentProjectId={currentProjectId}
                            currentFileId={currentFileId}
                            dataToDisplay={dataToDisplay}
                            initialIgnoreValidationList={
                                initialIgnoreValidationList
                            }
                            setSplitViewVertical={setSplitViewVertical}
                            splitViewVertical={splitViewVertical}
                            setSplitViewHorizontal={setSplitViewHorizontal}
                            splitViewHorizontal={splitViewHorizontal}
                            setImageToScrollTo={setImageToScrollTo}
                            imageToScrollTo={imageToScrollTo}
                            setDataToDisplay={setDataToDisplay}
                            initialBalance={initialBalance}
                            setInitialBalance={setInitialBalance}
                            gridApi={gridApi}
                            setGridApi={setGridApi}
                            clickedRowIndex={clickedRowIndex}
                            setClickedRowIndex={setClickedRowIndex}
                            clickedColumnId={clickedColumnId}
                            setClickedColumnId={setClickedColumnId}
                            multipleRowsSelected={multipleRowsSelected}
                            setMultipleRowsSelected={setMultipleRowsSelected}
                            initialAllImages={initialAllImages}
                            setTableError={setTableError}
                            blurButtons={blurButtons}
                        />
                        {splitViewVertical || splitViewHorizontal ? (
                            <div
                                data-testid="pdf-view"
                                className={`results-page-table-image-div 
                                scroll-results-pdf-image 
                                flex-direction-column
                                ${imageSize}
                            `}
                            >
                                {initialAllImages !== null
                                    ? initialAllImages.map((image) => (
                                        <ResultsPagePdfImage
                                            imageId={image.imageId}
                                            key={image.imageId}
                                            imageSource={image.imageLink}
                                            imageDataTestId={imageDataTestId}
                                            splitViewVertical={
                                                splitViewVertical
                                            }
                                            splitViewHorizontal={
                                                splitViewHorizontal
                                            }
                                            imageToScrollTo={imageToScrollTo}
                                            setImageToScrollTo={
                                                setImageToScrollTo
                                            }
                                            setZoomInFunction={
                                                setZoomInFunction
                                            }
                                            setZoomOutFunction={
                                                setZoomOutFunction
                                            }
                                            setResetTransformFunction={
                                                setResetTransformFunction
                                            }
                                        />
                                    ))
                                    : null}
                                <ResultsPageImageToolbar
                                    pageDisplayed={imageToScrollTo}
                                    zoomInFunction={zoomInFunction}
                                    zoomOutFunction={zoomOutFunction}
                                    resetTransformFunction={
                                        resetTransformFunction
                                    }
                                    selectedFiles={[]}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                <ResultsFileDetails
                    isCollapsed={isCollapsed}
                    setIsCollapsed={setIsCollapsed}
                    currentProjectId={currentProjectId}
                    currentFileId={currentFileId}
                    currentPage={currentPage}
                    resetResultsSideBarInfo={resetResultsSideBarInfo}
                    setResetResultsSideBarInfo={setResetResultsSideBarInfo}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    selectedProjectInfo={null}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            </div>
        </>
    );
};

export default ResultsPage;
