export const addFiles = (filesInProject, filesToAdd) => {
    let filteredFilesToAdd = [];
    let idsOfFilesInProject = [];

    filesInProject.forEach((file) => {
        idsOfFilesInProject.push(file["file_id"]);
    });

    if (filesInProject.length !== 0) {
        for (let file of filesToAdd) {
            let fileId = file["file_id"];

            if (!idsOfFilesInProject.includes(fileId)) {
                filteredFilesToAdd.push(file);
            }
        }
    } else {
        filteredFilesToAdd = filesToAdd;
    }

    let filesToDisplay = [...filesInProject, ...filteredFilesToAdd];

    return filesToDisplay;
};
