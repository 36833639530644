import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InputFieldPhoneNumber from "../../../../../components/input-fields/input-fields-phone-number.component";
import InputFieldVerificationCode from "../../../../../components/input-fields/input-fields-verification-code.component";
import CommonRegistrationButton from "../../../../../components/registration-buttons/registration-button-common.component";
import {
    cognitoUpdatePhoneNumberAction,
    verifyUserAttributeSubmitAction,
} from "../../../../../redux/thunks/thunks.actions.js";
// import "./account-page-manage-account.styles.scss";

const SetMfaSMS = ({
    showPhoneNumberField,
    showVerifyPhoneNumber,
    mfaChoice,
    setPhoneNumber,
    phoneNumber,
}) => {
    const dispatch = useDispatch();

    const [smsVerificationCodeConforms, setSMSVerificationCodeConforms] =
        useState(false);
    const [isSavePhoneNumberButtonDisabled, setIsSavePhoneNumberButtonDisabled] =
        useState(true);
    const [phoneVerificationCode, setPhoneVerificationCode] = useState("");

    const handleSavePhoneNumber = () => {
        dispatch(cognitoUpdatePhoneNumberAction(phoneNumber, mfaChoice));
    };

    const handleVerifyPhoneNumber = () => {
        dispatch(
            verifyUserAttributeSubmitAction(mfaChoice, phoneVerificationCode)
        );
    };

    return (
        <div data-testid="set-mfa-sms-div">
            {showPhoneNumberField ? (
                <>
                    <InputFieldPhoneNumber
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setIsSavePhoneNumberButtonDisabled={
                            setIsSavePhoneNumberButtonDisabled
                        }
                        testId={"set-mfa-sms"}
                    />
                    <div className="field-margin-bottom"></div>
                    <CommonRegistrationButton
                        buttonKind={"primary"}
                        isDisabled={isSavePhoneNumberButtonDisabled}
                        onClickFunction={handleSavePhoneNumber}
                        buttonDescription={"Add Phone Number"}
                        dataTestId={"manage-account-save-phone-number"}
                    />
                </>
            ) : null}
            <div className="field-margin-bottom"></div>
            {showVerifyPhoneNumber ? (
                <>
                    <InputFieldVerificationCode
                        setVerificationCode={setPhoneVerificationCode}
                        verificationCode={phoneVerificationCode}
                        setVerificationCodeConforms={
                            setSMSVerificationCodeConforms
                        }
                        verificationCodeConforms={smsVerificationCodeConforms}
                        labelText={"Phone verification code"}
                    />
                    <div className="field-margin-bottom"></div>
                    <CommonRegistrationButton
                        buttonKind={"primary"}
                        isDisabled={false}
                        onClickFunction={handleVerifyPhoneNumber}
                        buttonDescription={"Verify Phone Number"}
                        dataTestId={"manage-account-verify-phone-number"}
                    />
                </>
            ) : null}
        </div>
    );
};

export default SetMfaSMS;
