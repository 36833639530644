import { InlineNotification } from "carbon-components-react";
import React, { Fragment, useCallback, useEffect, useState } from "react";

const FileStateReloadNotification = ({
    selectedProjectFiles,
    showReloadNotification,
    setShowReloadNotification,
}) => {
    const [alreadyCheckedFileIds, setAlreadyCheckedFileIds] = useState(
        selectedProjectFiles
            .filter((obj) =>
                ["ReProcessed", "ReFailed"].includes(obj["upload_status"])
            )
            .map((el) => el["file_id"])
    );
    const [loadingFiles, setLoadingFiles] = useState([]);
    const [loadedFiles, setLoadedFiles] = useState([]);
    const [failedFiles, setFailedFiles] = useState([]);
    const [notificationClass, setNotificationClass] = useState("fade-in-1");
    const [notificationText, setNotificationText] = useState("");
    const [iconType, setIconType] = useState("info");

    /**
     * Runs when no more files are being uploaded
     * @param {*} loadedFiles
     * @param {*} failedFiles
     * @param {*} alreadyCheckedFiles
     */
    const loadingComplete = useCallback(
        (loadedFiles, failedFiles, alreadyCheckedFileIds) => {
            setTimeout(() => {
                setAlreadyCheckedFileIds([
                    ...alreadyCheckedFileIds,
                    ...loadedFiles.map((el) => el["file_id"]),
                    ...failedFiles.map((el) => el["file_id"]),
                ]);
                setLoadingFiles([]);
                setLoadedFiles([]);
                setFailedFiles([]);
                setNotificationClass("fade-out-1");
                setTimeout(() => {
                    setShowReloadNotification(false);
                }, 1000);
            }, 3000);
        },
        [setShowReloadNotification]
    );

    /**
     * Categorize files after removing all files already checked
     * @param {*} selectedProjectFiles
     * @param {*} alreadyCheckedFileIds
     */
    const categorizeFiles = (selectedProjectFiles, alreadyCheckedFileIds) => {
        // Find all files currently loading
        const loadingFiles = selectedProjectFiles.filter(
            (obj) => obj["upload_status"] === "ReLoading"
        );

        // If file is currently loading remove it from alreadyCheckedFileIds
        const newAlreadyCheckedFileIds = alreadyCheckedFileIds.filter(
            (fileId) => !loadingFiles.find((obj) => fileId === obj["file_id"])
        );

        // Only check files that haven't been loaded before
        const newFiles = selectedProjectFiles.filter(
            (obj) => !newAlreadyCheckedFileIds.includes(obj["file_id"])
        );

        // Don't reread files that have previously been loaded
        const loadedFiles = newFiles.filter(
            (obj) => obj["upload_status"] === "ReProcessed"
        );

        const failedFiles = newFiles.filter(
            (obj) => obj["upload_status"] === "ReFailed"
        );

        setLoadingFiles(loadingFiles);

        if (
            JSON.stringify(newAlreadyCheckedFileIds) !==
            JSON.stringify(alreadyCheckedFileIds)
        ) {
            setAlreadyCheckedFileIds(newAlreadyCheckedFileIds);
        }

        setLoadedFiles(loadedFiles);

        setFailedFiles(failedFiles);
    };

    /**
     * Decides what text should be displayed on
     *  screen as well as icon type for a single file
     * @param {int} numLoadingFiles
     * @param {int} numLoadedFiles
     * @param {int} numFailedFiles
     * @param {str} fileName
     */
    const singleFile = (
        numLoadingFiles,
        numLoadedFiles,
        numFailedFiles,
        fileName
    ) => {
        if (numLoadingFiles === 0) {
            if (numLoadedFiles === 1) {
                setNotificationText(`Reprocessing ${fileName}`);
                setIconType("success");
            } else if (numFailedFiles === 1) {
                setNotificationText(`Failed to Reprocess ${fileName}`);
                setIconType("error");
            }
        } else {
            setIconType("info");
            setNotificationText(`Reprocessing ${fileName}`);
        }
    };

    /**
     * Decides what text should be displayed on
     *  screen as well as icon type for multiple files
     * @param {int} numLoadingFiles
     * @param {int} numLoadedFiles
     * @param {int} numFailedFiles
     */
    const multipleFiles = (numLoadingFiles, numLoadedFiles, numFailedFiles) => {
        if (numLoadingFiles === 0) {
            if (numFailedFiles === 0) {
                setIconType("success");
                setNotificationText(`Reprocessed ${numLoadedFiles} files`);
            } else if (numLoadedFiles === 0) {
                setIconType("error");
                setNotificationText(
                    `${numFailedFiles} files failed to Reprocess`
                );
            } else {
                setIconType("warning");
                setNotificationText(
                    `Reprocessed ${numLoadedFiles} file(s) with ${numFailedFiles} failing`
                );
            }
        } else {
            setIconType("info");
            if (numFailedFiles === 0) {
                setNotificationText(
                    `Reprocessed ${numLoadedFiles} out of ${
                        numLoadingFiles + numLoadedFiles
                    } files`
                );
            } else {
                setNotificationText(
                    `Reprocessed ${numLoadedFiles + numFailedFiles} out of ${
                        numLoadingFiles + numLoadedFiles + numFailedFiles
                    } files with ${numFailedFiles} failing`
                );
            }
        }
    };

    /**
     * Updates the on screen state notification
     */
    const updateFileStateNotification = useCallback(
        (loadingFiles, loadedFiles, failedFiles) => {
            const numLoadingFiles = loadingFiles.length;
            const numLoadedFiles = loadedFiles.length;
            const numFailedFiles = failedFiles.length;

            if (numLoadingFiles !== 0) {
                setShowReloadNotification(true);
                setNotificationClass("fade-in-1");
            }

            if (numLoadingFiles + numLoadedFiles + numFailedFiles === 1) {
                let fileName;

                if (numLoadingFiles === 1) {
                    fileName = loadingFiles[0]["name"];
                } else if (numLoadedFiles === 1) {
                    fileName = loadedFiles[0]["name"];
                } else if (numFailedFiles === 1) {
                    fileName = failedFiles[0]["name"];
                }

                singleFile(
                    numLoadingFiles,
                    numLoadedFiles,
                    numFailedFiles,
                    fileName
                );
            }

            if (numLoadingFiles + numLoadedFiles + numFailedFiles > 1) {
                multipleFiles(numLoadingFiles, numLoadedFiles, numFailedFiles);
            }
        },
        [setShowReloadNotification]
    );

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            categorizeFiles(selectedProjectFiles, alreadyCheckedFileIds);
        }

        return () => {
            unmounted = true;
        };
    }, [selectedProjectFiles, alreadyCheckedFileIds]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (loadingFiles.length === 0 && showReloadNotification === true) {
                loadingComplete(loadedFiles, failedFiles, alreadyCheckedFileIds);
            }
            updateFileStateNotification(loadingFiles, loadedFiles, failedFiles);
        }

        return () => {
            unmounted = true;
        };
    }, [
        loadingFiles,
        loadedFiles,
        failedFiles,
        alreadyCheckedFileIds,
        showReloadNotification,
        updateFileStateNotification,
        loadingComplete,
    ]);

    return (
        <Fragment>
            {showReloadNotification ? (
                <InlineNotification
                    kind={iconType}
                    iconDescription="Close button"
                    id="reloading-notification"
                    data-testid="reloading-notification"
                    subtitle={<span>{notificationText}</span>}
                    title="File Reprocessing"
                    lowContrast={true}
                    className={`InlineNotification_mod ${notificationClass}`}
                />
            ) : null}
        </Fragment>
    );
};

export default FileStateReloadNotification;
