import { CheckmarkOutline16 } from "@carbon/icons-react";
import { AccordionItem } from "carbon-components-react";
import React from "react";
import { useDispatch } from "react-redux";
import CommonRegistrationButton from "../../../../components/registration-buttons/registration-button-common.component";
import { stripeCreateCheckoutSessionAction } from "../../../../redux/thunks/thunks.actions.js";
import "../account-page-dashboard.styles.scss";
import "./account-page-dashboard-subscription.styles.scss";

const AccountPageSubscriptionBusinessOption = ({
    isBusinessAccordionOpen,
    handleBusinessAccordionClick,
}) => {
    const dispatch = useDispatch();

    const handleMonthlySubscriptionClick = () => {
        dispatch(stripeCreateCheckoutSessionAction("monthly", "yes"));
    };

    const handleYearlySubscriptionClick = () => {
        dispatch(stripeCreateCheckoutSessionAction("yearly", "yes"));
    };

    return (
        <AccordionItem
            title="Business Subscription"
            className="margin-bottom"
            open={isBusinessAccordionOpen}
            onClick={handleBusinessAccordionClick}
            data-testid="business-subscription-option"
        >
            <div
                className="list margin-bottom"
                data-testid="business-subscription-feature-list"
            >
                <div data-testid="business-subscription-feature-list-left">
                    <p className="text-font" data-testid="feature-list-left-one">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Bank
                        Statements Only
                    </p>
                    <p className="text-font" data-testid="feature-list-left-two">
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> 500 pages
                        per month
                    </p>
                    <p
                        className="text-font"
                        data-testid="feature-list-left-three"
                    >
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> No download
                        or installations required
                    </p>
                    <p
                        className="text-font"
                        data-testid="feature-list-left-four"
                    >
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Extract and
                        arrange raw data into digitised format
                    </p>
                </div>
                <div data-testid="business-subscription-feature-list-right">
                    <p
                        className="text-font"
                        data-testid="feature-list-right-one"
                    >
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Download
                        digitised data into .xls
                    </p>
                    <p
                        className="text-font"
                        data-testid="feature-list-right-two"
                    >
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Formatted
                        for quick analysis
                    </p>
                    <p
                        className="text-font"
                        data-testid="feature-list-right-three"
                    >
                        {" "}
                        <CheckmarkOutline16 className="green-fill" /> Save and
                        re-visit your work
                    </p>
                </div>
            </div>
            <div
                className="larger-text margin-bottom"
                data-testid="payment-options-text"
            >
                Choose to pay monthly or yearly
            </div>
            <CommonRegistrationButton
                buttonKind="primary"
                isDisabled={false}
                onClickFunction={handleMonthlySubscriptionClick}
                buttonDescription="Monthly"
                dataTestId="monthly-subscription"
            />
            <CommonRegistrationButton
                className="button-blue-fill"
                buttonKind="primary"
                isDisabled={false}
                onClickFunction={handleYearlySubscriptionClick}
                buttonDescription="Yearly"
                dataTestId="yearly-subscription"
            />
        </AccordionItem>
    );
};

export default AccountPageSubscriptionBusinessOption;
