import { ArrowLeft32, ChevronSort32, Grid32, List32 } from "@carbon/icons-react";
import { Button, InlineNotification, Pagination } from "carbon-components-react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonButtons from "../../components/common-buttons/common-buttons.component";
import ResultsFileDetails from "../../components/results-page-components/results-page-components-file-details.component";
import SortMenu from "../../components/sort-menu/sort-menu.component";
import { removeExtension } from "../../helpers/helper-functions-2.js";

import {
    deleteFileAction,
    downloadExcelFilesAction,
    downloadMergedExcelFilesAction,
} from "../../redux/thunks/thunks.actions";

import {
    sortAlphabeticallyAscending,
    sortAlphabeticallyDescending,
    sortDateMostRecent,
    sortDateOldest,
} from "../../helpers/helper-functions.js";
import { setUserNotification } from "../../redux/user/user.actions";
import AltFileTiles from "./alt-file-tiles/alt-file-tiles.component";
import FileTiles from "./file-tiles/file-tiles.component";
import FileCategorisingNotification from "./files-dashboard.files-categoristaion-state-notification";
import FileStateReloadNotification from "./files-dashboard.files-reload-state-notification";
import FileStateNotification from "./files-dashboard.files-state-notification";
import "./files-dashboard.styles.scss";

const FilesDashboard = ({
    selectedProjectInfo,
    lastClickedFileId,
    handleBackClick,
    setFileModalOpen,
    blurButtons,
    homepageNumberOfWarnings,
    isCollapsed,
    setIsCollapsed,
}) => {
    const dispatch = useDispatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [pageSize, setPageSize] = useState(10);
    const [pageSize, setPageSize] = useState(JSON.parse(localStorage.getItem('filesPageSize') || 10));
    const [fileTileView, setFileTileView] = useState(JSON.parse(localStorage.getItem('fileTileView')) == null ? true : JSON.parse(localStorage.getItem('fileTileView')));
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [currentFileId, setCurrentFiletId] = useState(null);
    const [resetResultsSideBarInfo, setResetResultsSideBarInfo] =
        useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [sortType, setSortType] = useState(JSON.parse(localStorage.getItem('sortType')) || "dateOldest");

    useEffect(() => {
        localStorage.setItem("sortType", JSON.stringify(sortType))
    }, [sortType])

    useEffect(() => {
        localStorage.setItem("filesPageSize", JSON.stringify(pageSize))
    }, [pageSize])

    useEffect(() => {
        localStorage.setItem("fileTileView", JSON.stringify(fileTileView))
        setSelectedFiles([])
    }, [fileTileView])

    const [showSortMenu, setShowSortMenu] = useState(false);

    const [displayedFiles, setDisplayedFiles] = useState([]);
    const [fadeClass, setFadeClass] = useState("fade-in-1");
    const userNotification = useSelector((state) => state.user.userNotification);
    const filesUploading = useSelector((state) => state.projects.files_uploading)

    const [showUploadNotification, setShowUploadNotification] = useState(false);
    const [showReloadNotification, setShowReloadNotification] = useState(false);

    const [showCategorisationNotification, setShowCategorisationNotification] =
        useState(false);

    const [notificationDivHeight, setNotificationDivHeight] =
        useState("no-homepage-errors");

    const [altFilesSelectAll, setAltFilesSelectAll] = useState(false);
    const [altFilesDeselectAll, setAltFilesDeselectAll] = useState(false);

    const onBackClick = () => {
        handleBackClick();
    };

    /**
     * Adds a new file to the selectedFiles useState
     * @param {Object} newObject
     */
    const addFileSelected = (newObject) => {
        const newSelectedFiles = [...selectedFiles, newObject];

        setSelectedFiles(newSelectedFiles);
    };

    const handleDeleteFile = () => {
        for (let file of selectedFiles) {
            dispatch(
                deleteFileAction(selectedProjectInfo.project_id, file.fileId)
            );
        }
        setSelectedFiles([]);
    };

    const deselectAllFiles = () => {
        setSelectedFiles([]);
        setAltFilesDeselectAll(true);
        setAltFilesSelectAll(false);
    };

    const selectAllFiles = () => {
        let newFiles = [];
        var sortedFiles;

        // Need to sort here in the same order as the UI is showing
        if (sortType === "alphabeticallyAscending") {
            sortedFiles = sortAlphabeticallyAscending(selectedProjectInfo.files, "file_tiles");

        } else if (sortType === "alphabeticallyDescending") {
            sortedFiles = sortAlphabeticallyDescending(selectedProjectInfo.files, "file_tiles");

        } else if (sortType === "dateOldest") {
            sortedFiles = sortDateOldest(selectedProjectInfo.files, "file_tiles");

        } else if (sortType === "dateMostRecent") {
            sortedFiles = sortDateMostRecent(selectedProjectInfo.files, "file_tiles");
        }
        else {
            sortedFiles = selectedProjectInfo.files
        }

        sortedFiles.map((file) =>

            newFiles.push({
                fileName: file["name"],
                fileId: file["file_id"],
            })
        );

        setSelectedFiles(newFiles);
        setAltFilesSelectAll(true);
        setAltFilesDeselectAll(false);

        blurButtons();
    };

    /**
     * Removes a file to the selectedFiles useState
     * @param {Object} newObject
     */
    const removeFileSelected = (newObject) => {
        const newSelectedFiles = selectedFiles.filter(
            (currentObject) => currentObject.fileId !== newObject.fileId
        );

        setSelectedFiles(newSelectedFiles);
    };

    const confirmDelete = () => {
        if (selectedFiles.length === 0) {
            return;
        }

        let message = "Are you sure you wish to delete\n\n";
        let file_message = "";
        for (let file of selectedFiles) {
            file_message = file_message.concat(`${file.fileName}\n`);
        }
        message = message.concat(
            file_message,
            `from Project ${selectedProjectInfo.name}?`
        );

        if (window.confirm(message)) {
            handleDeleteFile();
        }
    };

    const mergeBankStatements = () => {
        if (selectedFiles.length === 0) {
            return;
        } else {
            let fileIdsToMerge = [];

            for (let file of selectedFiles) {
                fileIdsToMerge.push({
                    file_id: file.fileId,
                    file_name: removeExtension(file.fileName),
                });
            }

            let arrayOfProjectIdAndFiles = [];
            let objectToAppend = {
                project_id: selectedProjectInfo.project_id,
                files: fileIdsToMerge,
            };

            arrayOfProjectIdAndFiles.push(objectToAppend);

            dispatch(downloadMergedExcelFilesAction(arrayOfProjectIdAndFiles));
            setSelectedFiles([]);
        }
        blurButtons();
    };

    const downloadFiles = (excelType) => {
        if (selectedFiles.length === 0) {
            return;
        } else {
            let fileIdsToDownload = [];

            for (let file of selectedFiles) {
                fileIdsToDownload.push(file.fileId);
            }

            dispatch(
                downloadExcelFilesAction(
                    selectedProjectInfo.project_id,
                    excelType,
                    fileIdsToDownload
                    // selectedFiles
                )
            );
            setSelectedFiles([]);
        }
        blurButtons();
    };


    const updateCurrentPage = useCallback(
        (newPage) => {
            setCurrentPage(newPage);
        },
        [setCurrentPage]
    );

    // const updatePageSize = (newSize) => {
    //     setPageSize(newSize);
    // };

    const handleEditFileDetailsClick = () => {
        setIsCollapsed(!isCollapsed);
    };


    useEffect(() => {
        if (userNotification === null) {
            setFadeClass("fade-in-1");
        } else {
            setTimeout(() => {
                setFadeClass("fade-out-1");
                setTimeout(() => {
                    dispatch(setUserNotification(null));
                }, 1000);
            }, 5000);
        }
    }, [userNotification, dispatch]);

    useEffect(() => {
        switch (homepageNumberOfWarnings) {
            case 0:
                setNotificationDivHeight("no-homepage-errors");
                break;
            case 1:
                setNotificationDivHeight("one-homepage-errors");
                break;
            case 2:
                setNotificationDivHeight("two-homepage-errors");
                break;
            default:
                break;
        }
    }, [homepageNumberOfWarnings]);

    return (
        <Fragment>
            <div className="simple-row" style={{ height: "4rem" }}>
                <div className="tile-title"><span style={{ cursor: 'pointer' }} onClick={onBackClick}>Projects</span> / {selectedProjectInfo.name}</div>
                <Button
                    renderIcon={ArrowLeft32}
                    className="icon-button-dashboard"
                    data-testid="return-button"
                    id="return-button"
                    iconDescription="Back"
                    hasIconOnly
                    onClick={onBackClick}
                    tooltipPosition="bottom"
                />
                <Button
                    renderIcon={Grid32}
                    className={`icon-button-dashboard  ${fileTileView ? "item-pressed prevent-click" : ""
                        }`}
                    data-testid="file-tile-view-button"
                    id="file-tile-view-button"
                    iconDescription="File Tile View"
                    hasIconOnly
                    onClick={() => {
                        setFileTileView(true);
                    }}
                    tooltipPosition="bottom"
                />
                <Button
                    renderIcon={List32}
                    className={`icon-button-dashboard  ${!fileTileView ? "item-pressed prevent-click" : ""
                        }`}
                    data-testid="file-list-view-button"
                    id="file-list-view-button"
                    iconDescription="File List View"
                    hasIconOnly
                    onClick={() => {
                        setFileTileView(false);
                    }}
                    tooltipPosition="bottom"
                />
                {fileTileView && selectedProjectInfo.files.length > 1 ? (
                    <Button
                        id="files-sort-button"
                        data-testid="files-sort-button"
                        renderIcon={ChevronSort32}
                        className="icon-button-dashboard"
                        iconDescription={"Sort"}
                        hasIconOnly
                        onClick={() => { setShowSortMenu(!showSortMenu) }}
                        tooltipPosition="bottom"
                    />
                ) : null}
                <SortMenu
                    id={"files"}
                    showSortMenu={showSortMenu}
                    setSortType={setSortType}
                    setShowSortMenu={setShowSortMenu}
                    sortType={sortType}
                />
                <CommonButtons
                    id="file"
                    projectsSelectedCount={selectedFiles.length}
                    deleteButtonClickFunction={confirmDelete}
                    genericTableButtonDescription="Download Generic Tables"
                    genericTableButtonClickFunction={() =>
                        downloadFiles("generic_tables")
                    }
                    bankStatementButtonDescription="Download Bank Statements"
                    bankStatementButtonClickFunction={() =>
                        downloadFiles("statement")
                    }
                    bankStatementMergeFunction={() => mergeBankStatements()}
                    handleEditFileDetailsClick={handleEditFileDetailsClick}
                    deselectAll={deselectAllFiles}
                    selectAll={selectAllFiles}
                    selectedProjectOrFileLength={selectedFiles.length}
                    projectOrFileLength={selectedProjectInfo.files.length}
                />
                <div
                    className={`inline-notification-dashboard higher-z-index ${fadeClass} ${notificationDivHeight}`}
                >
                    <FileStateNotification
                        selectedProjectFiles={selectedProjectInfo["files"]}
                        showUploadNotification={showUploadNotification}
                        setShowUploadNotification={setShowUploadNotification}
                    />
                    <FileStateReloadNotification
                        selectedProjectFiles={selectedProjectInfo["files"]}
                        showReloadNotification={showReloadNotification}
                        setShowReloadNotification={setShowReloadNotification}
                    />
                    <FileCategorisingNotification
                        selectedProjectFiles={selectedProjectInfo["files"]}
                        showCategorisationNotification={
                            showCategorisationNotification
                        }
                        setShowCategorisationNotification={
                            setShowCategorisationNotification
                        }
                    />
                    {userNotification ? (
                        <InlineNotification
                            kind="info"
                            iconDescription="Close Notification"
                            title={userNotification}
                            lowContrast={true}
                            className={`InlineNotification_mod ${fadeClass}`}
                            data-testid="user-notification-inline-notification"
                        />
                    ) : null}
                    {filesUploading ? (
                        <InlineNotification
                            kind="info"
                            iconDescription="Close button"
                            id="uploading-notification"
                            data-testid="uploading-notification"
                            title="Uploading, please wait"
                            lowContrast={true}
                            className={`InlineNotification_mod ${fadeClass}`}
                        />
                    ) : null}
                </div>
            </div>
            {fileTileView ? (
                <>
                    <FileTiles
                        selectedProjectInfo={selectedProjectInfo}
                        selectedFiles={selectedFiles}
                        lastClickedFileId={lastClickedFileId}
                        areFilesSelected={selectedFiles.length !== 0}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        updateCurrentPage={updateCurrentPage}
                        updatePageSize={setPageSize}
                        addFileSelected={addFileSelected}
                        removeFileSelected={removeFileSelected}
                        setFileModalOpen={setFileModalOpen}
                        isCollapsed={isCollapsed}
                        setIsCollapsed={setIsCollapsed}
                        setCurrentProjectId={setCurrentProjectId}
                        setCurrentFiletId={setCurrentFiletId}
                        displayedFiles={displayedFiles}
                        setDisplayedFiles={setDisplayedFiles}
                        blurButtons={blurButtons}
                        sortType={sortType}
                    />
                    {selectedProjectInfo.files.length !== 0 ? (
                        <Pagination
                            backwardText="Previous page"
                            forwardText="Next page"
                            itemsPerPageText="Items per page:"
                            pageNumberText="Page Number"
                            page={currentPage}
                            pageSize={pageSize}
                            pageSizes={[1, 5, 10, 15, 20, 25]}
                            id="pagination-component"
                            data-testid="pagination-component"
                            totalItems={selectedProjectInfo.files.length}
                            onChange={(event) => {
                                setPageSize(event.pageSize);
                                setCurrentPage(event.page);
                            }}
                            className={`pagination-location ${!isCollapsed
                                ? "expanded-pagination-size"
                                : "pagination-size"
                                }`}
                        />
                    ) : null}
                </>
            ) : (
                <AltFileTiles
                    selectedProjectInfo={selectedProjectInfo}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setFileModalOpen={setFileModalOpen}
                    altFilesSelectAll={altFilesSelectAll}
                    altFilesDeselectAll={altFilesDeselectAll}
                />
            )}
            <ResultsFileDetails
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                currentProjectId={currentProjectId}
                currentFileId={currentFileId}
                currentPage={"filesDashboard"}
                resetResultsSideBarInfo={resetResultsSideBarInfo}
                setResetResultsSideBarInfo={setResetResultsSideBarInfo}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                selectedProjectInfo={selectedProjectInfo}
            />
        </Fragment>
    );
};

export default FilesDashboard;
