import { CheckmarkOutline16, ErrorOutline16 } from "@carbon/icons-react";
import React from "react";
import "./password-requirements.styles.scss";

const PasswordRequirements = ({
    hasRightLength,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialCase,
}) => {
    let specialCharacters = [
        "^",
        "$",
        "*",
        ".",
        "[",
        "]",
        "{",
        "}",
        "(",
        ")",
        "?",
        "-",
        '"',
        "!",
        "@",
        "#",
        "%",
        "&",
        ">",
        "<",
        "'",
        ":",
        ";",
        "|",
        "_",
        "~",
        "`",
        "\\",
        "/",
        "+",
        "_",
    ];

    return (
        <div className="light-background" data-testid="password-requirements">
            <div className="flex-display">
                {!hasRightLength ? (
                    <ErrorOutline16 className="margin-right" />
                ) : (
                    <CheckmarkOutline16 className="margin-right green-fill" />
                )}
                At least 8 characters long
            </div>
            <div className="flex-display">
                {!hasUpperCase ? (
                    <ErrorOutline16 className="margin-right" />
                ) : (
                    <CheckmarkOutline16 className="margin-right green-fill" />
                )}
                Uppercase letter
            </div>
            <div className="flex-display">
                {!hasLowerCase ? (
                    <ErrorOutline16 className="margin-right" />
                ) : (
                    <CheckmarkOutline16 className="margin-right green-fill" />
                )}
                Lowercase letter
            </div>
            <div className="flex-display">
                {!hasNumber ? (
                    <ErrorOutline16 className="margin-right" />
                ) : (
                    <CheckmarkOutline16 className="margin-right green-fill" />
                )}
                Number
            </div>
            <div className="flex-display">
                {!hasSpecialCase ? (
                    <ErrorOutline16 className="margin-right" />
                ) : (
                    <CheckmarkOutline16 className="margin-right green-fill" />
                )}
                Special character ({specialCharacters})
            </div>
        </div>
    );
};

export default PasswordRequirements;
