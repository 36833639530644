import { Form, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import PasswordRequirements from "../password-requirements/password-requirements.component";

const InputFieldPassword = ({
    pass,
    passwordConforms,
    setPass,
    displayPasswordRequirements,
    user,
    emailConforms,
    setPasswordConforms,
    setDisplayPasswordRequirements,
    stopOnFocusEvent,
    id,
}) => {
    const [type, setType] = useState("password");
    const [passwordWarning, setPasswordWarning] = useState("");
    const [showPasswordWarning, setShowPasswordWarning] = useState(false);
    const [hasRightLength, setHasRightLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasSpecialCase, setHasSpecialCase] = useState(false);

    const handlePasswordFieldBlur = () => {
        setDisplayPasswordRequirements(false);

        if (pass === "") {
            setPasswordWarning("Enter a password");
            setShowPasswordWarning(true);
        }
    };
    const handlePasswordFieldFocus = () => {
        if (!stopOnFocusEvent) {
            setDisplayPasswordRequirements(true);
        }
    };
    const togglePasswordVisibility = () => {
        setType(type === "password" ? "text" : "password");
    };

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (pass === "") {
                setPasswordWarning("Enter a password");
                setShowPasswordWarning(false);
            } else if (!passwordConforms) {
                setPasswordWarning("Enter a valid password");
                setShowPasswordWarning(true);
            } else {
                setPasswordWarning("");
                setShowPasswordWarning(false);
            }
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [pass, passwordConforms]);

    useEffect(() => {
        const containsUppercase = /^(?=.*[A-Z]).*$/;
        const containsLowercase = /^(?=.*[a-z]).*$/;
        const containsNumber = /^(?=.*[0-9]).*$/;
        const isValidLength = /^.{8,}$/;
        const containsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_]).*$/;

        containsUppercase.test(pass)
            ? setHasUpperCase(true)
            : setHasUpperCase(false);
        containsLowercase.test(pass)
            ? setHasLowerCase(true)
            : setHasLowerCase(false);
        containsNumber.test(pass) ? setHasNumber(true) : setHasNumber(false);
        isValidLength.test(pass)
            ? setHasRightLength(true)
            : setHasRightLength(false);
        containsSymbol.test(pass)
            ? setHasSpecialCase(true)
            : setHasSpecialCase(false);
    }, [pass]);

    useEffect(() => {
        hasRightLength &&
        hasNumber &&
        hasUpperCase &&
        hasLowerCase &&
        hasSpecialCase
            ? setPasswordConforms(true)
            : setPasswordConforms(false);
    }, [
        hasRightLength,
        hasNumber,
        hasUpperCase,
        hasLowerCase,
        hasSpecialCase,
        setPasswordConforms,
    ]);

    useEffect(() => {
        passwordConforms
            ? setDisplayPasswordRequirements(true)
            : setDisplayPasswordRequirements(false);
    }, [passwordConforms, setDisplayPasswordRequirements]);

    return (
        <Form>
            <TextInput.ControlledPasswordInput
                id={
                    ![null, ""].includes(id)
                        ? `password-input-${id}`
                        : "password-input"
                }
                data-testid={
                    ![null, ""].includes(id)
                        ? `password-input-${id}`
                        : "password-input"
                }
                labelText="Password"
                togglePasswordVisibility={togglePasswordVisibility}
                onChange={(e) => setPass(e.target.value)}
                invalid={showPasswordWarning}
                invalidText={passwordWarning}
                type={type}
                disabled={user !== "" && emailConforms ? false : true}
                onFocus={handlePasswordFieldFocus}
                onBlur={handlePasswordFieldBlur}
                value={pass}
            />
            {displayPasswordRequirements ? (
                <PasswordRequirements
                    hasRightLength={hasRightLength}
                    hasUpperCase={hasUpperCase}
                    hasLowerCase={hasLowerCase}
                    hasNumber={hasNumber}
                    hasSpecialCase={hasSpecialCase}
                />
            ) : null}
        </Form>
    );
};

export default InputFieldPassword;
