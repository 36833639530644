import { websocketUpdateProjectFile } from "../projects/projects.actions";
import { downloadFileResultsExcelWebsocketAction } from "../thunks/thunks.actions";
import * as actions from "./middleware.actions";
import * as websocketActions from "./websocket.actions";

const socketMiddleware = () => {
    let socket = null;

    const onOpen = (store, socket, action) => (event) => {
        let isWebSocketConnected =
            store.getState().websocket.isWebSocketConnected;

        if (!isWebSocketConnected) {
            socket.send(action.host.headers);
            store.dispatch(actions.wsConnected(action.host.url));
            store.dispatch(websocketActions.updateIsWebSocketConnected(true));
        }
    };

    const onClose = (store, socket, action) => (event) => {
        socket.close();
        store.dispatch(
            websocketActions.webSocketDisconnected(false)
        );
        store.dispatch(websocketActions.clearWebsocketMessage());
    }

    const onMessage = (store) => (event) => {
        const status = event.data;

        if (
            ![
                "WebSocket Connected",
                "Close WebSocket",
                "Incorrect credentials",
                "Valid credentials received",
                "No credentials sent, timed out",
            ].includes(status) &&
            !status.includes("Download") &&
            !status.includes("Categorisation")
        ) {
            const [
                message,
                projectId,
                fileId,
                fileName,
                previewLink,
                warningsString,
                validationErrorsString,
                pdfLink,
                excelLink,
                genericTablesExcelLink,
                notesStrings,
            ] = status.split("->");

            var cleanWarnings = warningsString.replace(/'/g, '"');
            var warnings = JSON.parse(cleanWarnings);

            var cleanValidationErrors = validationErrorsString.replace(
                /'/g,
                '"'
            );
            var validationErrors = JSON.parse(cleanValidationErrors);
            var cleanNotes = notesStrings.replace(/'/g, '"');
            var notes = JSON.parse(cleanNotes);

            const payload = {
                message: message,
                projectId: projectId,
                fileId: fileId,
                fileName: fileName,
                previewLink: previewLink,
                warnings: warnings,
                validationErrors: validationErrors,
                pdfLink: pdfLink,
                excelLink: excelLink,
                genericTablesExcelLink: genericTablesExcelLink,
                notes: notes,
            };

            store.dispatch(
                websocketUpdateProjectFile(
                    payload["projectId"],
                    payload["fileId"],
                    payload
                )
            );
        } else {
            if (
                [
                    "Close WebSocket",
                    "Incorrect credentials",
                    "No credentials sent, timed out",
                ].includes(status)
            ) {
                console.log("WebSocket disconnect from server side.")
                store.dispatch(actions.wsDisconnect());
            } else if (status.includes("Download")) {
                const projectId = status.split("->")[1];
                const fileId = status.split("->")[2];

                store.dispatch(
                    downloadFileResultsExcelWebsocketAction(projectId, fileId)
                );
            } else if (status.includes("Categorisation")) {
                store.dispatch(websocketActions.setWebsocketMessage(status));
            }
        }
    };

    // the middleware part of this function
    return (store) => (next) => (action) => {
        switch (action.type) {
            case "WS_CONNECT":
                let isWebSocketConnected =
                    store.getState().websocket.isWebSocketConnected;

                if (!isWebSocketConnected) {
                    socket = new WebSocket(action.host.url);
                }
                socket.onmessage = onMessage(store);
                socket.onopen = onOpen(store, socket, action);
                socket.onclose = onClose(store, socket, action)

                break;
            case "WS_DISCONNECT":
                if (socket !== null) {
                    socket.close();
                    store.dispatch(
                        websocketActions.webSocketDisconnected(false)
                    );
                    store.dispatch(websocketActions.clearWebsocketMessage());
                }
                break;
            default:
                return next(action);
        }
    };
};

export default socketMiddleware();
