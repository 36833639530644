import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import backgroundImageLeft from "../../assets/login-background-left.png";
import backgroundImage from "../../assets/login_9.png";
import AccordionAccountInfo from "../../components/accordion/accordion-account-info.component";
import AccordionVerifyEmail from "../../components/accordion/accordion-verify-email.component";
import HeaderHelperPagesComponent from "../../components/header/header-helper-pages.component";
import { determineBorderClass } from "../../helpers/helper-functions";
import { handleRegisterUser } from "../../helpers/helper-functions.js";
import {
    setLoginError,
    setProgressToNextStep,
    setRedirectToLogin,
} from "../../redux/auth/auth.actions";
import InlineNotificationError from "../inline-notification/inline-notification-error.component";
import "./registration-card.styles.scss";

const RegistrationCard = ({ x_amzn_marketplace_token }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const loginError = useSelector((state) => state.auth.loginError);
    const progressToNextStep = useSelector(
        (state) => state.auth.progressToNextStep
    );
    const redirectToLogin = useSelector((state) => state.auth.redirectToLogin);
    const reactAppVersion = process.env.REACT_APP_VERSION;

    const [user, setUser] = useState("");
    const [emailConforms, setEmailConforms] = useState(false);
    const [pass, setPass] = useState("");
    const [passwordConforms, setPasswordConforms] = useState(false);
    const [name, setName] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);

    const [displayPasswordRequirements, setDisplayPasswordRequirements] =
        useState(false);

    const [accountInfoAccordionBorder, setAccountInfoAccordionBorder] =
        useState("");
    const [isAccountInfoAccordionOpen, setIsAccountInfoAccordionOpen] =
        useState(true);
    const [isAccountInfoFinished, setIsAccountInfoFinished] = useState(false);
    const [accountInfoNextButtonDisabled, setAccountInfoNextButtonDisabled] =
        useState(true);

    const [verifyEmailAccordionBorder, setVerifyEmailAccordionBorder] =
        useState("");
    const [isVerifyEmailAccordionOpen, setIsVerifyEmailAccordionOpen] =
        useState(false);
    const [isVerifyEmailFinished] = useState(false);
    const [verifyEmailNextButtonDisabled, setVerifyEmailNextButtonDisabled] =
        useState(true);

    const [subscriptionType, setSubscriptionType] = useState("");
    const [paymentSchedule, setPaymentSchedule] = useState("");
    const [width, setWidth] = useState(window.innerWidth);

    const handleRegisterNewUser = useCallback(
        (e) => {
            e.preventDefault();
            handleRegisterUser(
                subscriptionType,
                paymentSchedule,
                user,
                pass,
                name,
                dispatch,
                false,
                x_amzn_marketplace_token
            );
        },
        [user, pass, name, subscriptionType, paymentSchedule, dispatch, x_amzn_marketplace_token]
    );

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.hasOwnProperty("type")) {
            setSubscriptionType(params.type);
        }
        if (params.hasOwnProperty("plan")) {
            setPaymentSchedule(params.plan);
        }
    }, [location]);

    useEffect(() => {
        if (loginError) {
            setTimeout(() => {
                dispatch(setLoginError(null));
            }, 5000);
        }
    }, [loginError, dispatch]);

    useEffect(() => {
        dispatch(setLoginError(null));
        dispatch(setProgressToNextStep(null));
        dispatch(setRedirectToLogin(null));
    }, [dispatch]);

    useEffect(() => {
        determineBorderClass(
            isAccountInfoFinished,
            isAccountInfoAccordionOpen,
            setAccountInfoAccordionBorder
        );
    }, [isAccountInfoFinished, isAccountInfoAccordionOpen]);

    useEffect(() => {
        determineBorderClass(
            isVerifyEmailFinished,
            isVerifyEmailAccordionOpen,
            setVerifyEmailAccordionBorder
        );
    }, [isVerifyEmailFinished, isVerifyEmailAccordionOpen]);

    useEffect(() => {
        switch (progressToNextStep) {
            case "verifyEmail":
                setIsAccountInfoAccordionOpen(false);
                setIsAccountInfoFinished(true);
                setIsVerifyEmailAccordionOpen(true);
                break;
            default:
                break;
        }
    }, [progressToNextStep]);

    useEffect(() => {
        if (redirectToLogin) {
            navigate("/login");
        }
    }, [redirectToLogin, navigate]);

    useEffect(() => {
        passwordConforms && emailConforms
            ? setAccountInfoNextButtonDisabled(false)
            : setAccountInfoNextButtonDisabled(true);
    }, [emailConforms, passwordConforms]);

    useEffect(() => {
        verificationCodeConforms && verificationCode !== ""
            ? setVerifyEmailNextButtonDisabled(false)
            : setVerifyEmailNextButtonDisabled(true);
    }, [verificationCodeConforms, verificationCode]);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    const renderAWSAssociate = () => {
        if (subscriptionType === "aws") {
            return (
                <Link
                    data-testid="login-link"
                    className="blue-text"
                    to={"mailto:oscar@demarq.com?subject=Request to associate AWS Marketplace contract with existing Oscar user" + x_amzn_marketplace_token}
                >
                    Please contact us to associate your account with AWS Marketplace
                </Link>
            );
        } else {
            return (
                <Link
                    data-testid="login-link"
                    className="blue-text"
                    to="/login"
                >
                    Log In
                </Link>
            );
        }

    }

    return (
        <>
            {width < 1024 ? <HeaderHelperPagesComponent /> : null}
            <div
                className="card-container black-background"
                data-testid="registration-card-container"
            >
                <div className="fields-area-registration">
                    <div className="field-margin-bottom">
                        <div className="login-title field-margin-bottom">
                            {subscriptionType === "aws" ? "Create an account to complete your AWS Marketplace purchase" : "Create a free Oscar account to get started"}
                        </div>
                        <div>
                            Already have an Oscar account?{" "}
                            {renderAWSAssociate()}
                        </div>
                    </div>
                    {loginError ? (
                        <div className="error-div" data-testid="error-div">
                            <InlineNotificationError
                                loginError={loginError}
                                hideCloseButton={true}
                                errorType="login-error"
                                pageLocation="registration"
                            />
                        </div>
                    ) : null}
                    <div className="accordion-background field-margin-bottom">
                        <AccordionAccountInfo
                            accountInfoAccordionBorder={
                                accountInfoAccordionBorder
                            }
                            isAccountInfoFinished={isAccountInfoFinished}
                            isAccountInfoAccordionOpen={
                                isAccountInfoAccordionOpen
                            }
                            setUser={setUser}
                            user={user}
                            setEmailConforms={setEmailConforms}
                            emailConforms={emailConforms}
                            setPass={setPass}
                            pass={pass}
                            passwordConforms={passwordConforms}
                            setDisplayPasswordRequirements={
                                setDisplayPasswordRequirements
                            }
                            displayPasswordRequirements={
                                displayPasswordRequirements
                            }
                            setPasswordConforms={setPasswordConforms}
                            handleRegisterNewUser={handleRegisterNewUser}
                            accountInfoNextButtonDisabled={
                                accountInfoNextButtonDisabled
                            }
                            setName={setName}
                            name={name}
                            subscriptionType={subscriptionType}
                        />
                    </div>
                    <div className="accordion-background field-margin-bottom">
                        {isAccountInfoFinished ? (
                            <AccordionVerifyEmail
                                verifyEmailAccordionBorder={
                                    verifyEmailAccordionBorder
                                }
                                isVerifyEmailFinished={isVerifyEmailFinished}
                                isVerifyEmailAccordionOpen={
                                    isVerifyEmailAccordionOpen
                                }
                                user={user}
                                setVerificationCode={setVerificationCode}
                                verificationCode={verificationCode}
                                setVerificationCodeConforms={
                                    setVerificationCodeConforms
                                }
                                verificationCodeConforms={
                                    verificationCodeConforms
                                }
                                verifyEmailNextButtonDisabled={
                                    verifyEmailNextButtonDisabled
                                }
                            />
                        ) : null}
                    </div>
                    <div
                        className="app-version-registration"
                        data-testid="app-version"
                    >
                        version {reactAppVersion}
                    </div>
                </div>
                {width < 1024 ? null : (
                    <React.Fragment>
                        <img
                            className="registration-image-left"
                            src={backgroundImageLeft}
                            alt="background left"
                        />
                        <img
                            className="registration-image"
                            data-testid="background-image"
                            src={backgroundImage}
                            alt="background"
                        />
                    </React.Fragment>
                )}
            </div>
        </>
    );
};

RegistrationCard.propTypes = {};

export default RegistrationCard;
