import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContextMenu from "../../components/context-menu/context-menu.component";
import PickDate from "../../components/pick-date/pick-date.component";
import helperFunctions from "../../components/results-page-components/results-page-components-data-table/data-table-helper-functions";
import { makeTableSchema } from "../../helpers/helper-functions-2.js";
import {
    downloadFileResultsExcelAction,
    patchFileResultsAction,
} from "../../redux/thunks/thunks.actions";
import ValidationColumn from "./results-page-components-data-table/data-table-ignore-validation-column.component";
import ResultsDataTableToolbar from "./results-page-components-data-table/data-table-toolbar.component";
import * as dayjs from 'dayjs';
import { checkIsDateIsValid } from "../../helpers/helper-functions.js";

var CustomParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(CustomParseFormat)


const ResultsDataTable = ({
    currentProjectId,
    currentFileId,
    dataToDisplay,
    setDataToDisplay,
    initialIgnoreValidationList,
    setSplitViewVertical,
    splitViewVertical,
    setSplitViewHorizontal,
    splitViewHorizontal,
    setImageToScrollTo,
    initialBalance,
    gridApiNotUsed,
    setGridApiNotUsed,
    clickedRowIndex,
    setClickedRowIndex,
    clickedColumnId,
    setClickedColumnId,
    multipleRowsSelected,
    setMultipleRowsSelected,
    initialAllImages,
    setTableError,
    setInitialBalance,
    blurButtons,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const gridApi = useRef();

    const newFileDetails = useSelector(
        (state) => state.fileDetails.newFileDetails
    );
    const fileDetails = useSelector((state) => state.fileDetails.fileDetails);
    const fileTablesInit = useSelector((state) => state.files.fileTables);
    const fileResultsInit = useSelector((state) => state.files.fileResults);

    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuOption, setContextMenuOption] = useState(null);
    const [showUndoButton, setShowUndoButton] = useState(false);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [totalValidationChecks, setTotalValidationChecks] = useState(null);
    const [upNextPosition, setUpNextPosition] = useState(0);
    const [downNextPosition, setDownNextPosition] = useState(0);
    const [currentValidationCheckInput, setCurrentValidationCheckInput] =
        useState(null);
    const [dataBeforeDrag, setDataBeforeDrag] = useState(null);
    const [validationList, setValidationList] = useState([]);
    const [filteringTable, setFilteringTable] = useState(false);
    const [selectedRows, setSelectedRows] = useState(0);
    // const [previousBalance, setPreviousBalance] = useState(null);
    const [searchedValue, setSearchedValue] = useState("");

    const [undoEventList, setUndoEventList] = useState([]);

    // const [currentRowIndex, setCurrentRowIndex] = useState();

    const disableFilterFunction = () => "";

    // setValidationChecksList;
    const cellClassRules = useMemo(() => (
        {
            "cell-fail": (params) =>
                helperFunctions.determineDifferenceCellColour(
                    params,
                    validationList,
                    filteringTable
                )
        }
    ), [validationList, filteringTable]);

    const dateCellClassRules = useMemo(() => (
        {
            "cell-fail": (params) =>
                helperFunctions.determineDateCellColour(
                    params,
                    validationList,
                    filteringTable
                ),
        }
    ), [validationList, filteringTable])

    // Helper Functions
    const getAllRowsData = useCallback(() => {
        let rowData = [];
        if (gridApi.current) {
            gridApi.current.forEachNode((node) => {
                rowData.push(node.data)
            });
        }
        return rowData;
    }, []);

    // eslint-disable-next-line
    const getAllUpToDateRows = useCallback(() => {
        if (gridApi.current) {
            let rowData = [];
            gridApi.current.rowModel.rowsToDisplay.forEach((node) =>
                rowData.push(node.data)
            );
            return rowData;
        }
    }, []);

    const validationCheck = useCallback(() => {
        if (gridApi.current) {
            let data = getAllRowsData();

            if (validationList) {
                let validationCheckValidationList =
                    helperFunctions.helperValidationCheck(
                        gridApi.current,
                        data,
                        validationList
                    );

                let filteredValidationCheckValidationList =
                    validationCheckValidationList.filter(
                        (item) => item.ignoreValidation !== true
                    );

                setTotalValidationChecks(
                    filteredValidationCheckValidationList.length
                );
                return validationCheckValidationList;
            }


        }
    }, [getAllRowsData, validationList]);

    const getAllRowsNode = useCallback(() => {
        let rowNode = [];
        gridApi.current.forEachNode((node) => rowNode.push(node));
        return rowNode;
    }, []);

    const onDeselectAllRows = useCallback(() => {
        if (gridApi.current) {
            gridApi.current.deselectAll();
            setSelectedRows(0);
        }
        blurButtons();
    }, [blurButtons]);

    const onDownloadAndSave = (buttonClicked) => {
        const fileResults = getAllRowsData();
        let validationCheckList = validationCheck();

        const finalValidationList = helperFunctions.determineFinalValidationList(
            validationCheckList,
            validationList
        );
        const updatedData = {
            file_results: fileResults,
            ignore_validation_errors: finalValidationList,
        };

        if (buttonClicked === "download") {
            if (
                fileDetails.statement_start_year !==
                newFileDetails.statement_start_year
            ) {
                let tableSchema = defineMakeTableSchema();
                let columnSpec;

                if (fileResultsInit.hasOwnProperty("user_generated_col_spec")) {
                    columnSpec = fileResultsInit.user_generated_col_spec;
                }

                dispatch(
                    downloadFileResultsExcelAction(
                        currentProjectId,
                        currentFileId,
                        updatedData,
                        newFileDetails,
                        tableSchema,
                        columnSpec,
                        navigate
                    )
                );
            } else {
                dispatch(
                    downloadFileResultsExcelAction(
                        currentProjectId,
                        currentFileId,
                        updatedData,
                        newFileDetails
                    )
                );
            }
        } else {
            if (
                fileDetails.statement_start_year !==
                newFileDetails.statement_start_year
            ) {
                let tableSchema = defineMakeTableSchema();
                let columnSpec = [];

                if (fileResultsInit.len !== 0) {
                    columnSpec = fileResultsInit.user_generated_col_spec;
                }

                dispatch(
                    patchFileResultsAction(
                        currentProjectId,
                        currentFileId,
                        updatedData,
                        newFileDetails,
                        tableSchema,
                        columnSpec,
                        navigate
                    )
                );
            } else {
                dispatch(
                    patchFileResultsAction(
                        currentProjectId,
                        currentFileId,
                        updatedData,
                        newFileDetails
                    )
                );
            }
        }
        blurButtons();
        setShowSaveButton(false);
    };

    const undoOptions = useCallback(
        (lastEvent) => {
            let lastEventType = lastEvent.eventType;
            let lastEventDetails = lastEvent.eventDetails;

            if (lastEventType === "cellEdited") {
                var previousValue = lastEventDetails.oldValue;
                var cellEditedColumnId = lastEventDetails.columnId;
                helperFunctions.updateSpecificCell(
                    lastEventDetails.rowIndex,
                    previousValue,
                    `${cellEditedColumnId}`,
                    gridApi.current
                );
            } else if (lastEventType === "addRow") {
                let displayedData = getAllRowsData();
                displayedData.splice(lastEventDetails.rowIndex, 1);
                setDataToDisplay(displayedData);
            } else if (lastEventType === "deleteRow") {
                setDataToDisplay(lastEventDetails.dataBeforeDelete);
            } else if (lastEventType === "swapDirectDebit") {
                helperFunctions.updateSpecificCell(
                    lastEventDetails.rowIndex,
                    lastEventDetails.oldDebitValue,
                    "Debit",
                    gridApi.current
                );
                helperFunctions.updateSpecificCell(
                    lastEventDetails.rowIndex,
                    lastEventDetails.oldCreditValue,
                    "Credit",
                    gridApi.current
                );
            } else if (lastEventType === "copyBalanceToStatementBalance") {
                helperFunctions.updateSpecificCell(
                    lastEventDetails.rowIndex,
                    lastEventDetails.oldValue,
                    "Statement Balance",
                    gridApi.current
                );
            } else if (lastEventType === "dragEvent") {
                setDataToDisplay(lastEventDetails.data);
            } else if (lastEventType === "multipleDragEvent") {
                setDataToDisplay(lastEventDetails.data);
            } else if (lastEventType === "updateDate") {
                setDataToDisplay(lastEventDetails.data);
            } else if (lastEventType === "incrementYear") {
                setDataToDisplay(lastEventDetails.data);
            } else if (lastEventType === "switchCreditDebit") {
                setDataToDisplay(lastEventDetails.data);
            } else if (lastEventType === "editDate") {
                let currentData = getAllRowsData();
                let currentDataCopy = JSON.parse(JSON.stringify(currentData));
                currentDataCopy[lastEventDetails.rowIndex].Date =
                    lastEventDetails.oldValue;
                setDataToDisplay(currentDataCopy);
            } else if (lastEventType === "editStartingBalance") {
                setInitialBalance(lastEventDetails.previousStartingBalance);
                undoCellEditedEvent(
                    0,
                    lastEventDetails.previousStartingBalance,
                    "Balance",
                );
            }

            if (!showSaveButton) {
                setShowSaveButton(true)
            }
        },
        [getAllRowsData, setDataToDisplay, setInitialBalance, showSaveButton]
    );

    const updateValidationList = useCallback((rowToggle) => {
        let validationCheckList = validationCheck();
        let updatedValidationList = helperFunctions.helperUpdateValidationList(
            validationCheckList,
            rowToggle
        );

        setValidationList(updatedValidationList);
    }, [validationCheck]);

    const defineMakeTableSchema = useCallback(() => {
        let finalTableSchema = makeTableSchema(fileTablesInit);

        return finalTableSchema;
    }, [fileTablesInit]);

    // Event Functions
    const onCellClickedEvent = (e) => {
        let columnClicked = e.column.colId;
        let clickedRowIndex = e.node.id;
        let selectedData = getSelectedRowNodes();

        setClickedColumnId(columnClicked);
        setShowContextMenu(false);
        setClickedRowIndex(clickedRowIndex);
        setSelectedRows(selectedData.length);
    };

    const onRowClickedEvent = (e) => {
        // let clickedRowIndex = e.rowIndex;
        let clickedRowId = e.node.id
        let currentRow = gridApi.current.getDisplayedRowAtIndex(clickedRowId);
        let pageNumber = parseFloat(gridApi.current.getValue("PageNumber", currentRow));
        setImageToScrollTo(pageNumber);
        setClickedRowIndex(clickedRowId);
        setShowContextMenu(false);
    };

    const onCellContextMenuEvent = useCallback(
        (event) => {
            let eventType = event.type;

            let AgGridKey = Object.keys(event.target).filter((key) =>
                key.includes("__AG")
            )[0];

            let agGridEvent = event.target[AgGridKey];

            if (eventType === "click") {
                if (agGridEvent) {
                    let rowIndex = agGridEvent.cellCtrl.rowNode.rowIndex;
                    setShowContextMenu(rowIndex);
                }
            } else if (eventType === "contextmenu") {
                event.preventDefault();

                if (agGridEvent) {
                    let colId = agGridEvent.cellCtrl.column.colId;
                    let rowIndex = agGridEvent.cellCtrl.rowNode.rowIndex;
                    setClickedRowIndex(rowIndex);
                    let currentRow = gridApi.current.getDisplayedRowAtIndex(rowIndex);
                    let pageNumber = parseFloat(
                        gridApi.current.getValue("PageNumber", currentRow)
                    );
                    setImageToScrollTo(pageNumber);
                    setClickedColumnId(colId);
                    // adjust the page position to the position of the table
                    // table is bellow the pageY by 8 rem
                    let y = helperFunctions.adjustContextMenuLocation(
                        event.pageY,
                        8
                    );
                    // table is to the left of the pageX by 1 rem
                    let x = helperFunctions.adjustContextMenuLocation(
                        event.pageX,
                        1
                    );
                    setAnchorPoint({ x: x, y: y });
                    setShowContextMenu(true);
                }
            }
            validationCheck();
        },
        [
            setAnchorPoint,
            setShowContextMenu,
            setImageToScrollTo,
            setClickedColumnId,
            setClickedRowIndex,
            validationCheck,
        ]
    );

    const setNewUndoList = useCallback(
        (eventType, eventDetails) => {
            let updatedUndoEventList = helperFunctions.defineEventDetails(
                eventType,
                eventDetails,
                undoEventList
            );

            setUndoEventList(updatedUndoEventList);
        },
        [undoEventList, setUndoEventList]
    );

    const onRowDragEnd = useCallback(
        (e) => {
            let currentIndex = e.overIndex;

            let eventDetails = {
                data: dataBeforeDrag,
            };

            if (gridApi.current.getSelectedRows().length <= 1) {
                if (currentIndex !== 0) {
                    setNewUndoList("dragEvent", eventDetails);
                } else {
                    setDataToDisplay(dataBeforeDrag);
                    setTableError(
                        "Rows cannot be moved to the first row of the table"
                    );
                }
            } else {
                if (currentIndex !== 0) {
                    setNewUndoList("multipleDragEvent", eventDetails);
                } else {
                    setDataToDisplay(dataBeforeDrag);
                    setTableError(
                        "Rows cannot be moved to the first row of the table"
                    );
                }
            }
            gridApi.current.refreshCells();
            validationCheck();
        },
        [
            setDataToDisplay,
            dataBeforeDrag,
            setNewUndoList,
            setTableError,
            validationCheck,
        ]
    );

    const onRowDragEnter = useCallback(
        (e) => {
            let data = [];
            let dataCopy = JSON.parse(JSON.stringify(getAllRowsData()));
            for (let row of dataCopy) {
                let newRowObject = helperFunctions.createNewRowObject(
                    "dragEvent",
                    row
                );
                data.push(newRowObject);
            }
            setDataBeforeDrag(data);
            // setIndexBeforeDragging(e.overIndex);
        },
        [getAllRowsData, setDataBeforeDrag]
    );

    const onFilterTextBoxChanged = useCallback(
        (e) => {
            let value = e.target.value;
            if (gridApi.current) {
                gridApi.current.setQuickFilter(value);
                gridApi.current.refreshCells();
                setSearchedValue(value);
            }
            
            if (value !== "") {
                setFilteringTable(true);
                gridApi.current.refreshCells();
            } else {
                setFilteringTable(false);
                gridApi.current.refreshCells();
            }
            // gridApi.current.refreshCells();

            console.log("Filtering, setting columns to width")
            gridApi.current.sizeColumnsToFit();
        },
        [setFilteringTable, gridApi]
    );

    const onGridReady = (params) => {
        gridApi.current = params.api
    };

    const getSelectedRowNodes = useCallback(() => {
        let selectedNodes = gridApi.current.getSelectedNodes();
        let selectedData = selectedNodes.map((node) => node);
        return selectedData;
    }, []);

    const defineCellEditedEvent = useCallback(
        (oldValue, rowIndex, columnId) => {
            let updatedUndoEventListCopy =
                helperFunctions.configureCellEditedEvent(
                    oldValue,
                    rowIndex,
                    columnId,
                    undoEventList
                );

            setUndoEventList(updatedUndoEventListCopy);
        },
        [undoEventList, setUndoEventList]
    );

    const handleOnCellValueChanged =
        (event) => {

            let changedColumn = event.colDef.colId

            if (["Debit", "Credit"].includes(changedColumn)) {

                // Need to update the Balance and Difference columns

                let oldBalance = dataToDisplay[event.rowIndex].Balance
                let oldValue = event.oldValue ? parseFloat(event.oldValue) : 0
                let newValue = event.newValue ? parseFloat(event.newValue) : 0
                let newBalance = parseFloat(oldBalance) - parseFloat(oldValue) + parseFloat(newValue)

                const updateData = (index, newBalance) => {
                    var previousBalance
                    const updatedDataArray = dataToDisplay.map((item, i, array) => {
                        if (i === index) {
                            item.Balance = newBalance
                            let newDifference = parseFloat(item["Statement Balance"]) - parseFloat(item.Balance)
                            item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                            previousBalance = newBalance
                        } else if (i > index) {
                            let value = item.Value ? parseFloat(item.Value) : 0
                            let updatedBalance = parseFloat(previousBalance) + value
                            item.Balance = updatedBalance
                            let newDifference = parseFloat(item["Statement Balance"]) - parseFloat(item.Balance)
                            item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                            previousBalance = updatedBalance
                        }
                        return item
                    })
                    setDataToDisplay(updatedDataArray)
                }

                updateData(event.rowIndex, newBalance)

                defineCellEditedEvent(event.oldValue, event.rowIndex, changedColumn)

            } else if (changedColumn === 'Statement Balance') {

                // Need to update the Difference column

                const updateData = (index) => {
                    const updatedDataArray = dataToDisplay.map((item, i) => {
                        if (i >= index) {
                            let newDifference = parseFloat(item["Statement Balance"]) - parseFloat(item.Balance)
                            item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                            return item
                        }
                        return item
                    })
                    setDataToDisplay(updatedDataArray)

                }

                updateData(event.rowIndex)
                defineCellEditedEvent(event.oldValue, event.rowIndex, changedColumn)

            } else if (changedColumn === "Balance") {

                // Starting balance updated.  Need to recalculate Balance and Difference

                const updateData = () => {
                    const updatedDataArray = dataToDisplay.map((item, i, array) => {
                        if (i > 0) {
                            let newBalance = parseFloat(array[i - 1].Balance) + parseFloat(item.Value)
                            item.Balance = ["", "NaN"].includes(String(newBalance)) ? "" : newBalance

                            let newDifference = parseFloat(item["Statement Balance"]) - newBalance
                            item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                        }
                        return item
                    }
                    )
                    setDataToDisplay(updatedDataArray)

                }

                updateData(event.rowIndex)
                defineCellEditedEvent(event.oldValue, event.rowIndex, changedColumn)

            } else if (changedColumn === "Date") {
                const isDate = Date.parse(event.newValue);
                if (isNaN(isDate)) {
                    undoCellEditedEvent(event.data.id, event.oldValue, changedColumn);
                    setTableError("The edited value is not a valid date");
                } else {
                    let eventDetails = {
                        rowIndex: event.rowIndex,
                        oldValue: event.oldValue,
                        newValue: event.newValue,
                    };
                    setNewUndoList("editDate", eventDetails);
                    updateDate(event.rowIndex, event.newValue)
                }
            } else if (changedColumn === "Description") {
                defineCellEditedEvent(event.oldValue, event.rowIndex, changedColumn)
            }

            adjustColumnWidth();
        }
    

    //         var numbers = /^[0-9]+$/;

    //         let newValue = event.newValue;
    //         let oldValue = event.oldValue;
    //         let rowIndex = event.node.id;
    //         let columnId = event.colDef.colId;

    //         let numericColumnList = ["Debit", "Credit", "Statement Balance"];

    //         if (columnId === "PageNumber") {
    //             if (numbers.test(newValue)) {
    //                 if (initialAllImages !== null) {
    //                     let maxPageNumber = initialAllImages.length;
    //                     if (newValue > maxPageNumber || newValue <= 0) {
    //                         undoCellEditedEvent(
    //                             rowIndex,
    //                             oldValue,
    //                             columnId
    //                         );
    //                         setTableError(
    //                             `The page number must be between 1 and ${maxPageNumber}`
    //                         );
    //                     } else {
    //                         defineCellEditedEvent(oldValue, rowIndex, columnId);
    //                     }
    //                 }
    //             } else {
    //                 undoCellEditedEvent(rowIndex, oldValue, columnId);
    //                 setTableError("The edited value is not a number");
    //             }
    //         } else if (numericColumnList.includes(columnId)) {
    //             let parsedNewValue = parseFloat(newValue);

    //             if (newValue === "") {
    //                 defineCellEditedEvent(oldValue, rowIndex, columnId);
    //             } else {
    //                 if (isNaN(parsedNewValue)) {
    //                     undoCellEditedEvent(
    //                         rowIndex,
    //                         oldValue,
    //                         columnId,
    //                     );
    //                     setTableError("The edited value is not a number");
    //                 } else {
    //                     defineCellEditedEvent(oldValue, rowIndex, columnId);
    //                 }
    //             }
    //         } else if (columnId === "Date") {
    //             const isDate = Date.parse(newValue);
    //             if (isNaN(isDate)) {
    //                 undoCellEditedEvent(rowIndex, oldValue, columnId);
    //                 setTableError("The edited value is not a valid date");
    //             } else {
    //                 let eventDetails = {
    //                     rowIndex: rowIndex,
    //                     oldValue: oldValue,
    //                     newValue: newValue,
    //                 };
    //                 setNewUndoList("editDate", eventDetails);
    //             }
    //         } else if (columnId === "Balance") {
    //             let parsedNewValue = parseFloat(newValue);

    //             if (isNaN(parsedNewValue)) {
    //                 setInitialBalance(previousBalance);
    //                 setTableError("The edited value is not a number");
    //             } else {
    //                 let eventDetails = {
    //                     previousStartingBalance: previousBalance,
    //                 };
    //                 setNewUndoList("editStartingBalance", eventDetails);
    //             }
    //         } else {
    //             defineCellEditedEvent(oldValue, rowIndex, columnId);
    //         }
    //         validationCheck();
    //     },
    //     [
    //         initialAllImages,
    //         defineCellEditedEvent,
    //         setTableError,
    //         validationCheck,
    //         setNewUndoList,
    //         previousBalance,
    //         setInitialBalance,
    //     ]
    // );

    const undoCellEditedEvent = (rowIndex, oldValue, columnId) => {
        helperFunctions.updateSpecificCell(
            rowIndex,
            oldValue,
            `${columnId}`,
            gridApi.current
        );
    };

    const adjustColumnWidth = useCallback(() => {
        if (gridApi.current) {
            console.log("Adjusting column width to fit")
            gridApi.current.sizeColumnsToFit();
            // gridApi.current.refreshCells();
            blurButtons();
        }
    }, [blurButtons]);

    const onArrowUp = useCallback(() => {
        let validationCheckList = validationCheck();
        let validationCheckListCopy = validationCheckList.filter(
            (validationElement) => validationElement.ignoreValidation !== true
        );
        let newIndex = upNextPosition - 1;

        if (newIndex < 0) {
            newIndex = 0;
        }

        if (newIndex >= 0 && newIndex <= validationCheckListCopy.length - 1) {
            let currentValidationCheck = validationCheckListCopy[newIndex];

            gridApi.current.ensureIndexVisible(currentValidationCheck.rowIndex);

            gridApi.current.setFocusedCell(
                currentValidationCheck.rowIndex,
                currentValidationCheck.colKey
            );
            setUpNextPosition(newIndex);
            setDownNextPosition(newIndex + 1);
        }
        blurButtons();
    }, [
        gridApi,
        validationCheck,
        setDownNextPosition,
        setUpNextPosition,
        upNextPosition,
        blurButtons,
    ]);

    const onArrowDown = useCallback(() => {
        let validationCheckList = validationCheck();

        let validationCheckListCopy = validationCheckList.filter(
            (validationElement) => validationElement.ignoreValidation !== true
        );
        let newIndex = downNextPosition;

        if (newIndex > validationCheckListCopy.length - 1) {
            newIndex = validationCheckListCopy.length - 1;
        }

        if (newIndex >= 0 && newIndex <= validationCheckListCopy.length - 1) {
            let currentValidationCheck = validationCheckListCopy[newIndex];

            gridApi.current.ensureIndexVisible(currentValidationCheck.rowIndex);

            gridApi.current.setFocusedCell(
                currentValidationCheck.rowIndex,
                currentValidationCheck.colKey
            );

            setUpNextPosition(newIndex);
            setDownNextPosition(newIndex + 1);
        }
        blurButtons();
    }, [
        gridApi,
        validationCheck,
        downNextPosition,
        setDownNextPosition,
        setUpNextPosition,
        blurButtons,
    ]);

    // Context Menu functions
    const swapDirectDebit = useCallback(
        (rowIndex) => {
            let currentRow = gridApi.current.getDisplayedRowAtIndex(rowIndex);
            let previousDebitValue = parseFloat(
                gridApi.current.getValue("Debit", currentRow)
            );
            let previousCreditValue = parseFloat(
                gridApi.current.getValue("Credit", currentRow)
            );

            helperFunctions.updateSpecificCell(
                currentRow.id,
                previousCreditValue,
                "Debit",
                gridApi.current
            );
            helperFunctions.updateSpecificCell(
                currentRow.id,
                previousDebitValue,
                "Credit",
                gridApi.current
            );

            setShowContextMenu(false);
            setContextMenuOption(null);
            setUpNextPosition(0);
            setDownNextPosition(0);

            let eventType = "swapDirectDebit";
            let eventDetails = {
                rowIndex: currentRow.id,
                oldDebitValue: previousDebitValue,
                oldCreditValue: previousCreditValue,
            };
            setNewUndoList(eventType, eventDetails);
            validationCheck();
        },
        [
            setShowContextMenu,
            gridApi,
            setContextMenuOption,
            setNewUndoList,
            setUpNextPosition,
            setDownNextPosition,
            validationCheck,
        ]
    );

    const copyBalanceToStatementBalance = useCallback(
        (rowIndex) => {
            let currentRow = gridApi.current.getDisplayedRowAtIndex(rowIndex);
            let previousBalanceValue = parseFloat(
                gridApi.current.getValue("Balance", currentRow)
            );
            let previousStatementBalanceValue = parseFloat(
                gridApi.current.getValue("Statement Balance", currentRow)
            );

            const updatedPreviousBalanceValue =
                String(previousBalanceValue) === "NaN"
                    ? null
                    : previousBalanceValue;

            helperFunctions.updateSpecificCell(
                rowIndex,
                updatedPreviousBalanceValue,
                "Statement Balance",
                gridApi.current
            );
            let eventType = "copyBalanceToStatementBalance";
            let eventDetails = {
                rowIndex: rowIndex,
                oldValue: previousStatementBalanceValue,
            };
            setNewUndoList(eventType, eventDetails);
            setShowContextMenu(false);
            setContextMenuOption(null);
            setUpNextPosition(0);
            setDownNextPosition(0);
            validationCheck();
        },
        [
            setShowContextMenu,
            gridApi,
            setContextMenuOption,
            setNewUndoList,
            setUpNextPosition,
            setDownNextPosition,
            validationCheck,
        ]
    );

    const incrementYear = useCallback(
        (rowIndex, amount) => {

            let eventDetails = {
                data: JSON.parse(JSON.stringify(getAllRowsData()))
            };

            if (rowIndex === 0) {
                alert("Not valid here.");
            } else {
                const updatedDataArray = dataToDisplay.map((item, i) => {
                    if (i >= rowIndex) {
                        const isDate = checkIsDateIsValid(item["Date"]);

                        if (isDate) {
                            let newDate = dayjs(item["Date"])
                            let incrementedDate = newDate.add(amount, 'year')
                            item["Date"] = incrementedDate.format('YYYY-MM-DD')
                        }
                        return item
                    }
                    return item
                })
                setDataToDisplay(updatedDataArray)
            }
            setShowContextMenu(false);
            setContextMenuOption(null);
            setUpNextPosition(0);
            setDownNextPosition(0);
            setNewUndoList("incrementYear", eventDetails);
        }
        , [dataToDisplay, setDataToDisplay, setNewUndoList, getAllRowsData]
    )

    // Button Functions
    const addRow = useCallback(() => {
        let dataCopy = JSON.parse(JSON.stringify(getAllRowsData()));

        if (clickedRowIndex === 0) {
            alert("Cannot add a row above the first row.");
        } else if (clickedRowIndex === null) {
            alert(
                "Please click on a row. A new row will be added above the selected row."
            );
        } else if (clickedRowIndex !== 0 || clickedRowIndex !== null) {
            let newRowObject = helperFunctions.createNewRowObject(
                "addRow",
                null
            );
            dataCopy.splice(clickedRowIndex, 0, newRowObject);
            setDataToDisplay(dataCopy);
            let eventType = "addRow";
            let eventDetails = {
                newRowObject: newRowObject,
                rowIndex: clickedRowIndex,
            };

            setNewUndoList(eventType, eventDetails);
            setClickedRowIndex(null);
        }
        validationCheck();
        blurButtons();
    }, [
        setDataToDisplay,
        setNewUndoList,
        setClickedRowIndex,
        clickedRowIndex,
        getAllRowsData,
        validationCheck,
        blurButtons,
    ]);

    const switchCreditDebit = useCallback(() => {
        let eventDetails = {
            data: JSON.parse(JSON.stringify(getAllRowsData()))
        };
        var updatedDataArray
        let selectedRows = getSelectedRowNodes();

        if (selectedRows.length > 0) {
            let selectedRowsIndex = selectedRows.map(row => row.rowIndex);
            updatedDataArray = dataToDisplay.map((item) => {
                 if (selectedRowsIndex.includes(item.id)) {
                    let orig_credit = item["Credit"]
                    item["Credit"] = item["Debit"]
                    item["Debit"] = orig_credit
                    return item
                 }
                 return item
            })
        } else {
            updatedDataArray = dataToDisplay.map((item, i) => {
                let orig_credit = item["Credit"]
                item["Credit"] = item["Debit"]
                item["Debit"] = orig_credit
                return item
            })
        }

        setDataToDisplay(updatedDataArray)
        setNewUndoList("switchCreditDebit", eventDetails);
        blurButtons();
    }
    , [dataToDisplay, setDataToDisplay, setNewUndoList, blurButtons, getAllRowsData, getSelectedRowNodes]
    )

    const deleteRow = useCallback(() => {
        let selectedRows = getSelectedRowNodes();
        let selectedRowsIndex = selectedRows.map((row) => row.rowIndex);
        let dataBeforeDelete = JSON.parse(JSON.stringify(getAllRowsData()));

        let eventType = "deleteRow";
        let eventDetails = {
            dataBeforeDelete: dataBeforeDelete,
        };

        if (selectedRowsIndex.length === 0) {
            alert(
                "Please click on a row to delete or select several rows for deletion"
            );
        } else if (selectedRowsIndex.length === 1) {
            if (selectedRowsIndex[0] === 0) {
                alert("Cannot delete the first row");
            } else {
                let updatedSelectedRows = JSON.parse(
                    JSON.stringify(selectedRowsIndex)
                ).filter((rowIndex) => rowIndex !== 0);
                let dataAfterDelete = getAllRowsNode()
                    .filter((row) => !updatedSelectedRows.includes(row.rowIndex))
                    .map((row) => row.data);

                // Recalculate balance and difference

                let updatedAfterDelete = dataAfterDelete.map((item, i, array) => {
                    if (i === 0) {
                    } else {
                        // Balance
                        let value = item.Value ? parseFloat(item.Value) : 0
                        let updatedBalance = parseFloat(array[i - 1].Balance) + value
                        item.Balance = updatedBalance

                        // Difference
                        let newDifference = parseFloat(item["Statement Balance"]) - parseFloat(item.Balance)
                        item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                    }
                    return item
                })

                setDataToDisplay(updatedAfterDelete);
                setNewUndoList(eventType, eventDetails);
                onDeselectAllRows();
            }
        } else {
            let updatedSelectedRows = JSON.parse(
                JSON.stringify(selectedRowsIndex)
            ).filter((rowIndex) => rowIndex !== 0);
            let dataAfterDelete = getAllRowsNode()
                .filter((row) => !updatedSelectedRows.includes(row.rowIndex))
                .map((row) => row.data);

            // Recalculate balance and difference

            let updatedAfterDelete = dataAfterDelete.map((item, i, array) => {
                if (i > 0) {

                    // Balance
                    let value = item.Value ? parseFloat(item.Value) : 0
                    let updatedBalance = parseFloat(array[i - 1].Balance) + value
                    item.Balance = updatedBalance

                    // Difference
                    let newDifference = parseFloat(item["Statement Balance"]) - parseFloat(item.Balance)
                    item.Difference = ["", "NaN"].includes(String(newDifference)) ? "" : newDifference
                }
                return item
            })

            setDataToDisplay(updatedAfterDelete);
            setNewUndoList(eventType, eventDetails);
            onDeselectAllRows();
        }

        setClickedRowIndex(null);
        validationCheck();
        blurButtons();

        // setCurrentRowIndex(selectedRowsIndex[0])

    }, [
        setNewUndoList,
        getAllRowsData,
        getAllRowsNode,
        getSelectedRowNodes,
        onDeselectAllRows,
        setClickedRowIndex,
        setDataToDisplay,
        validationCheck,
        blurButtons,
    ]);

    const handleUndo = useCallback(() => {
        let undoEventListCopy = JSON.parse(JSON.stringify(undoEventList));

        let lastEventIndex = undoEventList.length - 1;
        let lastEvent = undoEventList[lastEventIndex];

        undoOptions(lastEvent);
        undoEventListCopy.pop();
        setUndoEventList(undoEventListCopy);
        setClickedRowIndex(null);
        setUpNextPosition(0);
        setDownNextPosition(0);
        validationCheck();
        blurButtons();
    }, [
        undoEventList,
        setUndoEventList,
        undoOptions,
        setUpNextPosition,
        setDownNextPosition,
        setClickedRowIndex,
        validationCheck,
        blurButtons,
    ]);

    const onSplitViewVertical = useCallback(() => {
        setSplitViewVertical(!splitViewVertical);
        setSplitViewHorizontal(false);
        setImageToScrollTo(1);
        blurButtons();
    }, [
        setSplitViewVertical,
        splitViewVertical,
        setSplitViewHorizontal,
        setImageToScrollTo,
        blurButtons,
    ]);

    const onSplitViewHorizontal = useCallback(() => {
        setSplitViewHorizontal(!splitViewHorizontal);
        setSplitViewVertical(false);
        setImageToScrollTo(1);
        blurButtons();
    }, [
        setSplitViewHorizontal,
        splitViewHorizontal,
        setSplitViewVertical,
        setImageToScrollTo,
        blurButtons,
    ]);

    const updateDate = useCallback((rowIndex, newDate) => {
        let currentData = getAllRowsData();

        if (currentData[rowIndex]) {
            let eventDetails = {
                data: currentData,
            };
            setNewUndoList("updateDate", eventDetails);
            let currentDataCopy = JSON.parse(JSON.stringify(currentData));
            currentDataCopy[rowIndex].Date = newDate;
            setDataToDisplay(currentDataCopy);
            validationCheck();
        }

    }, [getAllRowsData, setDataToDisplay, setNewUndoList, validationCheck]);

    // const onCategorise = useCallback(() => {
    //     navigate(`/categorisation/${currentProjectId}/${currentFileId}`);
    // }, [currentProjectId, currentFileId, navigate]);

    // Use Effects
    useEffect(() => {
        if (
            initialIgnoreValidationList === null ||
            initialIgnoreValidationList === undefined
        ) {
            setValidationList([]);
        } else {
            let initialIgnoreValidationListCopy = JSON.parse(
                JSON.stringify(initialIgnoreValidationList)
            );
            setValidationList(initialIgnoreValidationListCopy);
        }
    }, [initialIgnoreValidationList, setValidationList]);

    useEffect(() => {
        if (contextMenuOption === "handleSwapCreditDebit") {
            swapDirectDebit(clickedRowIndex);
        } else if (contextMenuOption === "handleCopyBalanceToStatementBalance") {
            copyBalanceToStatementBalance(clickedRowIndex);
        } else if (contextMenuOption === "handleIncrementYear") {
            incrementYear(clickedRowIndex, 1);
        } else if (contextMenuOption === "handleDecrementYear") {
            incrementYear(clickedRowIndex, -1);
        }
        validationCheck();
    }, [
        contextMenuOption,
        clickedColumnId,
        clickedRowIndex,
        copyBalanceToStatementBalance,
        swapDirectDebit,
        validationCheck,
        incrementYear
    ]);

    useEffect(() => {
        if (undoEventList.length === 0) {
            setShowUndoButton(false);
            //setShowSaveButton(false);
        } else {
            setShowUndoButton(true);
            setShowSaveButton(true);
        }
    }, [undoEventList]);

    useEffect(() => {
        const currentValidationCheckInput = downNextPosition;
        let updatedCurrentIndex;

        if (
            currentValidationCheckInput <= 0 ||
            currentValidationCheckInput > totalValidationChecks
        ) {
            updatedCurrentIndex = "";
        } else {
            updatedCurrentIndex = currentValidationCheckInput;
        }
        setCurrentValidationCheckInput(updatedCurrentIndex);
    }, [downNextPosition, totalValidationChecks]);

    // useEffect(() => {
    //     if (gridApi.current) {
    //         if (location.pathname.split("/")[1] === "results") {
    //             window.addEventListener("resize", adjustColumnWidth);
    //         } else {
    //             window.removeEventListener("resize", adjustColumnWidth);
    //         }

    //         if (currentRowIndex) {
    //             gridApi.current.ensureIndexVisible(currentRowIndex, 'middle');
    //             setCurrentRowIndex(undefined)
    //         }
    //     }
    // }, [gridApi, currentRowIndex, location.pathname, adjustColumnWidth]);

    useEffect(() => {
        validationCheck();
    }, [gridApi, validationCheck, totalValidationChecks]);

    useEffect(() => {
        let validationList = [null, undefined, 0];
        !validationList.includes(selectedRows)
            ? setMultipleRowsSelected(true)
            : setMultipleRowsSelected(false);
    }, [selectedRows, setMultipleRowsSelected]);

    // useEffect(() => {
    //     adjustColumnWidth();
    // }, [adjustColumnWidth]);

    const IgnoreValidationCellRendererParams = useMemo(() => (
        {
            updateValidationList: updateValidationList,
            validationList: validationList,
            filteringTable: filteringTable,
        }
    ), [updateValidationList, validationList, filteringTable]);

    const dateCellRendererParams = useMemo(() => (
        { updateDate: updateDate, }
    ), [updateDate]);

    const columnDefs = useMemo(() => (
        [
            {
                field: "Date",
                colId: "Date",
                sortable: true,
                filter: true,
                editable: (params) => params.node.rowIndex !== 0,
                rowDrag: (params) => params.node.rowIndex !== 0,
                cellRendererParams: dateCellRendererParams,
                cellRenderer: PickDate,
                cellClassRules: dateCellClassRules,
                width: 300,
            },
            {
                field: "Type",
                colId: "Type",
                filter: true,
                editable: (params) => params.node.rowIndex !== 0,
                width: 150
            },
            {
                field: "Description",
                colId: "Description",
                filter: true,
                editable: (params) => params.node.rowIndex !== 0,
                width: 400,
                headerTooltip: "Transaction Description",
            },
            {
                field: "Debit",
                colId: "Debit",
                getQuickFilterText: disableFilterFunction,
                editable: (params) => params.node.rowIndex !== 0,
                valueFormatter: (params) => helperFunctions.formatToTwoDecimalPlaces(params)
            },
            {
                field: "Credit",
                colId: "Credit",
                getQuickFilterText: disableFilterFunction,
                editable: (params) => params.node.rowIndex !== 0,
                valueFormatter: (params) => helperFunctions.formatToTwoDecimalPlaces(params)
            },
            {
                headerName: "Value",
                colId: "Value",
                getQuickFilterText: disableFilterFunction,
                valueGetter: (params) => helperFunctions.valueColumnValueGetter(params)
            },
            {
                field: "Statement Balance",
                colId: "Statement Balance",
                getQuickFilterText: disableFilterFunction,
                editable: (params) => params.node.rowIndex !== 0,
                valueFormatter: (params) => helperFunctions.formatToTwoDecimalPlaces(params),
                headerTooltip: "Statement Balance"
            },
            {
                field: "Balance",
                colId: "Balance",
                getQuickFilterText: disableFilterFunction,
                valueGetter: (params) => helperFunctions.balanceColumnValueGetter(
                    params,
                    initialBalance
                ),
                valueSetter: (params) => helperFunctions.balanceColumnValueSetter(
                    params,
                    setInitialBalance,
                ),
                editable: (params) => params.node.rowIndex === 0,
                valueFormatter: (params) =>
                    helperFunctions.formatToTwoDecimalPlaces(params),
                headerTooltip: "Balance"
            },
            {
                field: "Difference",
                colId: "Difference",
                getQuickFilterText: disableFilterFunction,
                valueGetter: (params) =>
                    helperFunctions.differenceColumnValueGetter(params)
                ,
                valueFormatter:
                    (params) =>
                        helperFunctions.formatToTwoDecimalPlaces(params)
                ,
                cellClassRules: cellClassRules,
                headerTooltip: "Difference",
            },
            {
                field: "PageNumber",
                colId: "PageNumber",
                sortable: true,
                filter: true,
                editable: (params) => params.node.rowIndex !== 0,
                headerTooltip: "Page Number"
            },
            {
                field: "Ignore Validation",
                colId: "IgnoreValidation",
                getQuickFilterText: disableFilterFunction,
                cellRenderer: ValidationColumn,
                cellRendererParams: IgnoreValidationCellRendererParams,
                headerTooltip: "Ignore Validation Warnings"
            }
        ]
    ), [cellClassRules, dateCellClassRules, dateCellRendererParams, initialBalance, setInitialBalance, IgnoreValidationCellRendererParams]);

    const getRowId = useMemo(() => {
        return (params) => {
            var rowId
            if (params.data) {
                rowId = params.data.id
            } else {
                rowId = 1
            }
            return rowId
        };
    }, []);


    return (
        <>
            <div
                className={`
                    results-page-table-image-div 
                    ag-theme-material
                `}
                data-testid="results-page-data-table"
                onContextMenu={(e) => onCellContextMenuEvent(e)}
            >
                <ResultsDataTableToolbar
                    onFilterTextBoxChanged={onFilterTextBoxChanged}
                    addRow={addRow}
                    deleteRow={deleteRow}
                    handleUndo={handleUndo}
                    showUndoButton={showUndoButton}
                    onSplitViewVertical={onSplitViewVertical}
                    onSplitViewHorizontal={onSplitViewHorizontal}
                    onArrowUp={onArrowUp}
                    onArrowDown={onArrowDown}
                    totalValidationChecks={totalValidationChecks}
                    currentValidationCheckInput={currentValidationCheckInput}
                    onDeselectAllRows={onDeselectAllRows}
                    multipleRowsSelected={multipleRowsSelected}
                    handleDownloadAndSave={onDownloadAndSave}
                    splitViewVertical={splitViewVertical}
                    splitViewHorizontal={splitViewHorizontal}
                    filteringTable={filteringTable}
                    searchedValue={searchedValue}
                    switchCreditDebit={switchCreditDebit}
                    showSaveButton={showSaveButton}
                    adjustColumnWidth={adjustColumnWidth}
                />
                <AgGridReact
                    data-testid="results-page-data-table"
                    defaultColDef={{
                        sortable: false,
                        filter: false,
                        resizable: true,
                    }}
                    rowDragManaged={true}
                    rowDragMultiRow={true}
                    rowSelection={"multiple"}
                    rowMultiSelectWithClick={true}
                    onGridReady={(params) => onGridReady(params)}
                    rowData={dataToDisplay}
                    onRowDragEnd={onRowDragEnd}
                    onRowDragEnter={onRowDragEnter}
                    columnHoverHighlight={true}
                    onRowClicked={(e) => onRowClickedEvent(e)}
                    onCellClicked={(e) => onCellClickedEvent(e)}
                    onCellValueChanged={(e) => handleOnCellValueChanged(e)}
                    onFirstDataRendered={adjustColumnWidth}
                    suppressMoveWhenRowDragging={true}
                    columnDefs={columnDefs}
                    valueCache={true}
                    getRowId={getRowId}
                >

                </AgGridReact>
                <ContextMenu
                    anchorPoint={anchorPoint}
                    showContextMenu={showContextMenu}
                    setContextMenuOption={setContextMenuOption}
                />
            </div>
        </>
    );
};

export default ResultsDataTable;
