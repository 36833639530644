const pre = "user";
const SET_USER = `${pre}/SET_USER`;
const CLEAR_USER = `${pre}/CLEAR_USER`;
const SET_USER_NOTIFICATION = `${pre}/SET_USER_NOTIFICATION`;


const userTypes = {
    SET_USER,
    CLEAR_USER,
    SET_USER_NOTIFICATION,
};

export default userTypes;
