import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gridInfoUnConvert } from "../../helpers/helper-functions";
import PDFImage from "./pdf-image.component";

const AllPDFImages = ({
    combinedInfo,
    pageToScrollTo,
    resetMaskSideBarInfo,
    setPageToScrollTo,
    setPageDisplayed,
    setZoomInFunction,
    setZoomOutFunction,
    setResetTransformFunction,
    disablePlaceGrid,
    placeGrid,
    allWarningGrids,
    setAllWarningGrids,
    setSelectedObject,
    selectedObject,
    setDeleteLineFunction,
    setDeleteRectFunction,
    setClearRectFunction,
    setBackEndSubmitGrids,
    addColumnTables,
    removeColumnTables,
    maxColumnsInfo,
    setMaxColumnsInfo,
    recheckColSpec,
    setResetMaskSideBarInfo,
    setUndoEventList,
    undoEventList,
    undo,
    setUndo,
    undoRunXTimes,
    setUndoRunXTimes,
    columnSpec,
    setSelectedPageUrl,
    setSelectedRect,
    autoGridColsRows,
    setAutoGridColsRows,
    isSideNavBarInfoOpen,
    setIsSideNavBarInfoOpen,
    setNavInfoToDisplay,
    undoAutoGrid,
    setUndoAutoGrid,
    blurButtons,
    setAllDocumentWarnings,
    autoMergeColsRows,
    setAutoMergeColsRows,
    runAutoMerge,
    setRunAutoMerge,
    setIsAutoMergeButtonDisabled,
}) => {
    const fileDetails = useSelector((state) => state.fileDetails.fileDetails);
    const [multiplePageGrids, setMultiplePageGrids] = useState([]);
    const [newColumn, setNewColumn] = useState(null);

    const pageGridUpdate = () => {}
    // const pageGridUpdate = useCallback(
    //     (gridInfos, pdfWidth, pdfHeight, pageNumber) => {
    //         console.log(pageNumber)
    //         const pageGrids = {
    //             grids: gridInfos,
    //             pdfWidth: pdfWidth,
    //             pdfHeight: pdfHeight,
    //             pageNumber: pageNumber,
    //         };

    //         const multiplePageGridsWithoutNewPage = multiplePageGrids.filter(
    //             (gridsInfo) => gridsInfo["pageNumber"] !== pageNumber
    //         );

    //         const oldInfo = multiplePageGrids.filter(
    //             (gridsInfo) => gridsInfo["pageNumber"] === pageNumber
    //         )[0];

    //         if (JSON.stringify(oldInfo) !== JSON.stringify(pageGrids)) {
    //             console.log("Are we here?")
    //             setMultiplePageGrids(
    //                 JSON.parse(
    //                     JSON.stringify([
    //                         ...multiplePageGridsWithoutNewPage,
    //                         pageGrids,
    //                     ])
    //                 )
    //             );
    //         }
    //     },
    //     [multiplePageGrids]
    // );

    const calculateMaxColumns = (multipleGridPages) => {
        let maxColumns = 0;
        let repeatMax = 0;

        for (let pageGrids of multipleGridPages) {
            for (let grid of pageGrids["grids"]) {
                if (grid["vLines"].length + 1 > maxColumns) {
                    maxColumns = grid["vLines"].length + 1;
                    repeatMax = 1;
                } else if (grid["vLines"].length + 1 === maxColumns) {
                    repeatMax = repeatMax + 1;
                }
            }
        }

        return { maxColumns: maxColumns, repeatMax: repeatMax };
    };

    useEffect(() => {
        if (combinedInfo.length !== 0) {
            if (combinedInfo.length === multiplePageGrids.length) {
                const maxColumnsInfo = calculateMaxColumns(multiplePageGrids);
                let warningGrids = [];
                let documentWarnings = [];
                for (let pageGrids of multiplePageGrids) {
                    let pageNumber = pageGrids["pageNumber"];

                    for (let grid of pageGrids["grids"]) {
                        if (
                            grid["vLines"].length + 1 <
                            maxColumnsInfo["maxColumns"]
                        ) {
                            warningGrids.push({
                                gridWarning: {
                                    gridId: grid["rect"]["id"],
                                    colourWarning: true,
                                    message: "Too few columns",
                                },
                                pageId: pageNumber,
                            });
                        }
                    }
                }

                for (let warningGrid of warningGrids) {
                    let warningGridPageNumber = warningGrid.pageId;
                    if (!documentWarnings.includes(warningGridPageNumber)) {
                        documentWarnings.push(warningGridPageNumber);
                    }
                }

                documentWarnings = documentWarnings.sort(function (a, b) {
                    return a - b;
                });

                setMaxColumnsInfo(maxColumnsInfo);
                setAllWarningGrids(warningGrids);
                setAllDocumentWarnings(documentWarnings);
            }
        }
    }, [
        multiplePageGrids,
        combinedInfo,
        setMaxColumnsInfo,
        setAllWarningGrids,
        setAllDocumentWarnings,
    ]);

    useEffect(() => {
        const updatedTableSchema = [];
        let gridIndex = 1;
        for (let gridsObject of multiplePageGrids) {
            let backEndGrid = gridInfoUnConvert(
                gridsObject["grids"],
                gridsObject["pdfWidth"],
                gridsObject["pdfHeight"],
                gridsObject["imageId"],
                false
            );

            for (let gridObject of backEndGrid) {
                updatedTableSchema.push({
                    pageNumber: gridsObject["pageNumber"],
                    mask: [
                        {
                            ...gridObject,
                            maskNumber: gridIndex,
                        },
                    ],
                    angle: 0,
                    pdfWidth: gridsObject["pdfWidth"],
                    pdfHeight: gridsObject["pdfHeight"],
                });
                gridIndex = gridIndex + 1;
            }
        }

        setBackEndSubmitGrids({
            page: updatedTableSchema,
        });
    }, [multiplePageGrids, setBackEndSubmitGrids, fileDetails]);

    return (
        <>
            <div
                data-testid="file-pdf-images-container"
                className="file-pdf-images-container no-scrollbar"
            >
                {combinedInfo.map(({ imageInfo, fileTables }) => (
                    <PDFImage
                        key={`file-pdf-image-${imageInfo.imageId}`}
                        imageObject={imageInfo}
                        gridInfos={fileTables}
                        resetMaskSideBarInfo={resetMaskSideBarInfo}
                        allGridInfos={multiplePageGrids}
                        setMultiplePageGrids={setMultiplePageGrids}
                        selectedObject={selectedObject}
                        imageId={imageInfo.imageId}
                        pageToScrollTo={pageToScrollTo}
                        allWarningGrids={allWarningGrids}
                        setPageToScrollTo={setPageToScrollTo}
                        setPageDisplayed={setPageDisplayed}
                        setZoomInFunction={setZoomInFunction}
                        setZoomOutFunction={setZoomOutFunction}
                        setResetTransformFunction={setResetTransformFunction}
                        setSelectedObject={setSelectedObject}
                        pageGridUpdate={pageGridUpdate}
                        disablePlaceGrid={disablePlaceGrid}
                        placeGrid={placeGrid}
                        setDeleteLineFunction={setDeleteLineFunction}
                        setDeleteRectFunction={setDeleteRectFunction}
                        setClearRectFunction={setClearRectFunction}
                        addColumnTables={addColumnTables}
                        removeColumnTables={removeColumnTables}
                        maxColumnsInfo={maxColumnsInfo}
                        newColumn={newColumn}
                        setNewColumn={setNewColumn}
                        recheckColSpec={recheckColSpec}
                        setResetMaskSideBarInfo={setResetMaskSideBarInfo}
                        setUndoEventList={setUndoEventList}
                        undoEventList={undoEventList}
                        undo={undo}
                        setUndo={setUndo}
                        undoRunXTimes={undoRunXTimes}
                        setUndoRunXTimes={setUndoRunXTimes}
                        columnSpec={columnSpec}
                        setSelectedPageUrl={setSelectedPageUrl}
                        setSelectedRect={setSelectedRect}
                        autoGridColsRows={autoGridColsRows}
                        setAutoGridColsRows={setAutoGridColsRows}
                        isSideNavBarInfoOpen={isSideNavBarInfoOpen}
                        setIsSideNavBarInfoOpen={setIsSideNavBarInfoOpen}
                        setNavInfoToDisplay={setNavInfoToDisplay}
                        undoAutoGrid={undoAutoGrid}
                        setUndoAutoGrid={setUndoAutoGrid}
                        blurButtons={blurButtons}
                        autoMergeColsRows={autoMergeColsRows}
                        setAutoMergeColsRows={setAutoMergeColsRows}
                        runAutoMerge={runAutoMerge}
                        setRunAutoMerge={setRunAutoMerge}
                        setIsAutoMergeButtonDisabled={
                            setIsAutoMergeButtonDisabled
                        }
                    />
                ))}
            </div>
        </>
    );
};

export default AllPDFImages;
