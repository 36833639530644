import React from "react";
import ResultsDataTableToolbarSearch from "../../data-table-toolbar-search/data-table-toolbar-search.component";
import ResultsDataTableToolbarButtons from "./data-table-toolbar-buttons.component";

const ResultsDataTableToolbar = ({
    onFilterTextBoxChanged,
    addRow,
    deleteRow,
    handleUndo,
    showUndoButton,
    onSplitViewVertical,
    onSplitViewHorizontal,
    onArrowUp,
    onArrowDown,
    totalValidationChecks,
    currentValidationCheckInput,
    onDeselectAllRows,
    multipleRowsSelected,
    handleDownloadAndSave,
    splitViewVertical,
    splitViewHorizontal,
    filteringTable,
    searchedValue,
    switchCreditDebit,
    showSaveButton,
    adjustColumnWidth,
}) => {
    return (
        <>
            <div className="results-page-data-table-toolbar flex-direction-row">
                <ResultsDataTableToolbarSearch
                    onFilterTextBoxChanged={onFilterTextBoxChanged}
                    searchedValue={searchedValue}
                    pageLocation="results"
                />
                <ResultsDataTableToolbarButtons
                    addRow={addRow}
                    deleteRow={deleteRow}
                    handleUndo={handleUndo}
                    showUndoButton={showUndoButton}
                    onSplitViewVertical={onSplitViewVertical}
                    onSplitViewHorizontal={onSplitViewHorizontal}
                    onArrowUp={onArrowUp}
                    onArrowDown={onArrowDown}
                    totalValidationChecks={totalValidationChecks}
                    currentValidationCheckInput={currentValidationCheckInput}
                    onDeselectAllRows={onDeselectAllRows}
                    multipleRowsSelected={multipleRowsSelected}
                    handleDownloadAndSave={handleDownloadAndSave}
                    splitViewVertical={splitViewVertical}
                    splitViewHorizontal={splitViewHorizontal}
                    filteringTable={filteringTable}
                    switchCreditDebit={switchCreditDebit}
                    showSaveButton={showSaveButton}
                    adjustColumnWidth={adjustColumnWidth}
                />
            </div>
        </>
    );
};

export default ResultsDataTableToolbar;
