const pre = "file_details";
const addFileDetails = `${pre}/addFileDetails`;
const updatedAddedFileDetails = `${pre}/updatedAddedFileDetails`;
const clearFileDetails = `${pre}/clearFileDetails`;
const setRedirectionDetails = `${pre}/setRedirectionDetails`;
const clearRedirectionDetails = `${pre}/clearRedirectionDetails`;
const updateFileDetails = `${pre}/updateFileDetails`;
const clearUpdateFileDetails = `${pre}/clearUpdateFileDetails`;



const fileDetailsTypes = {
    addFileDetails,
    updatedAddedFileDetails,
    clearFileDetails,
    setRedirectionDetails,
    clearRedirectionDetails,
    updateFileDetails,
    clearUpdateFileDetails,
};

export default fileDetailsTypes;
