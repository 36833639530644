import { Help20, Logout20, UserAvatarFilled20 } from "@carbon/icons-react";
import {
    Header,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
} from "carbon-components-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo_white.png";
import { logOutFunction } from "../../helpers/helper-functions";
import { establishWebsocketConnection } from "../../redux/thunks/thunks.actions";

const HeaderComponent = ({ isLogin, displayNavOptions, isAccountPage, isProjectsPage, isMaskPage, isResultsPage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [projectId, setProjectId] = useState(null);
    const [fileId, setFiletId] = useState(null);
    const logoRedirection = "/";

    const redirectionDetails = useSelector(
        (state) => state.fileDetails.redirectionDetails
    );

    const user = useSelector((state) => state.user.user);

    // const authToken = useSelector((state) => state.auth.authToken)

    // const isWebSocketConnected = useSelector((state) => state.websocket.isWebSocketConnected)

    const handleLogout = () => {
        logOutFunction(dispatch, user);
    };

    const handleLink = (e, url) => {
        e.preventDefault();
        navigate(url);
    };

    const handleAccount = (e, url) => {
        navigate("/account");
    };

    useEffect(() => {
        setProjectId(redirectionDetails.projectId);
        setFiletId(redirectionDetails.fileId);
    }, [redirectionDetails]);

    // useEffect(() => {
    //     if (!isLogin && authToken && !isWebSocketConnected) {
    //         dispatch(establishWebsocketConnection());
    //     }
    // }, [dispatch, isLogin, authToken, isWebSocketConnected]);

    useEffect(() => {
        dispatch(establishWebsocketConnection());
    }, [dispatch]);

    return (
        <div
            id="header-component"
            data-testid="header-component"
            className="header-component"
        >
            <Header aria-label="Demarq Oscar">
                <HeaderName
                    prefix=""
                    href={logoRedirection}
                    data-testid="header-component-link"
                >
                    <img
                        className="logo"
                        data-testid="logo-image"
                        src={logo}
                        alt="logo"
                    />
                </HeaderName>
                {isLogin ? null : (
                    <HeaderNavigation aria-label="Demarq Oscar Navigation">
                        {displayNavOptions ? (
                            <>
                                <HeaderMenuItem
                                    data-testid="projects-nav-option"
                                    onClick={(e) => handleLink(e, "/")}
                                    isCurrentPage={isProjectsPage ? true : false}
                                >
                                    Projects
                                </HeaderMenuItem>
                                <HeaderMenuItem
                                    data-testid="mask-nav-option"
                                    onClick={(e) =>
                                        handleLink(
                                            e,
                                            `/mask/${projectId}/${fileId}`
                                        )
                                    }
                                    style={isAccountPage || isProjectsPage ? {pointerEvents: "none", opacity: "0.4"} : {}}
                                    isCurrentPage={isMaskPage ? true : false}
                                >
                                    Mask
                                </HeaderMenuItem>
                                <HeaderMenuItem
                                    data-testid="results-nav-option"
                                    onClick={(e) =>
                                        handleLink(
                                            e,
                                            `/results/${projectId}/${fileId}`
                                        )
                                    }
                                    style={isAccountPage || isProjectsPage ? {pointerEvents: "none", opacity: "0.4"} : {}}
                                    isCurrentPage={isResultsPage ? true : false}
                                >
                                    Results
                                </HeaderMenuItem>
                                {/* <HeaderMenuItem
                                    data-testid="categorisation-nav-option"
                                    onClick={(e) =>
                                        handleLink(
                                            e,
                                            `/categorisation/${projectId}/${fileId}`
                                        )
                                    }
                                >
                                    Categorisation
                                </HeaderMenuItem> */}
                            </>
                        ) : null}
                    </HeaderNavigation>
                )}
                <HeaderGlobalBar>
                    <HeaderGlobalAction
                        aria-label="Help"
                        onClick={(e) =>
                            window.open(
                                "https://docs.demarq.io/oscar/",
                                "_blank" // <- This is what makes it open in a new window.
                            )
                        }
                        data-testid="help-button"
                    >
                        <Help20 />
                    </HeaderGlobalAction>
                    {isLogin ? null : (
                        <>
                            <HeaderGlobalAction
                                aria-label="Account"
                                onClick={handleAccount}
                                data-testid="account-button"
                            >
                                <UserAvatarFilled20 />
                            </HeaderGlobalAction>
                            <HeaderGlobalAction
                                aria-label="Logout"
                                onClick={handleLogout}
                                data-testid="logout-button"
                            >
                                <Logout20 />
                            </HeaderGlobalAction>
                        </>
                    )}
                </HeaderGlobalBar>
            </Header>
        </div>
    );
};

HeaderComponent.propTypes = {
    isLogin: PropTypes.bool,
    displayNavOptions: PropTypes.bool,
};

export default HeaderComponent;
