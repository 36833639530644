const pre = "websocket/";
const UPDATE_WEBSOCKET = pre + "UPDATE_WEBSOCKET";
const UPDATE_STATE_WEBSOCKET = pre + "UPDATE_STATE_WEBSOCKET";
const WEBSOCKET_CONNECTED = pre + "WEBSOCKET_CONNECTED";
const WEBSOCKET_DISCONNECTED = pre + "WEBSOCKET_DISCONNECTED";
const SET_WEBSOCKET_MESSAGE = pre + "SET_WEBSOCKET_MESSAGE";
const CLEAR_WEBSOCKET_MESSAGE = pre + "CLEAR_WEBSOCKET_MESSAGE"


const websocketTypes = {
    UPDATE_WEBSOCKET,
    UPDATE_STATE_WEBSOCKET,
    WEBSOCKET_CONNECTED,
    WEBSOCKET_DISCONNECTED,
    SET_WEBSOCKET_MESSAGE,
    CLEAR_WEBSOCKET_MESSAGE,
};

export default websocketTypes;
