import { Form, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const InputFieldEmail = ({
    setUser,
    user,
    setEmailConforms,
    emailConforms,
    disabledField,
}) => {
    const [emailWarning, setEmailWarning] = useState("");
    const [showEmailWarning, setShowEmailWarning] = useState(false);

    useEffect(() => {
        const validEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (validEmail.test(user)) {
            setEmailConforms(true);
        } else {
            setEmailConforms(false);
        }
    }, [user, setEmailConforms]);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (user === "") {
                setEmailWarning("Enter an email");
                setShowEmailWarning(false);
            } else if (emailConforms) {
                setEmailWarning("");
                setShowEmailWarning(false);
            } else {
                setEmailWarning("Enter a valid email");
                setShowEmailWarning(true);
            }
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [user, emailConforms]);

    return (
        <Form>
            <TextInput
                id="email-input"
                data-testid="email-input"
                labelText="Email"
                onChange={(e) => setUser(e.target.value)}
                onBlur={() => {
                    if (user === "") {
                        setEmailConforms(false);
                        setEmailWarning("Enter an email");
                        setShowEmailWarning(true);
                    }
                }}
                invalid={showEmailWarning}
                invalidText={emailWarning}
                disabled={disabledField}
                type="text"
            />
        </Form>
    );
};

export default InputFieldEmail;
