import { ZoomOut16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const ZoomOutButton = ({ zoomOutFunction, page }) => {
    return (
        <Button
            renderIcon={ZoomOut16}
            iconDescription="Zoom Out (Ctrl -)"
            data-testid={`${page}-page-image-toolbar-zoom-out-button`}
            id={`${page}-page-image-toolbar-zoom-out-button`}
            className="default-button-colour"
            hasIconOnly={true}
            onClick={() => zoomOutFunction ? zoomOutFunction() : null}
        />
    );
};

export default ZoomOutButton;
