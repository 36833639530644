import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoginCard from "../../components/login-card/login-card.component";
import { Auth } from '@aws-amplify/auth';
import {
    setLoginError,
    setRedirectToLogin,
    setAuthToken,
    setTokenType,
    clearAuthToken,
} from "../../redux/auth/auth.actions";
import { clearUser } from "../../redux/user/user.actions";


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authToken = useSelector((state) => state.auth.authToken);
    const loginError = useSelector((state) => state.auth.loginError);
    const redirectToLogin = useSelector((state) => state.auth.redirectToLogin);

    const query = useQuery()

    let existingAWSUser = query.get("existing_aws_user")

    useEffect(() => {
        if (authToken) {
            if (loginError) {
                dispatch(setLoginError(null));
            }
            navigate("/");
        }
    }, [authToken, loginError, dispatch, navigate]);

    useEffect(() => {
        if (redirectToLogin) {
            setTimeout(() => {
                dispatch(setRedirectToLogin(null));
            }, 5000);
        }
    }, [redirectToLogin, dispatch]);

    const getUser = useCallback(
        async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            console.log(currentUser);

            dispatch(setAuthToken(currentUser["signInUserSession"]["accessToken"]["jwtToken"]));
            dispatch(setTokenType("Bearer"));

        } catch (error) {
            console.error(error);
            console.log('Not signed in');
            dispatch(clearAuthToken())
            dispatch(clearUser())
        }
    }, [dispatch])

    useEffect(() => {     
        getUser();
    }, [getUser]);


    useEffect(() => {
        switch (loginError) {
            case "User is not confirmed.":
                navigate("/verify-account");
                break;
            default:
                break;
        }
    }, [loginError, navigate]);

    return (
        <div
            className="login-page-background"
            data-testid="login-page-background"
        >
            <LoginCard existingAWSUser={existingAWSUser}/>
        </div>
    );
};

export default LoginPage;
