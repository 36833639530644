import { Form, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";

const InputFieldVerificationCode = ({
    setVerificationCode,
    verificationCode,
    setVerificationCodeConforms,
    verificationCodeConforms,
    labelText,
}) => {
    const [verificationCodeWarning, setVerificationCodeWarning] = useState("");
    const [showVerificationCodeWarning, setShowVerificationCodeWarning] =
        useState(false);

    const id = `${labelText.split(" ").join("-").toLowerCase()}-input`;

    const onKeyDown = (e) => {
        e.key === "Enter" && e.preventDefault();
    };

    useEffect(() => {
        const isValidLength = /^[0-9]{6,6}$/;

        isValidLength.test(verificationCode)
            ? setVerificationCodeConforms(true)
            : setVerificationCodeConforms(false);
    }, [verificationCode, setVerificationCodeConforms]);

    useEffect(() => {
        const identifier = setTimeout(() => {
            if (verificationCode === "") {
                setVerificationCodeWarning("");
                setShowVerificationCodeWarning(false);
            } else if (!verificationCodeConforms) {
                setVerificationCodeWarning(
                    `${labelText} is not a 6-digit number`
                );
                setShowVerificationCodeWarning(true);
            } else {
                setVerificationCodeWarning("");
                setShowVerificationCodeWarning(false);
            }
        }, 500);

        return () => {
            clearTimeout(identifier);
        };
    }, [verificationCode, verificationCodeConforms, labelText]);

    return (
        <Form>
            <TextInput
                id={id}
                data-testid={id}
                labelText={labelText}
                invalid={showVerificationCodeWarning}
                invalidText={verificationCodeWarning}
                onChange={(e) => setVerificationCode(e.target.value)}
                onBlur={() => {
                    if (verificationCode === "") {
                        setVerificationCodeWarning("Enter a verification code");
                        setShowVerificationCodeWarning(true);
                    }
                }}
                onKeyDown={(e) => onKeyDown(e)}
                type="text"
            />
        </Form>
    );
};

export default InputFieldVerificationCode;
