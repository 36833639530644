import {
    Launch16,
    SettingsAdjust16,
    Table16,
} from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { removeExtension } from "../../../../helpers/helper-functions-2.js";

const GridResultsButton = ({ fileId, projectId, fileName }) => {
    const navigate = useNavigate();

    const [showGrid, setShowGrid] = useState(false);

    const toggleGrid = () => {
        setShowGrid(!showGrid);
    };

    const closeGrid = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setShowGrid(false);
        }
    };

    const handleLink = (e, projectId, fileId, redirection) => {
        e.preventDefault();
        const url = `${redirection}/${projectId}/${fileId}`;
        navigate(url);
    };

    return (
        <div onBlur={closeGrid}>
            <Button
                role="display-redirect-buttons"
                renderIcon={Launch16}
                focusable={false}
                data-testid={`mask-results-toggle-${removeExtension(fileName)}`}
                id={`mask-results-toggle-${fileId}`}
                iconDescription="Open"
                hasIconOnly={true}
                onClick={toggleGrid}
                className="make-buttons-disappear"
            />
            {showGrid ? (
                <div
                    id={`grid-results-dropdown-${fileId}`}
                    data-testid={`grid-results-dropdown-${fileId}`}
                    className="flex-column"
                >
                    <Button
                        role="mask-redirect"
                        data-testid={`open-mask-${fileId}`}
                        id={`open-mask-${fileId}`}
                        renderIcon={SettingsAdjust16}
                        iconDescription="Open Mask"
                        hasIconOnly
                        onClick={(e) => handleLink(e, projectId, fileId, "mask")}
                        className="make-buttons-disappear"
                    />
                    <Button
                        role="results-redirect"
                        data-testid={`open-results-${fileId}`}
                        id={`open-results-${fileId}`}
                        renderIcon={Table16}
                        iconDescription="Open Results"
                        hasIconOnly
                        onClick={(e) =>
                            handleLink(e, projectId, fileId, "results")
                        }
                        className="make-buttons-disappear"
                    />
                    {/* <Button
                        role="categorisation-redirect"
                        data-testid={`open-categorisation-${fileId}`}
                        id={`open-categorisation-${fileId}`}
                        renderIcon={Category16}
                        iconDescription="Open Categorisation"
                        hasIconOnly
                        onClick={(e) =>
                            handleLink(e, projectId, fileId, "categorisation")
                        }
                        className="make-buttons-disappear"
                    /> */}
                </div>
            ) : null}
        </div>
    );
};

export default GridResultsButton;
