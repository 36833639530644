import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import backgroundImageLeft from "../../assets/login-background-left.png";
import backgroundImage from "../../assets/login_8.png";
import AccordionMFASMS from "../../components/accordion/accordion-mfa-sms.component";
import AccordionMFA from "../../components/accordion/accordion-mfa.component";
import AccordionNewPasswordRequired from "../../components/accordion/accordion-new-password-required.component";
import AccordionSetUpMFA from "../../components/accordion/accordion-set-up-mfa.component";
import AccordionTermsAndConditions from "../../components/accordion/accordion-terms-conditions.component";
import HeaderHelperPagesComponent from "../../components/header/header-helper-pages.component";
import InlineNotificationSuccess from "../../components/inline-notification/inline-notification-success.component";
import { determineBorderClass } from "../../helpers/helper-functions";
import {
    setAuthMessage,
    setLoginError,
    setProgressToNextStep,
    setQRCode,
    setQRCodeUsername,
    setQRCognitoUser,
} from "../../redux/auth/auth.actions";
import {
    awsCognitoLogIn,
    awsCognitoVerifyMFACode,
} from "../../redux/thunks/thunks.actions";
import AccordionLogIn from "../accordion/accordion-log-in.component";
import InlineNotificationError from "../inline-notification/inline-notification-error.component";
import { Button } from "carbon-components-react";
import { awsCognitoFederatedSignIn } from "../../api/index.js";


const LoginCard = ({ existingAWSUser }) => {
    const dispatch = useDispatch();
    const loginError = useSelector((state) => state.auth.loginError);
    const reactAppVersion = process.env.REACT_APP_VERSION;
    const authMessage = useSelector((state) => state.auth.authMessage);
    const progressToNextStep = useSelector(
        (state) => state.auth.progressToNextStep
    );

    const [user, setUser] = useState("");
    const [emailConforms, setEmailConforms] = useState(false);

    const [pass, setPass] = useState("");
    const [mfaCode, setMfaCode] = useState("");

    const [loginAccordionBorder, setLoginAccordionBorder] = useState("");
    const [isLoginAccordionOpen, setIsLoginAccordionOpen] = useState(true);
    const [isLoginFinished, setIsLoginFinished] = useState(false);

    const [mfaSetUpAccordionBorder, setMfaSetUpAccordionBorder] = useState("");
    const [isMfaSetUpAccordionOpen, setIsMfaSetUpAccordionOpen] =
        useState(false);
    const [isMfaSetUpFinished, setIsMfaSetUpFinished] = useState(false);
    const [displayMfaSetUpAccordion, setDisplayMfaSetUpAccordion] =
        useState(false);

    const [mfaAccordionBorder, setMfaAccordionBorder] = useState("");
    const [isMfaAccordionOpen, setIsMfaAccordionOpen] = useState(false);
    const [isMfaFinished, setIsMfaFinished] = useState(false);
    const [displayMfaAccordion, setDisplayMfaAccordion] = useState(false);

    const [mfaSMSCode, setMfaSMSCode] = useState("");
    const [mfaSMSAccordionBorder, setMfaSMSAccordionBorder] = useState("");
    const [isMfaSMSAccordionOpen, setIsMfaSMSAccordionOpen] = useState(false);
    const [isMfaSMSFinished, setIsMfaSMSFinished] = useState(false);
    const [displayMfaSMSAccordion, setDisplaySMSMfaAccordion] = useState(false);

    const [showAzureButton, setShowAzureButton] = useState(false);

    useEffect(() => {
        if (window.location.hostname === process.env.REACT_APP_AZURE_FRONTEND_DOMAIN) {
            setShowAzureButton(true);
        } else setShowAzureButton(false);
    }, [])


    const [
        termsAndConditionsAccordionBorder,
        setTermsAndConditionsAccordionBorder,
    ] = useState("");
    const [
        areTermsAndConditionsAccordionOpen,
        setAreTermsAndConditionsAccordionOpen,
    ] = useState(false);
    const [areTermsAndConditionsFinished] = useState(false);
    const [
        displayTermsAndConditionsAccordion,
        setDisplayTermsAndConditionsAccordion,
    ] = useState(false);

    const [
        newPasswordRequiredAccordionBorder,
        setNewPasswordRequiredAccordionBorder,
    ] = useState("");
    const [
        isNewPasswordRequiredAccordionOpen,
        setIsNewPasswordRequiredAccordionOpen,
    ] = useState(false);
    const [isNewPasswordRequiredFinished] = useState(false);
    const [
        displayNewPasswordRequiredAccordion,
        setDisplayNewPasswordRequiredAccordion,
    ] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    const handleLogin = useCallback(
        (e) => {
            e.preventDefault();
            if (!user || !pass) {
                dispatch(
                    setLoginError("*You must enter a username and password")
                );
            } else {
                dispatch(awsCognitoLogIn(user, pass));
            }
        },
        [user, pass, dispatch]
    );

    const handleMfaAuthentication = (e) => {
        e.preventDefault();
        if (!user || !pass) {
            dispatch(setLoginError("*You must enter a username and password"));
        } else {
            dispatch(awsCognitoLogIn(user, pass, mfaCode));
        }
    };

    const handleMFASetUp = (e) => {
        e.preventDefault();
        if (mfaCode) {
            dispatch(awsCognitoVerifyMFACode(mfaCode));
        }
    };

    useEffect(() => {
        determineBorderClass(
            isLoginFinished,
            isLoginAccordionOpen,
            setLoginAccordionBorder
        );
    }, [isLoginFinished, isLoginAccordionOpen]);

    useEffect(() => {
        determineBorderClass(
            isMfaSetUpFinished,
            isMfaSetUpAccordionOpen,
            setMfaSetUpAccordionBorder
        );
    }, [isMfaSetUpFinished, isMfaSetUpAccordionOpen]);

    useEffect(() => {
        determineBorderClass(
            isMfaFinished,
            isMfaAccordionOpen,
            setMfaAccordionBorder
        );
    }, [isMfaFinished, isMfaAccordionOpen]);

    useEffect(() => {
        determineBorderClass(
            isMfaSMSFinished,
            isMfaSMSAccordionOpen,
            setMfaSMSAccordionBorder
        );
    }, [isMfaSMSFinished, isMfaSMSAccordionOpen]);

    useEffect(() => {
        determineBorderClass(
            areTermsAndConditionsFinished,
            areTermsAndConditionsAccordionOpen,
            setTermsAndConditionsAccordionBorder
        );
    }, [
        areTermsAndConditionsFinished,
        areTermsAndConditionsAccordionOpen,
        setTermsAndConditionsAccordionBorder,
    ]);

    useEffect(() => {
        determineBorderClass(
            isNewPasswordRequiredFinished,
            isNewPasswordRequiredAccordionOpen,
            setNewPasswordRequiredAccordionBorder
        );
    }, [
        isNewPasswordRequiredFinished,
        isNewPasswordRequiredAccordionOpen,
        setNewPasswordRequiredAccordionBorder,
    ]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (loginError) {
                setTimeout(() => {
                    dispatch(setLoginError(null));
                }, 5000);
            }
            if (authMessage) {
                setTimeout(() => {
                    dispatch(setAuthMessage(null));
                }, 5000);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [loginError, authMessage, dispatch]);

    useEffect(() => {
        switch (progressToNextStep) {
            case "mfaSetup":
                setIsLoginAccordionOpen(false);
                setIsLoginFinished(true);
                setIsMfaSetUpAccordionOpen(true);
                setDisplayMfaSetUpAccordion(true);
                setIsNewPasswordRequiredAccordionOpen(false);
                setDisplayNewPasswordRequiredAccordion(false);
                break;
            case "newPasswordRequired":
                setIsLoginAccordionOpen(false);
                setIsLoginFinished(true);
                setIsNewPasswordRequiredAccordionOpen(true);
                setDisplayNewPasswordRequiredAccordion(true);
                break;
            case "mfaCode":
                setIsLoginAccordionOpen(false);
                setIsLoginFinished(true);
                setIsMfaAccordionOpen(true);
                setDisplayMfaAccordion(true);
                break;
            case "mfaCodeSMS":
                setIsLoginAccordionOpen(false);
                setIsLoginFinished(true);
                setIsMfaSMSAccordionOpen(true);
                setDisplaySMSMfaAccordion(true);
                break;
            case "showTermsAndConditions":
                if (isLoginAccordionOpen) {
                    setIsLoginAccordionOpen(false);
                    setIsLoginFinished(true);
                }
                if (isNewPasswordRequiredAccordionOpen) {
                    setIsNewPasswordRequiredAccordionOpen(false);
                    setDisplayNewPasswordRequiredAccordion(false);
                }
                setIsMfaAccordionOpen(false);
                setIsMfaFinished(true);
                setIsMfaSMSFinished(true);
                setAreTermsAndConditionsAccordionOpen(true);
                setDisplayTermsAndConditionsAccordion(true);
                break;
            case "showTermsAndConditionsFromMfaSetUp":
                setIsMfaSetUpAccordionOpen(false);
                setIsMfaSetUpFinished(true);
                setAreTermsAndConditionsAccordionOpen(true);
                setDisplayTermsAndConditionsAccordion(true);
                break;
            default:
                break;
        }
    }, [
        progressToNextStep,
        isLoginAccordionOpen,
        isNewPasswordRequiredAccordionOpen,
    ]);

    useEffect(() => {
        let unmounted = false;

        if (unmounted) {
            dispatch(setQRCode(null));
            dispatch(setQRCodeUsername(null));
            dispatch(setQRCognitoUser(null));
            dispatch(setProgressToNextStep(null));
        }

        return () => {
            unmounted = true;
        };
    }, [dispatch]);

    useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    useEffect(() => {
        dispatch(setProgressToNextStep(null));
    }, [dispatch]);

    useEffect(() => {
        setIsLoginAccordionOpen(true);
        setIsLoginFinished(false);
        setIsMfaSetUpAccordionOpen(false);
        setDisplayMfaSetUpAccordion(false);
        setIsNewPasswordRequiredAccordionOpen(false);
        setDisplayNewPasswordRequiredAccordion(false);
        setIsMfaAccordionOpen(false);
        setDisplayMfaAccordion(false);
        setIsMfaSMSAccordionOpen(false);
        setDisplaySMSMfaAccordion(false);
        setAreTermsAndConditionsAccordionOpen(false);
        setDisplayTermsAndConditionsAccordion(false);
    }, []);

    useEffect(() => {
        let unmounted = false;

        const keyDownEventListener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                handleLogin(event);
            }
        };

        if (!unmounted) {
            document.addEventListener("keydown", keyDownEventListener);
        }

        return () => {
            document.removeEventListener("keydown", keyDownEventListener);
            unmounted = true;
        };
    }, [pass, user, handleLogin]);

    return (
        <>
            {width < 1024 ? <HeaderHelperPagesComponent /> : null}
            <div className="card-container" data-testid="login-card-container">
                <div className="fields-area">
                    <div className="field-margin-bottom">
                        <div className="login-title field-margin-bottom">
                            Log In
                        </div>
                        {existingAWSUser ? (
                            <div>
                                This AWS Marketplace account is already associated with an Oscar user.  Please log in.
                            </div>
                        ) : (
                            showAzureButton ? (
                                <div>
                                    Don't have an account?{" "}
                                    Ask your administrator for access.
                                </div>
                             ) : (
                                <div>
                                    Don't have an account?{" "}
                                    <Link
                                        data-testid="create-account-link"
                                        className="blue-text"
                                        to="/register"
                                    >
                                        Create an account
                                    </Link>
                                </div>
                             )
                        )
                        }
                    </div>
                    <div className="login-notification">
                        {loginError ? (
                            <InlineNotificationError
                                loginError={loginError}
                                hideCloseButton={true}
                                errorType="login-error"
                                pageLocation="login"
                            />
                        ) : null}
                        {authMessage ? (
                            <InlineNotificationSuccess
                                authMessage={authMessage}
                                pageLocation="login"
                            />
                        ) : null}
                    </div>

                    {showAzureButton && <Button
                        className="azure-ad-button"
                        id="azure-ad-button"
                        data-testid="azure-ad-button"
                        onClick={() => awsCognitoFederatedSignIn()}
                    >
                        Sign in with Azure AD
                    </Button>}

                    {showAzureButton ? null :
                        <>
                            <div className="field-margin-bottom">
                                <AccordionLogIn
                                    loginAccordionBorder={loginAccordionBorder}
                                    isLoginAccordionOpen={isLoginAccordionOpen}
                                    isLoginFinished={isLoginFinished}
                                    setUser={setUser}
                                    user={user}
                                    setEmailConforms={setEmailConforms}
                                    emailConforms={emailConforms}
                                    setPass={setPass}
                                    pass={pass}
                                    handleLogin={handleLogin}
                                />
                            </div>
                            {displayMfaSetUpAccordion ? (
                                <div className="accordion-background field-margin-bottom">
                                    <AccordionSetUpMFA
                                        mfaSetUpAccordionBorder={mfaSetUpAccordionBorder}
                                        isMfaSetUpAccordionOpen={isMfaSetUpAccordionOpen}
                                        isMfaSetUpFinished={isMfaSetUpFinished}
                                        setMfaCode={setMfaCode}
                                        handleMFASetUp={handleMFASetUp}
                                        mfaCode={mfaCode}
                                    />
                                </div>
                            ) : null}
                            {displayMfaAccordion ? (
                                <div className="accordion-background field-margin-bottom">
                                    <AccordionMFA
                                        mfaAccordionBorder={mfaAccordionBorder}
                                        isMfaAccordionOpen={isMfaAccordionOpen}
                                        isMfaFinished={isMfaFinished}
                                        setMfaCode={setMfaCode}
                                        handleMfaAuthentication={handleMfaAuthentication}
                                        mfaCode={mfaCode}
                                    />
                                </div>
                            ) : null}
                            {displayMfaSMSAccordion ? (
                                <div className="accordion-background field-margin-bottom">
                                    <AccordionMFASMS
                                        mfaSMSAccordionBorder={mfaSMSAccordionBorder}
                                        isMfaSMSAccordionOpen={isMfaSMSAccordionOpen}
                                        isMfaSMSFinished={isMfaSMSFinished}
                                        setMfaSMSCode={setMfaSMSCode}
                                        // handleMfaAuthentication={handleMfaAuthentication}
                                        mfaSMSCode={mfaSMSCode}
                                    />
                                </div>
                            ) : null}
                            {displayTermsAndConditionsAccordion ? (
                                <div className="accordion-background field-margin-bottom">
                                    <AccordionTermsAndConditions
                                        termsAndConditionsAccordionBorder={
                                            termsAndConditionsAccordionBorder
                                        }
                                        areTermsAndConditionsFinished={
                                            areTermsAndConditionsFinished
                                        }
                                        areTermsAndConditionsAccordionOpen={
                                            areTermsAndConditionsAccordionOpen
                                        }
                                    />
                                </div>
                            ) : null}
                            {displayNewPasswordRequiredAccordion ? (
                                <div className="accordion-background field-margin-bottom">
                                    <AccordionNewPasswordRequired
                                        newPasswordRequiredAccordionBorder={
                                            newPasswordRequiredAccordionBorder
                                        }
                                        isNewPasswordRequiredFinished={
                                            isNewPasswordRequiredFinished
                                        }
                                        isNewPasswordRequiredAccordionOpen={
                                            isNewPasswordRequiredAccordionOpen
                                        }
                                        user={user}
                                        pass={pass}
                                    />
                                </div>
                            ) : null}
                            <div className="app-version" data-testid="login-app-version">
                                version {reactAppVersion}
                            </div>
                        </>
                    }
                </div>

                {width < 1024 ? null : (
                    <React.Fragment>
                        <img
                            className="background-image-left"
                            src={backgroundImageLeft}
                            alt="background left"
                        />
                        <img
                            className="background-image"
                            data-testid="background-image"
                            src={backgroundImage}
                            alt="background"
                        />
                    </React.Fragment>
                )}
            </div>
        </>
    );
};

export default LoginCard;
