import { ArrowDown16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import "./registration-buttons.styles.scss";

const CommonRegistrationButton = ({
    buttonKind,
    isDisabled,
    onClickFunction,
    buttonDescription,
    dataTestId,
}) => {
    const [buttonClass, setButtonClass] = useState("common-registration-button");

    useEffect(() => {
        switch (buttonDescription) {
            case "Reset to Previous State":
                setButtonClass("inline-notification-button");
                break;
            case "Register for free for 250 more pages":
                setButtonClass("guest-extra-text");
                break;
            default:
                setButtonClass("common-registration-button");
                break;
        }
    }, [buttonDescription]);

    return (
        <Button
            className={`${buttonClass} ${
                buttonKind === "ghost" ? "button-hover" : ""
            } ${buttonDescription === "Yearly" ? "blue-button" : ""}`}
            kind={buttonKind}
            data-testid={`${dataTestId}-button`}
            id={`${dataTestId}-button`}
            onClick={onClickFunction}
            disabled={isDisabled}
        >
            {buttonDescription}
            {![
                "Resend Code",
                "Reset to Previous State",
                "Register for free for 250 more pages",
            ].includes(buttonDescription) ? (
                <ArrowDown16 className="arrow-down" />
            ) : null}
        </Button>
    );
};

export default CommonRegistrationButton;
