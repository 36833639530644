import { InlineNotification } from "carbon-components-react";
import React from "react";
import CommonRegistrationButton from "../registration-buttons/registration-button-common.component";
import "./inline-notification.styles.scss";

const InlineNotificationError = ({
    loginError,
    hideCloseButton,
    onCloseButtonClick,
    displayExtraText,
    resetFunction,
    errorType,
    pageLocation,
}) => {
    return (
        <InlineNotification
            kind={loginError === "Some tables are invalid" ? "warning" : "error"}
            id={`${pageLocation}-${errorType}-inline-notification`}
            data-testid={`${pageLocation}-${errorType}-inline-notification`}
            title={loginError}
            lowContrast={true}
            className="inline-notification fade-in-1 small-notification"
            hideCloseButton={hideCloseButton}
            onCloseButtonClick={() => onCloseButtonClick(errorType)}
            subtitle={
                displayExtraText ? (
                    <CommonRegistrationButton
                        buttonKind={"ghost"}
                        isDisabled={false}
                        onClickFunction={resetFunction}
                        buttonDescription={"Reset to Previous State"}
                        dataTestId={"reset-to-previous-state"}
                    />
                ) : null
            }
        />
    );
};

export default InlineNotificationError;
