import { Form, TextInput } from "carbon-components-react";
import React, { useState } from "react";

const InputFieldName = ({ name, setName, inputFieldNamedId }) => {
    const [showNameWarning, setShowNameWarning] = useState(false);

    const emptyFieldValidator = (field, fieldValidStateUpdate) => {
        if (field === "") {
            fieldValidStateUpdate(true);
        } else {
            fieldValidStateUpdate(false);
        }
    };

    return (
        <Form>
            <TextInput
                id={`name-input-field-${inputFieldNamedId}`}
                data-testid={`name-input-field-${inputFieldNamedId}`}
                labelText="Name"
                onChange={(e) => setName(e.target.value)}
                onBlur={() => emptyFieldValidator(name, setShowNameWarning)}
                invalid={showNameWarning}
                invalidText={showNameWarning ? "Name is required" : ""}
                type="text"
            />
        </Form>
    );
};

export default InputFieldName;
