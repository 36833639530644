import { ZoomIn16 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";

const ZoomInButton = ({ zoomInFunction, page}) => {
    return (
        <Button
            renderIcon={ZoomIn16}
            iconDescription="Zoom In (Ctrl +)"
            data-testid={`${page}-page-image-toolbar-zoom-in-button`}
            id={`${page}-page-image-toolbar-zoom-in-button`}
            className="default-button-colour"
            hasIconOnly={true}
            onClick={() => zoomInFunction ? zoomInFunction() : null}
        />
    );
};

export default ZoomInButton;