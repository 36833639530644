import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderHelperPagesComponent from "../../components/header/header-helper-pages.component";
import InlineNotificationError from "../../components/inline-notification/inline-notification-error.component";
import InputFieldEmail from "../../components/input-fields/input-fields-email.component";
import InputFieldVerificationCode from "../../components/input-fields/input-fields-verification-code.component";
import CommonRegistrationButton from "../../components/registration-buttons/registration-button-common.component";
import { setAuthMessage, setLoginError } from "../../redux/auth/auth.actions";
import {
    awsCognitoConfirmRegistration,
    awsCognitoResendConfirmationCode,
} from "../../redux/thunks/thunks.actions";
import "./verify-account.styles.scss";

const VerifyAccountPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginError = useSelector((state) => state.auth.loginError);
    const authMessage = useSelector((state) => state.auth.authMessage);

    const [user, setUser] = useState(null);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [emailVerificationCode, setEmailVerificationCode] = useState(null);
    const [verificationCodeConforms, setVerificationCodeConforms] =
        useState(false);
    const [emailConforms, setEmailConforms] = useState(false);

    const handleEmailValidation = (e) => {
        e.preventDefault();
        dispatch(awsCognitoConfirmRegistration(user, emailVerificationCode));
    };

    const handleResendEmailVerificationCode = (e) => {
        e.preventDefault();
        dispatch(awsCognitoResendConfirmationCode(user));
        setVerifyEmail(true);
    };

    useEffect(() => {
        if (authMessage) {
            switch (authMessage) {
                case "You have already confirmed your account. You can now sign in.":
                    navigate("/login");
                    break;
                default:
                    break;
            }
            setTimeout(() => {
                dispatch(setAuthMessage(null));
            }, 5000);
        }
    }, [authMessage, dispatch, navigate]);

    useEffect(() => {
        if (loginError) {
            setTimeout(() => {
                dispatch(setLoginError(null));
            }, 5000);
        }
    }, [loginError, dispatch]);

    return (
        <div className="background" data-testid="verify-account-page">
            <HeaderHelperPagesComponent />
            <div className="fields-area centred">
                <div className="field-margin-bottom">
                    <div className="verify-account-title field-margin-bottom">
                        Your account has not been verified
                    </div>
                    <div>Please verify it</div>
                </div>
                {loginError ? (
                    <div className="error-div" data-testid="error-div">
                        <InlineNotificationError
                            loginError={loginError}
                            hideCloseButton={true}
                            errorType="login-error"
                            pageLocation="verify-account"
                        />
                    </div>
                ) : null}
                <div className="field-margin-bottom">
                    <InputFieldEmail
                        setUser={setUser}
                        user={user}
                        setEmailConforms={setEmailConforms}
                        emailConforms={emailConforms}
                        disabledField={verifyEmail ? true : false}
                    />
                </div>
                {verifyEmail ? null : (
                    <CommonRegistrationButton
                        buttonKind="primary"
                        isDisabled={emailConforms ? false : true}
                        onClickFunction={handleResendEmailVerificationCode}
                        buttonDescription="Next"
                        dataTestId="verify-email-verification-code"
                    />
                )}
                {verifyEmail ? (
                    <>
                        <div
                            className="field-margin-bottom"
                            data-testid="verification-code-text-verify-page"
                        >
                            We sent a 6-digit verification code to {user}. This
                            code is valid for 30 minutes.
                        </div>
                        <div className="field-margin-bottom">
                            <InputFieldVerificationCode
                                setVerificationCode={setEmailVerificationCode}
                                verificationCode={emailVerificationCode}
                                setVerificationCodeConforms={
                                    setVerificationCodeConforms
                                }
                                verificationCodeConforms={
                                    verificationCodeConforms
                                }
                                labelText="Verify email code"
                            />
                        </div>
                        <CommonRegistrationButton
                            buttonKind="primary"
                            isDisabled={
                                emailVerificationCode !== "" &&
                                verificationCodeConforms
                                    ? false
                                    : true
                            }
                            onClickFunction={handleEmailValidation}
                            buttonDescription="Verify"
                            dataTestId="submit-verify-email-verification-code"
                        />
                        <CommonRegistrationButton
                            buttonKind="ghost"
                            isDisabled={false}
                            onClickFunction={handleResendEmailVerificationCode}
                            buttonDescription="Resend Code"
                            dataTestId="resend-verify-email-verification-code"
                        />
                    </>
                ) : null}
            </div>
        </div>
    );
};

VerifyAccountPage.propTypes = {};

export default VerifyAccountPage;
