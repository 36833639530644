import {
    autogridMask,
    automergeMask,
    awsCognitoChangePassword,
    awsCognitoConfirmSignIn,
    awsCognitoConfirmSignUp,
    awsCognitoFederatedSignIn,
    awsCognitoForgotPassword,
    awsCognitoForgotPasswordSubmit,
    awsCognitoNewPasswordRequired,
    awsCognitoResendSignUp,
    awsCognitoSignIn,
    awsCognitoSignOut,
    awsCognitoSignUp,
    awsCognitoVerifyTotpToken,
    awsCognitoVerifyUserAttribute,
    awsCognitoVerifyUserAttributeSubmit,
    awsCurrentAuthenticatedUser,
    awsUpdatePhoneNumber,
    awsUpdateTermsAndConditionsUserAttributes,
    deleteFile,
    deleteProjectAPI,
    downloadExcelFiles,
    downloadFileResultsExcel,
    downloadMergedExcelFiles,
    emailVerifiedSendAlert,
    getCurrentUser,
    getFileColSpec,
    getFileDetails,
    getFileImages,
    getFileResults,
    getFileTables,
    getLoginTokenAPI,
    getProjectFiles,
    getProjects,
    patchFileResults,
    postProject,
    postTablesUpdate,
    projectDownloadMultipleExcelFiles,
    registerGuestUser,
    registerUser,
    renameProjectAPI,
    reprocessSavedFile,
    restoreFile,
    stripeCreateCheckoutSession,
    stripeManageAccount,
    updateCurrentUser,
    updateFileDetails,
    updateFileUploadStatus,
    uploadFileAPI,
} from "../../api/index.js";
import {
    checkIfUserAttributeHasBeenVerified,
    checkTermsAndConditions,
    getDownloadFile,
    getHeaders,
    getRedirectionDetails,
    goToHomePage,
    handleApiError,
    handleUserInfo,
    phoneNumberNotVerifiedFlow,
    qrCodeReset,
    redirectToAccount,
    redirectToCheckout,
    setJWTToken,
    setPreferredMFAFlow,
} from "../../helpers/helper-functions.js";
import {
    wsConnect,
    wsDisconnect,
} from "../../redux/websocket/middleware.actions";
import {
    setAuthMessage,
    setAuthToken,
    setCognitoUser,
    setLoginError,
    setProgressToNextStep,
    setRedirectToLogin,
    setTokenType,
    clearAuthToken,
} from "../auth/auth.actions";
import {
    addFileDetails,
    clearFileDetails,
    clearRedirectionDetails,
    setRedirectionDetails,
} from "../file-details/file-details.actions.js";
import {
    addFileResults,
    clearFileImages,
    clearFileResults,
    clearFileTables,
    clearImagesFileName,
    downloadFileResults,
    downloadFiles,
    downloadProjectFiles,
    saveFileResults,
    setFileColSpec,
    setFileImages,
    setFileTables,
    setImagesFileName,
} from "../files/files.actions";
import {
    clearAutoGrid,
    clearAutoMerge,
    setAutoGrid,
    setAutoMerge,
} from "../mask/mask.actions";
import {
    addFilesToProject,
    addProject,
    deleteProject,
    removeFileFromProject,
    renameProject,
    setProjects,
    updateProjectFileState,
    toggleProjectLoad,
    toggleFilesUploading,
} from "../projects/projects.actions.js";
import { clearUser, setUser, setUserNotification } from "../user/user.actions";

export const getProjectList = () => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;
        dispatch(toggleProjectLoad())

        getCurrentUser(headers)
            .then((res) => {
                let userData = res.data;
                dispatch(setUser(userData));
                handleUserInfo(dispatch, userData);

                getProjects(headers)
                    .then((res) => {
                        dispatch(setProjects(res.data));
                        res.data.forEach((project) => {
                            dispatch(addFilesToProject(project.project_id, project.files))
                        })
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getProjectFilesAction = (projectId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        getProjectFiles(projectId, headers)
            .then((res) => {
                dispatch(addFilesToProject(projectId, res.data));
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getAllFilesForAllProjects = (projects) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        for (let project of projects) {
            let projectId = project.project_id;
            getProjectFiles(projectId, headers)
                .then((res) => {
                    dispatch(addFilesToProject(projectId, res.data));
                })
                .catch((error) => {
                    handleApiError(error, dispatch, isLoginEndpoint);
                });
        }
    };
};

export const addNewProject = (projectName, projectDescription) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        postProject(projectName, projectDescription, headers)
            .then((res) => {
                dispatch(addProject({ ...res.data, files: [] }));
                return;
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const deleteFileAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        deleteFile(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(removeFileFromProject(projectId, fileId));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const updateFileUploadStatusAction = (
    projectId,
    fileId,
    uploadStatus
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        updateFileUploadStatus(projectId, fileId, uploadStatus, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(
                        updateProjectFileState(projectId, fileId, uploadStatus)
                    );
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const uploadFiles = (projectId, files) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;
        dispatch(toggleFilesUploading())

        for (let file of files) {
            uploadFileAPI(projectId, file, headers)
                .then((res) => {
                    dispatch(addFilesToProject(projectId, res.data));
                })
                .catch((error) => {
                    handleApiError(error, dispatch, isLoginEndpoint);
                });
        }
    };
};

export const renameProjectAction = (
    projectId,
    projectName,
    projectDescription
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        renameProjectAPI(projectId, projectName, projectDescription, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(
                        renameProject(projectId, projectName, projectDescription)
                    );
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const deleteProjectAction = (projectId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        deleteProjectAPI(projectId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(deleteProject(projectId));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const establishWebsocketConnection = () => {
    return (dispatch, getState) => {
        const state = getState();

        const WEBSOCKET_URL = {
            url: `${process.env.REACT_APP_WEBSOCKET_URL}`,
            headers: state.auth.authToken,
        };

        dispatch(wsConnect(WEBSOCKET_URL));
    };
};

export const downloadExcelFilesAction = (projectId, excelType, files) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        downloadExcelFiles(projectId, excelType, files, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    // now returning the fileName in the response
                    let fileName = res.headers.filename;
                    getDownloadFile(res.data, fileName);
                    dispatch(downloadFiles(files));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const downloadMergedExcelFilesAction = (requestBody) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        downloadMergedExcelFiles(requestBody, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    let filename = res.headers.filename;
                    getDownloadFile(res.data, filename);
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const projectDownloadMultipleExcelFilesAction = (
    projectIds,
    excelType
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        projectDownloadMultipleExcelFiles(projectIds, excelType, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    let fileName = res.headers.filename;
                    getDownloadFile(res.data, fileName);
                    dispatch(downloadProjectFiles(projectIds));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getLoginToken = (user, pass) => {
    return (dispatch, getState) => {
        let isLoginEndpoint = true;
        dispatch(setLoginError(null));

        getLoginTokenAPI(user, pass)
            .then((res) => {
                if (res.status >= 200 && res.status <= 300) {
                    dispatch(setAuthToken(res.data.access_token));
                    dispatch(setTokenType(res.data.token_type));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getFileDetailsAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const redirectionDetails = getRedirectionDetails(projectId, fileId);
        let isLoginEndpoint = false;

        dispatch(clearFileDetails());
        dispatch(clearRedirectionDetails());
        dispatch(setRedirectionDetails(redirectionDetails));

        getFileDetails(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(addFileDetails(res.data));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getFileResultsAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        dispatch(clearFileResults());

        getFileResults(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(addFileResults(res.data));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const patchFileResultsAction = (
    projectId,
    fileId,
    updatedData,
    newFileDetails,
    tableSchema,
    columnSpec,
    navigate
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const fileDetails = { projectId: projectId, fileId: fileId };
        let isLoginEndpoint = false;

        patchFileResults(projectId, fileId, updatedData, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(saveFileResults(fileDetails));
                    dispatch(clearFileResults());
                    dispatch(addFileResults(res.data));
                    if (tableSchema) {
                        dispatch(
                            handleReprocessingAction(
                                projectId,
                                fileId,
                                newFileDetails,
                                tableSchema,
                                columnSpec,
                                false,
                                navigate
                            )
                        );
                    } else {
                        dispatch(
                            updateFileDetailsAction(
                                projectId,
                                fileId,
                                newFileDetails
                            )
                        );
                    }
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const downloadFileResultsExcelAction = (
    projectId,
    fileId,
    updatedData,
    newFileDetails,
    tableSchema,
    columnSpec,
    navigate
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const fileDetails = { projectId: projectId, fileId: fileId };
        let isLoginEndpoint = false;
        const redirectionDetails = getRedirectionDetails(projectId, fileId);

        patchFileResults(projectId, fileId, updatedData, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    dispatch(saveFileResults(fileDetails));
                    dispatch(clearFileResults());
                    dispatch(addFileResults(res.data));

                    if (tableSchema) {
                        dispatch(
                            handleReprocessingAction(
                                projectId,
                                fileId,
                                newFileDetails,
                                tableSchema,
                                columnSpec,
                                true,
                                navigate
                            )
                        );
                        dispatch(
                            setUserNotification(
                                "Your file will be downloaded as soon as it has been reprocessed."
                            )
                        );
                    } else {
                        dispatch(clearFileDetails());
                        dispatch(clearRedirectionDetails());
                        dispatch(setRedirectionDetails(redirectionDetails));

                        updateFileDetails(
                            projectId,
                            fileId,
                            fileDetails,
                            headers
                        )
                            .then((res) => {
                                if (res.status >= 200 && res.status < 300) {
                                    dispatch(addFileDetails(res.data));
                                }

                                downloadFileResultsExcel(
                                    projectId,
                                    fileId,
                                    headers
                                )
                                    .then((res) => {
                                        if (
                                            res.status >= 200 &&
                                            res.status < 300
                                        ) {
                                            let filename = res.headers.filename;
                                            dispatch(
                                                downloadFileResults(fileDetails)
                                            );
                                            getDownloadFile(res.data, filename);
                                        }
                                    })
                                    .catch((error) => {
                                        handleApiError(
                                            error,
                                            dispatch,
                                            isLoginEndpoint
                                        );
                                    });
                            })
                            .catch((error) => {
                                handleApiError(error, dispatch, isLoginEndpoint);
                            });
                    }
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const downloadFileResultsExcelWebsocketAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        // const redirectionDetails = getRedirectionDetails(projectId, fileId);
        let isLoginEndpoint = false;

        const fileDetails = { projectId: projectId, fileId: fileId };

        downloadFileResultsExcel(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    let filename = res.headers.filename;
                    dispatch(downloadFileResults(fileDetails));
                    getDownloadFile(res.data, filename);
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getFileImagesAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const redirectionDetails = getRedirectionDetails(projectId, fileId);
        let isLoginEndpoint = false;

        dispatch(clearRedirectionDetails());
        dispatch(clearFileImages());
        dispatch(clearImagesFileName());
        dispatch(setRedirectionDetails(redirectionDetails));

        getFileImages(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status <= 300) {
                    dispatch(setFileImages(res.data.all_file_images));
                    dispatch(setImagesFileName(res.data.file_name));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getFileTableAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        dispatch(clearFileTables());

        getFileTables(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status <= 300) {
                    dispatch(setFileTables(res.data));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const postTablesUpdateAction = (
    projectId,
    fileId,
    tableSchema,
    columnSpec
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        postTablesUpdate(
            projectId,
            fileId,
            tableSchema,
            columnSpec,
            headers
        ).catch((error) => {
            handleApiError(error, dispatch, isLoginEndpoint);
        });
    };
};

export const reprocessSavedFileAction = (
    projectId,
    fileId,
    tableSchema,
    columnSpec,
    isFileToBeDownloaded,
    pageLocation,
    navigate,
    autoIncrementYear,
    forceTwoDecimalPlaces,
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        reprocessSavedFile(
            projectId,
            fileId,
            tableSchema,
            columnSpec,
            isFileToBeDownloaded,
            pageLocation,
            headers,
            autoIncrementYear,
            forceTwoDecimalPlaces,
        )
            .then((response) => {
                goToHomePage(navigate, projectId);
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getFileColSpecAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        getFileColSpec(projectId, fileId, headers)
            .then((res) => {
                if (res.status >= 200 && res.status <= 300) {
                    dispatch(setFileColSpec(res.data));
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const updateFileDetailsAction = (projectId, fileId, fileDetails) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;
        const redirectionDetails = getRedirectionDetails(projectId, fileId);

        dispatch(clearFileDetails());
        dispatch(clearRedirectionDetails());
        dispatch(setRedirectionDetails(redirectionDetails));

        updateFileDetails(projectId, fileId, fileDetails, headers)
            .then((res) => {
                dispatch(addFileDetails(res.data));
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const awsCognitoRegister = (
    user,
    pass,
    name,
    phoneNumber,
    subscriptionType,
    paymentSchedule,
    isGuest,
    x_amzn_marketplace_token
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);

        awsCognitoSignUp(user, pass, phoneNumber)
            .then((res) => {
                let awsUserId = res.userSub;
                if (!isGuest) {
                    registerUser(
                        user,
                        pass,
                        name,
                        awsUserId,
                        "company",
                        phoneNumber,
                        "jobTitle",
                        headers,
                        subscriptionType,
                        paymentSchedule,
                        x_amzn_marketplace_token
                    )
                        .then((res) => {
                            dispatch(setProgressToNextStep("verifyEmail"));
                        })
                        .catch((error) => {
                            handleApiError(error, dispatch, true);
                            dispatch(setProgressToNextStep(null));
                        });
                } else {
                    registerGuestUser(
                        user,
                        pass,
                        name,
                        awsUserId,
                        "company",
                        phoneNumber,
                        "jobTitle",
                        headers,
                        subscriptionType,
                        paymentSchedule
                    )
                        .then((res) => {
                            dispatch(setProgressToNextStep("verifyEmail"));
                        })
                        .catch((error) => {
                            handleApiError(error, dispatch, true);
                            dispatch(setProgressToNextStep(null));
                        });
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, true);
                dispatch(setProgressToNextStep(null));
            });
    };
};

export const awsCognitoConfirmRegistration = (user, validationCode) => {
    return (dispatch) => {
        awsCognitoConfirmSignUp(user, validationCode)
            .then((res) => {
                dispatch(setRedirectToLogin(true));
                dispatch(
                    setAuthMessage("Account Verified. You can now sign in.")
                );
            })
            .catch((error) => {
                handleApiError(error, dispatch, true);
            });
    };
};

export const awsCognitoResendConfirmationCode = (user) => {
    return (dispatch) => {
        awsCognitoResendSignUp(user)
            .then((res) => {})
            .catch((error) => {
                handleApiError(error, dispatch, true);
            });
    };
};

export const awsCognitoLogOut = () => {
    return (dispatch, getState) => {
        const isLoginEndpoint = false;
        const state = getState();

        const WEBSOCKET_URL = {
            url: `${process.env.REACT_APP_WEBSOCKET_URL}`,
            headers: state.auth.authToken,
        };

        awsCognitoSignOut()
            .then((res) => {
                dispatch(clearAuthToken());
                dispatch(clearUser());
                dispatch(setProjects([]));
                dispatch(wsDisconnect(WEBSOCKET_URL));
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const awsCognitoLogIn = (user, pass, mfaCode, newPassword) => {
    return (dispatch) => {
        let isLoginEndpoint = true;
        dispatch(setLoginError(null));

        awsCognitoSignIn(user, pass)
            .then((res) => {
                let challengeName = res.challengeName;
                let cognitoUser = res;
                if (challengeName === "SOFTWARE_TOKEN_MFA") {
                    dispatch(setProgressToNextStep("mfaCode"));
                    if (mfaCode) {
                        awsCognitoConfirmSignIn(
                            cognitoUser,
                            mfaCode,
                            "SOFTWARE_TOKEN_MFA"
                        )
                            .then((res) => {
                                let jwtToken =
                                    res.signInUserSession.accessToken.jwtToken;
                                awsCurrentAuthenticatedUser()
                                    .then((response) => {
                                        let termsAndConditionsAccepted =
                                            response.attributes[
                                                "custom:acceptedTCs"
                                            ];
                                        if (
                                            termsAndConditionsAccepted ===
                                            "False"
                                        ) {
                                            dispatch(
                                                setProgressToNextStep(
                                                    "showTermsAndConditions"
                                                )
                                            );
                                        } else {
                                            setJWTToken(dispatch, jwtToken);
                                        }
                                    })
                                    .catch((error) => {
                                        handleApiError(
                                            error,
                                            dispatch,
                                            isLoginEndpoint
                                        );
                                    });
                            })
                            .catch((error) => {
                                handleApiError(error, dispatch, isLoginEndpoint);
                            });
                    }
                } else if (challengeName === "SMS_MFA") {
                    dispatch(setCognitoUser(null));
                    dispatch(setProgressToNextStep("mfaCodeSMS"));
                    dispatch(setCognitoUser(cognitoUser));
                } else if (challengeName === "NEW_PASSWORD_REQUIRED") {
                    dispatch(setProgressToNextStep("newPasswordRequired"));
                    if (newPassword !== (null || undefined)) {
                        awsCognitoNewPasswordRequired(cognitoUser, newPassword)
                            .then((res) => {
                                let jwtToken =
                                    res.signInUserSession.accessToken.jwtToken;

                                const headers = {
                                    Authorization: `Bearer ${jwtToken}`,
                                };

                                let awsUserId = res.username;

                                registerUser(
                                    user,
                                    pass,
                                    user,
                                    awsUserId,
                                    "company",
                                    "phone_number",
                                    "jobTitle",
                                    headers
                                ).then((res) => {
                                    if (
                                        user ===
                                        process.env.REACT_APP_ADMIN_EMAIL_ADDRESS
                                    ) {
                                        awsUpdateTermsAndConditionsUserAttributes(
                                            cognitoUser,
                                            "True"
                                        );
                                    } else {
                                        awsUpdateTermsAndConditionsUserAttributes(
                                            cognitoUser,
                                            "False"
                                        );
                                    }

                                    checkTermsAndConditions(
                                        dispatch,
                                        headers,
                                        jwtToken,
                                        isLoginEndpoint
                                    );
                                });
                            })
                            .catch((error) => {
                                handleApiError(error, dispatch, isLoginEndpoint);
                            });
                    }
                } else {
                    let jwtToken = res.signInUserSession.accessToken.jwtToken;
                    const headers = {
                        Authorization: `Bearer ${jwtToken}`,
                    };

                    checkTermsAndConditions(
                        dispatch,
                        headers,
                        jwtToken,
                        isLoginEndpoint
                    );
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const awsCognitoVerifyMFACode = (mfaCode) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);

        awsCurrentAuthenticatedUser().then((res) => {
            let cognitoUser = res;
            let jwtToken = res.signInUserSession.accessToken.jwtToken;
            let termsAndConditionsAccepted =
                res.attributes["custom:acceptedTCs"];

            awsCognitoVerifyTotpToken(cognitoUser, mfaCode)
                .then((res) => {
                    if (termsAndConditionsAccepted === "False") {
                        qrCodeReset(dispatch, cognitoUser, headers);

                        dispatch(
                            setProgressToNextStep(
                                "showTermsAndConditionsFromMfaSetUp"
                            )
                        );
                    } else {
                        setJWTToken(dispatch, jwtToken);
                        qrCodeReset(dispatch, cognitoUser, headers);
                    }
                })
                .catch((error) => {
                    handleApiError(error, dispatch, true);
                });
        });
    };
};

export const setUpMFAFromManageAccount = (mfaCode) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;

        awsCurrentAuthenticatedUser().then((res) => {
            let cognitoUser = res;

            awsCognitoVerifyTotpToken(cognitoUser, mfaCode)
                .then((res) => {
                    qrCodeReset(dispatch, cognitoUser, headers);
                    dispatch(
                        setAuthMessage(
                            "Multi Factor Authentication successfully set up"
                        )
                    );
                })
                .catch((error) => {
                    handleApiError(error, dispatch, isLoginEndpoint);
                });
        });
    };
};

export const acceptTermsAndConditions = () => {
    return (dispatch) => {
        awsCurrentAuthenticatedUser().then((res) => {
            let cognitoUser = res;
            let jwtToken = res.signInUserSession.accessToken.jwtToken;
            const headers = {
                Authorization: `Bearer ${jwtToken}`,
            };
            awsUpdateTermsAndConditionsUserAttributes(cognitoUser, "True")
                .then((res) => {
                    setJWTToken(dispatch, jwtToken);
                    updateCurrentUser(
                        headers,
                        "terms_conditions_accepted",
                        true
                    );
                    emailVerifiedSendAlert(headers);
                })
                .catch((error) => {
                    handleApiError(error, dispatch, true);
                });
        });
    };
};

export const awsCognitoForgotPasswordAction = (userEmail) => {
    return (dispatch) => {
        awsCognitoForgotPassword(userEmail)
            .then((res) => {})
            .catch((error) => {
                handleApiError(error, dispatch, true);
            });
    };
};

export const awsCognitoForgotPasswordSubmitAction = (
    userEmail,
    verificationCode,
    newPassword
) => {
    return (dispatch) => {
        awsCognitoForgotPasswordSubmit(userEmail, verificationCode, newPassword)
            .then((res) => {
                dispatch(
                    setAuthMessage(
                        "Password successfully changed. You can now sign in."
                    )
                );
            })
            .catch((error) => {
                handleApiError(error, dispatch, true);
            });
    };
};

export const stripeCreateCheckoutSessionAction = (subscriptionType, upgrade) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;

        stripeCreateCheckoutSession(subscriptionType, upgrade, headers)
            .then((res) => {
                let stripeSessionId = res.data.sessionId;
                redirectToCheckout(stripeSessionId);
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const stripeManageAccountAction = () => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;

        stripeManageAccount(headers)
            .then((res) => {
                let portalURL = res.data.portal_url;
                redirectToAccount(portalURL);
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const getCurrentUserAction = () => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;
        // dispatch(clearUser());

        getCurrentUser(headers)
            .then((res) => {
                let userData = res.data;
                dispatch(setUser(userData));
                handleUserInfo(dispatch, userData);
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const restoreFileAction = (projectId, fileId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;

        restoreFile(projectId, fileId, headers)
            .then((res) => {})
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const autogridMaskAction = (projectId, fileId, maskObject) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;
        dispatch(clearAutoGrid());

        autogridMask(projectId, fileId, maskObject, headers)
            .then((res) => {
                dispatch(setAutoGrid(res.data));
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const automergeMaskAction = (
    projectId,
    fileId,
    maskObject,
    mergeDirection
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        const isLoginEndpoint = false;
        dispatch(clearAutoMerge());

        automergeMask(projectId, fileId, maskObject, mergeDirection, headers)
            .then((res) => {
                dispatch(setAutoMerge(res.data));
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const changePassword = (oldPassword, newPassword) => {
    return (dispatch) => {
        const isLoginEndpoint = false;

        awsCurrentAuthenticatedUser()
            .then((response) => {
                let cognitoUser = response;

                awsCognitoChangePassword(cognitoUser, oldPassword, newPassword)
                    .then((response) => {
                        switch (response) {
                            case "SUCCESS":
                                dispatch(
                                    setAuthMessage(
                                        "Password changed successfully"
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const verifyUserAttributeAction = (cognitoUser, attribute) => {
    return (dispatch) => {
        const isLoginEndpoint = false;

        awsCognitoVerifyUserAttribute(cognitoUser, attribute)
            .then((response) => {
                switch (response) {
                    case "SUCCESS":
                        dispatch(
                            setAuthMessage(
                                "A message with a verification code has been sent to your registered phone number"
                            )
                        );
                        break;
                    default:
                        break;
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const verifyUserAttributeSubmitAction = (attribute, code) => {
    return (dispatch, getState) => {
        const isLoginEndpoint = false;
        const state = getState();
        const headers = getHeaders(state);

        awsCurrentAuthenticatedUser()
            .then((response) => {
                let cognitoUser = response;

                awsCognitoVerifyUserAttributeSubmit(cognitoUser, attribute, code)
                    .then((response) => {
                        switch (response) {
                            case "SUCCESS":
                                setPreferredMFAFlow(
                                    dispatch,
                                    cognitoUser,
                                    "SMS",
                                    headers
                                );
                                break;
                            default:
                                break;
                        }
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const cognitoUpdatePhoneNumberAction = (value, attribute) => {
    return (dispatch) => {
        const isLoginEndpoint = false;

        awsCurrentAuthenticatedUser()
            .then((response) => {
                let cognitoUser = response;

                awsUpdatePhoneNumber(cognitoUser, value)
                    .then((response) => {
                        switch (response) {
                            case "SUCCESS":
                                dispatch(
                                    setAuthMessage(
                                        "Phone number successfully updated"
                                    )
                                );
                                break;
                            default:
                                break;
                        }
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .then(() => {
                if (attribute !== "updatePhoneNumber") {
                    awsCurrentAuthenticatedUser().then((res) => {
                        let user = res;

                        let isPhoneNumberVerified =
                            checkIfUserAttributeHasBeenVerified(
                                "phone_number",
                                res
                            );

                        if (!isPhoneNumberVerified) {
                            phoneNumberNotVerifiedFlow(
                                dispatch,
                                user,
                                attribute
                            );
                        }
                    });
                }
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const verifySMSMFA = (mfaSMSCode) => {
    return (dispatch, getState) => {
        const isLoginEndpoint = false;
        const state = getState();
        const cognitoUser = state.auth.cognitoUser;
        dispatch(setCognitoUser(null));

        awsCognitoConfirmSignIn(cognitoUser, mfaSMSCode, "SMS_MFA")
            .then((res) => {
                awsCurrentAuthenticatedUser()
                    .then((response) => {
                        let jwtToken =
                            response.signInUserSession.accessToken.jwtToken;

                        let termsAndConditionsAccepted =
                            response.attributes["custom:acceptedTCs"];
                        if (termsAndConditionsAccepted === "False") {
                            dispatch(
                                setProgressToNextStep("showTermsAndConditions")
                            );
                        } else {
                            setJWTToken(dispatch, jwtToken);
                        }
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const redirectionActions = (projectId) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        getCurrentUser(headers)
            .then((res) => {
                let userData = res.data;
                dispatch(setUser(userData));
                handleUserInfo(dispatch, userData);

                getProjects(headers)
                    .then((res) => {
                        dispatch(setProjects(res.data));

                        getProjectFiles(projectId, headers)
                            .then((res) => {
                                dispatch(addFilesToProject(projectId, res.data));
                            })
                            .catch((error) => {
                                handleApiError(error, dispatch, isLoginEndpoint);
                            });
                    })
                    .catch((error) => {
                        handleApiError(error, dispatch, isLoginEndpoint);
                    });
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const handleReprocessingAction = (
    projectId,
    fileId,
    fileDetails,
    tableSchema,
    columnSpec,
    isFileToBeDownloaded,
    pageLocation,
    navigate,
    autoIncrementYear,
    forceTwoDecimalPlaces,
) => {
    return (dispatch, getState) => {
        const state = getState();
        const headers = getHeaders(state);
        let isLoginEndpoint = false;

        updateFileDetails(projectId, fileId, fileDetails, headers)
            .then((res) => {
                dispatch(addFileDetails(res.data));
                dispatch(
                    reprocessSavedFileAction(
                        projectId,
                        fileId,
                        tableSchema,
                        columnSpec,
                        isFileToBeDownloaded,
                        pageLocation,
                        navigate,
                        autoIncrementYear,
                        forceTwoDecimalPlaces,
                    )
                );
            })
            .catch((error) => {
                handleApiError(error, dispatch, isLoginEndpoint);
            });
    };
};

export const awsCognitoFederatedSignInAction = () => {
    return (dispatch) => {
        awsCognitoFederatedSignIn().then((res) => {
            console.log("COGNITO RESPONSE", res)
        })
    }
}
