import { CaretSortDown32, CaretSortUp32, Warning32 } from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";
import "./warning-button.styles.scss";

const WarningButton = ({
    blurButtons,
    displayUpDownWarningButtons,
    setDisplayUpDownWarningButtons,
    navigateThroughWarningsUp,
    navigateThroughWarningsDown,
}) => {
    const onClick = () => {
        setDisplayUpDownWarningButtons(!displayUpDownWarningButtons);
        blurButtons();
    };

    return (
        <div className="warning-buttons">
            <Button
                renderIcon={Warning32}
                iconDescription="Mask Warnings"
                data-testid="mask-page-file-toolbar-open-scroll-warnings-button"
                id="mask-page-file-toolbar-open-scroll-warnings-button"
                className="warning-button-colour"
                hasIconOnly={true}
                onClick={() => onClick()}
            />
            {displayUpDownWarningButtons ? (
                <div className="warning-scroll-buttons">
                    <Button
                        renderIcon={CaretSortUp32}
                        iconDescription="Previous Warning"
                        data-testid="mask-page-file-toolbar-scroll-warning-up-button"
                        id="mask-page-file-toolbar-scroll-warning-up-button"
                        className="warning-button-colour up"
                        hasIconOnly={true}
                        onClick={navigateThroughWarningsUp}
                    />
                    <Button
                        renderIcon={CaretSortDown32}
                        iconDescription="Next Warning"
                        data-testid="mask-page-file-toolbar-scroll-warning-down-button"
                        id="mask-page-file-toolbar-scroll-warning-down-button"
                        className="warning-button-colour down"
                        hasIconOnly={true}
                        onClick={navigateThroughWarningsDown}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default WarningButton;
