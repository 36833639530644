const pre = "files";
const GET_FILE_IMAGES = `${pre}/GET_FILE_IMAGES`;
const CLEAR_FILE_IMAGES = `${pre}/CLEAR_FILE_IMAGES`;
const SET_IMAGES_FILE_NAME = `${pre}/SET_IMAGES_FILE_NAME`;
const CLEAR_IMAGES_FILE_NAME = `${pre}/CLEAR_IMAGES_FILE_NAME`;
const SET_FILE_TABLES = `${pre}/SET_FILE_TABLES`;
const CLEAR_FILE_TABLES = `${pre}/CLEAR_FILE_TABLES`;
const SET_FILE_COL_SPEC = `${pre}/SET_FILE_COL_SPEC`;
const CLEAR_FILE_COL_SPEC = `${pre}/CLEAR_FILE_COL_SPEC`;
const SET_FILE_RESULTS = `${pre}/SET_FILE_RESULTS`;
const CLEAR_FILE_RESULTS = `${pre}/CLEAR_FILE_RESULTS`;
const SAVE_FILE_RESULTS = `${pre}/SAVE_FILE_RESULTS`;
const DOWNLOAD_FILE_RESULTS = `${pre}/DOWNLOAD_FILE_RESULTS`;
const DOWNLOAD_PROJECT_FILES = `${pre}/DOWNLOAD_PROJECT_FILES`;
const DOWNLOAD_FILES = `${pre}/DOWNLOAD_FILES`;
const SET_CATEGORISED_FILE_RESULTS = `${pre}/SET_CATEGORISED_FILE_RESULTS`;
const CLEAR_CATEGORISED_FILE_RESULTS = `${pre}/CLEAR_CATEGORISED_FILE_RESULTS`;
const SET_FILE_STATUS = `${pre}/SET_FILE_STATUS`;
const CLEAR_FILE_STATUS = `${pre}/CLEAR_FILE_STATUS`;

const fileTypes = {
    GET_FILE_IMAGES,
    CLEAR_FILE_IMAGES,
    SET_IMAGES_FILE_NAME,
    CLEAR_IMAGES_FILE_NAME,
    SET_FILE_TABLES,
    CLEAR_FILE_TABLES,
    SET_FILE_COL_SPEC,
    CLEAR_FILE_COL_SPEC,
    SET_FILE_RESULTS,
    CLEAR_FILE_RESULTS,
    SAVE_FILE_RESULTS,
    DOWNLOAD_FILE_RESULTS,
    DOWNLOAD_PROJECT_FILES,
    DOWNLOAD_FILES,
    SET_CATEGORISED_FILE_RESULTS,
    CLEAR_CATEGORISED_FILE_RESULTS,
    SET_FILE_STATUS,
    CLEAR_FILE_STATUS,
};

export default fileTypes;
