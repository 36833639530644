// import { Accordion, AccordionItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import ManageAccountSetUpMFA from "../../../../components/account-page-components/account-page-dashboard/account-page-manage-account/mfa-setup/mfa-setup.component";
import InputFieldPassword from "../../../../../components/input-fields/input-fields-password.component";
import CommonRegistrationButton from "../../../../../components/registration-buttons/registration-button-common.component";
import { setAPIError } from "../../../../../redux/api/api.actions.js";
import { changePassword } from "../../../../../redux/thunks/thunks.actions.js";
import "../account-page-manage-account.styles.scss";

const AccountDetailsUpdatePassword = ({
    userInfo,
    authMessage,
    setIsManagePasswordOpen,
}) => {
    const dispatch = useDispatch();

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConforms, setNewPasswordConforms] = useState(false);
    const [displayPasswordRequirements, setDisplayPasswordRequirements] =
        useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [user, setUser] = useState("");

    const handleSubmitNewPassword = () => {
        if (newPassword === currentPassword) {
            dispatch(
                setAPIError(
                    "The new password has to be different from current one"
                )
            );
        } else {
            dispatch(changePassword(currentPassword, newPassword));
        }
    };

    useEffect(() => {
        if (userInfo) {
            setUser(userInfo.email);
        }
    }, [userInfo]);

    useEffect(() => {
        switch (authMessage) {
            case "Password changed successfully":
                setNewPassword("");
                setCurrentPassword("");
                setIsManagePasswordOpen(false);
                break;
            default:
                break;
        }
    }, [authMessage, setIsManagePasswordOpen]);

    return (
        <div className="margin-bottom" data-testid="update-password-element">
            <div className="password-div">
                <div
                    className="larger-text margin-bottom"
                    data-testid="current-password-input-field-text"
                >
                    Please enter your current password
                </div>
                <InputFieldPassword
                    setPass={setCurrentPassword}
                    pass={currentPassword}
                    setPasswordConforms={() => {}}
                    passwordConforms={true}
                    setDisplayPasswordRequirements={() => {}}
                    displayPasswordRequirements={false}
                    user={user}
                    emailConforms={true}
                    stopOnFocusEvent={true}
                    id={"current-password"}
                />
            </div>

            <div
                className="larger-text margin-bottom"
                data-testid="new-password-input-field-text"
            >
                Please enter a new password
            </div>
            <InputFieldPassword
                setPass={setNewPassword}
                pass={newPassword}
                setPasswordConforms={setNewPasswordConforms}
                passwordConforms={newPasswordConforms}
                setDisplayPasswordRequirements={setDisplayPasswordRequirements}
                displayPasswordRequirements={displayPasswordRequirements}
                user={user}
                emailConforms={true}
                stopOnFocusEvent={false}
                id={"new-password"}
            />
            <div className="bigger-margin-bottom"></div>
            <CommonRegistrationButton
                buttonKind="primary"
                isDisabled={newPasswordConforms ? false : true}
                onClickFunction={handleSubmitNewPassword}
                buttonDescription="Update password"
                dataTestId="submit-password-verification-code"
            />
        </div>
    );
};

export default AccountDetailsUpdatePassword;
