import { checkIsDateIsValid } from "../../../helpers/helper-functions.js";
// Column Value Getters
let valuesToCompareAgainst = ["", "NaN"];

const differenceColumnValueGetter = (params) => {
    if (params.api !== null) {
        let rowIndex = params.node.rowIndex;
        let currentRow = params.api.getDisplayedRowAtIndex(rowIndex);
        let statementBalance = parseFloat(
            getValueFunction(params, "Statement Balance", currentRow)
        );
        let balance = parseFloat(
            getValueFunction(params, "Balance", currentRow)
        );
        let difference;
        let stringStatementBalance = String(statementBalance);

        switch (stringStatementBalance) {
            case "":
                difference = Number(0.0);
                break;
            case "NaN":
                difference = Number(0.0);
                break;
            default:
                difference = Number(statementBalance - balance).toFixed(2);
                break;
        }

        params.node.data["Difference"] = difference;

        return difference;
    }
};

const valueColumnValueGetter = (params) => {
    if (params.api !== null) {
        let creditValue = parseFloat(params.data.Credit).toFixed(2)
        let debitValue = parseFloat(params.data.Debit).toFixed(2)

        let stringCreditValue = String(creditValue);
        let stringDebitValue = String(debitValue);
        let value;

        if (
            valuesToCompareAgainst.includes(stringDebitValue) &&
            valuesToCompareAgainst.includes(stringCreditValue)
        ) {
            value = "";
        } else if (valuesToCompareAgainst.includes(stringDebitValue)) {
            value = `${creditValue}`;
        } else if (valuesToCompareAgainst.includes(stringCreditValue)) {
            value = `-${debitValue}`;
        } else {
            let valueToDisplay = parseFloat(creditValue - debitValue).toFixed(2);
            value = valueToDisplay;
        }

        return value;
    }
};

const balanceColumnValueGetter = (params, initialBalance) => {
    // if (params.api !== null) {
    // let rowIndex = params.node.rowIndex;
    // // let currentRow = params.api.getDisplayedRowAtIndex(rowIndex);
    // let previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
    // // let value = parseFloat(getValueFunction(params, "Value", currentRow));
    // let value = params.getValue('Value')
    // let stringValue = String(value);
    // let updatedStatementBalance;

    // if (rowIndex !== 0) {
    //     let previousBalance = parseFloat(
    //         getValueFunction(params, "Balance", previousRow)
    //     );
    //     if (valuesToCompareAgainst.includes(stringValue)) {
    //         updatedStatementBalance = previousBalance;
    //     } else {
    //         updatedStatementBalance = previousBalance + value;
    //     }
    // } else {
    //     updatedStatementBalance = initialBalance;
    // }

    // // params.node.data["Balance"] = updatedStatementBalance;

    // return updatedStatementBalance;

    // }
    let value = parseFloat(params.getValue('Value'))
    let previousRow = params.api.getDisplayedRowAtIndex(params.node.rowIndex - 1)

    let stringValue = String(value);
    let updatedStatementBalance;

    if (params.node.rowIndex !== 0) {
        let previousBalance = parseFloat(
            params.api.getValue('Balance', previousRow)
        );
        if (valuesToCompareAgainst.includes(stringValue)) {
            updatedStatementBalance = previousBalance;
        } else {
            updatedStatementBalance = previousBalance + value;
        }
    } else {
        updatedStatementBalance = initialBalance;
    }

    return updatedStatementBalance
};

const balanceColumnValueSetter = (
    params,
    setInitialBalance,
) => {
    if (params.api !== null) {

        let rowIndex = params.node.id;
        let newValue = params.newValue;
        let colId = `${params.colDef.colId}`;

        updateSpecificCell(rowIndex, newValue, `${colId}`, params.api);

        setInitialBalance(newValue);
    }

};

// Helper functions
const getValueFunction = (params, columnId, row) => {
    if (params.api !== null) {
        let value = params.api.getValue(`${columnId}`, row);
        return value;
    }
};
const createNewRowObject = (eventType, element) => {
    let newRowObject;

    switch (eventType) {
        case "dragEvent":
            let drag = 0;
            let updatedDrag;
            if (element.hasOwnProperty("drag")) {
                updatedDrag = element["drag"] + 1;
            } else {
                updatedDrag = drag;
            }

            newRowObject = {
                Date: element["Date"],
                Type: element["Type"],
                Description: element["Description"],
                Debit: element["Debit"],
                Credit: element["Credit"],
                Value: element["Value"],
                "Statement Balance": element["Statement Balance"],
                Balance: element["Balance"],
                Difference: element["Difference"],
                PageNumber: element["PageNumber"],
                row_coordinates: element["row_coordinates"],
                drag: updatedDrag,
                id: element["id"]
            };

            return newRowObject;

        default:
            newRowObject = {
                Date: "",
                Type: "",
                Description: "",
                Debit: null,
                Credit: null,
                Value: null,
                "Statement Balance": null,
                Balance: null,
                Difference: null,
                PageNumber: null,
                row_coordinates: null,
            };
            return newRowObject;
    }
};

const updateSpecificCell = (rowIndex, value, columnId, gridApi) => {
    var itemsToUpdate = [];
    let currentRow = gridApi.getRowNode(rowIndex);
    let updatedValue = String(value) === "NaN" ? null : value;
    var data = currentRow.data;
    data[columnId] = updatedValue;

    itemsToUpdate.push(data);
    gridApi.applyTransaction({
        update: itemsToUpdate,
    });
};

const determineDateCellColour = (params, validationList, filteringTable) => {
    let ignoreValidation = getIgnoreValidationStatusByIndex(
        validationList,
        params.node.rowIndex
    );

    if (params.api !== null) {
        let rowIndex = params.node.rowIndex;
        let currentRow = params.api.getDisplayedRowAtIndex(rowIndex);
        let previousRow = params.api.getDisplayedRowAtIndex(rowIndex - 1);
        let date = getValueFunction(params, "Date", currentRow);
        let currentDate = new Date(date);
        let previousDate = new Date(
            getValueFunction(params, "Date", previousRow)
        );

        var isCurrentDateValid
        if (date instanceof Date) {
            isCurrentDateValid = true
        } else {
            isCurrentDateValid = checkIsDateIsValid(date);
        }

        if (
            (previousDate > currentDate &&
                !ignoreValidation &&
                !filteringTable &&
                rowIndex !== 0) ||
            (!isCurrentDateValid && rowIndex !== 0)
        ) {
            return "cell-fail";
        }
    }
};

const determineDifferenceCellColour = (
    params,
    validationList,
    filteringTable
) => {
    let ignoreValidation = getIgnoreValidationStatusByIndex(
        validationList,
        params.node.rowIndex
    );
    if (params.api !== null) {
        let value = parseFloat(Number(params.value).toFixed(2));
        if (value !== 0 && !ignoreValidation && !filteringTable) {
            return "cell-fail";
        }
    }
};

const getIgnoreValidationStatusByIndex = (validationList, currentRowIndex) => {
    if (!validationList) {
        validationList = []
    }
    let validationListCopy = JSON.parse(JSON.stringify(validationList));
    let ignoreValidation = false;
    if (validationListCopy !== null) {
        let infoForThisToggle = validationListCopy.filter(
            (validationItem) => validationItem.rowIndex === currentRowIndex
        );
        if (infoForThisToggle.length !== 0) {
            ignoreValidation = infoForThisToggle[0]["ignoreValidation"];
        }
    }
    return ignoreValidation;
};

const formatToTwoDecimalPlaces = (params) => {
    if (params.api !== null) {
        let value = params.value;
        let formattedValue;
        switch (value) {
            case null:
                formattedValue = null;
                return formattedValue;
            case undefined:
                formattedValue = null;
                return formattedValue;
            case "":
                formattedValue = null;
                return formattedValue;
            default:
                formattedValue = parseFloat(value).toFixed(2);
                return formattedValue;
        }
    }
};

const convertRemToPixels = (rem) => {
    let remToPixelsValue = rem * 16;
    return remToPixelsValue;
};

const adjustContextMenuLocation = (value, rem) => {
    let remToPixelsValue = convertRemToPixels(rem);
    let updatedPixelValue = value - remToPixelsValue;

    return updatedPixelValue;
};

const getValidationObjectByIndex = (validationList, index) => {
    let foundItem;
    validationList.filter((item) => {
        if (item.rowIndex === index) {
            foundItem = item;
        }
        return foundItem;
    });

    return foundItem;
};

const helperValidationCheck = (gridApi, data, validationList) => {
    if (gridApi) {
        let validationCheckValidationList = [];

        for (let i = 0; i < data.length; i++) {
            let currentRow = gridApi.getDisplayedRowAtIndex(i);
            let previousRow = gridApi.getDisplayedRowAtIndex(i - 1);

            let difference = parseFloat(
                gridApi.getValue("Difference", currentRow)
            );
            let date = gridApi.getValue("Date", currentRow);
            let currentDate = new Date(date);
            let previousDate = new Date(gridApi.getValue("Date", previousRow));

            var isCurrentDateValid
            if (date instanceof Date) {
                isCurrentDateValid = true
            } else {
                isCurrentDateValid = checkIsDateIsValid(date);
            }

            let validationItemAlreadyExists = getValidationObjectByIndex(
                validationList,
                i
            );

            if (difference !== 0.0) {
                if (
                    validationItemAlreadyExists === null ||
                    validationItemAlreadyExists === undefined
                ) {
                    validationCheckValidationList.push({
                        rowIndex: i,
                        colKey: "Difference",
                        ignoreValidation: false,
                    });
                } else {
                    validationCheckValidationList.push({
                        rowIndex: i,
                        colKey: "Difference",
                        ignoreValidation:
                            validationItemAlreadyExists.ignoreValidation,
                    });
                }
            }
            if (previousDate > currentDate || (!isCurrentDateValid && i !== 0)) {
                if (
                    validationItemAlreadyExists === null ||
                    validationItemAlreadyExists === undefined
                ) {
                    validationCheckValidationList.push({
                        rowIndex: i,
                        colKey: "Date",
                        ignoreValidation: false,
                    });
                } else {
                    validationCheckValidationList.push({
                        rowIndex: i,
                        colKey: "Date",
                        ignoreValidation:
                            validationItemAlreadyExists.ignoreValidation,
                    });
                }
            }
        }
        return validationCheckValidationList;
    }
};

const helperUpdateValidationList = (validationCheckList, rowToggle) => {
    if (validationCheckList && validationCheckList.length !== 0) {
        let validationListCopy = JSON.parse(JSON.stringify(validationCheckList));

        let updatedValidationList = validationListCopy.map((item) => {
            if (item.rowIndex === rowToggle.rowIndex) {
                item.ignoreValidation = rowToggle.ignoreValidation;
                return item;
            } else {
                return item;
            }
        });

        updatedValidationList.sort((a, b) => {
            return a.rowIndex - b.rowIndex;
        });
        return updatedValidationList;
    }
};

const determineFinalValidationList = (validationCheckList, validationList) => {
    let finalValidationList = [];

    for (let validationItem of validationCheckList) {
        if (validationList.length !== 0) {
            for (let toggleInfo of validationList) {
                if (validationItem["rowIndex"] === toggleInfo["rowIndex"]) {
                    validationItem["ignoreValidation"] =
                        toggleInfo["ignoreValidation"];
                }
            }
            if (!validationItem.hasOwnProperty("ignoreValidation")) {
                validationItem["ignoreValidation"] = false;
            }
        } else {
            validationItem["ignoreValidation"] = false;
        }
        finalValidationList.push(validationItem);
    }
    return finalValidationList;
};

const defineEventDetails = (eventType, eventDetails, undoEventList) => {
    let undoEventListCopy = JSON.parse(JSON.stringify(undoEventList));
    let event = { eventType: eventType, eventDetails: eventDetails };
    undoEventListCopy.push(event);
    let updatedUndoEventListCopy;

    if (undoEventListCopy.length <= 5) {
        updatedUndoEventListCopy = undoEventListCopy;
    } else {
        updatedUndoEventListCopy = undoEventListCopy.slice(-5);
    }
    return updatedUndoEventListCopy;
};

const configureCellEditedEvent = (oldValue, rowIndex, columnId, undoEventList) => {
    let eventType = "cellEdited";
    let eventDetails = {
        oldValue: oldValue,
        rowIndex: rowIndex,
        columnId: columnId,
    };

    let updatedUndoEventListCopy = defineEventDetails(
        eventType,
        eventDetails,
        undoEventList
    );

    return updatedUndoEventListCopy;

};

const helperFunctions = {
    differenceColumnValueGetter,
    valueColumnValueGetter,
    balanceColumnValueGetter,
    getValueFunction,
    createNewRowObject,
    updateSpecificCell,
    determineDateCellColour,
    determineDifferenceCellColour,
    formatToTwoDecimalPlaces,
    adjustContextMenuLocation,
    helperValidationCheck,
    helperUpdateValidationList,
    determineFinalValidationList,
    getIgnoreValidationStatusByIndex,
    balanceColumnValueSetter,
    defineEventDetails,
    configureCellEditedEvent,
};

export default helperFunctions;
