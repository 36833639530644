import React from "react";
import AddGridButton from "./mask-page-file-toolbar-buttons/add-grid-button.component";
import AutoGrid from "./mask-page-file-toolbar-buttons/auto-grid-button.component";
import AutoMerge from "./mask-page-file-toolbar-buttons/auto-merge-button.component";
import DeleteButton from "./mask-page-file-toolbar-buttons/delete-button.component";
import SaveButton from "./mask-page-file-toolbar-buttons/save-button.component";
import UndoButton from "./mask-page-file-toolbar-buttons/undo-button.component";
import WarningButton from "./mask-page-file-toolbar-buttons/warning-button.component";
import CopyButton from "./mask-page-file-toolbar-buttons/copy-columns-button.component";

const MaskPageFileToolbar = ({
    pathname,
    backEndSubmitGrids,
    gridButtonClass,
    toggleAllowPlaceGrid,
    selectedObject,
    deleteLineFunction,
    deleteRectFunction,
    clearRectFunction,
    handleUndo,
    isUndoButtonMaskPageDisabled,
    selectedPageUrl,
    selectedRect,
    setAutoGridColsRows,
    columnSpec,
    setUndoAutoGrid,
    blurButtons,
    allDocumentWarnings,
    displayUpDownWarningButtons,
    setDisplayUpDownWarningButtons,
    navigateThroughWarningsUp,
    navigateThroughWarningsDown,
    hideUpDownWarningButtons,
    setAutoMergeColsRows,
    setRunAutoMerge,
    displayMergeButtons,
    setDisplayMergeButtons,
    isAutoMergeButtonDisabled,
    setIsAutoMergeButtonDisabled,
    showCopyButtons,
    setShowCopyButtons,
}) => {
    return (
        <>
            <div
                className="mask-page-file-toolbar higher-z-index"
                data-testid="mask-page-file-toolbar"
            >
                <DeleteButton
                    selectedObject={selectedObject}
                    deleteLineFunction={deleteLineFunction}
                    deleteRectFunction={deleteRectFunction}
                    blurButtons={blurButtons}
                    hideUpDownWarningButtons={hideUpDownWarningButtons}
                />
                <CopyButton
                    selectedRect={selectedRect}
                    setAutoMergeColsRows={setAutoMergeColsRows}
                    setRunAutoMerge={setRunAutoMerge}
                    runAutoMerge={displayMergeButtons}
                    backEndSubmitGrids={backEndSubmitGrids}
                    blurButtons={blurButtons}
                    showCopyButtons={showCopyButtons}
                    setShowCopyButtons={setShowCopyButtons}
                    setDisplayMergeButtons={setDisplayMergeButtons}
                />
                <AutoGrid
                    pathname={pathname}
                    selectedPageUrl={selectedPageUrl}
                    selectedRect={selectedRect}
                    setAutoGridColsRows={setAutoGridColsRows}
                    backEndSubmitGrids={backEndSubmitGrids}
                    clearRectFunction={clearRectFunction}
                    setUndoAutoGrid={setUndoAutoGrid}
                    blurButtons={blurButtons}
                    hideUpDownWarningButtons={hideUpDownWarningButtons}
                    setIsAutoMergeButtonDisabled={setIsAutoMergeButtonDisabled}
                />
                <AddGridButton
                    gridButtonClass={gridButtonClass}
                    toggleAllowPlaceGrid={toggleAllowPlaceGrid}
                />
                <SaveButton
                    pathname={pathname}
                    backEndSubmitGrids={backEndSubmitGrids}
                    columnSpec={columnSpec}
                    blurButtons={blurButtons}
                    hideUpDownWarningButtons={hideUpDownWarningButtons}
                />
                <UndoButton
                    handleUndo={handleUndo}
                    isUndoButtonMaskPageDisabled={isUndoButtonMaskPageDisabled}
                />
                <AutoMerge
                    pathname={pathname}
                    selectedPageUrl={selectedPageUrl}
                    selectedRect={selectedRect}
                    backEndSubmitGrids={backEndSubmitGrids}
                    clearRectFunction={clearRectFunction}
                    blurButtons={blurButtons}
                    setAutoMergeColsRows={setAutoMergeColsRows}
                    setRunAutoMerge={setRunAutoMerge}
                    setUndoAutoGrid={setUndoAutoGrid}
                    displayMergeButtons={displayMergeButtons}
                    setDisplayMergeButtons={setDisplayMergeButtons}
                    isAutoMergeButtonDisabled={isAutoMergeButtonDisabled}
                    setIsAutoMergeButtonDisabled={setIsAutoMergeButtonDisabled}
                    setShowCopyButtons={setShowCopyButtons}
                />
                {allDocumentWarnings.length !== 0 ? (
                    <WarningButton
                        blurButtons={blurButtons}
                        displayUpDownWarningButtons={displayUpDownWarningButtons}
                        setDisplayUpDownWarningButtons={
                            setDisplayUpDownWarningButtons
                        }
                        navigateThroughWarningsUp={navigateThroughWarningsUp}
                        navigateThroughWarningsDown={navigateThroughWarningsDown}
                    />
                ) : null}
            </div>
        </>
    );
};

export default MaskPageFileToolbar;
