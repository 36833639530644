import { Column, Row } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountPageSubscriptionDashboard from "../../components/account-page-components/account-page-dashboard/account-page-dashboard-subscription/account-page-dashboard-subscription.component";
import ManageAccountDashboard from "../../components/account-page-components/account-page-dashboard/account-page-manage-account/account-page-manage-account.component";
import MIDashboard from "../../components/account-page-components/account-page-dashboard/account-page-mi-dashboard/account-page-mi-dashboard.component";
import AccountPageSideNavBar from "../../components/account-page-components/account-page-side-nav-bar/account-page-side-nav-bar.component";
import HeaderComponent from "../../components/header/header.component";
import InlineNotificationError from "../../components/inline-notification/inline-notification-error.component";
import InlineNotificationSuccess from "../../components/inline-notification/inline-notification-success.component";
import { configureInAppApiErrors } from "../../helpers/helper-functions-2.js";
import { clearApiError } from "../../redux/api/api.actions";
import { setAuthMessage } from "../../redux/auth/auth.actions";
import {
    getCurrentUserAction,
    getProjectList,
} from "../../redux/thunks/thunks.actions";
import "./account-page.styles.scss";

const AccountPage = () => {
    const dispatch = useDispatch();

    const authMessage = useSelector((state) => state.auth.authMessage);
    const loginError = useSelector((state) => state.auth.loginError);
    const apiErrors = useSelector((state) => state.api.apiError);
    const userInfoState = useSelector((state) => state.user.user);

    const [userInfo, setUserInfo] = useState(true);
    const [isSubscriptionBeingDisplayed, setIsSubscriptionBeingDisplayed] =
        useState(true);
    const [isMIBeingDisplayed, setIsMIBeingDisplayed] = useState(false);
    const [isManageAccountBeingDisplayed, setIsManageAccountBeingDisplayed] =
        useState(false);

    const [errorToDisplay, setErrorToDisplay] = useState("");
    useState(true);

    useEffect(() => {
        dispatch(getCurrentUserAction());
        dispatch(getProjectList());
    }, [dispatch]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (apiErrors) {
                setTimeout(() => {
                    dispatch(clearApiError());
                    setErrorToDisplay("");
                }, 5000);
            }
            if (authMessage) {
                setTimeout(() => {
                    dispatch(setAuthMessage(null));
                }, 5000);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [apiErrors, authMessage, dispatch]);

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            if (userInfoState) {
                setUserInfo(userInfoState);
            }
        }

        return () => {
            unmounted = true;
        };
    }, [userInfoState]);

    useEffect(() => {
        if (apiErrors !== null) {
            let editedErrors = configureInAppApiErrors(apiErrors);
            let notificationSubTitle = editedErrors[1];
            setErrorToDisplay(notificationSubTitle);
        }
    }, [apiErrors]);

    return (
        <>
            <div
                className="bx--grid--full-width page-layout"
                data-testid="account-page-dashboard"
            >
                <HeaderComponent isLogin={false} displayNavOptions={true} isAccountPage={true} />
                <Row className="row" style={{ overflow: "hidden" }}>
                    <Column sm={2} md={3} lg={3} className="file-explorer-box">
                        <div className="inline-notification-account">
                            {errorToDisplay !== "" ? (
                                <InlineNotificationError
                                    loginError={errorToDisplay}
                                    errorType="api-error"
                                    pageLocation="account"
                                />
                            ) : null}
                            {authMessage ? (
                                <InlineNotificationSuccess
                                    authMessage={authMessage}
                                    pageLocation="account"
                                />
                            ) : null}
                        </div>
                        <AccountPageSideNavBar
                            isSubscriptionBeingDisplayed={
                                isSubscriptionBeingDisplayed
                            }
                            setIsSubscriptionBeingDisplayed={
                                setIsSubscriptionBeingDisplayed
                            }
                            isMIBeingDisplayed={isMIBeingDisplayed}
                            setIsMIBeingDisplayed={setIsMIBeingDisplayed}
                            isManageAccountBeingDisplayed={
                                isManageAccountBeingDisplayed
                            }
                            setIsManageAccountBeingDisplayed={
                                setIsManageAccountBeingDisplayed
                            }
                        />
                    </Column>
                    {isManageAccountBeingDisplayed ? (
                        <Column
                            sm={2}
                            md={4}
                            lg={12}
                            className="dashboard-box scroll"
                        >
                            <ManageAccountDashboard
                                userInfo={userInfo}
                                loginError={loginError}
                            />
                        </Column>
                    ) : null}
                    {isSubscriptionBeingDisplayed ? (
                        <Column sm={2} md={4} lg={12} className="dashboard-box">
                            <AccountPageSubscriptionDashboard
                                userInfo={userInfo}
                            />
                        </Column>
                    ) : null}
                    {isMIBeingDisplayed ? (
                        <Column sm={2} md={4} lg={12} className="dashboard-box">
                            <MIDashboard userInfo={userInfo} />
                        </Column>
                    ) : null}
                </Row>
            </div>
        </>
    );
};

export default AccountPage;
