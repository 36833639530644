import "@stripe/stripe-js";
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import PrivateRoute from "./helpers/__auth__/private-route.component";
import AccountPage from "./pages/account/account.page";
// import CategorisationPage from "./pages/categorisation/categorisation.page";
import ForgotPasswordPage from "./pages/forgot-password/forgot-password.page";
import HomePage from "./pages/homepage/homepage.page";
import LoginPage from "./pages/login/login.page.jsx";
import MaskPage from "./pages/mask/mask.page";
// import PricingPlansPage from "./pages/pricing_plans.page.jsx";
import RegistrationPage from "./pages/registration/registration.page";
import ResultsPage from "./pages/results/results.page";
import VerifyAccountPage from "./pages/verify-account/verify-account.page";


if (window.location.hostname === process.env.REACT_APP_AZURE_FRONTEND_DOMAIN) {
    // Need to set up a host of varaibles here, need some kind of lookup table
    // All the oauth stuff, redirectSignIn/Out, cognito domain
    Amplify.configure({
        aws_cognito_region: process.env.REACT_APP_AZURE_AWS_COGNITO_REGION,
        aws_user_pools_id: process.env.REACT_APP_AZURE_AWS_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id:
            process.env.REACT_APP_AZURE_AWS_COGNITO_APP_CLIENT_ID,
        // TODO This will need to be conditionally set based on domain
        oauth: {
                domain: process.env.REACT_APP_AZURE_AWS_COGNITO_DOMAIN,
                scope: ['openid', 'email'],
                redirectSignIn: process.env.REACT_APP_AZURE_FRONTEND_ENDPOINT,
                redirectSignOut: process.env.REACT_APP_AZURE_FRONTEND_ENDPOINT + '/login',
                responseType: 'code',
                logoutUrls: [process.env.REACT_APP_AZURE_FRONTEND_ENDPOINT + '/login']
            },
        
    });
} else {
    Amplify.configure({
        aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
        aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id:
            process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID,        
    });
}


const App = (props) => {
    useEffect(() => {
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
        });
    }, []);

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/verify-account" element={<VerifyAccountPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            {/* <Route path="/pricing_plans" element={<PricingPlansPage />} /> */}
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <HomePage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/mask/*"
                element={
                    <PrivateRoute>
                        <MaskPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/results/*"
                element={
                    <PrivateRoute>
                        <ResultsPage />
                    </PrivateRoute>
                }
            />
            {/* <Route
                path="/categorisation/*"
                element={
                    <PrivateRoute>
                        <CategorisationPage />
                    </PrivateRoute>
                }
            /> */}
            <Route
                path="/account"
                element={
                    <PrivateRoute>
                        <AccountPage />
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default App;
