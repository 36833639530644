import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
    setLoginError,
    setProgressToNextStep,
} from "../../redux/auth/auth.actions";

export const PrivateRoute = ({ children, redirectTo }) => {
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.auth.authToken);
    const user = useSelector((state) => state.user.user);
    const loginError = useSelector((state) => state.auth.loginError);
    const progressToNextStep = useSelector(
        (state) => state.auth.progressToNextStep
    );

    const redirectToLogin = <Navigate to="/login" />;

    useEffect(() => {
        if (progressToNextStep) {
            setTimeout(() => {
                dispatch(setProgressToNextStep(null));
            }, 5000);
        }
    }, [progressToNextStep, dispatch]);

    if (authToken) {
        return authToken ? children : <Navigate to={redirectTo} />;
    } else {
        if (user !== null) {
            if (loginError === null) {
                dispatch(
                    setLoginError(
                        "You have already registered. Please Log In"
                    )
                );
            }
        }
        return redirectToLogin;
    }
};

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
};

export default PrivateRoute;
