import {
    Download32,
    Reset16,
    RowInsert16,
    Save16,
    SplitScreen16,
    TrashCan16,
    Undo16,
    ArrowsHorizontal16,
    FitToWidth16,
} from "@carbon/icons-react";
import { Button } from "carbon-components-react";
import React from "react";
import ResultsDataTableToolbarValidationChecks from "./data-table-toolbar-validation-checks.component";

const ResultsDataTableToolbarButtons = ({
    addRow,
    deleteRow,
    handleUndo,
    showUndoButton,
    onSplitViewVertical,
    onSplitViewHorizontal,
    onArrowUp,
    onArrowDown,
    totalValidationChecks,
    currentValidationCheckInput,
    onDeselectAllRows,
    multipleRowsSelected,
    handleDownloadAndSave,
    splitViewVertical,
    splitViewHorizontal,
    filteringTable,
    switchCreditDebit,
    showSaveButton,
    adjustColumnWidth,
}) => {
    return (
        <>
            <div
                className={`flex-direction-row 
                    margin-right 
                    results-page-data-table-toolbar-buttons`}
            >
                <ResultsDataTableToolbarValidationChecks
                    onArrowUp={onArrowUp}
                    onArrowDown={onArrowDown}
                    totalValidationChecks={totalValidationChecks}
                    currentValidationCheckInput={currentValidationCheckInput}
                    filteringTable={filteringTable}
                />
                {multipleRowsSelected ? (
                    <Button
                        renderIcon={Reset16}
                        className="icon-button-dashboard"
                        data-testid="results-page-deselect-all-rows-button"
                        id="results-page-deselect-all-rows-button"
                        iconDescription="Deselect All Rows"
                        hasIconOnly
                        onClick={onDeselectAllRows}
                    />
                ) : null}

                <Button
                    renderIcon={Download32}
                    className="icon-button-dashboard"
                    data-testid="results-page-download-button"
                    id="results-page-download-button"
                    iconDescription="Download"
                    hasIconOnly
                    onClick={() => handleDownloadAndSave("download")}
                    size="lg"
                />
                <Button
                    renderIcon={Save16}
                    // className="icon-button-dashboard"
                    className={`icon-button-dashboard 
                        ${showSaveButton ? "icon-button-save-available" : ""}`}
                    data-testid="results-page-save-button"
                    id="results-page-save-button"
                    iconDescription={showSaveButton ? "You have unsaved changes" : "Save"}
                    hasIconOnly
                    onClick={() => handleDownloadAndSave("save")}
                />
                <Button
                    renderIcon={ArrowsHorizontal16}
                    className="icon-button-dashboard"
                    data-testid="results-page-switch-credit-debits"
                    id="results-page-switch-credit-debit-button"
                    iconDescription="Swap Credits/Debits"
                    hasIconOnly
                    onClick={switchCreditDebit}
                />
                <Button
                    renderIcon={RowInsert16}
                    className="icon-button-dashboard"
                    data-testid="results-page-add-row-button"
                    id="results-page-add-row-button"
                    iconDescription="Add Row"
                    hasIconOnly
                    onClick={addRow}
                />
                <Button
                    renderIcon={TrashCan16}
                    className="icon-button-dashboard"
                    data-testid="results-page-delete-row-button"
                    id="results-page-delete-row-button"
                    iconDescription="Delete Row"
                    hasIconOnly
                    onClick={deleteRow}
                />
                <Button
                    renderIcon={FitToWidth16}
                    className="icon-button-dashboard"
                    data-testid="results-page-fit-to-width-button"
                    id="results-page-fit-to-width-button"
                    iconDescription="Fit columns to width"
                    hasIconOnly
                    onClick={adjustColumnWidth}
                />
                <Button
                    renderIcon={Undo16}
                    className={`icon-button-dashboard 
                        ${showUndoButton ? "" : "clicked-button"}`}
                    data-testid="results-page-undo-button"
                    id="results-page-undo-button"
                    iconDescription="Undo"
                    hasIconOnly
                    disabled={showUndoButton ? false : true}
                    onClick={handleUndo}
                />
                <Button
                    renderIcon={SplitScreen16}
                    className={`icon-button-dashboard ${splitViewVertical ? "clicked-button" : ""
                        }`}
                    data-testid="results-page-split-view-vertical-button"
                    id="results-page-split-view-vertical-button"
                    iconDescription="Split View Vertical"
                    hasIconOnly
                    onClick={onSplitViewVertical}
                />
                <Button
                    className={`icon-button-dashboard ${splitViewHorizontal ? "clicked-button" : ""
                        }`}
                    data-testid="results-page-split-view-horizontal-button"
                    id="results-page-split-view-horizontal-button"
                    iconDescription="Split View Horizontal"
                    hasIconOnly
                    onClick={onSplitViewHorizontal}
                >
                    <SplitScreen16 className="rotate-90" />
                </Button>
                {/* <Button
                    renderIcon={Category16}
                    className="icon-button-dashboard"
                    data-testid="results-page-categorise-button"
                    id="results-page-categorise-button"
                    iconDescription="Categorise"
                    hasIconOnly
                    onClick={onCategorise}
                /> */}
            </div>
        </>
    );
};

export default ResultsDataTableToolbarButtons;
